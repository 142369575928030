import React, { useContext, useEffect, useState, useCallback } from "react";

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { ICompany, useCompanyService } from "services";
import { TextFieldFormik } from "components/fields";

type Props = {
    id: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void,
    [key: string]: any;
};

const companyBlank: Partial<ICompany> = {
    id: "",
    name: "",
    version: 0
};

const formValidationSchema = Yup.object({
    name: Yup.string()
        .max(50, "Must be at most 50 characters")
        .required("Name is a required field"),
});

export default function CreateEditModal(props: Props) {
    const companyService = useCompanyService();
    const { id, isVisible, setIsVisible, onSuccess } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ company, setCompany ] = useState({ ...companyBlank });

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSubmit = (values: Partial<ICompany>, { setSubmitting, setErrors }: FormikHelpers<Partial<ICompany>>) => {
        if (id) {
            setIsLoading(true);
            companyService.update(id, values).then((company) => {
                setCompany({
                    id: company.id,
                    name: company.name,
                    version: company.version,
                });
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => setSubmitting(false));
        } else {
            setIsLoading(true);
            companyService.create({ name: values.name }).then((company) => {
                setCompany({
                    id: company.id,
                    name: company.name,
                    version: company.version,
                });
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => setSubmitting(false));
        }
    };

    useEffect(() => {
        setCompany({ ...companyBlank });

        if (id) {
            setIsLoading(true);
            companyService.get(id).then((company) => {
                setCompany({
                    id: company.id,
                    name: company.name,
                    version: company.version,
                });
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
                onCancel();
            });
        }
    }, [ id ]);

    if (!isVisible)
        return null;

    return (
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <Formik<Partial<ICompany>>
                enableReinitialize={true}
                initialValues={company}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<Partial<ICompany>>) => (
                    <FormControl as="form" onSubmit={formik.handleSubmit as any}>
                        <ModalContent bg="cardBG" color="text">
                            <ModalHeader>{!id ? "Create Company" : "Edit Company"}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TextFieldFormik
                                    label="Name"
                                    type="text"
                                    name="name"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Name"
                                    required={true}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    isLoading={isLoading}
                                    variant="secondary"
                                    onClick={onCancel}
                                >Cancel</Button>
                                <Button
                                    isLoading={isLoading}
                                    variant="primary"
                                    ml={3}
                                    type="submit"
                                >{!id ? "Create" : "Save"}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </FormControl>
                )}
            </Formik>
        </Modal>
    );
}
