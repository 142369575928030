/*
    Routes used for constructing menu in Sidebar and Navbar components
*/
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
    MdBarChart,
    MdAssignment,
    MdHome,
    MdDescription,
    MdArticle
} from "react-icons/md";

import { TbTable } from "react-icons/tb";

import { AiOutlineAudit } from "react-icons/ai";
import { RiUserSettingsFill } from "react-icons/ri";
import { FaHammer, FaHospitalUser, FaFileSignature, FaInfoCircle } from "react-icons/fa";
import { BsFileMedicalFill, BsShieldFillCheck } from "react-icons/bs";
import { MdAccountBalanceWallet } from "react-icons/md";

import { IMenuRoute } from "./route.interface";
import { Role } from "services";
import {
    IoBuildOutline,
    IoBusiness,
    IoCard,
    IoFileTrayFull,
    IoKey,
    IoPeople,
    IoPerson,
    IoShare,
    IoWallet
} from "react-icons/io5";
import { BiSupport } from "react-icons/bi";

export const menuRoutes: IMenuRoute[] = [
    {
        name: "Home",
        path: "/app/default",
        icon: <Icon as={MdHome} width="20px" height="20px" margin={"auto"} />,
    },
    {
        name: "Dashboard",
        path: "/app/dashboard",
        icon: <Icon as={MdBarChart} width="20px" height="20px" margin={"auto"} />,
        allowedRoles: [ Role.ConvenetUser ],
        superuser: true,
    },
    {
        name: "API Documentation",
        path: "/convenet-documentation",
        url: process.env.REACT_APP_API_DOC_URL,
        icon: <Icon as={MdDescription} width="20px" height="20px" margin={"auto"} />,
        allowedRoles: [ Role.Developer, Role.ConvenetUser ],
    },
    {
        name: "Developer Documentation",
        path: "/developer-documentation",
        url: process.env.REACT_APP_WIKI_URL,
        icon: <Icon as={MdArticle} width="20px" height="20px" margin={"auto"} />,
        allowedRoles: [ Role.Developer, Role.ConvenetUser ],
    },
    {
        name: "Tokenisation",
        icon: (
            <Icon
                as={FaInfoCircle}
                width="15px"
                height="15px"
                margin={"auto"}
            />
        ),
        path: "/app/tokenisation",
        allowedRoles: [ Role.Developer, Role.ConvenetUser ],
    },
    {
        name: "Manage Product Review",
        path: "/app/product-review",
        icon: <Icon as={TbTable} width="20px" height="20px" margin={"auto"} />,
        allowedRoles: [ Role.ConvenetUser ],
    },
    {
        name: "Building an Integration",
        path: "",
        icon: (
            <Icon as={IoBuildOutline} width="20px" height="20px" margin={"auto"} />
        ),
        children: [
            // Check Suitability
            {
                name: "Check Suitability",
                icon: (
                    <Icon
                        as={BsShieldFillCheck}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/check-suitable",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            // Test Patients
            {
                name: "Test Patients",
                icon: (
                    <Icon
                        as={IoPerson}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/test-patients",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            // Test GPs
            {
                name: "Test GPs",
                icon: (
                    <Icon
                        as={BsFileMedicalFill}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/test-gps",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Develop",
                icon: (
                    <Icon
                        as={FaHammer}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/develop",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "API Credentials",
                icon: (
                    <Icon
                        as={IoKey}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/api-credentials",
                allowedRoles: [ Role.Developer ],
            },
            {
                name: "Your Team",
                icon: (
                    <Icon
                        as={IoPeople}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/team",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Your Product",
                icon: (
                    <Icon
                        as={IoFileTrayFull}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/product",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Your Company",
                icon: (
                    <Icon
                        as={IoBusiness}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/company",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Submit for Review",
                icon: (
                    <Icon
                        as={IoShare}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/submit",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Sign Contract",
                icon: (
                    <Icon
                        as={FaFileSignature}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/sign-contract",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            },
            {
                name: "Chat to an Expert",
                icon: (
                    <Icon
                        as={BiSupport}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/convenet-integration/contact-expert",
                allowedRoles: [ Role.Developer, Role.ConvenetUser ],
            }
        ],
    },
    {
        name: "Billing",
        path: "",
        icon: (
            <Icon as={MdBarChart} width="20px" height="20px" margin={"auto"} />
        ),
        allowedRoles: [ Role.Developer, Role.ConvenetUser ],
        children: [
            {
                name: "Sign Up Fee",
                icon: (
                    <Icon
                        as={IoCard}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/billing/sign-up-fee",
            },
            {
                name: "API Subscription",
                icon: (
                    <Icon
                        as={IoWallet}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/billing/api-subscription",
            },
            {
                name: "Payment History",
                icon: (
                    <Icon
                        as={MdAccountBalanceWallet}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/billing/payment-history",
            }
        ],
    },
    {
        name: "System Administration",
        path: "",
        icon: (
            <Icon as={MdBarChart} width="20px" height="20px" margin={"auto"} />
        ),
        allowedRoles: [ Role.ConvenetUser ],
        children: [
            {
                name: "Auth Audit",
                icon: (
                    <Icon
                        as={AiOutlineAudit}
                        width="20px"
                        height="20px"
                        margin={"auto"}
                    />
                ),
                path: "/app/auth-audit",
            },
            {
                name: "Manage Companies",
                icon: (
                    <Icon
                        as={MdBarChart}
                        width="20px"
                        height="20px"
                        margin={"auto"}
                    />
                ),
                path: "/app/companies",
            },
            {
                name: "Manage Contracts",
                icon: (
                    <Icon
                        as={MdAssignment}
                        width="20px"
                        height="20px"
                        margin={"auto"}
                    />
                ),
                path: "/app/contracts",
            },
            {
                name: "Manage Users",
                icon: (
                    <Icon
                        as={RiUserSettingsFill}
                        width="15px"
                        height="15px"
                        margin={"auto"}
                    />
                ),
                path: "/app/users",
            },
            {
                name: "Manage Test Patients",
                icon: (
                    <Icon
                        as={FaHospitalUser}
                        width="20px"
                        height="20px"
                        margin={"auto"}
                    />
                ),
                path: "/app/patient", // TODO
            },
            {
                name: "Manage GPs",
                icon: (
                    <Icon
                        as={BsFileMedicalFill}
                        width="20px"
                        height="20px"
                        margin={"auto"}
                    />
                ),
                path: "/app/gp",
            },
        ],
    },
];
