import React, { useEffect, useState, memo } from "react";

import { Checkbox, Flex } from "@chakra-ui/react";
import { useField } from "formik";
import { useFormikContext } from "formik";
import { ListOfMedicalRecordsEnum } from "./patient-details/CreateEditPatientModal";

const MyCheckboxDependent: React.FC<any> = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const { label, name, flag } = props;

    const {
        values: { medical_record_enabled, list_of_medical_records_enabled },
        setFieldValue
    } = useFormikContext<any>();

    const [ field ] = useField({ name: props.name, type: "checkbox" });
    const [ checked, setChecked ] = useState(false);

    useEffect(() => {
        if (field.value != undefined) {
            setChecked(field.value);
            setFieldValue(name, field.value);
            setFieldValue("list_of_medical_records_enabled",
                (list_of_medical_records_enabled & ~(1 << flag)) | (+field.value << flag)
            );
        } else {
            if (list_of_medical_records_enabled & (1 << flag)) {
                setFieldValue(name, true);
                setChecked(true);
            }
        }

    }, [ field.value, flag, name, list_of_medical_records_enabled ]);

    return (
        <Flex direction="column">
            <Checkbox
                isChecked={checked && medical_record_enabled}
                isDisabled={!medical_record_enabled}
                {...field}
                {...props}
            >
                {label}
            </Checkbox>
        </Flex>
    );
};

export default memo(MyCheckboxDependent);
