import { ApiScopeEnum } from "./api-client.interface";

export interface IIntegrationProductReview {
    id: string;
    integration_product: string;
    pentest_completed: boolean;
    nhs_approval: boolean;
    sign_up_fee_option: SignUpFeeOptionChoice;
    sign_up_fee: string;
    subscriptions: string[]; // TODO
    subscription_option: SubscriptionOptionChoice;
    contract_templates: any[];
    scopes: ApiScopeEnum[];
}

export enum SignUpFeeOptionChoice {
    standard = 0,
    waive = 1,
    bespoke = 2
}

export enum SubscriptionOptionChoice {
    standard = 0,
    bespoke = 1
}
