import { create } from "zustand";

import {
    IUserMe,
    IPermission,
    ICompany,
    IIntegrationProduct,
    IntegrationProductStatusChoice
} from "services";

interface IAppState {
    pageTitle: string;
    setPageTitle: (title: string) => void;

    userData: IUserMe;
    permissions: IPermission[];

    company: ICompany;
    integrationProduct: IIntegrationProduct;

    showUpdateBanner: boolean;
    newversion: string;

    // Develop Card states
    isSuitable: boolean;
    apiCredentialsGenerated: boolean;
    setApiCredentialsGenerated: (value: boolean) => void,

    // Prepare for Review card states
    completeProductDetailsTick: boolean;
    signUpFeePaidTick: boolean;
    signUpFeeWaived: boolean;
    setSignUpFeeWaived: (value: boolean) => void,
    informationGovernanceTick: boolean;
    isSubmittedForReview: () => boolean;

    // GoLive card states
    isProductLive: boolean;
    pentestCompletedTick: boolean;
    nhsApprovalTick: boolean;
    liveCredentialsGenerated: boolean;
    setLiveCredentialsGenerated: (value: boolean) => void;

    // version errors
    versionError: boolean;
    setVersionError: (value: boolean) => void;
}

export const useAppStore = create<IAppState>((set, get) => ({
    pageTitle: "Convenet Portal",
    setPageTitle: (title: string) => set({ pageTitle: title }),

    userData: {} as any,
    permissions: [],

    company: {} as ICompany,
    integrationProduct: {} as IIntegrationProduct,

    showUpdateBanner: false,
    newversion: process.env.REACT_APP_VERSION || "",

    // Develop Card states
    isSuitable: false,
    apiCredentialsGenerated: false,
    setApiCredentialsGenerated: (value: boolean) => set({ apiCredentialsGenerated: value }),

    // Prepare for Review card states
    completeProductDetailsTick: false,
    signUpFeePaidTick: false,
    signUpFeeWaived: false,
    setSignUpFeeWaived: (value: boolean) => set({ signUpFeeWaived: value }),
    informationGovernanceTick: false,
    isSubmittedForReview: () => {
        if (get().signUpFeeWaived)
            return get().completeProductDetailsTick && get().informationGovernanceTick;
        return get().completeProductDetailsTick && get().signUpFeePaidTick && get().informationGovernanceTick;
    },

    // GoLive card states
    isProductLive: false,
    pentestCompletedTick: false,
    nhsApprovalTick: false,
    liveCredentialsGenerated: false,
    setLiveCredentialsGenerated: (value: boolean) => set({ liveCredentialsGenerated: value }),

    versionError: false,
    setVersionError: (value: boolean) => {
        sessionStorage.setItem("versionError", String(value));
        set({ versionError: value });
    },
}));
