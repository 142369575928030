import React, { useState, useMemo } from "react";
import {
    Text,
    IconButton,
    Button,
    Box,
    Flex,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from "@chakra-ui/react";
import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
    IFiltersActions,
    SortOrder,
} from "components/paged-grid";

import {
    IPermAction,
    IAuthLog,
    useAuthLogService,
    useUserService,
    useApiClientService,
    ApiScopeEnum,
    ApiClientEnv,
    IApiClient
} from "services";
import BoxCard from "components/card/Card";
import { format } from "date-fns";

export default function AuthAudit() {
    const authLogService = useAuthLogService();
    const userService = useUserService();
    const [ reloadKey, setReloadKey ] = useState(0);
    const [ filterEmail, setFilterEmail ] = useState("");
    const [ filterCompanyName, setFilterCompanyName ] = useState("");
    const reloadGrid = () => setReloadKey((value) => value + 1);

    const columns: GridColumn[] = useMemo(() => [
        {
            field: "id",
            header: "ID",
            getComponent: (row: Row<IAuthLog>) => {
                return <Text as="span">{row.data.id}</Text>;
            },
        },
        {
            field: "user_email",
            header: "Email",
            sortable: true,
        },
        {
            field: "remote_address",
            header: "IP",
            sortable: true
        },
        {
            field: "event",
            header: "Event",
            sortable: true,
            getComponent: (row: Row<IAuthLog>) => {
                return <Text as="span">{authLogService.getAuthEventMessage(row.data.event)}</Text>;
            },
        },
        {
            field: "",
            header: "Company",
            getComponent: (row: Row<IAuthLog>) => {
                return <Text as="span">{row.data.user?.company_name}</Text>;
            },
        },
        {
            field: "timestamp",
            header: "Timestamp",
            sortable: true,
            sortDir: SortOrder.Desc,
            getComponent: (row: Row<IAuthLog>) => {
                return <Text as="span">{format(new Date(row.data.timestamp), "yyyy-MM-dd HH:mm:ss OOOO")}</Text>;
            },
        }
    ], [ authLogService ]);

    const filters: IFilterSettings[] = [
        {
            field: "user_email",
            match: () => "icontains",
            getValue: () => filterEmail, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Email"
                    value={filterEmail}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterEmail(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterEmail(value); }}
                ></Input>
            </Flex>
        },
        {
            field: "user__company__name", // user.company.name
            match: () => "icontains",
            getValue: () => filterCompanyName, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Company Name"
                    value={filterCompanyName}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterCompanyName(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterCompanyName(value); }}
                ></Input>
            </Flex>
        },
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setFilterEmail("");
            setFilterCompanyName("");
            reloadGrid();
        },
        onApply: () => reloadGrid()
    };

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<IAuthLog>
                    reloadKey={reloadKey}
                    columns={columns}
                    dataService={authLogService}
                    //rowActions={rowActions}
                    //globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    //rowPreview={rowPreview}
                    filters={filters}
                    filtersActions={filtersActions}
                />
            </BoxCard>
        </Box>
    );
}
