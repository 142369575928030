import React, { useContext } from "react";
// Chakra Imports
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode,
    Box,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/SearchBar";
// Assets
import { MdNotificationsNone, MdInfoOutline } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";

import { AppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { IoMenuOutline, IoPerson, IoPersonCircleOutline } from "react-icons/io5";
import { SidebarContext } from "../../contexts/SidebarContext";
import { ProfileIcon } from "views/admin/convenet-integration/FigmaIcons";
import { useAuthService, useUserService } from "services";

export type HeaderLinksProps = {
    secondary: boolean;
};

export default function NavbarMenu(props: HeaderLinksProps) {
    const { secondary } = props;
    const navigate = useNavigate();
    const authService = useAuthService();
    const { userData } = useUserService();
    const { setIsLoggedIn } = useContext(AppContext);

    const userName = `${userData.first_name || ""} ${userData.last_name || ""}`;

    // Chakra Color Mode
    const navbarIcon = useColorModeValue("gray.400", "white");
    const textColor = useColorModeValue("darkText", "lightText");
    // const fieldBgColor = useColorModeValue("lightFieldBackground", "darkFieldBackground");
    const textColorBrand = useColorModeValue("brand.700", "brand.400");
    const ethColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue(
        "mainBackground",
        "rgba(135, 140, 189, 0.3)"
    );
    const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
    const ethBox = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const borderButton = useColorModeValue(
        "secondaryGray.500",
        "whiteAlpha.200"
    );

    const handleLogOut = () => {
        authService.logout().then(() => {
            setIsLoggedIn(false);
        });
    };

    const handleShowProfile = () => {
        navigate("/app/profile");
    };

    const menuColor = useColorModeValue("gray.400", "white");

    const { onOpen } = useContext(SidebarContext);

    return (
        <Flex
            w={{ sm: "100%", md: "auto" }}
            alignItems="center"
            flexDirection="row"
            flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
            p="10px"
        >
            {/* Sidebar Drawer Open Button */}
            <Icon
                as={IoMenuOutline}
                color={menuColor}
                my="auto"
                display={{ sm: "100%", xl:"none" }}
                w="20px"
                h="20px"
                me="10px"
                onClick={onOpen}
                _hover={{ cursor: "pointer" }}
            />



            {/* <SearchBar
                mb={secondary ? { base: "10px", md: "unset" } : "unset"}
                me="10px"
                borderRadius="30px"
            /> */}
            {/* <Menu>
                <MenuButton p="0px">
                    <Icon
                        mt="6px"
                        as={MdNotificationsNone}
                        color={navbarIcon}
                        w="18px"
                        h="18px"
                        me="10px"
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="20px"
                    borderRadius="20px"
                    bg="menuBG"
                    border="none"
                    mt="22px"
                    me={{ base: "30px", md: "unset" }}
                    minW={{ base: "unset", md: "400px", xl: "450px" }}
                    maxW={{ base: "360px", md: "unset" }}
                >
                    <Flex justifyItems="space-between" w="100%" mb="20px">
                        <Text fontSize="md" fontWeight="600" color="menuText">
                            Notifications
                        </Text>
                        <Text
                            fontSize="sm"
                            fontWeight="500"
                            color={textColorBrand}
                            ms="auto"
                            cursor="pointer"
                        >
                            Mark all read
                        </Text>
                    </Flex>
                    <Flex flexDirection="column">
                        <MenuItem
                            _hover={{ bg: "none" }}
                            _focus={{ bg: "none" }}
                            px="0"
                            borderRadius="8px"
                            mb="10px"
                        >
                            <ItemContent
                                info="Horizon UI Dashboard PRO"
                                aName="Alicia"
                            />
                        </MenuItem>
                        <MenuItem
                            _hover={{ bg: "none" }}
                            _focus={{ bg: "none" }}
                            px="0"
                            borderRadius="8px"
                            mb="10px"
                        >
                            <ItemContent
                                info="Horizon Design System Free"
                                aName="Josh Henry"
                            />
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>*/}


            <Menu>
                <MenuButton borderColor="BOD.5" borderRadius="50%" overflow="hidden" height="24px" width="24px">
                    <ProfileIcon color="profileIcon" />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg="menuBG"
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="text.sm"
                            fontWeight="700"
                            color="menuText"
                        >
                            {userName}
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        <MenuItem
                            bg="inherit"
                            color="menuText"
                            _hover={{ bg: "themeBG", color: "text" }}
                            borderRadius="8px"
                            px="14px"
                            onClick={handleShowProfile}
                        >
                            <Text fontSize="text.sm">User Profile</Text>
                        </MenuItem>
                        <MenuItem
                            bg="inherit"
                            _hover={{ bg: "themeBG" }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                            onClick={() => handleLogOut()}
                        >
                            <Text fontSize="text.sm">Log out</Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}
