export enum ApiScopeEnum {
    Patient = "patient",
    Consultation = "consultation",
    Pds = "pds"
}

export enum ApiClientEnv {
    test = 0,
    live = 1
}

export interface IApiClient {
    id: string;
    company: string; // UUID
    client_id: string;
    is_valid: boolean;
    environment: ApiClientEnv;
    client_secret?: string;
    scopes: ApiScopeEnum[];
}
