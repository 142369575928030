import React, { useContext, useState, useReducer, useRef, createContext } from "react";

import {
    Button,
    Box,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from "@chakra-ui/react";

type Props = AlertProps & {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
};

export function AlertModal(props: Props) {
    const {
        isVisible,
        setIsVisible,
        onSuccess,
        onFail,
        header = "Alert",
        message = "Are you sure?",
        yes = "Yes",
        no = "No",
    } = props;

    const cancelRef = useRef<any>();

    const onYes = () => {
        setIsVisible(false);
        onSuccess?.();
    };
    const onNo = () => {
        setIsVisible(false);
        onFail?.();
    };

    return (
        <AlertDialog isOpen={isVisible} onClose={onNo} leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {header}
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {message}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        {no != "null" && <Button variant="secondary" mr={3} ref={cancelRef} onClick={onNo}>{no}</Button>}
                        <Button variant="primary" onClick={onYes}>{yes}</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

const AlertContext = createContext({} as any);

type AlertProps = {
    header: string;
    message: string;
    yes?: string;
    no?: string;
    onSuccess?: () => void;
    onFail?: () => void;
}

export function AlertContextProvider({ children }: any) {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ alertProps, setAlertProps ] = useState<AlertProps>({} as any);

    const alertShow = (props: AlertProps) => {
        setAlertProps(props);
        setIsOpen(true);
    };

    return (
        <AlertContext.Provider value={{ alertShow }}>
            {children}
            <AlertModal
                isVisible={isOpen}
                setIsVisible={setIsOpen}
                {...alertProps}
            />
        </AlertContext.Provider>
    );
}

export function useAlert(): (props: AlertProps) => void {
    const { alertShow } = useContext(AlertContext);

    return alertShow;
}
