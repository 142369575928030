import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Skeleton,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import BoxCard from "components/card/Card";

import { useGPService, IGP } from "services";
import EditGPDetails from "./EditGPDetails";
import AppointmentSlots from "./AppointmentSlots";
import Appointments from "views/admin/patients/components/appointments/Appointments";

function LabelItem(props: { label: string, w?: any, children?: any }) {
    const { label, children, w = "33.3%" } = props;

    return (
        <Flex w={{ base: "100%", md: "50%", xl: "33.3%" }} mb={5}>
            <Flex direction="column" minW="10.75rem">
                <Text fontWeight="bold">{label}</Text>
            </Flex>
            <Flex direction="column" color="gray.500">
                {children}
            </Flex>
        </Flex>
    );
}

export default function TestGPDetails() {
    const { gpId } = useParams();
    const gpService = useGPService();
    const [ activeGP, setActiveGP ] = useState<IGP>({} as IGP);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isVisible, setIsVisible ] = useState(false);

    useEffect(() => {
        if (!gpId)
            return;

        gpService.get(gpId).then((gp) => {
            setActiveGP(gp);
            setIsLoaded(true);
        });
    }, [ gpId ]);

    if (!isLoaded)
        return null;

    return (
        <Box mt={5}>
            <VStack spacing={5}>
                {/* TODO: create GPCard component? */}
                <Skeleton isLoaded={isLoaded} w="100%">
                    <BoxCard>
                        <Flex
                            direction="column"
                            w="100%"
                        >
                            <Flex flexWrap="wrap">
                                <LabelItem label="Practice Name">{activeGP.practice_name || "-"}</LabelItem>
                                <LabelItem label="ODS Code">{activeGP.ods_code || "-"}</LabelItem>
                                <LabelItem label="System">{activeGP.system || "-"}</LabelItem>
                            </Flex>
                            <Flex flexWrap="wrap">
                                <LabelItem label="GP Name">
                                    <Text>{activeGP.gp_title || "-"}</Text>
                                    <Text>{activeGP.gp_fornames}</Text>
                                    <Text>{activeGP.gp_surname}</Text>
                                </LabelItem>
                                <LabelItem label="GP Role">{activeGP.gp_role || "-"}</LabelItem>
                                <LabelItem label=""></LabelItem>
                            </Flex>
                            <Flex flexWrap="wrap">
                                <LabelItem label="GP Practice Address">
                                    {[ 1, 2, 3, 4, 5 ].map((i) => {
                                        const address_line = (activeGP as any)[`practice_address_line_${i}`];
                                        return (address_line || i == 1 ? <Text key={i}>{address_line || "-"}</Text> : null);
                                    })}
                                </LabelItem>
                            </Flex>
                        </Flex>
                        <Flex justify="flex-end" align="center" mt="5">
                            <Button
                                variant="secondary"
                                mr={2}
                                onClick={() => setIsVisible(true)}
                            >
                                EDIT
                            </Button>
                        </Flex>
                    </BoxCard>
                </Skeleton>
                <Skeleton isLoaded={isLoaded} w="100%">
                    <BoxCard>
                        <Tabs
                            isFitted={true}
                            m={5}
                            isLazy={true}
                        >
                            <TabList>
                                <Tab>Appointment Slots</Tab>
                                <Tab>Appointments</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <AppointmentSlots activeGP={activeGP}/>
                                </TabPanel>
                                <TabPanel>
                                    <Appointments activeGP={activeGP} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </BoxCard>
                </Skeleton>
            </VStack>

            <EditGPDetails
                id={activeGP.id}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                onSuccess={() => {}}
                onUpdate={(gp: IGP) => setActiveGP(gp)}
                gpData={activeGP}
            />
        </Box>
    );
}
