import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { IMenuRoute } from "routes";

const NavLinkItem: React.FC<{ index: number; route: IMenuRoute }> = (props) => {
    const location = useLocation();
    const brandColor = "darkFieldBackground";
    const activeBoxShadow = "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)";

    // verifies if routeName is the one active (in browser input)
    const isActive = location.pathname.includes(props.route.path);

    const children = props.route.icon ? (
        <Box>
            <HStack
                spacing={ isActive ? "22px" : "26px" }
                py="5px"
                ps="10px"
            >
                <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={ isActive ? "card" : "0px" }
                    bg={ isActive ? "activeBox" : "inherit" }
                    boxShadow={ isActive ? activeBoxShadow : "unset" }
                    h="54px"
                >
                    <Box
                        color={ isActive ? "activeIcon" : brandColor }
                        me="18px"
                        bg={ isActive ? "activeBgIcon" : "white" }
                        borderRadius="12px"
                        w={"30px"}
                        h={"30px"}
                        ml="16px"
                        display={"flex"}
                    >
                        {props.route.icon}
                    </Box>
                    <Text
                        me="auto"
                        color={ isActive ? "activeColor" : "text" }
                        fontWeight={ isActive ? "bold" : "normal" }
                        userSelect="none"
                    >
                        {props.route.name}
                    </Text>
                </Flex>
            </HStack>
        </Box>
    ) : (
        <Box>
            <HStack
                spacing={ isActive ? "22px" : "26px" }
                py="5px"
                ps="10px"
            >
                <Text
                    me="auto"
                    color={ isActive ? "activeColor" : "inactiveColor" }
                    fontWeight={ isActive ? "bold" : "normal" }
                >
                    {props.route.name}
                </Text>
                <Box
                    h="36px"
                    w="4px"
                    bg="brand.400"
                    borderRadius="5px"
                />
            </HStack>
        </Box>
    );

    // NavLink/Link don't support absolute urls, use <a> instead
    return <>
        {props.route.url ? (
            <a key={props.index} href={props.route.url} target="_blank" rel="noreferrer">
                {children}
            </a>
        ) : (
            <NavLink key={props.index} to={props.route.url || props.route.path}>
                {children}
            </NavLink>
        )}
    </>;
};

export default NavLinkItem;
