import React, { useEffect, useState } from "react";

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Flex,
    Text,
    GridItem,
    SimpleGrid,
    Box,
    Grid,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { DropDownFormik } from "components/fields";
import {
    IGP,
    useGPService,
    YesNoChoice,
    useUserService,
} from "services";

type Props = {
    id: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void;
    onUpdate?: (gp: IGP) => void;
    gpData: IGP;
};

const gpBlank: IGP = {
    id: "",
    company: "",
    ods_code: "",
    practice_name: "",
    system: "",
    appointment_booking_reason: "",
    appointments_supported: null,
    gp_fornames: "",
    gp_role: "",
    gp_surname: "",
    gp_title: "",
    medical_record_supported: null,
    post_code: "",
    practice_address_line_1: "",
    practice_address_line_2: "",
    practice_address_line_3: "",
    practice_address_line_4: "",
    practice_address_line_5: "",
    prescribing_comment: "",
    repeat_prescriptions_supported: null,
    disable_services: false,
};

const formValidationSchema = Yup.object({
    appointments_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    medical_record_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    repeat_prescriptions_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    appointment_booking_reason: Yup.string()
        .oneOf([ "notRequested", "requestedOptional", "requestedMandatory" ])
        .nullable(),
    prescribing_comment: Yup.string()
        .oneOf([ "notRequested", "requestedOptional", "requestedMandatory" ])
        .nullable(),
    disable_services: Yup.boolean().required("This field should not be blank"),
});

export default function EditGPDetails(props: Props) {
    const { id, isVisible, setIsVisible, gpData, onUpdate, onSuccess } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const userService = useUserService();
    const { userData } = userService;
    const gpService = useGPService();
    const [ isSuperUser ] = useState(userService.meIsSuperUser());
    const [ gp, setGp ] = useState<IGP>({
        ...gpBlank,
        ...(isSuperUser ? {} : { company: userData.company })
    });

    useEffect(() => {
        if (Object.entries(gpData).length > 0) {
            setGp(gpData);
        }
    }, [ id, gpData ]);

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSubmit = (values: IGP, { setSubmitting, setErrors }: FormikHelpers<IGP>) => {
        if (id) {
            setIsLoading(true);
            gpService.update(id, values).then((gp) => {
                setGp(gp);
                setIsLoading(false);
                onSuccess?.();
                onUpdate?.(gp);
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            setIsLoading(true);
            gpService.create(values).then((response) => {
                setGp(gpBlank);
                setIsLoading(false);
                onSuccess?.();
                onUpdate?.(gp);
                setIsVisible(false);
            }).catch((error) => {
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    if (!isVisible)
        return null;

    return (
        <Modal
            size={"5xl"}
            isOpen={isVisible}
            onClose={onCancel}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent bg="cardBG" color="text">
                <ModalHeader>
                    <Text color="text">{!id ? "Create GP" : "Edit GP"}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik<IGP>
                        enableReinitialize={true}
                        initialValues={gp}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<IGP>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <Grid templateColumns="repeat(2, 1fr)"
                                    color="text"
                                    fontSize="text.sm"
                                    columnGap={{ base: "5", md: "10", lg: "20" }}
                                    rowGap="5"
                                >
                                    <DropDownFormik
                                        type="number"
                                        label="Appointments Supported"
                                        name="appointments_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        type="number"
                                        label="Medical Record Supported"
                                        name="medical_record_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        type="number"
                                        label="Repeat Prescriptions Supported"
                                        name="repeat_prescriptions_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        label="Prescribing Comment"
                                        name="prescribing_comment"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={"notRequested"}>Not Requested</option>
                                        <option value={"requestedOptional"}>Requested Optional</option>
                                        <option value={"requestedMandatory"}>Requested Mandatory</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        label="Appointment Booking Reason"
                                        name="appointment_booking_reason"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={"notRequested"}>Not Requested</option>
                                        <option value={"requestedOptional"}>Requested Optional</option>
                                        <option value={"requestedMandatory"}>Requested Mandatory</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        type="boolean"
                                        label="Disable Services"
                                        name="disable_services"
                                        required
                                    >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"}>No</option>
                                    </DropDownFormik>
                                </Grid>

                                <Flex
                                    align={"center"}
                                    alignItems={"center"}
                                    justifyContent="flex-end"
                                    mt="5"
                                >
                                    <Button
                                        isLoading={isLoading}
                                        variant="secondary"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        isLoading={isLoading}
                                        variant="primary"
                                        ml={3}
                                        type="submit"
                                        isDisabled={!(formik.isValid && formik.dirty)}
                                    >
                                        {!id ? "Create" : "Save"}
                                    </Button>
                                </Flex>
                            </FormControl>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
}
