import React, { useState } from "react";

import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlineCheck } from "react-icons/ai";

import {
    GridActionsRowButton,
    GridColumn,
    IFilterSettings,
    PagedGrid,
    Row,
    SelectionMode,
    SortOrder,
} from "components/paged-grid";
import { IPatient, IPermAction, IPrescription, PrescriptionStatusResponse, usePrescriptionService, useRepeatMedicationService } from "services";
import DefaultSkeleton from "../DefaultSkeleton";
import CustomMoment from "components/utils/CustomMoment";
import { formatInTimeZone } from "date-fns-tz";

const PrescriptionStatusColor = {
    [PrescriptionStatusResponse.Requested]: "DG.1",
    [PrescriptionStatusResponse.Rejected]: "TBO.6",
    [PrescriptionStatusResponse.Issued]: "green.500",
    [PrescriptionStatusResponse.Processed]: "gray.600"
};

type Props = {
    activePatient: IPatient;
};

const Prescriptions = (props: Props) => {
    const { activePatient } = props;

    const prescriptionService = usePrescriptionService();
    const repeatMedicationsService = useRepeatMedicationService();

    const [ key, setKey ] = useState(0);
    const [ isLoaded, setIsLoaded ] = useState(false);

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    const updateRepeatMedicationLastIssueDate = async (patientId: string, medicineId: string) => {
        const repeatMedications = await repeatMedicationsService.getAll({ patient: patientId, medicine_id: medicineId });

        const newDate = new Date();
        const dateFormatInTimeZoneUTC = formatInTimeZone(newDate, "UTC", "yyyy-MM-dd"); // send without time

        if (repeatMedications.length > 0) {
            repeatMedicationsService.update(repeatMedications[0].id, { ...repeatMedications[0], last_issue_date: dateFormatInTimeZoneUTC }, { showSuccessMessage: false })
                .catch(err => console.log(err));
        }
    };

    const btnClickHandler = async (newStatus: PrescriptionStatusResponse, rowData: IPrescription) => {
        const prescription = {
            ...rowData,
            status: newStatus
        };

        if (newStatus === PrescriptionStatusResponse.Issued) {
            updateRepeatMedicationLastIssueDate(prescription.patient, prescription.medicine_id);
        }

        await prescriptionService.update(prescription.id, prescription);
        setKey((value) => value + 1);
    };

    const columns: GridColumn[] = [
        {
            field: "medicine_name",
            header: "Medicine",
            //props: { w: "60%" },
            getComponent: (row: Row<IPrescription>) => {
                return (
                    <Box my={3}>
                        <Text
                            as="span"
                            fontSize={14}
                        >{`${row.data.medicine_name}`}</Text>
                    </Box>
                );
            },
        },
        {
            field: "request_date",
            header: "Date Requested",
            //props: { w: "10%" },
            sortable: true,
            sortDir: SortOrder.Desc,
            getComponent: (row: Row<IPrescription>) => {
                return <CustomMoment date={row.data.request_date} dateFormat="dd/MM/yyyy" />;
            },
        },
        {
            field: "status",
            header: "Status",
            //props: { w: "30%" },
            getComponent: (row: Row<IPrescription>) => {
                const bgColor = row.data.status in PrescriptionStatusResponse
                    ? PrescriptionStatusColor[row.data.status]
                    : "";
                const status = row.data.status in PrescriptionStatusResponse
                    ? row.data.status
                    : "-";

                return (
                    <Flex>
                        <Box>
                            <Text
                                bgColor={bgColor}
                                color={status != "-" ? "white" : "black"}
                                p={2}
                                borderRadius={6}
                            >
                                {status}
                            </Text>
                        </Box>
                    </Flex>
                );
            },
        },
    ];

    const rowActions: GridActionsRowButton<IPrescription>[] = [
        {
            text: "Issue",
            type: IPermAction.Change,
            handler: (row: Row<IPrescription>) => {
            },
            getComponent: (row: Row<IPrescription>) => {
                const isDisabled = row.data.status in PrescriptionStatusResponse && row.data.status !== PrescriptionStatusResponse.Requested;
                return (
                    <Button
                        variant="tertiary"
                        size="xs"
                        leftIcon={<AiOutlineCheck />}
                        mr={5}
                        isDisabled={isDisabled}
                        onClick={() => btnClickHandler(PrescriptionStatusResponse.Issued, row.data)}
                    >
                        Issue
                    </Button>
                );
            },
        },
        {
            text: "Reject",
            type: IPermAction.Change,
            handler: (row: Row<IPrescription>) => {
            },
            getComponent: (row: Row<IPrescription>) => {
                const isDisabled = row.data.status in PrescriptionStatusResponse && row.data.status !== PrescriptionStatusResponse.Requested;

                return (
                    <Button
                        variant="tertiary"
                        size="xs"
                        leftIcon={<MdOutlineCancel />}
                        isDisabled={isDisabled}
                        onClick={() => btnClickHandler(PrescriptionStatusResponse.Rejected, row.data)}
                    >
                        Reject
                    </Button>
                );
            },
        }
    ];

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            enabled: () => !!activePatient.id,
            getValue: () => activePatient.id,
            getComponent: () => <></>,
        },

    ];

    if (!activePatient.id)
        return null;

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IPrescription>
                        reloadKey={key}
                        columns={columns}
                        dataService={prescriptionService}
                        filters={filters}
                        rowActions={rowActions}
                        selectionMode={SelectionMode.None}
                        isLoaded={gridLoaded}
                    />
                </Collapse>
            </Box>
        </>
    );
};

export default Prescriptions;
