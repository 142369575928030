import React, { useEffect, useState } from "react";

import { Box, Collapse, Flex, Text } from "@chakra-ui/react";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    IFilterSettings,
    PagedGrid,
    Row,
    SelectionMode,
} from "components/paged-grid";
import { IProxy, IPermAction, useProxyService, IPatient } from "services";
import { useAlert } from "components/alert-modal";
import AddProxyModal from "./AddProxyModal";
import DefaultSkeleton from "../DefaultSkeleton";

type Props = {
    activePatient: IPatient;
}

const Proxies = (props: Props) => {
    const proxyService = useProxyService();
    const { activePatient } = props;

    const alertShow = useAlert();

    const [ key, setKey ] = useState(0);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);

    const reloadGrid = () => {
        setKey(key => key + 1);
    };

    const columns: GridColumn[] = [
        {
            field: "proxied_patient",
            header: "Proxy Patient Name",
            getComponent: (row: Row<IProxy>) => {
                return (
                    <Box my={3}>
                        <Text as="span">
                            {row.data.proxied_patient_full_name}
                        </Text>
                    </Box>
                );
            },
        },
        {
            field: "relationship",
            header: "Relationship",
            //props: { w: "100%" },
            getComponent: (row: Row<IProxy>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text as="span">
                                {row.data.relationship}
                            </Text>
                        </Box>
                    </Flex>
                );
            },
        },
    ];

    const rowActions: GridActionsRowButton<IProxy>[] = [
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IProxy>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete a Proxy?",
                    onSuccess: () => {
                        proxyService.delete(row.data.id).then(() => reloadGrid());
                    }
                });
            },
        }
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Proxy",
            type: IPermAction.Add,
            variant: "primary",
            handler: () => {
                setIsVisible(true);
            },
        },
    ];

    const dialogCloseHandler = () => {
        setIsVisible(false);
    };

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            getValue: () => activePatient.id,
            getComponent: () => <></>,
        },
    ];

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IProxy>
                        reloadKey={key}
                        columns={columns}
                        dataService={proxyService}
                        filters={filters}
                        selectionMode={SelectionMode.None}
                        rowActions={rowActions}
                        globalActions={globalActions}
                        isLoaded={gridLoaded}
                    />
                </Collapse>
            </Box>

            {isVisible && (
                <AddProxyModal
                    isVisible={isVisible}
                    onClose={dialogCloseHandler}
                    onSave={reloadGrid}
                    activePatient={activePatient}
                />
            )}
        </>
    );
};

export default Proxies;
