import { usePagedCrudService } from "./crud.service";
import { IContract, ITemplate, USignResponse } from "./contract.interface";

const contractService = (function() {
    const contractService = usePagedCrudService<IContract>({
        url: "/api/contracts/contract/",
        entityTitle: "Contract",
        model: "contract"
    });

    const initiateSigning = (id: string, role?: string) =>
        contractService._post<USignResponse>(
            `${contractService.url}${id}/sign/`,
            { payload: { role } }
        );

    return {
        ...contractService,
        initiateSigning,
    };
})();

export const useContractService = () => contractService;
