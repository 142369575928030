import { usePagedCrudService } from "services";
import { IAppointmentSlot } from "./appointment-slot.interface";

const appointmentSlotService = (function () {
    const service = usePagedCrudService<IAppointmentSlot>({
        url: "/api/mock/appointment-slot/",
        entityTitle: "Appointment Slot",
        model: "appointmentslot"
    });

    return service;
})();

export const useAppointmentSlotService = () => appointmentSlotService;
