import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Spacer, Stack, Text, Link, VStack } from "@chakra-ui/react";

import BoxCard from "components/card/Card";

const TokenisationIntegration = () => {
    const textLink = "TBO.5";
    const whitepaperLink = "https://datavant.com/wp-content/uploads/dlm_uploads/2018/09/WhitePaper_-De-Identifying-and-Linking-Structured-Data.pdf";


    const getRouterLink = (routePath: string, linkText = "here") => {
        return (
            <Link
                as={RouterLink}
                to={routePath}
                color={textLink}
            >
                { linkText }
            </Link>
        );
    };

    const getLink = (link: string, linkText = "here") => {
        return (
            <Link
                as="a"
                isExternal
                color={textLink}
                href={ link }
            >
                { linkText }
            </Link>
        );
    };

    return <Box mt={6}>
        <BoxCard>
            <Box>
                <Stack gap={2}>
                    <VStack alignItems={"left"}>
                        <Text>Convenet is a Datavant company.</Text>
                        <Spacer />
                        <Text>Datavant supports the efforts of healthcare service providers to link & exchange healthcare data to multiple organisations safely and securely. They are the leaders in this field in the USA.</Text>
                        <Spacer />
                        <Text>Datavant&apos;s tokenisation functionality uses the underlying identifying information in a dataset to create a universal, de-identified key that can be referenced to link records across datasets. For more information on how it works, check out our <Link as="a" target="_blank" ml={1} color={textLink} href={whitepaperLink} >whitepaper</Link>.</Text>
                        <Spacer />
                        <Text>You can use the tokenise endpoint in our sandbox to test this and see how it works. There&apos;s also information in the specification. Technical support queries should be sent to: <Link href="mailto:convenet-support@datavant.com" isExternal color={textLink} >convenet-support@datavant.com</Link></Text>
                        <Spacer />
                        <Text>If you interested in using this service in production and chatting about commercials, please <Link href="mailto:elise.reiter@datavant.com" isExternal color={textLink}>contact us</Link>.</Text>
                        <Spacer />

                    </VStack>
                </Stack>
            </Box>
        </BoxCard>
    </Box>;
};

export default TokenisationIntegration;
