import React, { useEffect, useState } from "react";

import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    Text,
    ModalFooter,
    Button,
    Flex,
} from "@chakra-ui/react";

import SelectProxyPatient from "./SelectProxyPatient";
import { IPatient, IProxy, useProxyService } from "services";

const AddProxyModal: React.FC<{
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
    activePatient: IPatient;
}> = (props) => {
    const { activePatient } = props;
    const proxyService = useProxyService();

    const closeHandler = () => {
        props.onClose();
    };

    const onSelectProxyPatient = async (selectedPatient: IPatient) => {
        const newProxy: IProxy = {
            id: "",
            patient: activePatient.id,
            proxied_patient: selectedPatient.id,
            relationship: "Family Relation"
        };
        await proxyService.create(newProxy);
        closeHandler();
        props.onSave();
    };

    return (
        <Modal
            size={"6xl"}
            isOpen={props.isVisible}
            onClose={closeHandler}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign={"center"}>
                    <Text>Add Proxy</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SelectProxyPatient
                        onSelect={onSelectProxyPatient}
                        activePatient={activePatient}
                    />
                </ModalBody>
                <ModalFooter>
                    <Flex
                        align="center"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Button colorScheme="red" onClick={closeHandler}>
                            Close
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddProxyModal;
