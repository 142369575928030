import React, { useEffect, useState } from "react";

import {
    Badge,
    Button,
    Flex,
    Input,
    Text,
} from "@chakra-ui/react";

import {
    GridActionsRowButton,
    GridColumn,
    IFilterSettings,
    PagedGrid,
    Row,
    SelectionMode,
} from "components/paged-grid";
import { IGP, IPatient, IProxy, useProxyService, usePatientService } from "services";

const SelectProxyPatient: React.FC<{
    onSelect: (selectedPatient: IPatient) => void;
    activePatient: IPatient;
}> = (props) => {
    const patientService = usePatientService();
    const proxyService = useProxyService();
    const { activePatient } = props;
    const [ proxiedPatients, setProxiedPatients ] = useState<IProxy[]>([]);

    const [ key, setKey ] = useState(0);
    const [ filterFirstName, setFilterFirstName ] = useState("");
    const reloadGrid = () => setKey((value) => value + 1);

    useEffect(() => {
        proxyService.getAll({ patient: activePatient.id }).then((proxied: any) => {
            setProxiedPatients(proxied);
            reloadGrid();
        });
    }, [ activePatient.id ]);

    // On first run do not show PagedGrid, wait for proxiedPatients to be set
    if (key == 0)
        return null;

    const columns: GridColumn[] = [
        {
            field: "first_name",
            header: "Name",
            getComponent: (row: Row<IPatient>) => {
                return (
                    <Text as="span">{`${row.data.first_name} ${row.data.surname}`}</Text>
                );
            },
        },
        {
            field: "description",
            header: "Description",
            props: { whiteSpace: "normal", minWidth: "300px" },
            getComponent: (row: Row<IPatient>) => {
                return <Text as="span">{row.data.description}</Text>;
            },
        },
        {
            field: "gp",
            header: "GP",
            getComponent: (row: Row<IPatient>) => {
                return (
                    <Text>
                        {row.data.gp_practice_name || ""}
                    </Text>
                );
            },
        },
    ];

    const rowActions: GridActionsRowButton<IPatient>[] = [
        {
            text: "Select",
            visible: (row: Row<IPatient>) => {
                const existingProxiedPatients = proxiedPatients;

                if (existingProxiedPatients.filter((x) => x.proxied_patient === row.data.id).length > 0) {
                    return false;
                }

                if (activePatient.id === row.data.id)
                    return false;

                return true;
            },
            handler: (row: Row<IPatient>) => props.onSelect(row.data),
            getComponent(row: Row<IPatient>) {
                return (
                    <Button
                        aria-label={this.text}
                        mr={"8px"}
                        variant="primary"
                        onClick={() => this.handler?.(row)}
                    >
                        Select
                    </Button>
                );
            },
        },
        {
            text: "",
            visible: (row: Row<IPatient>) => {
                const existingProxiedPatients = proxiedPatients;

                if (existingProxiedPatients.filter((x) => x.proxied_patient === row.data.id).length > 0) {
                    return true;
                }

                if (activePatient.id === row.data.id)
                    return false;

                return false;
            },
            getComponent: (row: Row<IPatient>) => (
                <Badge variant="outline" colorScheme="green">
                    Already Proxied
                </Badge>
            ),
        },
        {
            text: "",
            visible: (row: Row<IPatient>) => activePatient.id === row.data.id,
            getComponent: (row: Row<IPatient>) => (
                <Badge variant="outline" colorScheme="green">
                    Current Patient
                </Badge>
            ),
        },
    ];

    const filters: IFilterSettings[] = [
        // the proxied_patient and patient have to belong to the same GP
        {
            field: "gp",
            visible: () => false,
            getValue: () => activePatient.gp ?? "", // "" === no filter
            getComponent: () => <></>,
        },
        {
            field: "id__notin",
            visible: () => false,
            getValue: () => [ activePatient.id, ...proxiedPatients.map(p => p.proxied_patient) ].join(","),
            getComponent: () => <></>,
        },
        {
            field: "full_name",
            match: () => "icontains",
            getValue: () => filterFirstName, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant="filter"
                        type="text"
                        placeholder="Search by Name..."
                        value={filterFirstName}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setFilterFirstName(value);
                                reloadGrid();
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setFilterFirstName(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
        {
            field: "",
            getValue: () => null,
            getComponent: () => (
                <Flex my="8px" marginLeft="auto">
                    <Button
                        mx="8px"
                        variant="tertiary"
                        onClick={() => {
                            setFilterFirstName("");
                            reloadGrid();
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        ml="8px"
                        variant="secondary"
                        onClick={() => reloadGrid()}
                    >
                        Filter
                    </Button>
                </Flex>
            ),
        },
    ];

    return (
        <PagedGrid<IPatient>
            reloadKey={key}
            columns={columns}
            dataService={patientService}
            rowActions={rowActions}
            selectionMode={SelectionMode.None}
            filters={filters}
        />
    );
};

export default SelectProxyPatient;
