import { IEmailDetails } from "./email-details.interface";
import { IPermission, IRole } from "./role.interface";

export enum UserEnum {
    Invited = 0,
    Created,
    Active,
    Inactive
}

interface IUserBase {
    id: string;
    company: string; // company id
    company_name?: string;
    impersonated_company?: string; // id
    impersonated_company_name?: string;
    email: string;
    first_name?: string;
    last_name?: string;
    is_superuser: boolean;
    is_staff: boolean;
    roles: string[];
    status: UserEnum;

    // Computed fields
    mfa_enabled: boolean;
}

// for /api/auth/user/me
export interface IUserMe extends IUserBase {
    roles_details?: IRole[];
    user_permissions_details?: IPermission[];
    all_permissions_details?: IPermission[];
    google_account_status: number; // 1-google_only, 2-local_only, 3-connected
}

// for /api/auth/user/
export interface IUser extends IUserBase {
    last_login?: Date;
    is_active?: boolean;
    date_joined?: Date;
    username?: string;
    email_details?: IEmailDetails[]; // TODO
    user_permissions?: any[]; // TODO
}

export interface IUserInvite {
    email: string;
    first_name?: string;
    last_name?: string;
    company: string; // ID
    roles: string[]; // IDs
}
