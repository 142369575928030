import { usePagedCrudService } from "services";
import { IPatient } from "./patients.interface";

const patientService = (function() {
    const patientService = usePagedCrudService<IPatient>({
        url: "/api/mock/patient/",
        entityTitle: "Patient",
        model: "patient"
    });

    const regenerateLinkageKey = (id: string) =>
        patientService._patch(`/api/mock/patient/${id}/regenerate-linkage-key/`);

    return {
        ...patientService,
        regenerateLinkageKey
    };
})();

export const usePatientService = () => patientService;
