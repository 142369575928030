import React, { useState, useReducer, useContext, useEffect } from "react";
import {
    Flex,
    Text,
    IconButton,
    Button,
    Box,
    useDisclosure
} from "@chakra-ui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    EditIcon,
    DeleteIcon,
    TriangleUpIcon,
    TriangleDownIcon,
    AddIcon,
} from "@chakra-ui/icons";

import RadialBarChart from "./components/RadialBarChart";
import { subDays } from "date-fns";
import CustomMoment from "components/utils/CustomMoment";


const query = `
    fields @timestamp, @message
    | parse @message "NHS Sequence ID=*, lambda=Convenet-* *, API=*, Patient=*, CompanyId=*, Request ID=*, message=*" as AuditID, EndPoint, request, API, PatientID, CompanyID, ConvenetRequestID, APIcall
    | filter APIcall like "START"
    | stats count(EndPoint) by CompanyID
`;
const name = "/aws/lambda/Convenet-api-testio-get-company";

const queryUniquePDS = `
    fields @type
    | filter @type = "START"
    | stats count(@type)
`;

const logGroupNames = [
    "/aws/lambda/Convenet-api-production-patient-pds-get-details",
    "/aws/lambda/Convenet-api-production-patient-pds-get-nhsNumber",
    "/aws/lambda/Convenet-Lambda-production-patient-pds-get-details",
    "/aws/lambda/Convenet-Lambda-production-patient-pds-get-nhsNumber"
];

const logGroupNames2 = [
    "/aws/lambda/Convenet-api-production-patient-records-get",
    "/aws/lambda/Convenet-api-production-add-patient-proxy",
    "/aws/lambda/Convenet-api-production-delete-appointment",
    "/aws/lambda/Convenet-api-production-delete-patient-proxy",
    "/aws/lambda/Convenet-api-production-get-appointment",
    "/aws/lambda/Convenet-api-production-get-appointment-slot",
    "/aws/lambda/Convenet-api-production-get-patient-proxy-list",
    "/aws/lambda/Convenet-api-production-get-patient-settings",
    "/aws/lambda/Convenet-api-production-get-practice-settings",
    "/aws/lambda/Convenet-api-production-patient-medication-get",
    "/aws/lambda/Convenet-api-production-patient-prescription-post",
    "/aws/lambda/Convenet-api-production-patient-prescription-status-post",
    "/aws/lambda/Convenet-api-production-patient-registration-post",
    "/aws/lambda/Convenet-api-production-post-appointment"
];

export default function Dashboard() {
    const endTime = new Date;
    // const startTime = moment(endTime).subtract(30, "days").toDate();
    const startTime = subDays(endTime, 30);

    return (
        <Box mt={6} color="lightText" pl="5px">
            <Box mb="2">
                <Text as="span" ml="5px" color="lightSecondaryText">
                Start Time: <CustomMoment date={startTime} dateFormat="dd/MM/yy" />
                </Text>
                <Text as="span" ml="8px" color="lightSecondaryText">
                End Time: <CustomMoment date={endTime} dateFormat="dd/MM/yy" />
                </Text>
            </Box>
            <Flex
                direction="row"
                flexWrap="wrap"
                justifyContent={{ base: "center", md: "start" }}
            >
                <RadialBarChart
                    queryString={query}
                    logGroupName={name}
                    startTime={startTime}
                    endTime={endTime}
                    label="api-testio-get-company"
                />
            </Flex>
            <Box as="section">
                <Text as="div" textAlign="center">Total Unique PDS Requests</Text>
                <Flex
                    direction="row"
                    flexWrap="wrap"
                    justifyContent={{ base: "center", md: "start" }}
                >
                    {logGroupNames.map((groupName: string, i: number) => (
                        <RadialBarChart
                            key={i}
                            queryString={queryUniquePDS}
                            logGroupName={groupName}
                            startTime={startTime}
                            endTime={endTime}
                            label={groupName.replace("/aws/lambda/Convenet-", "").replace(/-/g, " ")}
                        />
                    ))}
                </Flex>
            </Box>
            <Box as="section">
                <Text as="div" textAlign="center">Total Unique IM1 requests</Text>
                <Flex
                    direction="row"
                    flexWrap="wrap"
                    justifyContent={{ base: "center", md: "start" }}
                >
                    {logGroupNames2.map((groupName: string, i: number) => (
                        <RadialBarChart
                            key={i}
                            queryString={queryUniquePDS}
                            logGroupName={groupName}
                            startTime={startTime}
                            endTime={endTime}
                            label={groupName.replace("/aws/lambda/Convenet-", "").replace(/-/g, " ")}
                        />
                    ))}
                </Flex>
            </Box>
        </Box>
    );
}
