import React, { memo } from "react";

import {
    Flex,
    Checkbox
} from "@chakra-ui/react";
import { useField } from "formik";

const CheckboxFormik: React.FC<any> = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [ field ] = useField({ name: props.name, type: "checkbox" });
    const { label } = props;

    return (
        <Flex direction="column">
            <Checkbox isChecked={field.value} {...field} {...props} >
                {label}
            </Checkbox>
        </Flex>
    );
};

export default memo(CheckboxFormik);
