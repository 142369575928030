import { useBaseService, usePagedCrudService } from "services/crud.service";
import { StripePriceRecurringInterval } from "views/admin/billing/api-subscription/SubscriptionItem";
import { IStripePrice } from "./products.service";

export interface BespokeProductPrice {
    // id = models.UUIDField(primary_key=True, default=uuid4, editable=False)
    id: string;

    integration_product: string;

    // cost = models.IntegerField()  # In pence
    cost: number | any;

    // product = models.ForeignKey(
    //     Product, models.DO_NOTHING, related_name="bespoke_prices"
    // )
    product: string | number;

    // price = models.ForeignKey(Price, models.DO_NOTHING, related_name="bespoke_product")
    price: string;

    price_details: IStripePrice;

    // recurring_interval = StripeEnumField(enum=PlanInterval, null=True)
    // recurring_interval: StripePriceRecurringInterval | null;

    // recurring_interval_count = models.IntegerField(null=True)
    // recurring_interval_count: number;
}

const bespokePriceService = (function useBespokePriceService() {
    const base = useBaseService();
    const url = "/api/billing/price/bespoke/";

    const bespokePriceService = usePagedCrudService<BespokeProductPrice>({
        url: "/api/billing/price/bespoke/",
        entityTitle: "Bespoke Product",
        model: "bespokeproductprice"
    });

    // GET / list bespoke prices for current company or all companies for admins
    const getBespokePrices = (): Promise<BespokeProductPrice[]> => {
        return base._get(`${url}`);
    };

    // GET /<id>/ get bespoke price by id
    const getById = (id: string): Promise<BespokeProductPrice> => {
        return base._get(`${url}${id}/`);
    };

    // POST / create bespoke price
    //     takes name, cost, company, and product in body.
    //     Will create a price in stripe for the given product id and assign this price to the given company, only allowing the product to be purchased at this price going forward.
    //     Only one bespoke price can be assigned to a given product/company pairing
    const createBespokePrice = (bespokePrice: Partial<BespokeProductPrice>): Promise<BespokeProductPrice> => {
        return base._post(`${url}`, { payload: bespokePrice });
    };

    // DELETE /<id>/ delete a given bespoke price
    //     This only deletes the object in the database, not the price in Stripe as stripe will not always allow deletion of prices
    //     Deleting bespoke price will allow any price id for the given product to be allowable, or a new bespoke price to be assigned
    const deleteBespokePrice = (id: string): Promise<any> => {
        return base._delete(`${url}${id}/`);
    };

    return {
        ...bespokePriceService,
        getBespokePrices,
        getById,
        createBespokePrice,
        deleteBespokePrice
    };
})();

export const useBespokePriceService = () => bespokePriceService;