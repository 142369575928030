import React, { useEffect, useRef } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Flex, Heading, Link, Text, useToast } from "@chakra-ui/react";
import BoxCard from "components/card/Card";
import { IoEllipsisHorizontalCircle } from "react-icons/io5";
import { useAppStore } from "store";
import { BsCheck2 } from "react-icons/bs";
import { IntegrationProductStatusChoice, useIntegrationProductService } from "services";
import useTicks from "hooks/use-ticks";

const SubmitForReview = () => {

    const integrationProductService = useIntegrationProductService();
    const toast = useToast();
    const { updateIntegrationProduct } = useTicks();

    const company = useAppStore.getState().company;
    const integrationProduct = useAppStore(state => state.integrationProduct);

    const productDetailsComplete = useAppStore.getState().completeProductDetailsTick;
    const signUpFeePaid = useAppStore.getState().signUpFeePaidTick;
    const informationGovernanceComplete = useAppStore.getState().informationGovernanceTick;
    const isSubmittedForReview = useAppStore(state => state.isSubmittedForReview);
    const signUpFeeWaived = useAppStore(state => state.signUpFeeWaived);

    const iconCheckColor = "#11A04A";
    const iconUncheckColor = "#DBDBDB";
    const linkText = "TBO.5";

    const productDetails = productDetailsComplete
        ? <>
            <BsCheck2 color={iconCheckColor} size="30px"/>
            <Text>Product Details Completed</Text>
        </>
        : <>
            <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
            <Flex>
                <Text>Please complete your </Text>
                <Link
                    as={RouterLink}
                    color={linkText}
                    ml={2}
                    to={"/app/convenet-integration/product"}
                >
                     Product Details
                </Link>
            </Flex>
        </>;

    const signUpFee = signUpFeePaid
        ? <>
            <BsCheck2 color={iconCheckColor} size="30px"/>
            <Text>Sign Up Fee Paid</Text>
        </>
        : <>
            <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
            <Flex>
                <Text>Please pay the </Text>
                <Link
                    as={RouterLink}
                    color={linkText}
                    ml={2}
                    to={"/app/billing/sign-up-fee"}
                >
                 Sign Up Fee
                </Link>
            </Flex>
        </>;

    const informationGovernance = informationGovernanceComplete
        ? <>
            <BsCheck2 color={iconCheckColor} size="30px"/>
            <Text>Information Governance Completed</Text>
        </>
        : <>
            <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
            <Flex>
                <Text>Please complete your </Text>
                <Link
                    as={RouterLink}
                    color={linkText}
                    ml={2}
                    to={"/app/convenet-integration/company"}
                >
                 Information Governance
                </Link>
            </Flex>
        </>;

    const warningToast = () => toast({
        title: "Warning!",
        description: "Please complete all requirements before submitting for review.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right"
    });

    const submitForReview = async () => {
        const condition = signUpFeeWaived
            ? (productDetailsComplete && informationGovernanceComplete)
            : (productDetailsComplete && signUpFeePaid && informationGovernanceComplete);

        if (!condition) return warningToast();

        await integrationProductService.update(
            integrationProduct.id,
            { ...integrationProduct, status: IntegrationProductStatusChoice.under_review },
            { successMessage: "Product submitted for review." }
        ).then(resp => updateIntegrationProduct())
            .catch(err => console.log(err));
    };

    if (integrationProduct.status === IntegrationProductStatusChoice.under_review) {
        return (
            <Box my={6}>
                <BoxCard>
                    <Text textAlign="center" color="TBO.5">
                        {integrationProduct.name} is under review.
                    </Text>
                    <Text
                        paddingTop={4}
                        color="gray.500"
                        textAlign="start"
                    >
                        We are reviewing the information you have supplied and will be in touch
                        if we have any questions. This process usually takes no longer than a week.
                    </Text>
                </BoxCard>
            </Box>
        );
    }

    if (integrationProduct.status === IntegrationProductStatusChoice.live) {
        return (
            <Box my={6}>
                <BoxCard>
                    <Text textAlign="center" color="TBO.5">
                        {integrationProduct.name || "Your product"} has passed its review and can Go Live.
                    </Text>
                </BoxCard>
            </Box>
        );
    }

    return <Box my={6}>
        <BoxCard>
            <Text
                color="gray.500"
                textAlign="start"
                fontSize="text.md"
            >
                When you are ready to put your product Live you need to have it reviewed by Convenet to ensure that all NHS requirements have been met.
            </Text>
            <Flex direction="column" gap={3}>
                <Text fontSize={"text.xl"} color="TBO.4" my="5">Requirements</Text>
                <Flex alignItems="center" gap={2} fontSize="text.md">
                    { productDetails }
                </Flex>
                { !signUpFeeWaived &&
                    <Flex alignItems="center" gap={2} fontSize="text.md">
                        { signUpFee }
                    </Flex>
                }
                <Flex alignItems="center" gap={2} fontSize="text.md">
                    { informationGovernance }
                </Flex>
            </Flex>

        </BoxCard>
        <Flex flexDirection="column" alignItems="flex-end" mt="5" gap={2} fontSize="text.sm">
            <Button
                variant={"primary"}
                type="submit"
                onClick={ submitForReview }
            >
                        Submit for Review
            </Button>
        </Flex>
    </Box>;
};

export default SubmitForReview;
