import React, { useCallback, useEffect, useState } from "react";

import {
    Button,
    Heading,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Image,
    Flex,
    Badge,
    Text,
    useColorModeValue,
    Tag,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";

import BoxCard from "components/card/Card";
import { IStripePrice, IStripeProduct, useProductsService } from "services/billing/products.service";
import { getTextWithCurrency } from "components/utils/currency-helper";
import CustomMoment from "components/utils/CustomMoment";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import BespokePrices from "../BespokePricesModal";
import { ProductIcon } from "views/admin/convenet-integration/FigmaIcons";
import SubscriptionsSkeleton from "../SubscriptionsSkeleton";
import { usePortalService } from "services/billing/portal.service";
import { SignUpFeeOptionChoice } from "services/integration-product-review.interface";
import { useAppStore } from "store";
import useTicks from "hooks/use-ticks";

export type PurchasedResponse = { purchased: boolean, timestamp: string, price: string };

const SignUpFee = () => {
    const productService = useProductsService();
    const bespokePricesService = useBespokePriceService();
    const portalService = usePortalService();
    const { updateIntegrationProduct } = useTicks();

    const company = useAppStore(state => state.company);
    const integrationProduct = useAppStore(state => state.integrationProduct);

    const iconColor = useColorModeValue("gray.200", "white");

    const [ hasPurchased, setHasPurchased ] = useState({} as PurchasedResponse);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ redirecting, setRedirecting ] = useState(false);
    const [ showBespokePricesModal, setShowBespokePricesModal ] = useState(false);
    const [ showLinkForChangePrice, setShowLinkForChangePrice ] = useState(false);
    const [ updatePaymentMethod, setUpdatePaymentMethod ] = useState(false);
    const [ product, setProduct ] = useState({} as IStripeProduct);
    const [ price, setPrice ] = useState({} as IStripePrice);
    const [ ipUpdated, setIpUpdated ] = useState(false);

    // product review states
    const [ standardPrice, setStandardPrice ] = useState(false);

    useEffect(() => {
        updateIntegrationProduct().then(() => setIpUpdated(true));
    }, []);

    useEffect(() => {
        const func = async () => {
            const setupFee = await productService.getDefaultOneTime();
            setProduct(setupFee);

            // show standard or bespoke price according to integrationProductReview model
            if (integrationProduct.sign_up_fee_option === SignUpFeeOptionChoice.bespoke) {
                setStandardPrice(false);

                // get bestpoke price for the current product
                const productBespokePrice = await bespokePricesService.getAll({ integration_product__company__id: company.id, product__id: setupFee.id });

                if (productBespokePrice.length > 0) {
                    setPrice(productBespokePrice[0].price_details);
                } else {
                    setPrice(setupFee.default_price);
                }
            }

            if (integrationProduct.sign_up_fee_option === SignUpFeeOptionChoice.standard) {
                setStandardPrice(true);
                setPrice(setupFee.default_price);
            }

            const purchased: PurchasedResponse = await productService.hasPurchasedProduct(setupFee.djstripe_id, integrationProduct.id);

            if (purchased.purchased) {
                setHasPurchased({ ...purchased });
                setShowLinkForChangePrice(false);
                setUpdatePaymentMethod(true);
            } else {
                setShowLinkForChangePrice(true);
            }

            setIsLoading(false);
        };

        ipUpdated && func();
    }, [ integrationProduct, ipUpdated ]);

    const pay = async () => {
        try {
            setRedirecting(true);
            const stripeSignUpFeeLink = await productService.checkout(product.djstripe_id, { payload: { price: price.id, integration_product: integrationProduct.id } });
            window.location.href = stripeSignUpFeeLink;
        } catch (error) {
            console.log(error);
        }
    };

    const onChangePrice = (addedPrice: BespokeProductPrice) => {
        setPrice(addedPrice.price_details);
    };

    const priceText = useCallback(() => {
        return price?.id ? getTextWithCurrency(price.currency, price.unit_amount / 100) : "";
    }, [ price ]);

    const onDeletePrice = () => {
        // set the price to default price
        setPrice(product.default_price);
    };

    const updatePaymentMethodHandler = async () => {
        const _portalURL = await portalService.redirectToPortal("/app/billing/sign-up-fee/");
        window.location.href = _portalURL;
    };

    const menu = <Menu>
        <MenuButton
            boxSize={6}
            as={IconButton}
            hidden={standardPrice}
            aria-label='Options'
            icon={<BsThreeDots />}
            variant='outline'
            alignSelf="end"
        />
        <MenuList>
            {showLinkForChangePrice &&
                <MenuItem onClick={() => setShowBespokePricesModal(true)}>
                    Change Price
                </MenuItem>
            }
            {updatePaymentMethod &&
                <MenuItem onClick={() => updatePaymentMethodHandler()}>
                    Update Payment Method
                </MenuItem>
            }
        </MenuList>
    </Menu>;

    if (integrationProduct.sign_up_fee_option === SignUpFeeOptionChoice.waive) {
        return <Text>No sign up fee is required</Text>;
    }

    return <Flex direction="row" gap={5} margin={"1px auto"}>
        {isLoading
            ?
            <SubscriptionsSkeleton />
            :
            <>
                {product.id &&
                    <BoxCard margin={"1px auto"} w={{ sm: "auto", md: "450px", lg: "450px" }} my={5}>
                        <Flex p={2} direction="column">
                            <Stat>
                                <StatLabel mb={1}>
                                    <Flex justify={"space-between"}>
                                        <Heading size="md">{product.name}</Heading>
                                        {menu}
                                    </Flex>
                                </StatLabel>
                                <StatNumber>
                                    <Flex gap={3}>
                                        <Text fontSize="md">{`${priceText()}`}</Text>
                                        <Tag colorScheme={"blue"}>Plus VAT</Tag>
                                    </Flex>
                                </StatNumber>
                                <StatHelpText>{product.description || <br />}</StatHelpText>
                            </Stat>
                            <Flex justify="center" mb={5}>
                                <Image
                                    src={product.images[0]}
                                    fallback={<ProductIcon boxSize={200} color={iconColor} />}
                                    alt='Product Image'
                                    boxSize='200px'
                                    objectFit='cover'
                                />
                            </Flex>
                            {!hasPurchased.purchased ?
                                <Button
                                    onClick={pay}
                                    isLoading={redirecting}
                                    loadingText="Redirecting to Stripe..."
                                >
                                    Pay {product.name}
                                </Button>
                                :
                                <Badge colorScheme={"whatsapp"} textAlign="center">
                                    Paid on {<CustomMoment date={hasPurchased.timestamp} dateFormat="dd/MM/yyyy"></CustomMoment>}
                                </Badge>
                            }
                        </Flex>
                    </BoxCard>
                }
            </>
        }
        {showBespokePricesModal &&
            <BespokePrices
                product={product}
                onChange={onChangePrice}
                company={company}
                onDeletePrice={onDeletePrice}
                showModal={showBespokePricesModal}
                setShowModal={setShowBespokePricesModal}
            />
        }
    </Flex>;
};

export default SignUpFee;
