import { IRepeatMedication, usePagedCrudService } from "services";

const repeatMedicationService = (function() {
    const repeatMedicationService = usePagedCrudService<IRepeatMedication>({
        url: "/api/mock/repeat-medication/",
        entityTitle: "Repeat Medication",
        model: "repeatmedication"
    });

    return repeatMedicationService;
})();

export const useRepeatMedicationService = () => repeatMedicationService;
