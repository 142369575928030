import React from "react";

import { Flex, Stack, Text, Input } from "@chakra-ui/react";
import { useField } from "formik";

// TODO: merge with TextFieldFormik component
const InputFormik: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props);
    const {
        id = "text-field",
        required,
        readOnly,
        placeholder,
        type = "text",
        mb,
        variant,
        ...rest
    } = props;

    const onChangeWrap = (e: any) => {
        helpers.setTouched(true);
        (props.onChange || field.onChange)(e);
    };

    return (
        <Stack w="100%">
            <Input
                {...field}
                {...rest}
                type={type}
                onChange={onChangeWrap}
                placeholder={placeholder}
                fontWeight="500"
                borderColor={meta.error ? "red" : "gray.400"}
            />
            {meta.touched && meta.error ? (
                <Text as="span" fontSize="text.xs" color={"red"} h="14px" ml={3} alignSelf="flex-start">
                    {meta.error}
                </Text>
            ) : null}
        </Stack>
    );
};

export default InputFormik;
