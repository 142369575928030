export const medicalRecordOriginalJson = {
    schema2: "string",
    isProxied: true,
    schema1: {
        medicalRecord: {
            allergies: [
                {
                    availabilityDateTime: "string",
                    textValue: "string",
                    numericOperator: "string",
                    numericUnits: "string",
                    associatedText: [
                        {
                            textType: "string",
                            text: "string",
                        },
                    ],
                    episodicity: "string",
                    numericValue: 0,
                    range: {
                        minimumText: "string",
                        maximumText: "string",
                        maximum: 0,
                        units: "string",
                        minimum: 0,
                    },
                    abnormalReason: "string",
                    authorisingUserInRoleGuid: "string",
                    displayValue: "string",
                    codeId: 0,
                    abnormal: "string",
                    observationType: "string",
                    eventGuid: "string",
                    term: "string",
                    enteredByUserInRoleGuid: "string",
                    effectiveDate: {
                        datePart: "string",
                        value: "string",
                    },
                },
            ],
            testResults: [
                {
                    value: {
                        availabilityDateTime: "string",
                        textValue: "string",
                        numericOperator: "string",
                        numericUnits: "string",
                        associatedText: [
                            {
                                textType: "string",
                                text: "string",
                            },
                        ],
                        episodicity: "string",
                        numericValue: 0,
                        range: {
                            minimumText: "string",
                            maximumText: "string",
                            maximum: 0,
                            units: "string",
                            minimum: 0,
                        },
                        abnormalReason: "string",
                        authorisingUserInRoleGuid: "string",
                        displayValue: "string",
                        codeId: 0,
                        abnormal: "string",
                        observationType: "string",
                        eventGuid: "string",
                        term: "string",
                        enteredByUserInRoleGuid: "string",
                        effectiveDate: {
                            datePart: "string",
                            value: "string",
                        },
                    },
                    childValues: [
                        {
                            availabilityDateTime: "string",
                            textValue: "string",
                            numericOperator: "string",
                            numericUnits: "string",
                            associatedText: [
                                {
                                    textType: "string",
                                    text: "string",
                                },
                            ],
                            episodicity: "string",
                            numericValue: 0,
                            range: {
                                minimumText: "string",
                                maximumText: "string",
                                maximum: 0,
                                units: "string",
                                minimum: 0,
                            },
                            abnormalReason: "string",
                            authorisingUserInRoleGuid: "string",
                            displayValue: "string",
                            codeId: 0,
                            abnormal: "string",
                            observationType: "string",
                            eventGuid: "string",
                            term: "string",
                            enteredByUserInRoleGuid: "string",
                            effectiveDate: {
                                datePart: "string",
                                value: "string",
                            },
                        },
                    ],
                },
            ],
            sex: "string",
            dateOfBirth: "string",
            medication: [
                {
                    prescriptionType: "string",
                    dosage: "string",
                    availabilityDateTime: "string",
                    quantity: 0,
                    quantityUnit: "string",
                    quantityRepresentation: "string",
                    authorisingUserInRoleGuid: "string",
                    isMixture: true,
                    codeId: 0,
                    mixture: {
                        mixtureName: "string",
                        constituents: [
                            {
                                MixtureName: "string",
                                Constituent: [
                                    {
                                        constituentName: "string",
                                        strength: "string",
                                    },
                                ],
                            },
                        ],
                    },
                    drugStatus: "string",
                    drugName: "string",
                    lastIssueDate: "string",
                    eventGuid: "string",
                    term: "string",
                    enteredByUserInRoleGuid: "string",
                    firstIssueDate: "string",
                    effectiveDate: {
                        datePart: "string",
                        value: "string",
                    },
                },
            ],
            title: "string",
            consultations: [
                {
                    consultantName: "string",
                    availabilityDateTime: "string",
                    codeId: 0,
                    location: "string",
                    eventGuid: "string",
                    term: "string",
                    authorisingUserInRoleGuid: "string",
                    enteredByUserInRoleGuid: "string",
                    sections: [
                        {
                            code: "string",
                            pageNumber: 0,
                            observations: [
                                {
                                    availabilityDateTime: "string",
                                    textValue: "string",
                                    numericOperator: "string",
                                    numericUnits: "string",
                                    associatedText: [
                                        {
                                            textType: "string",
                                            text: "string",
                                        },
                                    ],
                                    episodicity: "string",
                                    numericValue: 0,
                                    range: {
                                        minimumText: "string",
                                        maximumText: "string",
                                        maximum: 0,
                                        units: "string",
                                        minimum: 0,
                                    },
                                    abnormalReason: "string",
                                    authorisingUserInRoleGuid: "string",
                                    displayValue: "string",
                                    codeId: 0,
                                    abnormal: "string",
                                    observationType: "string",
                                    eventGuid: "string",
                                    term: "string",
                                    enteredByUserInRoleGuid: "string",
                                    effectiveDate: {
                                        datePart: "string",
                                        value: "string",
                                    },
                                },
                            ],
                            header: "string",
                        },
                    ],
                    effectiveDate: {
                        datePart: "string",
                        value: "string",
                    },
                },
            ],
            forenames: "string",
            users: [
                {
                    role: "string",
                    surname: "string",
                    organisation: "string",
                    title: "string",
                    forenames: "string",
                    userInRoleGuid: "string",
                },
            ],
            immunisations: [
                {
                    availabilityDateTime: "string",
                    textValue: "string",
                    numericOperator: "string",
                    numericUnits: "string",
                    associatedText: [
                        {
                            textType: "string",
                            text: "string",
                        },
                    ],
                    episodicity: "string",
                    numericValue: 0,
                    range: {
                        minimumText: "string",
                        maximumText: "string",
                        maximum: 0,
                        units: "string",
                        minimum: 0,
                    },
                    abnormalReason: "string",
                    authorisingUserInRoleGuid: "string",
                    displayValue: "string",
                    codeId: 0,
                    abnormal: "string",
                    observationType: "string",
                    eventGuid: "string",
                    term: "string",
                    enteredByUserInRoleGuid: "string",
                    effectiveDate: {
                        datePart: "string",
                        value: "string",
                    },
                },
            ],
            PatientGuid: "string",
            surname: "string",
            problems: {
                significance: "string",
                observation: {
                    availabilityDateTime: "string",
                    textValue: "string",
                    numericOperator: "string",
                    numericUnits: "string",
                    associatedText: [
                        {
                            textType: "string",
                            text: "string",
                        },
                    ],
                    episodicity: "string",
                    numericValue: 0,
                    range: {
                        minimumText: "string",
                        maximumText: "string",
                        maximum: 0,
                        units: "string",
                        minimum: 0,
                    },
                    abnormalReason: "string",
                    authorisingUserInRoleGuid: "string",
                    displayValue: "string",
                    codeId: 0,
                    abnormal: "string",
                    observationType: "string",
                    eventGuid: "string",
                    term: "string",
                    enteredByUserInRoleGuid: "string",
                    effectiveDate: {
                        datePart: "string",
                        value: "string",
                    },
                },
                problemEndDate: "string",
                status: "string",
            },
        },
    },
    requestId: "string",
    schema3: [
        {
            date: "string",
            location: "string",
            doneBy: "string",
            recordItems: [
                {
                    details: "string",
                    type: "string",
                    url: "string",
                    binaryDataId: "string",
                },
            ],
        },
    ],
};
