import { useBaseService, usePagedCrudService, TOptions } from "../crud.service";
import { Recurring } from "./invoice.interface";

// TODO: add interfaces

export interface IStripeProduct {
    active: boolean;
    attributes: any[];
    caption: string;
    created: string;
    deactivate_on: any;
    description: string;
    djstripe_created: string;
    djstripe_id: number;
    djstripe_owner_account: string;
    djstripe_updated: string;
    id: string;
    images: any[];
    livemode: boolean;
    metadata: any;
    name: string;
    package_dimensions: any;
    prices: IStripePrice[];
    shippable: any;
    statement_descriptor: string;
    type: string;
    unit_label: string;
    url: string;
    default_price: IStripePrice;
  }

export interface IStripePrice {
    active: boolean;
    billing_scheme: string;
    created: string;
    currency: string;
    description: string;
    djstripe_created: string;
    djstripe_id: number;
    djstripe_owner_account: string;
    djstripe_updated: string;
    id: string;
    livemode: boolean;
    lookup_key: string;
    metadata: any;
    nickname: string;
    product: string;
    product_name: string;
    recurring: Recurring;
    transform_quantity: any;
    tiers: any;
    tiers_mode: string;
    type: string;
    unit_amount: number;
    unit_amount_decimal: string;
  }

export function usePageCrudProductService() {
    const service = usePagedCrudService<IStripeProduct>({
        url: "/api/billing/product/",
        entityTitle: "Product",
        model: "djstripe_product"
    });

    return service;
}

const productsService = (function useProductsService() {
    const base = useBaseService();
    const url = "/api/billing/product/";

    const pcService = usePagedCrudService<IStripeProduct>({
        url: "/api/billing/product/",
        entityTitle: "Product",
        model: "djstripe_product"
    });

    // GET / -> Lists product, includes nested representation of list of product's choice
    const getProducts = (): Promise<IStripeProduct[]> => {
        return base._get(`${url}`);
    };

    //     GET /<id>/ -> Specific product details
    const getProductById = (id: string | number): Promise<IStripeProduct> => {
        return base._get(`${url}${id}/`);
    };

    const getDefaultOneTime = (): Promise<IStripeProduct> => {
        return base._get(`${url}default-one-time/`);
    };

    const hasPurchasedProduct = (product_id: string | number, integration_product: string): Promise<any> => {
        return base._get(`${url}${product_id}/purchased/?integration_product=${integration_product}`);
    };

    //     POST /<product_id/checkout/ -> Returns Stripe checkout URL to allow user to purchase. If the product has already been purchased by this company, returns HTTP 400.
    const checkout = (product_id: string | number, options?: Partial<TOptions>): Promise<string> => {
        return base._post(`${url}${product_id}/checkout/`, options);
    };

    return {
        ...pcService,
        getProducts,
        getProductById,
        getDefaultOneTime,
        hasPurchasedProduct,
        checkout,
    };
})();

export const useProductsService = () => productsService;
