import { usePagedCrudService } from "./crud.service";
import { IIntegrationProductReview } from "./integration-product-review.interface";

const integrationProductReviewService = (function() {
    const integrationProductReviewService = usePagedCrudService<IIntegrationProductReview>({
        url: "/api/mock/integration-product-review/",
        entityTitle: "Integration Product Review",
        model: "integrationproductreview"
    });

    return {
        ...integrationProductReviewService,
    };
})();

export const useIntegrationProductReviewService = () => integrationProductReviewService;