import React from "react";
import { Skeleton, Stack } from "@chakra-ui/react";

const APICredentialsSkeleton: React.FC<{ isLoaded: boolean }> = (props) => {
    return <Stack padding={4} spacing={1}>
        <Skeleton h='40px' isLoaded={props.isLoaded}>
        </Skeleton>
        <Skeleton
            h='40px'
            isLoaded={props.isLoaded}
            color='white'
            fadeDuration={1}
        >
        </Skeleton>
        <Skeleton
            h='40px'
            isLoaded={props.isLoaded}
            fadeDuration={4}
            color='white'
        >
        </Skeleton>
    </Stack>;
};

export default APICredentialsSkeleton;