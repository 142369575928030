import React, { useState, useEffect } from "react";

import { Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import BoxCard from "components/card/Card";
import { GridActionsButton, GridActionsRowButton, GridColumn, IFilterSettings, PagedGrid, Row, SelectionMode } from "components/paged-grid";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import { ICompany, IPermAction, useUserService } from "services";
import { IStripePrice, IStripeProduct, useProductsService } from "services/billing/products.service";
import { getTextWithCurrency } from "components/utils/currency-helper";
import { usePriceService } from "services/billing/price.service";
import { useAlert } from "components/alert-modal";
import AddBespokePrice from "./sign-up-fee/AddBespokePrice";

type Props = {
    product: IStripeProduct;
    onChange: (price: any) => void;
    onDeletePrice?: () => void;
    company: ICompany;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

const BespokePricesModal = (props: Props) => {

    const bespokePriceService = useBespokePriceService();
    const alertShow = useAlert();

    const { product, company, onDeletePrice, showModal, setShowModal } = props;

    const [ key, setKey ] = useState(0);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ showAddButton, setShowAddButton ] = useState(true);
    const [ addedPrice, setAddedPrice ] = useState<BespokeProductPrice>({} as BespokeProductPrice);

    useEffect(() => {
        bespokePriceService.getAll({ integration_product__company__id: company.id, product__id: product.id })
            .then(resp => {
                if (resp.length > 0) setShowAddButton(false);
            });
    }, []);

    const reloadGrid = () => setKey((value) => value + 1);

    const onAddPrice = (addedPrice: BespokeProductPrice) => {
        setAddedPrice(addedPrice);
        setShowAddButton(false);
        reloadGrid();
    };

    //#region Grid properties
    const columns: GridColumn[] = [
        {
            field: "company",
            header: "Company",
            getComponent: (row: Row<BespokeProductPrice>) => {
                return <Text as="span">{ company.name }</Text>;
            },
        },
        {
            field: "product",
            header: "Product",
            getComponent: (row: Row<BespokeProductPrice>) => {
                return <Text as="span">{product.name}</Text>;
            },
        },
        {
            field: "cost",
            header: "Cost",
            getComponent: (row: Row<BespokeProductPrice>) => {
                return <Text as="span">{getTextWithCurrency("gbp", row.data.cost / 100)}</Text>;
            },
        },
    ];

    const rowActions: GridActionsRowButton<BespokeProductPrice>[] = [
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<BespokeProductPrice>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete this price?",
                    onSuccess: () => {
                        bespokePriceService.deleteBespokePrice(row.data.id)
                            .then(() => {
                                setKey((value) => value + 1);
                                setShowAddButton(true);
                                onDeletePrice?.();
                            });
                    }
                });
            }
        }
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Price",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setIsVisible(true);
            },
            visible: () => showAddButton
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "integration_product__company__id",
            visible: () => false,
            getValue: () => company.id,
            getComponent: () => <></>,
        },
        {
            field: "product__id",
            visible: () => false,
            getValue: () => product.id,
            getComponent: () => <></>,
        },

    ];
    //#endregion

    const onCancel = () => {
        setShowModal(false);
    };

    const onSave = () => {
        props.onChange?.(addedPrice);
        onCancel();
    };

    return (
        <Modal isOpen={ showModal } onClose={ onCancel } closeOnOverlayClick={ false } size="xl">
            <ModalOverlay />
            <ModalContent bg="cardBG" color="text">
                <ModalHeader>Add/Change Bespoke Price</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex gap={2}>
                        <Heading size="md" mb={5}>
                            {`<${ product.name }> bespoke price`}
                        </Heading>
                        <Tooltip label='Only one bespoke price can be assigned to a given product/company pairing. If you want to change the price you must first delete the existing one.' fontSize='sm' ml={5}>
                            <InfoIcon />
                        </Tooltip>
                    </Flex>

                    <PagedGrid<BespokeProductPrice>
                        reloadKey={key}
                        columns={columns}
                        dataService={bespokePriceService}
                        rowActions={rowActions}
                        globalActions={globalActions}
                        selectionMode={SelectionMode.None}
                        filters={filters}
                    />
                    { isVisible &&
                        <AddBespokePrice isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            product={product}
                            onSuccess={onAddPrice}
                            company={company} />
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="secondary"
                        onClick={onCancel}
                    >Cancel</Button>
                    <Button
                        variant="primary"
                        ml={3}
                        isDisabled={ !addedPrice.id }
                        onClick={ onSave }
                    >Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default BespokePricesModal;