import React, { useEffect, useState } from "react";

import { Select, Spacer, Stack, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";

import ShowChangeBespokePrice from "./ShowChangeBespokePrice";
import { IIntegrationProductReview, SignUpFeeOptionChoice } from "services/integration-product-review.interface";
import { IStripeProduct, useProductsService } from "services/billing/products.service";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import { useAppStore } from "store";
import BespokePrices from "views/admin/billing/BespokePricesModal";
import { PurchasedResponse } from "../billing/sign-up-fee/SignUpFee";
import { SelectFormik } from "components/fields";

type Props = {
    formikInstance: any;
    integrationProductReview: IIntegrationProductReview;
    existPrice: (value: boolean) => void;
};

const SignUpFeeSelect = (props: Props) => {
    const productsService = useProductsService();
    const bespokeProductPriceService = useBespokePriceService();

    const company = useAppStore(state => state.company);
    const integrationProduct = useAppStore(state => state.integrationProduct);

    const { formikInstance, integrationProductReview, existPrice } = props;

    // signUpFee option states
    const [ showSignUpFeePrice, setShowSignUpFeePrice ] = useState(false);
    const [ showBespokePricesModal, setShowBespokePricesModal ] = useState(false);
    const [ signUpFeeProduct, setSignUpFeeProduct ] = useState<IStripeProduct>({} as IStripeProduct);
    const [ signUpFeePriceError, setSignUpFeePriceError ] = useState(false);
    const [ signUpFeeBespokePrice, setSignUpFeeBespokePrice ] = useState<BespokeProductPrice>({} as BespokeProductPrice);

    const [ signUpFeePaid, setSignUpFeePaid ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);

    useEffect(() => {
        const load = async () => {
            setShowSignUpFeePrice(integrationProductReview.sign_up_fee_option === SignUpFeeOptionChoice.bespoke);

            // product required for BespokePriceModal
            let _product: IStripeProduct = {} as IStripeProduct;
            if (integrationProductReview.sign_up_fee) {
                _product = await productsService.getProductById(integrationProductReview.sign_up_fee);
            } else {
                _product = await productsService.getDefaultOneTime();
            }
            setSignUpFeeProduct(_product);

            await bespokeProductPriceService.getAll({ integration_product__company__id: company.id, product__id: _product.id })
                .then(resp => {
                    if (resp.length > 0) {
                        setSignUpFeeBespokePrice(resp[0]);
                    }
                    setSignUpFeePriceError(resp.length === 0);
                });

            const purchased: PurchasedResponse = await productsService.hasPurchasedProduct(_product.djstripe_id, integrationProduct.id);
            setSignUpFeePaid(purchased.purchased);

            setIsLoaded(true);
        };

        load();
    }, []);

    const getSignUpFeeOptionSelectProps = (formik: FormikProps<any>, name: string) => ({
        name,
        value: String((formik.values as any)[name]),
        onChange: async (e: any) => {
            const value = Number.parseInt(e.target.value);
            formik.setFieldValue(name, value, true);
            setShowSignUpFeePrice(value === SignUpFeeOptionChoice.bespoke ? true : false);
        }
    });

    const showModalSignUpFee = () => setShowBespokePricesModal(true);

    const onChangePrice = async (addedPrice: BespokeProductPrice) => {
        await bespokeProductPriceService.getAll({ integration_product__company__id: company.id, product__id: signUpFeeProduct.id })
            .then(resp => {
                if (resp.length > 0) {
                    setSignUpFeeBespokePrice(resp[0]);
                    setSignUpFeePriceError(false);
                    existPrice(true);
                }
            });
    };

    const onDeletePrice = () => {
        setSignUpFeeBespokePrice({} as BespokeProductPrice);
        setSignUpFeePriceError(true);
        existPrice(false);
    };

    if (!isLoaded) return <></>;

    return <>
        <Stack direction={{ base: "column", lg: "row" }}>
            <Text>Sign Up Fee Option</Text>
            <Spacer />
            <Stack w={{ base: "100%", lg: "35%" }}>
                <SelectFormik
                    type="number"
                    //name="sign_up_fee_option"
                    placeholder='Select option'
                    disabled={signUpFeePaid}
                    {...getSignUpFeeOptionSelectProps(formikInstance, "sign_up_fee_option")}
                >
                    <option value={SignUpFeeOptionChoice.standard}>Standard</option>
                    <option value={SignUpFeeOptionChoice.bespoke}>Bespoke</option>
                    <option value={SignUpFeeOptionChoice.waive}>Waive</option>
                </SelectFormik>
            </Stack>
        </Stack>

        {showSignUpFeePrice && !signUpFeePaid &&
            <ShowChangeBespokePrice
                bespokePrice={signUpFeeBespokePrice}
                priceError={signUpFeePriceError}
                showModal={showModalSignUpFee} />
        }

        {showBespokePricesModal &&
            <BespokePrices
                product={signUpFeeProduct}
                onChange={onChangePrice}
                company={company}
                onDeletePrice={onDeletePrice}
                showModal={showBespokePricesModal}
                setShowModal={setShowBespokePricesModal}
            />
        }
    </>;
};

export default SignUpFeeSelect;
