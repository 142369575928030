import axios, { AxiosInstance } from "axios";
import { useAppStore } from "store";

const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL || "",
    timeout: 20000,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

client.interceptors.response.use(function (response) {
    const version = response.headers["version"] != "None"
        ? response.headers["version"]
        : "";
    const oldversion = process.env.REACT_APP_VERSION || "";
    const update = version && oldversion && version !== oldversion || false;

    sessionStorage.setItem("showUpdateBanner", String(update));
    sessionStorage.setItem("newversion", String(version));
    useAppStore.setState({ showUpdateBanner: update, newversion: version });

    return response;
}, function (error) {
    return Promise.reject(error);
});

export default client;
