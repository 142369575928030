import React from "react";

import {
    Flex,
    Image,
    useColorModeValue,
    ColorMode
} from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/color-mode";

import { HSeparator } from "components/separator/Separator";
import logoLight from "assets/img/logo/logo-on-light.svg";
import logoDark from "assets/img/logo/logo-on-dark.svg";

export function SidebarBrand() {
    const { colorMode } = useColorMode();

    return (
        <Flex align="center" direction="column">
            <Image
                src={colorMode === "light" ? logoLight : logoDark}
                w="250px"
                mb="20px"
                mr="auto"
                borderRadius="0px"
            />
            <HSeparator mb="12.5px" />
        </Flex>
    );
}

export default SidebarBrand;
