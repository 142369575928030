import { TOptions, usePagedCrudService } from "./crud.service";
import { ICompany } from "./company.interface";
import { useAppStore } from "store";

const companyService = (function () {
    const companyService = usePagedCrudService<ICompany>({
        url: "/api/mock/company/",
        entityTitle: "Company",
        model: "company"
    });

    const impersonate = (company: string): Promise<any> =>
        companyService._post(
            "/api/auth/impersonate/impersonate-company/", {
                payload: { company },
                successMessage: "Now impersonating"
            }
        );

    const impersonateEnd = (): Promise<any> =>
        companyService._post(
            "/api/auth/impersonate/restore-company/", {
                successMessage: "Finished impersonating"
            }
        );

    const update = (id: string, item: Partial<ICompany>, options?: Partial<TOptions>): Promise<ICompany> =>
        companyService.update(id, item, options).then((updatedCompany) => {
            // update appStore
            const _companyStore = useAppStore.getState().company;

            if (updatedCompany.id === _companyStore.id) {
                const _updateStoreState = {
                    ..._companyStore,
                    ...updatedCompany
                };
                useAppStore.setState({ company: _updateStoreState });
            }

            return updatedCompany;
        });

    return {
        ...companyService,

        impersonate,
        impersonateEnd,

        update
    };
})();

export const useCompanyService = () => {
    const company = useAppStore((state) => state.company);

    return {
        ...companyService,

        company
    };
};
