import { POST_CODE_MAXLEN, ADDRESS_MAXLEN } from "../constants";

export enum PatientGenderEnum {
    Male = 0,
    Female = 1,
    Indeterminate = 2,
    NotKnown = 3
}

export enum PatientSensitiveChoiceENUM {
    "no" = 0,
    "yes" = 1,
    "invalidated" = 2
}

export enum LinkStatusEnum {
    "Unregistered" = 0,
    "Registered"
}

export interface IPatient extends IPatientDetails, IPatientSettings {
    // id = models.UUIDField(primary_key=True, default=uuid4, editable=False)
    id: string;

    // Computed fields
    readonly gp_ods_code?: string;
    readonly gp_practice_name?: string;
    readonly gp_company_name?: string;
}

export interface IPatientDetails {
    // first_name = models.CharField(max_length=20)
    first_name: string;

    // surname = models.CharField(max_length=20)
    surname: string;

    // date_of_birth = models.DateField()
    date_of_birth: Date | null;

    // gender = models.IntegerField(choices=PatientGenderChoice.choices)
    gender: PatientGenderEnum;

    // nhs_number = models.CharField(max_length=10)
    nhs_number: string | null;

    // account_id = models.CharField(max_length=10)
    account_id: string;

    // linkage_key = models.CharField(max_length=20)
    linkage_key: string;

    // link_status = TinyIntegerField()
    link_status: number | null;

    // address_line_1 = models.CharField(max_length=35, blank=True, null=True)
    address_line_1?: string[typeof ADDRESS_MAXLEN];

    // address_line_2 = models.CharField(max_length=35, blank=True, null=True)
    address_line_2?: string[typeof ADDRESS_MAXLEN];

    // address_line_3 = models.CharField(max_length=35, blank=True, null=True)
    address_line_3?: string[typeof ADDRESS_MAXLEN];

    // address_line_4 = models.CharField(max_length=35, blank=True, null=True)
    address_line_4?: string[typeof ADDRESS_MAXLEN];

    // address_line_5 = models.CharField(max_length=35, blank=True, null=True)
    address_line_5?: string[typeof ADDRESS_MAXLEN];

    // post_code = models.CharField(max_length=10, blank=True, null=True)
    post_code?: string[typeof POST_CODE_MAXLEN];

    // description = models.CharField(max_length=500, blank=True, null=True)
    description?: string;

    // nominated_pharmacy = models.CharField(max_length=50, blank=True, null=True)
    nominated_pharmacy?: string;

    // telephone_number = models.CharField(max_length=20, blank=True, null=True)
    telephone_number?: string;

    // gp = models.ForeignKey(Gp, models.DO_NOTHING)
    gp: string;  // GP id

    // copied_from_patient = models.ForeignKey(
    //     "self", models.DO_NOTHING, blank=True, null=True
    // )
    copied_from_patient: string;


    // ADDED SETTINGS INTERFACE

    // appointments_enabled = TinyIntegerField(blank=True, null=True)
    appointments_enabled?: number | null;

    // medical_record_enabled = TinyIntegerField(blank=True, null=True)
    medical_record_enabled?: boolean;

    // repeat_prescriptions_enabled = TinyIntegerField(blank=True, null=True)
    repeat_prescriptions_enabled?: number | null;

    // list_of_medical_records_enabled = TinyIntegerField(blank=True, null=True)
    list_of_medical_records_enabled?: number | null;

    // deceased = TinyIntegerField(blank=True, null=True)
    deceased?: number | null;

    // sensitive = models.IntegerField(choices=PatientSensitiveChoice.choices, blank=True, null=True)
    sensitive?: number;

    include_for_clone?: boolean;
}

export interface IPatientSettings {
    // appointments_enabled = TinyIntegerField(blank=True, null=True)
    appointments_enabled?: number | null;

    // medical_record_enabled = TinyIntegerField(blank=True, null=True)
    medical_record_enabled?: boolean;

    // repeat_prescriptions_enabled = TinyIntegerField(blank=True, null=True)
    repeat_prescriptions_enabled?: number | null;

    // list_of_medical_records_enabled = TinyIntegerField(blank=True, null=True)
    list_of_medical_records_enabled?: number | null;

    // deceased = TinyIntegerField(blank=True, null=True)
    deceased?: number | null;

    // sensitive = models.IntegerField(choices=PatientSensitiveChoice.choices, blank=True, null=True)
    sensitive?: number;
}


