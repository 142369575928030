import React, { useState, useReducer, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
} from "@chakra-ui/react";
import AuthPage from "./components/AuthPage";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { TRegData, TRegDataConfirm } from "services/auth.type";
import { TextFieldFormik } from "components/fields";
import { useAuthService } from "services";

const regDataInitial: TRegData = {
    email: "",
    first_name: "",
    last_name: "",
    company_name: "",
};

const formValidationSchema = Yup.object({
    email: Yup.string()
        .email()
        .required("Email is a required field"),
    first_name: Yup.string()
        .max(30)
        .required("First Name is a required field"),
    last_name: Yup.string()
        .max(30)
        .required("Last Name is a required field"),
    company_name: Yup.string()
        .max(50, "Must be at most 50 characters")
        .required("Company is a required field"),
});

function CreateAccount() {
    const navigate = useNavigate();
    const authService = useAuthService();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showSecondView, setShowSecondView ] = useState(false);
    const [ email, setEmail ] = useState("");

    const onSubmit = async (values: TRegData, { setSubmitting, setErrors }: FormikHelpers<TRegData>) => {
        setIsLoading(true);

        await authService
            .registerv2(values)
            .then(() => {
                setEmail(values.email);
                setShowSecondView(true);
                setIsLoading(false);
            })
            .catch((errors) => {
                setErrors({ ...errors });
                setIsLoading(false);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <AuthPage>
            { !showSecondView &&
                <Flex
                    w={{ base: "90%", sm: "376px" }}
                    mx="auto"
                    justify="center"
                    alignItems="center"
                    px="43px"
                    py="35px"
                    flexDirection="column"
                    backgroundColor="authCardBG"
                    borderRadius={"card"}
                    boxShadow={"md"}
                    textAlign="center"
                >
                    <Heading
                        color="text"
                        fontSize="headingSmall.lg"
                        mb="9"
                        lineHeight="1.5"
                        fontWeight="600"
                    >
                        Sign Up
                    </Heading>
                    <Formik<TRegData>
                        enableReinitialize={true}
                        initialValues={regDataInitial}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<TRegData>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <Box>
                                    <TextFieldFormik
                                        w={"100%"}
                                        type="email"
                                        name="email"
                                        auth={true}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Email"
                                        required={true}
                                    />
                                    <TextFieldFormik
                                        w={"100%"}
                                        type="text"
                                        name="first_name"
                                        auth={true}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="First Name"
                                        required={true}
                                        maxLength={30}
                                    />
                                    <TextFieldFormik
                                        w={"100%"}
                                        type="text"
                                        name="last_name"
                                        auth={true}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Last Name"
                                        required={true}
                                        maxLength={30}
                                    />
                                    <TextFieldFormik
                                        w={"100%"}
                                        type="text"
                                        name="company_name"
                                        auth={true}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Company Name"
                                        maxLength={50}
                                    />
                                    <Button
                                        variant="primaryFullWidth"
                                        mb="3"
                                        type="submit"
                                        isLoading={isLoading}
                                    >
                                    Sign Up
                                    </Button>
                                </Box>
                            </FormControl>
                        )}

                    </Formik>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        maxW="100%"
                    >
                        <Text
                            color="textDetails"
                            fontWeight="400"
                            lineHeight={1.5}
                            fontSize="text.xs"
                        >
                            Already have an account?
                            <NavLink to="/auth/login">
                                <Text
                                    color="textBrand"
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                    Sign In
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            }

            {/* second view */}
            { showSecondView &&
                <Flex
                    w={{ base: "90%", sm: "376px" }}
                    mx="auto"
                    justify="center"
                    alignItems="center"
                    px="43px"
                    py="35px"
                    flexDirection="column"
                    backgroundColor="authCardBG"
                    borderRadius={"card"}
                    boxShadow={"md"}
                    textAlign="center"
                >
                    <Heading
                        color="text"
                        fontSize="headingSmall.lg"
                        mb="9"
                        lineHeight="1.5"
                        fontWeight="600"
                    >
                        Sign Up
                    </Heading>

                    <Text mb={5}>
                        We have sent an email to <i>{email}</i>. This email will allow you to set your password.
                    </Text>

                    <Button
                        variant="primaryFullWidth"
                        mb="5px"
                        isLoading={isLoading}
                        onClick={ () => navigate("/auth/login") }
                    >
                        Sign In
                    </Button>
                </Flex>
            }
        </AuthPage>
    );
}

export default CreateAccount;
