import { usePagedCrudService } from "./crud.service";
import { IApiClient } from "./api-client.interface";

export function useApiClientService() {
    const service = usePagedCrudService<IApiClient>({
        url: "/api/mock/api-client/",
        entityTitle: "Api Client",
        model: "cognitoapiclient"
    });

    return service;
}
