import React, { useContext, useEffect, useState } from "react";

import {
    Text,
    IconButton,
    Button,
    Box,
    Flex,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from "@chakra-ui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    SettingsIcon,
    DeleteIcon,
    TriangleUpIcon,
    TriangleDownIcon,
    AddIcon,
} from "@chakra-ui/icons";

import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
    IFiltersActions,
} from "components/paged-grid";

import CreateEditModal from "./components/CreateEditModal";
import EditApiKeysModal from "./components/EditApiKeys";
import { useAlert } from "components/alert-modal";

import { IPermAction, ICompany, useCompanyService, useUserService, useApiClientService, ApiScopeEnum, ApiClientEnv, IApiClient } from "services";
import BoxCard from "components/card/Card";

const defaultCompanyId = "00000000-0000-0000-0000-000000000001";

const rowPreview: RowPreview<ICompany> = {
    name: "Row data preview",
    height: "80px",
    visible: (row: Row<ICompany>) => true,
    getComponent: (row: Row<ICompany>) =>
        <Box textAlign="center" position="relative">
            <Box backgroundColor={"CG.3"}
                borderRadius="8px"
                position="relative"
                top="4px"
            >
                Row data preview:<br/>
                <strong>ID</strong>: {row.data.id}<br/>
                <strong>name</strong>: {row.data.name}
            </Box>
        </Box>
};

export default function ManageCompanies() {
    const companyService = useCompanyService();
    const userService = useUserService();
    const [ key, setKey ] = useState(0);
    const [ id, setId ] = useState("");
    const [ isVisible, setIsVisible ] = useState(false);
    const [ filterName, setFilterName ] = useState("");
    const [ isEditApiKeysVisible, setIsEditApiKeysVisible ] = useState(false);

    const reloadGrid = () => {
        // Force PagedGrid to reload the data
        setKey((value) => value + 1);
    };
    const alertShow = useAlert();

    const columns: GridColumn[] = [
        {
            field: "id",
            header: "ID",
            getComponent: (row: Row<ICompany>) => {
                return <Text as="span">{row.data.id?.substring(row.data.id.length - 6)}</Text>;
            },
        },
        {
            field: "name",
            header: "Company Name",
        },
        {
            field: "api_clients",
            header: "Cognito Keys",
            //props: { w: "100%" },
            getComponent: (row: Row<ICompany>) => {
                const live = row.data.api_clients?.filter((api_client) =>
                    api_client.environment === ApiClientEnv.live && api_client.is_valid
                ).length;

                const test = row.data.api_clients?.filter((api_client) =>
                    api_client.environment === ApiClientEnv.test && api_client.is_valid
                ).length;

                const invalid = row.data.api_clients?.filter((api_client) => !api_client.is_valid).length;

                return <Text>
                    { test ? `test: ${test} ` : "" }
                    { live ? `live: ${live} ` : "" }
                    { invalid ? `broken: ${invalid}` : "" }
                </Text>;
            },
        },
    ];

    const rowActions: GridActionsRowButton<ICompany>[] = [
        {
            text: "Manage Api Keys",
            icon: () => <SettingsIcon />,
            visible: () => userService.meIsSuperUser(),
            handler: (row: Row<ICompany>) => {
                setId(row.data.id);
                setIsEditApiKeysVisible(true);
            },
        },
        {
            text: "Edit",
            type: IPermAction.Change,
            visible: (row: Row<ICompany>) => row.data.id !== defaultCompanyId,
            handler: (row: Row<ICompany>) => {
                setId(row.data.id);
                setIsVisible(true);
            },
        },
        {
            text: "Delete",
            type: IPermAction.Delete,
            visible: (row: Row<ICompany>) => row.data.id !== defaultCompanyId,
            handler: (row: Row<ICompany>) => {
                if (row.data.id !== defaultCompanyId) {
                    alertShow({
                        header: "Warning",
                        message: "Are you sure you want to delete a company?",
                        onSuccess: () => {
                            companyService.delete(row.data.id).then(() => setKey((value) => value + 1));
                        }
                    });
                }
            },
        }
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setId("");
                setIsVisible(true);
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "name",
            match: () => "icontains",
            getValue: () => filterName, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Company Name"
                    value={filterName}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterName(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterName(value); }}
                ></Input>
            </Flex>
        },
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setFilterName("");
            reloadGrid();
        },
        onApply: () => reloadGrid()
    };

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<ICompany>
                    reloadKey={key}
                    columns={columns}
                    dataService={companyService}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    //rowPreview={rowPreview}
                    filters={filters}
                    filtersActions={filtersActions}
                />
            </BoxCard>

            <CreateEditModal
                id={id}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                onSuccess={reloadGrid}
            />

            <EditApiKeysModal
                id={id}
                isVisible={isEditApiKeysVisible}
                setIsVisible={setIsEditApiKeysVisible}
                onSuccess={reloadGrid}
            />

        </Box>
    );
}
