import React, { useState, useEffect, useMemo } from "react";
import {
    Text,
    Flex,
    IconButton,
    Button,
    Box,
    Checkbox,
    Tooltip,
    Input,
    Spinner,
    List,
    ListItem,
    Select,
} from "@chakra-ui/react";
import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
    IFiltersActions,
} from "components/paged-grid";
import {
    ICompany,
    IContract,
    IContractSigner,
    IPermAction,
    ITemplate,
    IUser,
    UserEnum,
    useCompanyService,
    useContractService,
    useTemplateService,
    useUserService,
    useIntegrationProductService,
    IIntegrationProduct
} from "services";
import { useAlert } from "components/alert-modal";
import BoxCard from "components/card/Card";
import CreateEditContract from "./components/CreateEditContract";
import { FaFileSignature } from "react-icons/fa";
import ManageTemplatesModal from "./components/ManageTemplatesModal";

type Props = {
    signContract?: boolean; // Sign Contract or Manage Contract pages
};

export default function ManageContracts(props: Props) {
    const { signContract = false } = props;
    const integrationProductService = useIntegrationProductService();
    const contractService = useContractService();
    const templateService = useTemplateService();
    const userService = useUserService();
    const companyService = useCompanyService();
    const { userData } = userService;
    const [ reloadKey, setReloadKey ] = useState(0);
    const [ id, setId ] = useState("");
    const [ isCreateEditVisible, setIsCreateEditVisible ] = useState(false);
    const [ integrationProducts, setIntegrationProducts ] = useState([] as IIntegrationProduct[]);
    const [ templates, setTemplates ] = useState([] as ITemplate[]);
    const [ users, setUsers ] = useState([] as IUser[]);
    const [ filterCompany, setFilterCompany ] = useState(
        signContract ? (userData.impersonated_company || userData.company) : ""
    );
    const [ filterByCompany, setFilterByCompany ] = useState(!signContract ? (userData.impersonated_company || userData.company) : "");
    const [ companies, setCompanies ] = useState<ICompany[]>([] as ICompany[]);
    const alertShow = useAlert();
    const [ isManageTemplatesVisible, setIsManageTemplatesVisible ] = useState(false);

    const reloadGrid = () => setReloadKey((value) => value + 1);

    const load = async () => {
        const _integration_products = await integrationProductService.getAll();
        const _templates = await templateService.getAll({ is_active: true });
        const _users = await userService.getAll({ status: UserEnum.Active });

        setIntegrationProducts(_integration_products);
        setTemplates(_templates);
        setUsers(_users);
    };

    useEffect(() => {
        load();
        companyService.getAll().then((companies) => setCompanies(companies));
    }, []);

    const columns: GridColumn[] = useMemo(() => [
        {
            field: "company_name",
            header: "Company",
            visible: () => !signContract,
            getComponent: (row: Row<IContract>) => {
                return <Text as="span">{row.data.company_name}</Text>;
            },
        },
        /*{ // Removed by Steve's request as we have one IP per Company
            field: "integration_product_name",
            header: "Integration Product",
            getComponent: (row: Row<IContract>) => {
                return <Text as="span">{row.data.integration_product_name}</Text>;
            },
        },*/
        {
            field: "template_name",
            header: "Template",
            getComponent: (row: Row<IContract>) => {
                return <Text as="span">{row.data.template_name}</Text>;
            },
        },
        {
            field: "signers",
            header: "Signatories",
            getComponent: (row: Row<IContract>) => {
                return <List>
                    {row.data.signers.map((signer: IContractSigner, i) => {
                        return <ListItem key={i}>{`${signer.user_first_name} ${signer.user_last_name} (${signer.role})`}</ListItem>;
                    })}
                </List>;
            },
        },
        {
            field: "status",
            header: "Status",
            getComponent: (row: Row<IContract>) => {
                return <>
                    <Text as="span">{row.data.status}</Text>
                    {/*(row.data.status?.toLowerCase() == "sent" || row.data.status?.toLowerCase() == "partiallysigned")
                        && <Spinner ml={2} size="xs" />*/}
                </>;
            },
        },
    ], [ signContract ]);

    const rowActions: GridActionsRowButton<IContract>[] = useMemo(() => [
        {
            text: "Sign Document",
            icon: () => <FaFileSignature />,
            variant: "ghost",
            visible: (row: Row<IContract>) => !!row.data.signers.find((s) => s.user === userData.id)?.role,
            handler: (row: Row<IContract>) => {
                const role = row.data.signers.find((s) => s.user === userData.id)?.role;

                contractService.initiateSigning(row.data.id, role).then((response) => {
                    if (response.redirect_url) {
                        window.open(response.redirect_url);
                    } else {
                        reloadGrid();
                    }
                });
            },
        },
        {
            text: "Edit",
            type: IPermAction.Change,
            visible: (row: Row<IContract>) => !signContract && row.data.status.toLowerCase() == "created",
            handler: (row: Row<IContract>) => {
                setId(row.data.id);
                setIsCreateEditVisible(true);
            },
        },
        {
            text: "Delete",
            type: IPermAction.Delete,
            visible: () => !signContract,
            handler: (row: Row<IContract>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete a contract?",
                    onSuccess: () => {
                        contractService.delete(row.data.id).then(() => {

                            reloadGrid();
                        });
                    }
                });
            },
        },
    ], []);

    const globalActions: GridActionsButton[] = useMemo(() => [
        {
            text: "Manage Contract Templates",
            variant: "primary",
            type: IPermAction.Sync,
            handler: async () => {
                setIsManageTemplatesVisible(true);
            },
            getComponent() {
                return <Button
                    variant="primary"
                    onClick={this.handler}
                >
                    {this.text}
                </Button>;
            },
        },
        {
            text: "Add Contract",
            variant: "secondary",
            type: IPermAction.Add,
            visible: () => !signContract,
            handler: () => {
                // Auto-fill is obsolete
                /*if (signContract && !userData.is_superuser) {
                    // auto-fill
                    contractService.create({}).then((response) => {
                        reloadGrid();
                    }).catch((error) => {
                    });
                } else*/ {
                    setId("");
                    setIsCreateEditVisible(true);
                }
            },
        },
    ], []);

    const filters: IFilterSettings[] = [
        {
            field: "integration_product__company",
            match: () => "exact",
            visible: () => !signContract,
            enabled: () => signContract,
            getValue: () => filterCompany, // "" === no filter
            getComponent: () => <></>,
        },
        {
            field: "integration_product__company",
            match: () => "exact",
            visible: () => !signContract,
            enabled: () => !signContract,
            getValue: () => filterByCompany, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        variant={"filter"}
                        value={filterByCompany}
                        placeholder="Search by Company..."
                        onChange={({ target: { value } }) =>
                            setFilterByCompany(value)
                        }
                    >
                        {companies.map((company, idx) => {
                            return <option key={idx} value={company.id}>{company.name}</option>;
                        })}
                    </Select>
                </Flex>
            ),
        },
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setFilterByCompany(userData.impersonated_company || userData.company);
            reloadGrid();
        },
        onApply: () => reloadGrid()
    };

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<IContract>
                    reloadKey={reloadKey}
                    dataService={contractService}
                    columns={columns}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    filters={filters}
                    filtersActions={filtersActions}
                    /*interval={{
                        timeout: 5000,
                        // Update if one of the contracts has 'Sent' or 'PartiallySigned' status
                        update: (rows: Row<IContract>[]) =>
                            rows.some((row: Row<IContract>) =>
                                row.data.status.toLowerCase() == "sent" || row.data.status.toLowerCase() == "partiallysigned"),
                    }}*/
                />
            </BoxCard>

            <CreateEditContract
                id={id}
                isVisible={isCreateEditVisible}
                setIsVisible={setIsCreateEditVisible}
                onSuccess={reloadGrid}
                integrationProducts={integrationProducts}
                users={users}
                templates={templates}
            />

            <ManageTemplatesModal
                isVisible={isManageTemplatesVisible}
                setIsVisible={setIsManageTemplatesVisible}
                onClose={() => {
                    templateService.getAll({ is_active: true }).then((_templates) => setTemplates(_templates));
                }}
            />
        </Box>
    );
}
