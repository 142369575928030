import { mode } from "@chakra-ui/theme-tools";
import { StyleFunctionProps } from "@chakra-ui/styled-system";

export const buttonStyles = {
    components: {
        Button: {
            baseStyle: {
                borderRadius: "card",
                transition: ".25s all ease",
                boxSizing: "border-box",
                textTransform: "uppercase",
                lineHeight: "16px",
                height: "40px",
                letterSpacing: "0.025em",
                _focus: {
                    boxShadow: "none",
                },
                _active: {
                    boxShadow: "none",
                },
            },
            defaultProps: {
                size: "md",
                variant: "primary",
            },
            sizes: {
                xs: {
                    fontSize: "text.xs",
                    py: "2",
                    px: "3",
                },
                md: {
                    fontSize: "text.sm",
                    py: "2",
                    px: "4",
                },
                lg: {
                    fontSize: "text.sm",
                    py: "2",
                    px: "8",
                }
            },
            variants: {
                primary: () => ({
                    color: "primaryButtonText",
                    backgroundColor: "primaryButtonBG",

                    _hover: {
                        opacity: 0.8,
                    },
                }),
                secondary: () => ({
                    color: "secondaryButtonText",
                    backgroundColor: "secondaryButtonBG",
                    border: "2px solid",
                    borderColor: "secondaryButtonText",

                    _hover: {
                        color: "CG.1",
                        backgroundColor: "secondaryButtonText",
                        opacity: 0.8,
                    },
                }),
                tertiary: () => ({
                    color: "tertiaryButtonText",
                    backgroundColor: "tertiaryButtonBG",
                    border: "2px solid",
                    borderColor: "transparent",

                    _hover: {
                        borderColor: "tertiaryButtonText",
                    },
                }),

                primaryFullWidth: () => ({
                    color: "primaryButtonText",
                    backgroundColor: "primaryButtonBG",
                    width: "100%",
                    _hover: {
                        opacity: 0.8,
                    },
                }),

                secondaryFullWidth: () => ({
                    color: "secondaryButtonText",
                    backgroundColor: "secondaryButtonBG",
                    border: "2px solid",
                    borderColor: "secondaryButtonText",
                    width: "100%",

                    _hover: {
                        color: "CG.1",
                        backgroundColor: "secondaryButtonText",
                        opacity: 0.8,
                    },
                }),

                // Note: OLD variants
                outline: () => ({
                    borderRadius: "xl",
                }),
                // Note: in fact, 'props' contains also ALL props from the Button component
                brand: (props: StyleFunctionProps) => ({
                    bg: mode("brand.500", "brand.400")(props),
                    color: "white",
                    _focus: {
                        bg: mode("brand.500", "brand.400")(props),
                    },
                    _active: {
                        bg: mode("brand.500", "brand.400")(props),
                    },
                    _hover: {
                        bg: mode("brand.600", "brand.400")(props),
                    },
                }),
                darkBrand: (props: StyleFunctionProps) => ({
                    bg: mode("brand.900", "brand.400")(props),
                    color: "white",
                    _focus: {
                        bg: mode("brand.900", "brand.400")(props),
                    },
                    _active: {
                        bg: mode("brand.900", "brand.400")(props),
                    },
                    _hover: {
                        bg: mode("brand.800", "brand.400")(props),
                    },
                }),
                lightBrand: (props: StyleFunctionProps) => ({
                    bg: mode("BTN.1", "whiteAlpha.100")(props),
                    color: mode("brand.500", "white")(props),
                    _focus: {
                        bg: mode("BTN.1", "whiteAlpha.100")(props),
                    },
                    _active: {
                        bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
                    },
                    _hover: {
                        bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
                    },
                }),
                light: (props: StyleFunctionProps) => ({
                    bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
                    color: mode("secondaryGray.900", "white")(props),
                    _focus: {
                        bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
                    },
                    _active: {
                        bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
                    },
                    _hover: {
                        bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
                    },
                }),
                action: (props: StyleFunctionProps) => ({
                    fontWeight: "500",
                    borderRadius: "50px",
                    bg: mode("secondaryGray.300", "brand.400")(props),
                    color: mode("brand.500", "white")(props),
                    _focus: {
                        bg: mode("secondaryGray.300", "brand.400")(props),
                    },
                    _active: {
                        bg: mode("secondaryGray.300", "brand.400")(props),
                    },
                    _hover: {
                        bg: mode("secondaryGray.200", "brand.400")(props),
                    },
                }),
                setup: (props: StyleFunctionProps) => ({
                    fontWeight: "500",
                    borderRadius: "50px",
                    bg: mode("transparent", "brand.400")(props),
                    border: mode("1px solid", "0px solid")(props),
                    borderColor: mode(
                        "secondaryGray.400",
                        "transparent"
                    )(props),
                    color: mode("secondaryGray.900", "white")(props),
                    _focus: {
                        bg: mode("transparent", "brand.400")(props),
                    },
                    _active: { bg: mode("transparent", "brand.400")(props) },
                    _hover: {
                        bg: mode("secondaryGray.100", "brand.400")(props),
                    },
                }),
            },
        },
    },
};
