import { useBaseService, TOptions } from "./crud.service";
import { TAuthData, TGoogleData, TRegData, TRegDataConfirm, IMfaResponse, IAuthDataMfa } from "./auth.type";

import objectToFormData from "./obj-toformdata";

const authService = (function() {
    const base = useBaseService();
    const url = "/api/auth/";

    const getToken = (): Promise<any> => {
        return base._get("/api/auth/csrftoken/");
    };

    const isAuthenticated = (): Promise<{isAuthenticated: boolean}> => {
        return base._get("/api/auth/isauthenticated/");
    };

    const isUserLocked = (): Promise<{ lockout_timeout: number, lockout: boolean }> => {
        return base._get("/api/auth/login/lockout/");
    };

    const login = (authData: TAuthData): Promise<IMfaResponse | undefined> => {
        return base._post("/api/auth/login/", {
            payload: objectToFormData(authData),
            successMessage: "Logged In"
        });
    };

    const loginMfa = (authDataMfa: IAuthDataMfa): Promise<any> => {
        return base._post("/api/auth/login/code/", {
            payload: objectToFormData(authDataMfa),
            successMessage: "Logged In"
        });
    };

    const logout = (): Promise<any> => {
        return base._post("/api/auth/logout/", { successMessage: "Logged Out" });
    };

    const registerv2 = (regData: TRegData): Promise<any> => {
        return base._post("/api/auth/registration/anonymous-invite/", {
            payload: objectToFormData(regData),
            successMessage: "Invite sent"
        });
    };

    const registerv2Confirm = (regData: TRegDataConfirm): Promise<any> => {
        return base._post("/api/auth/registration/", {
            payload: objectToFormData(regData),
            successMessage: "Registered"
        });
    };

    const getGoogleData = (): Promise<TGoogleData> => {
        return base._get<TGoogleData>("/api/auth/google/");
    };

    const loginGoogle = (access_token: string, options: Partial<TOptions> = {}): Promise<{ access_token: string }> => {
        return base._post("/api/auth/google/", {
            payload: objectToFormData({ access_token }),
            successMessage: "Logged In with Google",
            ...options
        });
    };

    const registerGoogle = ({ access_token, company_name }: any): Promise<any> => {
        return base._post("/api/auth/google/register/", {
            payload: objectToFormData({ access_token, company_name }),
            successMessage: "Registered with Google"
        });
    };

    // Accept invite using Google account instead of setting passwords
    const acceptInviteGoogle = ({ user, token, access_token }: any, options: Partial<TOptions> = {}): Promise<any> => {
        return base._post("/api/auth/google/accept-invite/", {
            payload: objectToFormData({ user, token, access_token }),
            successMessage: "Invite accepted with Google",
            ...options
        });
    };

    // For the logged users only: connect the local account to a Google one
    const connectGoogle = ({ access_token }: any): Promise<any> => {
        return base._post("/api/auth/google/connect/", {
            payload: objectToFormData({ access_token }),
            successMessage: "Connected to Google"
        });
    };

    const passwordReset = (email: string): Promise<any> => {
        return base._post("/api/auth/password/reset/", {
            payload: objectToFormData({ email }),
            successMessage: "Password reset e-mail has been sent"
        });
    };

    const passwordConfirm = (confData: any): Promise<any> => {
        return base._post("/api/auth/password/reset/confirm/", {
            payload: objectToFormData(confData),
            successMessage: "New password set"
        });
    };

    return {
        getToken,
        isAuthenticated,
        isUserLocked,
        login,
        loginMfa,
        logout,
        registerv2,
        registerv2Confirm,

        getGoogleData,
        loginGoogle,
        registerGoogle,
        acceptInviteGoogle,
        connectGoogle,

        passwordReset,
        passwordConfirm,
    };
})();

export const useAuthService = () => authService;
