import { useBaseService } from "services/crud.service";

const portalService = (function usePortalService() {
    const base = useBaseService();
    const url = "/api/billing/portal/";

    const getPortalURL = (): Promise<string> => {
        return base._get(`${url}`);
    };

    const redirectToPortal = (redirect_path: string): Promise<string> => {
        return base._post(url, { payload: { redirect_path: redirect_path } });
    };

    return {
        getPortalURL,
        redirectToPortal
    };
})();

export const usePortalService = () => portalService;