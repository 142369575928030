import React, { useState } from "react";

import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { useAlert } from "components/alert-modal";
import { ApiClientEnv, ApiScopeEnum, IApiClient, useApiClientService, useUserService } from "services";
import { useAppStore } from "store";

type Props = {
    userApiKeys: (apiClientData: IApiClient) => void;
    environment: ApiClientEnv;
}

const GenerateAPICredentials = (props: Props) => {
    const apiClientService = useApiClientService();
    const { userData } = useUserService();

    const company = useAppStore(state => state.company);

    const [ isLoading, setIsLoading ] = useState(false);
    const { userApiKeys, environment } = props;
    const alert = useAlert();

    const textColor = "gray.500";

    const generateApiCredentials = async () => {
        if (!userData.mfa_enabled) {
            alert({
                header: "2FA Required",
                message: "You need to have 2FA set on your account to generate your API Credentials.",
                yes: "OK",
                no: "null",
            });
            return;
        }

        setIsLoading(true);

        await apiClientService.create({
            company: company.id,
            environment: environment,
            scopes: [ ApiScopeEnum.Patient, ApiScopeEnum.Consultation, ApiScopeEnum.Pds ]
        })
            .then(resp => userApiKeys(resp))
            .finally(() => setIsLoading(false));
    };

    const envType = environment === ApiClientEnv.test ? "Test" : "Live";

    return <Box margin="1px auto">
        <Flex alignItems="flex-start" fontSize="1.2em" minH="100px">
            <Text
                textAlign={"center"}
                color={textColor}
                mt={0}
            >
                {environment === ApiClientEnv.test
                    ? "You need to have 2FA set on your account to generate API Credentials."
                    : "You need to have 2FA set on your account and submit your product for review to generate API Credentials."
                }
            </Text>
        </Flex>

        <Flex alignItems="center" fontSize="1.2em">
            <Button
                variant={"primary"}
                w="100%"
                isLoading={ isLoading }
                onClick={ generateApiCredentials }
            >
                Generate {envType} API Credentials
            </Button>
        </Flex>
    </Box>;
};

export default GenerateAPICredentials;
