import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Flex,
    Grid,
    GridItem,
    Text,
    Box,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { DropDownFormik, TextFieldFormik } from "components/fields";
import {
    IGP,
    ICompany,
    useCompanyService,
    useGPService,
    useUserService,
    ADDRESS_MAXLEN,
    POST_CODE_MAXLEN,
} from "services";

const ensurePropertiesValidValues = (obj: any, keysArray: string[]) => {
    const copyObj = { ...obj };

    keysArray.forEach((key) => {
        switch (key) {
        case "appointment_booking_reason":
        case "prescribing_comment":
            if (![ "notRequested", "requestedOptional", "requestedMandatory" ].includes(copyObj[key]))
                copyObj[key] = "notRequested";
            break;
        default:
            if (![ 1, 0, -1 ].includes(copyObj[key]))
                copyObj[key] = -1;
        }
    });


    return copyObj;
};

const replaceNullObj = (obj: any) => {
    const copyObj = {
        ...ensurePropertiesValidValues(obj, [
            "appointments_supported",
            "medical_record_supported",
            "repeat_prescriptions_supported",
            "appointment_booking_reason",
            "prescribing_comment",
        ]),
    };

    Object.keys(copyObj).map((key) => {
        if (copyObj[key] === null) {
            return (copyObj[key] = "");
        }
    });

    return copyObj;
};

type Props = {
    id: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void;
    gpData: IGP;
    [key: string]: any;
};

const gpBlank: IGP = {
    id: "",
    company: "",
    ods_code: "",
    practice_name: "",
    system: "",
    appointment_booking_reason: "",
    appointments_supported: -1,
    gp_fornames: "",
    gp_role: "",
    gp_surname: "",
    gp_title: "",
    medical_record_supported: -1,
    post_code: "",
    practice_address_line_1: "",
    practice_address_line_2: "",
    practice_address_line_3: "",
    practice_address_line_4: "",
    practice_address_line_5: "",
    prescribing_comment: "",
    repeat_prescriptions_supported: -1,
    disable_services: false,
};

const formValidationSchema = Yup.object({
    ods_code: Yup.string()
        .required("Required")
        .max(10, "Must be at most 10 characters"),
    practice_name: Yup.string()
        .required("Required")
        .max(45, "Must be at most 45 characters"),
    system: Yup.string().oneOf([ "EMIS", "Vision", "TPP" ]).required("System is a required field"),
    appointments_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    medical_record_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    repeat_prescriptions_supported: Yup.number().oneOf([ 0, 1, -1 ]).nullable(),
    appointment_booking_reason: Yup.string()
        .oneOf([ "notRequested", "requestedOptional", "requestedMandatory" ])
        .nullable(),
    prescribing_comment: Yup.string()
        .oneOf([ "notRequested", "requestedOptional", "requestedMandatory" ])
        .nullable(),
    practice_address_line_1: Yup.string()
        .max(ADDRESS_MAXLEN, `Must be at most ${ADDRESS_MAXLEN} characters.`)
        .nullable(),
    practice_address_line_2: Yup.string()
        .max(ADDRESS_MAXLEN, `Must be at most ${ADDRESS_MAXLEN} characters.`)
        .nullable(),
    practice_address_line_3: Yup.string()
        .max(ADDRESS_MAXLEN, `Must be at most ${ADDRESS_MAXLEN} characters.`)
        .nullable(),
    practice_address_line_4: Yup.string()
        .max(ADDRESS_MAXLEN, `Must be at most ${ADDRESS_MAXLEN} characters.`)
        .nullable(),
    practice_address_line_5: Yup.string()
        .max(ADDRESS_MAXLEN, `Must be at most ${ADDRESS_MAXLEN} characters.`)
        .nullable(),
    post_code: Yup.string()
        .max(POST_CODE_MAXLEN, `Must be at most ${POST_CODE_MAXLEN} characters.`)
        .matches(/^[a-zA-Z0-9 ]+$/, "Must be alphanumeric"),
    gp_title: Yup.string().max(10, "Must be at most 10 characters").nullable(),
    gp_fornames: Yup.string()
        .max(20, "Must be at most 20 characters")
        .nullable(),
    gp_surname: Yup.string()
        .max(20, "Must be at most 20 characters")
        .nullable(),
    gp_role: Yup.string().max(50, "Must be at most 50 characters").nullable(),
    company: Yup.string().required("Company is a required field"),
    disable_services: Yup.boolean().required("This field should not be blank"),
});

export default function CreateEditGP(props: Props) {
    const companyService = useCompanyService();
    const gpService = useGPService();
    const userService = useUserService();
    const { userData } = userService;
    const [ isSuperUser ] = useState(userService.meIsSuperUser());
    const { id, isVisible, setIsVisible, gpData, onSuccess } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ companies, setCompanies ] = useState<ICompany[]>([] as any);
    const [ gp, setGp ]
        = useState<IGP>({
            ...gpBlank,
            ...(isSuperUser ? {} : { company: userData.impersonated_company || userData.company })
        });

    useEffect(() => {
        setGp(id ? gpData : {
            ...gpBlank,
            ...(isSuperUser ? {} : { company: userData.impersonated_company || userData.company })
        });

        if (companies && companies.length === 0) {
            companyService.getAll().then((response) => {
                setCompanies(response);
            });
        }
    }, [ id ]);

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSubmit = (values: IGP, { setSubmitting, setErrors }: FormikHelpers<IGP>) => {
        if (id) {
            setIsLoading(true);
            gpService.update(id, values).then((gp) => {
                setGp(replaceNullObj(gp));
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            setIsLoading(true);
            gpService.create(values).then((response) => {
                setGp(gpBlank);
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    if (!isVisible) return null;

    const initialFormValues = replaceNullObj(gp);

    return (
        <Modal
            size={"5xl"}
            isOpen={isVisible}
            onClose={onCancel}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent bg="cardBG" color="text">
                <ModalHeader>
                    <Text color="text">{!id ? "Create GP" : "Edit GP"}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <Box borderTop="1px solid" borderColor="gray.300" mx="card" my="3" />
                <ModalBody>
                    <Formik<IGP>
                        enableReinitialize={true}
                        initialValues={initialFormValues}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<IGP>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <Grid templateColumns="repeat(2, 1fr)"
                                    color="text"
                                    fontSize="text.sm"
                                    columnGap={{ base: "5", md: "10", lg: "20" }}
                                    rowGap="5"
                                >
                                    <TextFieldFormik
                                        type="text"
                                        label="ODS Code"
                                        name="ods_code"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="ODS Code"
                                        required={true}
                                        maxLength={10}
                                    />
                                    <DropDownFormik
                                        label="System"
                                        name="system"
                                        placeholder={"Select a System"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        required
                                    >
                                        <option value={"EMIS"}>EMIS</option>
                                        <option value={"Vision"}>Vision</option>
                                        <option value={"TPP"}>TPP</option>
                                    </DropDownFormik>
                                    {isSuperUser && <DropDownFormik
                                        label="Company"
                                        name="company"
                                        placeholder="Select a Company"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        required
                                    >
                                        {companies.map((item: ICompany, i: number) =>
                                            <option key={i} value={item.id}>{item.name}</option>
                                        )}
                                    </DropDownFormik>}
                                </Grid>
                                <Grid templateColumns="repeat(2, 1fr)"
                                    color="text"
                                    fontSize="text.sm"
                                    columnGap={{ base: "5", md: "10", lg: "20" }}
                                    rowGap="5"
                                >
                                    <Text fontSize={"text.xl"} color="TBO.4" my="3">Practice Details</Text>
                                    <Text />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Name"
                                        name="practice_name"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Name"
                                        required={true}
                                        maxLength={45}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Address Line 1"
                                        name="practice_address_line_1"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Address Line 1"
                                        maxLength={ADDRESS_MAXLEN}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Address Line 2"
                                        name="practice_address_line_2"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Address Line 2"
                                        maxLength={ADDRESS_MAXLEN}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Address Line 3"
                                        name="practice_address_line_3"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Address Line 3"
                                        maxLength={ADDRESS_MAXLEN}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Address Line 4"
                                        name="practice_address_line_4"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Address Line 4"
                                        maxLength={ADDRESS_MAXLEN}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Practice Address Line 5"
                                        name="practice_address_line_5"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Practice Address Line 5"
                                        maxLength={ADDRESS_MAXLEN}
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="Post Code"
                                        name="post_code"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Post Code"
                                        maxLength={POST_CODE_MAXLEN}
                                    />
                                </Grid>
                                <Grid templateColumns="repeat(2, 1fr)"
                                    color="text"
                                    fontSize="text.sm"
                                    columnGap={{ base: "5", md: "10", lg: "20" }}
                                    rowGap="5"
                                >
                                    <Text fontSize={"text.xl"} color="TBO.4" my="3">GP Details</Text>
                                    <Text />
                                    <TextFieldFormik
                                        type="text"
                                        label="GP Title"
                                        name="gp_title"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="GP Title"
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="GP Fornames"
                                        name="gp_fornames"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="GP Fornames"
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="GP Surname"
                                        name="gp_surname"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="GP Surname"
                                    />
                                    <TextFieldFormik
                                        type="text"
                                        label="GP Role"
                                        name="gp_role"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="GP Role"
                                    />
                                </Grid>
                                <Box borderTop="1px solid" borderColor="gray.300" my="3" />
                                <Grid templateColumns="repeat(2, 1fr)"
                                    color="text"
                                    fontSize="text.sm"
                                    columnGap={{ base: "5", md: "10", lg: "20" }}
                                    rowGap="5">
                                    <Text fontSize={"text.xl"} color="TBO.4" my="3">Services</Text>
                                    <Text />
                                    <DropDownFormik
                                        type="number"
                                        label="Appointments Supported"
                                        name="appointments_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        label="Appointment Booking Reason"
                                        name="appointment_booking_reason"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={"notRequested"}>Not Requested</option>
                                        <option value={"requestedOptional"}>Requested Optional</option>
                                        <option value={"requestedMandatory"}>Requested Mandatory</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        type="number"
                                        label="Medical Record Supported"
                                        name="medical_record_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <Text/>
                                    <DropDownFormik
                                        type="number"
                                        label="Repeat Prescriptions Supported"
                                        name="repeat_prescriptions_supported"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                        <option value={-1}>Unknown</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        label="Prescribing Comment"
                                        name="prescribing_comment"
                                        placeholder={"Select"}
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                    >
                                        <option value={"notRequested"}>Not Requested</option>
                                        <option value={"requestedOptional"}>Requested Optional</option>
                                        <option value={"requestedMandatory"}>Requested Mandatory</option>
                                    </DropDownFormik>
                                    <DropDownFormik
                                        type="boolean"
                                        label="Disable Services"
                                        name="disable_services"
                                        required
                                    >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"}>No</option>
                                    </DropDownFormik>
                                </Grid>
                                <Flex
                                    align={"center"}
                                    alignItems={"center"}
                                    justifyContent="flex-end"
                                    mt="5"
                                >
                                    <Button
                                        isLoading={isLoading}
                                        variant="secondary"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        isLoading={isLoading}
                                        variant="primary"
                                        ml={3}
                                        type="submit"
                                        isDisabled={!(formik.isValid && formik.dirty)}
                                    >
                                        {!id ? "Create" : "Save"}
                                    </Button>
                                </Flex>
                            </FormControl>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
}
