export const repeatMedicationOriginalJson = {
    isProxied: true,
    requestId: "string",
    medications: [
        {
            lastIssued: "string",
            dose: "string",
            quantity: "string",
            medicineId: "string",
            canBeRequested: true,
            issueNumber: 0,
            medicineName: "string",
        },
    ],
};
