import React, { useState } from "react";

import { Box, Collapse, Text } from "@chakra-ui/react";

import {
    GridActionsButton,
    GridColumn,
    PagedGrid,
    Row,
    SelectionMode,
    IFilterSettings,
    GridActionsRowButton,
} from "components/paged-grid";
import { IPatient, IPermAction, IRepeatMedication, MedicationListResponse, useRepeatMedicationService } from "services";
import UploadJson from "components/upload-files/UploadJson";
import { repeatMedicationOriginalJson } from "./repeat-medication-json";
import DefaultSkeleton from "../DefaultSkeleton";
import CustomMoment from "components/utils/CustomMoment";
import { useAlert } from "components/alert-modal";

type Props = {
    activePatient: IPatient;
}

const RepeatMedications = (props: Props) => {
    const repeatMedicationService = useRepeatMedicationService();

    const alertShow = useAlert();

    const { activePatient } = props;

    const [ key, setKey ] = useState(0);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);

    const reloadGrid = () => {
        setKey(value => value + 1);
    };

    const columns: GridColumn[] = [
        {
            field: "medicine_name",
            header: "Medicine",
            props: { width: "40%" },
            getComponent: (row: Row<IRepeatMedication>) => {
                return (
                    <Box my={3}>
                        <Text as="span" fontSize={14}>
                            {row.data.medicine_name ?? ""}
                        </Text>
                    </Box>
                );
            },
        },
        {
            field: "last_issue_date",
            header: "Last Issue Date",
            props: { width: "40%" },
            getComponent: (row: Row<IRepeatMedication>) => {
                return (
                    <Box my={3}>
                        <Text as="span" fontSize={14}>
                            <CustomMoment date={row.data.last_issue_date} dateFormat="dd/MM/yyyy" />
                        </Text>
                    </Box>
                );
            },
        },
    ];

    const rowActions: GridActionsRowButton<IRepeatMedication>[] = [
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IRepeatMedication>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete the medication?",
                    onSuccess: () => {
                        repeatMedicationService.delete(row.data.id)
                            .then(() => {
                                reloadGrid();
                            })
                            .catch(err => console.log(err));
                    }
                });
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Repeat Medication JSON Data",
            type: IPermAction.Add,
            variant: "primary",
            handler: () => {
                setIsVisible(true);
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            getValue: () => activePatient.id,
            getComponent: () => <></>,
        },
    ];

    const dialogCloseHandler = () => {
        setIsVisible(false);
    };

    const onUploadhandler = async (jsonFile: MedicationListResponse) => {
        try {
            const medications: IRepeatMedication[] =
                jsonFile.medications.map((medication) => ({
                    id: "",
                    medicine_id: medication.medicineId || "",
                    medicine_name: medication.medicineName || "",
                    patient: activePatient.id || "",
                    can_be_requested: medication.canBeRequested,
                    dose: medication.dose,
                    issue_number: medication.issueNumber,
                    last_issue_date: new Date(medication.lastIssued),
                    quantity: medication.quantity
                }));

            await repeatMedicationService.createMany(medications);
            reloadGrid();
        } catch (error) {
            console.log(error);
        }
    };

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IRepeatMedication>
                        reloadKey={key}
                        columns={columns}
                        dataService={repeatMedicationService}
                        filters={filters}
                        rowActions={rowActions}
                        selectionMode={SelectionMode.None}
                        globalActions={globalActions}
                        isLoaded={gridLoaded}
                    />
                </Collapse>
            </Box>

            {isVisible && (
                <UploadJson
                    isVisible
                    header="Upload Repeat Medication Data"
                    onClose={dialogCloseHandler}
                    onSave={onUploadhandler}
                    jsonFile={repeatMedicationOriginalJson}
                />
            )}
        </>
    );
};

export default RepeatMedications;
