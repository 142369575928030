import React, { useEffect, useState } from "react";

import {
    Box,
    Flex,
    Text,
    Link,
    useColorModeValue,
    Select,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { ICompany, Role, useCompanyService, useUserService } from "services";
import DevelopCard from "./DevelopCard";
import PrepareForReviewCard from "./PrepareForReviewCard";
import GoLiveCard from "./GoLiveCard";

export default function ConvenetIntegration() {
    const companyService = useCompanyService();
    const userService = useUserService();
    const { userData } = useUserService();

    const [ canSelectCompany, setCanSelectCompany ] = useState(false);
    const [ companies, setCompanies ] = useState<ICompany[]>([]);

    const textLink = "TBO.5";
    const textColor = "text";
    const iconCheckColor = "#11A04A";

    const introductionTextColor = "gray.500";

    const isDeveloper = userService.meHasRole(Role.Developer) && !userService.meHasRole(Role.Developer);

    useEffect(() => {
        setCanSelectCompany(userService.meHasRole(Role.ConvenetUser) || userService.meHasRole(Role.ConvenetUser));
    }, [ userData ]);

    useEffect(() => {
        companyService.getAll().then(companies => {
            setCompanies(companies);
        }).catch(err => console.log(err));
    }, []);

    const onCompanyChange = async ({ target: { value } }: any) => {
        try {
            if (value !== userData.company) {
                await companyService.impersonate(value);
            } else {
                await companyService.impersonateEnd();
            }
            await userService.me();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box m={2} mt={6}>
            <Box color={introductionTextColor} lineHeight="5" fontSize="text.md">
                <Text>
                    The Convenet API allows easy access to NHS APIs.
                </Text>
                <Text>
                    To use the NHS APIs your product and business need to meet certain criteria, we suggest you
                    <Link
                        as={RouterLink}
                        to={"/app/convenet-integration/check-suitable"}
                        color={textLink}
                        mx={1}
                    >check that Convenet is suitable</Link>
                    before starting development of your product
                </Text>
            </Box>
            {canSelectCompany && <Flex
                mt={5}
                justify="left"
                direction={{ sm: "column", md: "row", lg: "row" }}
            >
                <Flex align={"center"} gap={5}>
                    <Text color={textColor}>Your selected company is: </Text>
                    <Flex>
                        <Select
                            onChange={ onCompanyChange }
                            isDisabled={isDeveloper}
                            value={userData.impersonated_company || userData.company}
                        >
                            {companies.map((company: ICompany, idx: number) =>
                                <option key={idx} value={company.id}>{company.name}</option>
                            )}
                        </Select>
                    </Flex>
                </Flex>
            </Flex>}
            <Flex
                justify="center"
                align="start"
                wrap={{ base: "wrap", md: "nowrap" }}
                mt={8}
                h="100%"
                position="relative"
                mb={{ base: "5", md: "0" }}
            >
                {/* Develop card */}
                <DevelopCard />

                {/* Prepare for review card */}
                <PrepareForReviewCard />

                {/* Go Live card */}
                <GoLiveCard />
            </Flex>
        </Box>
    );
}
