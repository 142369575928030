import React from "react";

import { Skeleton, SkeletonCircle, Stack } from "@chakra-ui/react";

import BoxCard from "components/card/Card";

const SubscriptionsSkeleton = () => {
    return (
        <BoxCard margin="1px auto" w={{ sm: "auto", md: "450px", lg: "450px" }} my={5}>
            <Stack margin="1px auto" w={"50%"} align="center" p={2}>
                <Skeleton h={3} w="100%"></Skeleton>
                <Skeleton h={3} w="100%"></Skeleton>
                <Skeleton h={3} w="100%"></Skeleton>
                <SkeletonCircle boxSize='200px' objectFit='cover' />
                <Skeleton h={5} w="100%"></Skeleton>
            </Stack>
        </BoxCard>
    );
};

export default SubscriptionsSkeleton;