import React, { useContext } from "react";

// chakra imports
import {
    Box,
    Flex,
    Drawer,
    DrawerBody,
    useColorModeValue,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
    renderThumb,
    renderTrack,
    renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SidebarContext } from "contexts/SidebarContext";
import { IMenuRoute } from "routes";

type SidebarProps = {
    routes: IMenuRoute[];
};

export default function Sidebar(props: SidebarProps) {
    const { routes } = props;

    const variantChange = "0.2s linear";
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
        "unset"
    );
    const sidebarMargins = "0px";

    return (
        <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
            <Box
                bg="themeBG"
                transition={variantChange}
                w="300px"
                h="calc(100vh - 32px)"
                m={sidebarMargins}
                minH="100%"
                overflowX="hidden"
                boxShadow={shadow}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <Content routes={routes} />
                </Scrollbars>
            </Box>
        </Box>
    );
}

type SidebarResponsiveProps = {
    routes: IMenuRoute[];
};

// FUNCTIONS
export function SidebarResponsive(props: SidebarResponsiveProps) {
    const { isOpen, onClose } = useContext(SidebarContext);

    const btnRef = React.useRef(null);

    const { routes } = props;

    return (
        <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement="left"
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent
                    w="285px"
                    maxW="285px"
                    bg="themeBG"
                >
                    <DrawerCloseButton
                        zIndex="3"
                        onClick={onClose}
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody maxW="285px" px="0rem" pb="0">
                        <Scrollbars
                            autoHide
                            renderTrackVertical={renderTrack}
                            renderThumbVertical={renderThumb}
                            renderView={renderView}
                        >
                            <Content routes={routes} />
                        </Scrollbars>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}
