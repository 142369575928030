import React, { useState, useEffect } from "react";

import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { AiOutlineDelete, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    PagedGrid,
    Row,
    RowPreview,
    SelectionMode,
    IFilterSettings,
} from "components/paged-grid";
import { IMedicalRecord, GetRecordResponse, IPermAction, useMedicalRecordService, IPatient } from "services";
import { useAlert } from "components/alert-modal";
import { medicalRecordOriginalJson } from "./medical-record-json";
import UploadJson from "components/upload-files/UploadJson";
import DefaultSkeleton from "../DefaultSkeleton";
import CustomMoment from "components/utils/CustomMoment";

const validateJson = (jsonFile: any) => {
    let json;
    try {
        json = JSON.parse(jsonFile);
    } catch (error) {
        json = jsonFile;
    }
    return json;
};

type Props = {
    activePatient: IPatient;
};

const MedicalRecord = (props: Props) => {
    const medicalRecordService = useMedicalRecordService();
    const alertShow = useAlert();

    const { activePatient } = props;

    const [ key, setKey ] = useState(0);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ medicalRecord, setMedicalRecord ] = useState<IMedicalRecord>({} as IMedicalRecord);

    const rowPreview: RowPreview<IMedicalRecord> = {
        name: "Row data preview",
        height: "auto",
        minHeight: "140px",
        visible: (row: Row<IMedicalRecord>) => true,
        getComponent: (row: Row<IMedicalRecord>) => {
            const parsedJson = validateJson(row.data.json);
            // const parsedJson = (row.data.json) as GetRecordResponse;
            return (
                <Box>
                    <Box top={5} pl={5}>
                        <ReactJson src={parsedJson}></ReactJson>
                    </Box>
                </Box>
            );
        },
    };

    const reloadGrid = () => {
        setKey((value) => value + 1);
    };

    const columns: GridColumn[] = [
        {
            field: "id",
            header: "Record",
            getComponent: (row: Row<IMedicalRecord>) => {
                return (
                    <Box my={3}>
                        <Text as="span" fontSize={14}>
                            Record 1
                        </Text>
                    </Box>
                );
            },
        },
        {
            field: "json",
            header: "Date",
            //props: { width: "100%" },
            getComponent: (row: Row<IMedicalRecord>) => {
                const jsonData = row.data.json as GetRecordResponse;
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <CustomMoment date={jsonData?.schema1?.medicalRecord?.dateOfBirth} dateFormat="dd/MM/yyyy" />
                        </Box>
                    </Flex>
                );
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Medical Record JSON Data",
            type: IPermAction.Add,
            variant: "primary",
            handler: () => {
                setIsVisible(true);
            },
        },
    ];

    const rowActions: GridActionsRowButton<IMedicalRecord>[] = [
        // {
        //     text: "View",
        //     type: IPermAction.View,
        //     handler: (row: Row<IMedicalRecord>) => {},
        //     getComponent(row: Row<IMedicalRecord>) {
        //         return (
        //             <Button
        //                 leftIcon={!row.unfolded ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        //                 mr={2}
        //                 variant="tertiary"
        //                 size="xs"
        //             >
        //                 View
        //             </Button>
        //         );
        //     },
        // },
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IMedicalRecord>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete a Medical Record?",
                    onSuccess: () => {
                        medicalRecordService.delete(row.data.id).then(() => {
                            setMedicalRecord({} as IMedicalRecord);
                            reloadGrid();
                        });
                    }
                });
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            getValue: () => activePatient.id,
            getComponent: () => <></>,
        },

    ];

    const dialogCloseHandler = () => {
        setIsVisible(false);
    };

    const onUploadhandler = async (jsonFile: GetRecordResponse) => {
        //save here the json file
        try {
            if (medicalRecord && medicalRecord.id) {
                const updatedRecord = { ...medicalRecord, json: jsonFile };

                await medicalRecordService.update(medicalRecord.id, updatedRecord);
            } else {
                const newMedicalRecord: IMedicalRecord = {
                    id: "",
                    json: jsonFile,
                    patient: activePatient.id
                };

                await medicalRecordService.create(newMedicalRecord);
            }

            setKey((value) => value + 1);
        } catch (error) {}
    };

    useEffect(() => {
        medicalRecordService.getAll({ patient: activePatient.id }).then((mr: any) => {
            setMedicalRecord(mr[0]);
        });
    }, []);

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IMedicalRecord>
                        reloadKey={key}
                        columns={columns}
                        dataService={medicalRecordService}
                        filters={filters}
                        selectionMode={SelectionMode.None}
                        globalActions={globalActions}
                        rowPreview={rowPreview}
                        rowActions={rowActions}
                        isLoaded={gridLoaded}
                    />
                </Collapse>
            </Box>

            {isVisible && (
                <UploadJson
                    isVisible
                    header="Replace Medical Record"
                    onClose={dialogCloseHandler}
                    onSave={onUploadhandler}
                    jsonFile={medicalRecordOriginalJson}
                />
            )}
        </>
    );
};

export default MedicalRecord;
