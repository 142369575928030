import { usePagedCrudService } from "services";
import { IAppointment } from "./appointment.interface";

const appointmentService = (function() {
    const service = usePagedCrudService<IAppointment>({
        url: "/api/mock/appointment/",
        entityTitle: "Appointment",
        model: "appointment"
    });

    return service;
})();

export const useAppointmentService = () => appointmentService;
