import { usePagedCrudService } from "services";
import { IProxy } from "./proxy.interface";

const proxyService =(function() {
    const proxyService = usePagedCrudService<IProxy>({
        url: "/api/mock/proxy/",
        entityTitle: "Proxy",
        model: "proxy"
    });

    return proxyService;
})();

export const useProxyService = () => proxyService;
