import React, { useState, useEffect, useRef, ChangeEvent, Fragment, memo } from "react";
import isEqual from "lodash/isEqual";
import { GridColumn, SortOrder } from "./grid-column.interface";
import {
    IFilterInfo,
    IFilterCommand,
    IFilterSettings,
    IFiltersActions,
    MatchType
} from "./filter.interface";
import { IPagedData } from "services/paged-data.interface";
import { SelectionMode } from "./selection-mode.enum";
import {
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
} from "./grid-actions.interface";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Select,
    Flex,
    Text,
    Button,
    Icon,
    IconButton,
    Checkbox,
    Tooltip,
    useBreakpointValue,
    Skeleton,
    TableProps,
    TableContainerProps,
    Alert,
    AlertTitle,
    AlertIcon,
    Stack,
    AlertDescription,
} from "@chakra-ui/react";
import {
    BiSortAlt2,
    BiSortUp,
    BiSortDown,
} from "react-icons/bi";
import { IoChevronForwardOutline } from "react-icons/io5";
import { AddIcon, ChevronLeftIcon, ChevronRightIcon, DeleteIcon, EditIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { FcCancel } from "react-icons/fc";

import "./select-fix.css";
import { IPermAction, TOptions, useUserService } from "services";
import OptionBox from "components/fields/OptionBox";

type PagedGridProps<T> = {
    reloadKey: number; // Force to reload the data
    dataService: {
        model: string;
        getPage: (filterCommand: IFilterCommand, options?: Partial<TOptions>) => Promise<IPagedData<T>>;
    };
    columns: GridColumn[];
    rowActions?: GridActionsRowButton<T>[];
    globalActions?: GridActionsButton[];
    selectionMode?: SelectionMode;
    rowPreview?: RowPreview<T>;
    //headBgColor?: string;
    //headTxtColor?: string;
    tableContainerProps?: TableContainerProps;
    tableProps?: TableProps;
    getData?: (gridData: T[]) => void;
    filters?: IFilterSettings[];
    //filtersWrap?: JSX.Element;
    filtersActions?: IFiltersActions,
    isLoaded?: () => void;
    rowsPerPage?: number;
    interval?: {
        timeout: number;
        update: (rows: Row<T>[]) => boolean;
    }
};

function _PagedGrid<TEntity>(props: PagedGridProps<TEntity>) {
    const userService = useUserService();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ rows, setRows ] = useState<Row<TEntity>[]>([] as any);
    const [ limit, setLimit ] = useState(props?.rowsPerPage || 10);
    const [ offset, setOffset ] = useState(0);
    const data = useRef<IPagedData<TEntity>>({} as any);
    const [ sortDirection, setSortDirection ]
        = useState<{ [key: string]: SortOrder }>(
            props.columns
                .filter((column) => column.sortable)
                .reduce((acc, column) => column.sortable
                    ? ({ ...acc, [column.field]: column?.sortDir || SortOrder.None })
                    : ({}), {})
        );
    const [ update, setUpdate ] = useState(0);
    const isCompact = useBreakpointValue<boolean>(
        { base: true, lg: false },
        { fallback: "base" }
    );
    const [ errorMessages, setErrorMessages ] = useState<string[]>([]);
    const [ showError, setShowError ] = useState(false);

    const visibleColumns = props.columns.filter((column) => column.visible ? column.visible() : true);

    const prevFilterRef = useRef<any[]>([] as any);

    const rowsPerPageOptions = [ 5, 10, 20 ];
    const selectionMode = props.selectionMode || SelectionMode.None;
    const showRowActions = !!props.rowActions?.length;

    // Force re-render the grid without loading new data
    const forceUpdate = () => setUpdate((value) => value + 1);
    const currentPage = (): string => {
        const count = data.current.count;
        const offsetMax = Math.min(offset + limit, count);

        return `${offset + 1} - ${offsetMax} of ${count}`;
    };

    const columnsNum = (): number => {
        return isCompact
            ? (
                Number(selectionMode !== SelectionMode.None) + Number(!!props.rowPreview) + 1
            )
            : (
                Number(selectionMode !== SelectionMode.None) +
                Number(!!props.rowPreview) +
                visibleColumns.length +
                Number(showRowActions)
            );
    };

    const incSortDirection = (field: string) => {
        let dir = sortDirection[field] || 0;
        dir = (dir + 1) % 3;
        setSortDirection({ [field] : dir });
    };

    const getSortDirection = (field: string): SortOrder => {
        return sortDirection[field] || 0;
    };

    const getSortIcon = (field: string) => {
        if (getSortDirection(field) === SortOrder.None)
            return BiSortAlt2;

        return getSortDirection(field) === SortOrder.Asc
            ? BiSortUp
            : BiSortDown;
    };

    const load = async () => {
        let filterCommand: IFilterCommand = {
            offset,
            limit
        };

        // update filterCommand
        if (props.filters) {
            const newFilters = props.filters.filter((filter: IFilterSettings): boolean => {
                const field = !!filter.field;
                const value = filter.getValue();
                const isValueNull = typeof value === "string" && value === "";
                const enabled = filter.enabled ? filter.enabled() : true;

                return field && !isValueNull && enabled;
            });

            // evaluate filter into plain object
            const evFilters = newFilters.map((filter) => ({
                name: filter.field,
                value: filter.getValue?.() || "",
                match: filter.match?.() || "",
            }));

            // reset offset if new != old filter and return
            // useEffect will re-execute load() again
            if (offset && !isEqual(prevFilterRef.current, evFilters)) {
                setOffset(0);
                return;
            }

            if (false) { // The old way to pass filters
                const suffix = (match: MatchType): string =>
                    (!match || match == "exact" ? "" : "__" + match);
                filterCommand = newFilters.reduce((acc, filter) =>
                    ({ ...acc, [filter.field + suffix(filter.match ? filter.match?.() : "")]: filter.getValue() }),
                {
                    ...filterCommand
                });
            } else { // Pass stringified object as filters="...."
                filterCommand.filters = JSON.stringify(newFilters.map((entry) => ({
                    name: entry.field,
                    value: entry.getValue(),
                    match: entry.match ? entry.match?.() || "" : ""
                } as IFilterInfo)));
            }
            prevFilterRef.current = evFilters;
        }

        // Update ordering
        const ordering = visibleColumns
            .filter((column) => column.sortable && getSortDirection(column.field))
            .map((column) => `${getSortDirection(column.field) === SortOrder.Asc ? "" : "-"}${column.field}`)
            .join(",");

        if (ordering) {
            filterCommand.ordering = ordering;
        } else {
            delete filterCommand.ordering;
        }

        setIsLoading(true);
        setShowError(false);
        setErrorMessages([]);

        return await props.dataService.getPage(
            filterCommand, {
                filterError: (axiosError) => axiosError.response?.status !== 403
            })
            .then((response: IPagedData<TEntity>) => {
                data.current = response;

                // passing getData prop to PagedGrid return the grid data to parent component
                if (props.getData) {
                    props.getData(response.results);
                }

                setRows(response.results.map((item) => ({
                    data: item,
                    checked: false,
                    unfolded: false,
                } as Row<TEntity>)));
            })
            .catch((error) => {
                const messages: string[] = Object.keys(error).map((key, i) =>
                    key === "non_field_errors" || key === "detail" ? error[key] : `${key}: ${error[key]}`);

                setShowError(true);
                setErrorMessages(messages);
            })
            .finally(() => {
                setIsLoading(false);
                props.isLoaded?.();
            });
    };

    const hasPerm = (type: IPermAction): boolean => {
        return userService.meHasPemissions(props.dataService.model, type);
    };

    useEffect(props.interval ? () => {
        const intervalID = setInterval(() => {
            const doUpdate = props.interval?.update?.(rows) ?? true;
            if (!isLoading && doUpdate)
                load();
        }, props.interval?.timeout || 2500);
        return () => clearInterval(intervalID);
    } : () => void(0), [ props.interval, rows ]);

    useEffect(() => {
        load();
    }, [ offset, limit, sortDirection, props.reloadKey ]);

    const changeRowsPerPageCount = (value: number) => {
        setLimit(value);
        setOffset(0);
    };

    // i +1/-1
    const changePage = (i: number) => {
        const newOffset = offset + limit * i;

        if (newOffset >= 0 && newOffset < data.current.count) {
            setOffset(newOffset);
        }
    };

    const renderFilters = (): JSX.Element | null => {
        if (!props.filters)
            return null;

        // visible ones
        const filters = props.filters.filter((f) => f.visible ? f.visible?.() : true);

        if (filters.length == 0)
            return null;

        const defaultWrapper =
            <Flex
                bg="transparent" px={0}
                borderRadius="8px"
                justifyContent="start"
                flexWrap="wrap"
            ></Flex>;

        const children = filters.map((filter, i: number) => (
            <Fragment key={i}>
                {filter.getComponent?.()}
            </Fragment>
        ));

        if (props.filtersActions) {
            children.push(
                <Fragment key={children.length}>
                    <Flex my="8px" ml="auto">
                        {props.filtersActions.onClear && <Button
                            variant="tertiary"
                            onClick={() => {
                                props.filtersActions?.onClear?.();
                            }}
                        >Clear</Button>}
                        {props.filtersActions.onApply && <Button
                            variant="secondary"
                            ml="3"
                            onClick={() => {
                                props.filtersActions?.onApply?.();
                            }}
                        >Filter</Button>}
                    </Flex>
                </Fragment>
            );
        }

        return React.cloneElement(/*props.filtersWrap ||*/ defaultWrapper, undefined, children);
    };

    const defaultIcons: { [key in IPermAction]?: JSX.Element } = {
        [IPermAction.View]: <TriangleDownIcon />,
        [IPermAction.Add]: <AddIcon />,
        [IPermAction.Change]: <EditIcon />,
        [IPermAction.Delete]: <DeleteIcon color={"#c94a4a"} />,
        [IPermAction.CancelInvite]: <FcCancel />,
    };

    const getRowActionComponent = (row: any, action: GridActionsRowButton<TEntity>): JSX.Element => {
        const icon = action.icon ? action.icon() : (
            action.type ? defaultIcons[action.type] : <></>
        );

        return (
            <Tooltip hasArrow label={action.text}>
                <IconButton
                    aria-label={action.text}
                    icon={icon}
                    size="text.lg"
                    variant="ghost"
                    w="40px"
                    mr={1}
                    onClick={() => action.handler?.(row)}
                />
            </Tooltip>
        );
    };

    const getGlobalActionComponent = (action: GridActionsButton): JSX.Element => {
        const icon = action.icon ? action.icon() : (
            action.type ? defaultIcons[action.type] : <></>
        );

        const variant = action.variant ?? "tertiary";

        return (
            <Button
                variant={variant}
                ml={"8px"}
                isDisabled={action.disabled ? action.disabled() : false}
                onClick={() => action.handler?.()}
            >{action.text}</Button>
        );
    };

    const columnSelect =
        selectionMode === SelectionMode.None ? null : (
            <Th
                color={"secondaryTextTable"}
                w="50px"
                borderBottom="1px solid" borderColor="tableBorderColor"
            >
                <Checkbox
                    isChecked={rows.every((a: any) => a.checked)}
                    onChange={({ target: { checked } }) => {
                        rows.forEach((row) => (row.checked = checked));
                        forceUpdate();
                    }}
                ></Checkbox>
            </Th>
        );

    const columnPreview =
        props.rowPreview ? (
            <Th px={0} w="16px"
                color={"secondaryTextTable"}
                borderBottom="1px solid" borderColor="tableBorderColor"
                // padding="12px 24px"
            ></Th>
        ) : null;

    const columnHeaders = visibleColumns.map((column: GridColumn, i) => (
        <Th
            key={i}
            padding="12px 24px"
            {...column.props}
            position="relative"
            _hover={column.sortable ? { cursor: "pointer", bg: "CG.2" } : null}
            fontSize={"text.xs"}
            color={"secondaryTextTable"}
            borderBottom="1px solid" borderColor="tableBorderColor"
            onClick={column.sortable ? () => {
                incSortDirection(column.field);
            } : void(0)}
        >
            <Flex display="flex" align="center">
                <Text as="span"
                    whiteSpace={column.sortable ? "normal" : undefined}
                    flexGrow={1}
                >
                    {column.header}
                </Text>
                {column.sortable ?
                    <Text as="i" ml="2">
                        <Icon as={getSortIcon(column.field)} height="5" width="5" />
                    </Text>
                    : null}
            </Flex>
        </Th>
    ));

    const columnActions = !showRowActions ? null : (
        <Th
            fontSize={"text.xs"}
            px="8px"
            borderBottom="1px solid" borderColor="tableBorderColor"
            color={"secondaryTextTable"}>
            Actions
        </Th>
    );

    const rowSelect = (row: Row<TEntity>) => {
        if (selectionMode === SelectionMode.None) return null;

        return (
            <Td
                px="8px" textAlign="center"
                borderBottom="1px solid" borderColor="tableBorderColor"
            >
                <Checkbox
                    isChecked={row.checked}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        row.checked = e.target.checked;
                        forceUpdate();
                    }}
                ></Checkbox>
            </Td>
        );
    };

    const rowPreview = (row: Row<TEntity>) => {
        if (!props.rowPreview) return null;

        return (
            <Td px={0} borderBottom="1px solid" borderColor="tableBorderColor">
                <IconButton
                    aria-label="Preview"
                    color="gray.400"
                    size="xs"
                    variant="ghost"
                    transform={!row.unfolded ? "rotate(0)" : "rotate(90deg)"}
                    icon={<IoChevronForwardOutline size="16px" />}
                    onClick={() => {
                        row.unfolded = !row.unfolded;
                        forceUpdate();
                    }}
                />

            </Td>
        );
    };

    const rowCells = (row: Row<TEntity>) => {
        return visibleColumns.map((column: GridColumn, i) => (
            <Td
                key={i}
                padding="26px 24px"
                {...column.props}
                borderBottom="1px solid"
                borderColor="tableBorderColor"
            >
                {column.getComponent?.(row) || (row.data as any)[column.field]}
            </Td>
        ));
    };

    const rowCellsDiv = (row: Row<TEntity>) => {
        return visibleColumns.map((column: GridColumn, i) => (
            <Flex
                flexDir="row"
                justifyContent="start"
                alignItems="center"
                key={i}
                py="6px"
                whiteSpace="normal"
            >
                <Box as="span" fontWeight={700} color="gray.600" textTransform="uppercase" w="50%" maxW="50%">
                    {column.header}
                </Box>
                <Box as="span" w="50%" maxW="50%">{column.getComponent?.(row) || (row.data as any)[column.field]}</Box>
            </Flex>
        ));
    };

    const rowActions = (row: Row<TEntity>) => {
        if (!showRowActions) return null;

        const actionsFilter = (action: GridActionsRowButton<TEntity>) => {
            const permitted = action.type ? hasPerm(action.type) : true;
            const visible = action.visible ? action.visible?.(row) : true;
            return permitted && visible;
        };

        return (
            <Td
                px="8px"
                borderBottom="1px solid" borderColor="tableBorderColor"
            >
                <Flex as="span" display="inline-flex" flexWrap="wrap">
                    {props.rowActions?.filter(actionsFilter).map(
                        (action: GridActionsRowButton<TEntity>, i) => (
                            <Text as="span" key={i} fontSize={"18px"}>
                                {!action.getComponent
                                    ? getRowActionComponent(row, action)
                                    : action.getComponent?.(row)}
                            </Text>
                        )
                    )}
                </Flex>
            </Td>
        );
    };

    const rowActionsDiv = (row: Row<TEntity>) => {
        if (!showRowActions) return null;

        const actionsFilter = (action: GridActionsRowButton<TEntity>) => {
            const permitted = action.type ? hasPerm(action.type) : true;
            const visible = action.visible ? action.visible?.(row) : true;
            return permitted && visible;
        };

        return (
            <Flex
                flexDir="row"
                flexWrap="wrap"
                justifyContent="start"
                alignItems="center"
            >
                <Box as="span" fontWeight={700} color="gray.600" textTransform="uppercase" w="50%">
                    Actions
                </Box>
                <Flex as="span" display="inline-flex">
                    {props.rowActions?.filter(actionsFilter).map(
                        (action: GridActionsRowButton<TEntity>, i) => (
                            <Text as="span" key={i} fontSize={"18px"}>
                                {!action.getComponent
                                    ? getRowActionComponent(row, action)
                                    : action.getComponent?.(row)}
                            </Text>
                        )
                    )}
                </Flex>
            </Flex>
        );
    };

    // Insert here the list of checked items in the grid?
    const globalActions = () => {
        const glActionsFilter = (action: GridActionsButton) => {
            const permitted = action.type ? hasPerm(action.type) : true;
            const visible = action.visible ? action.visible?.() : true;
            return permitted && visible;
        };

        return props.globalActions?.filter(glActionsFilter).map((action: GridActionsButton, i) => (
            <Text as="span" key={i}>
                {!action.getComponent
                    ? getGlobalActionComponent(action)
                    : action.getComponent?.(action)}
            </Text>
        ));
    };

    return <>
        {renderFilters()}
        <Skeleton isLoaded={!isLoading}><TableContainer
            borderRadius="6px"
            boxSizing="border-box"
            padding="16px"
            backgroundColor="white"
            color={"textTable"}
            pt={0}
            pl={0}
            pr={0}
            {...props.tableContainerProps}
        >
            <Table size="sm" layout="fixed" {...props.tableProps}>
                {/*<TableCaption placement="top">Grid placeholder</TableCaption>*/}
                <Thead
                    h={10}
                    borderBottom={"1px solid BOD.4"}

                >
                    <Tr fontSize={"16px"} borderBottom="1px solid" borderColor="tableBorderColor">
                        {columnSelect}
                        {columnPreview}
                        {!isCompact && <>
                            {columnHeaders}
                            {columnActions}
                        </>}
                        {isCompact && <>
                            <Th>Fields</Th>
                        </>}
                    </Tr>
                </Thead>
                <Tbody backgroundColor={"lightCardBackground"}>
                    {rows.map((row: Row<TEntity>, i) => (
                        <Fragment key={i}>
                            <Tr
                                key={i}
                                position="relative"
                                verticalAlign="baseline"
                                fontSize={"text.sm"}
                                borderBottom="1px solid" borderColor="tableBorderColor"
                                whiteSpace={{ base: "inherit", lg: "normal" }}
                            >
                                {rowSelect(row)}
                                {rowPreview(row)}
                                {!isCompact && <>
                                    {rowCells(row)}
                                    {rowActions(row)}
                                </>}
                                {isCompact && <Td>
                                    {rowCellsDiv(row)}
                                    {rowActionsDiv(row)}
                                </Td> }
                            </Tr>

                            {props.rowPreview && (
                                <Tr>
                                    <Box
                                        as="td"
                                        p="0"
                                        colSpan={columnsNum()}
                                        w="100%"
                                        color="text"
                                        backgroundColor="CG.1"
                                        boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                                    >
                                        <Box
                                            overflowY="hidden"
                                            transition="height 0.3s ease"
                                            height={!row.unfolded ? 0 : props.rowPreview.height}
                                            minHeight={!row.unfolded ? 0 : props.rowPreview.minHeight}
                                        >
                                            {props.rowPreview?.getComponent(row)}
                                        </Box>
                                    </Box>
                                </Tr>
                            )}
                        </Fragment>
                    ))}
                </Tbody>
            </Table>
        </TableContainer></Skeleton>
        {showError && <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
                {errorMessages.map((s, i) => <Text key={i} as="p">{s}</Text>)}
            </AlertDescription>
        </Alert>}
        <Box padding="12px 24px" fontSize={"text.sm"}>
            <Flex
                flexDirection={"row"}
                alignItems="center"
                justifyContent={"space-between"}
            >
                <Text as="div" pr="16px">
                    Show rows per page
                </Text>
                <Select
                    size="sm"
                    variant="pagination"
                    iconSize="20px"
                    w="75px"
                    className="select-fix-icon"
                    _hover={{ cursor: "pointer" }}
                    value={limit}
                    onChange={(e) =>
                        changeRowsPerPageCount(
                            parseInt(e.target.value)
                        )
                    }
                >
                    {rowsPerPageOptions.map((value, i) => (
                        <OptionBox key={i} value={value} >
                            {value}
                        </OptionBox>
                    ))}
                </Select>
                <Box flexGrow={"1"}></Box>
                <Box mx="16px">{currentPage()}</Box>
                <IconButton
                    aria-label="Left"
                    size="xs"
                    variant="ghost"
                    onClick={() => changePage(-1)}
                    icon={<ChevronLeftIcon boxSize={"16px"} />}
                />
                <IconButton
                    aria-label="Right"
                    size="xs"
                    variant="ghost"
                    onClick={() => changePage(+1)}
                    icon={<ChevronRightIcon boxSize={"16px"} />}
                />
            </Flex>
        </Box>
        {props.globalActions && props.globalActions.length > 0 && (
            <Box>
                <Flex flexDirection="row" justifyContent="flex-end">
                    {globalActions()}
                </Flex>
            </Box>
        )}
    </>;
}

const genericMemo: <TEntity>(component: TEntity) => TEntity = memo;

export const PagedGrid = genericMemo(_PagedGrid);
