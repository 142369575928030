import React, { useEffect, useState } from "react";

import { Box, Heading, Icon, useColorModeValue, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";

import BoxCard from "components/card/Card";
import { ApiClientEnv, IApiClient, useApiClientService, useUserService } from "services";
import { LiveIcon } from "../FigmaIcons";
import APICredentialsSkeleton from "./APICredentialsSkeleton";
import GenerateAPICredentials from "./GenerateAPICredentials";
import UserAPIKeys from "./UserAPIKeys";
import { VscDebugAlt } from "react-icons/vsc";
import { useAppStore } from "store";
import { isEmpty } from "lodash";

type Props = {
    environment: ApiClientEnv;
}

const CredentialCard = (props: Props) => {
    const apiClientService = useApiClientService();

    const company = useAppStore(state => state.company);
    const setApiCredentialsGenerated = useAppStore(state => state.setApiCredentialsGenerated);
    const setLiveCredentialsGenerated = useAppStore(state => state.setLiveCredentialsGenerated);

    const { userData } = useUserService();

    const [ isLoaded, setIsLoaded ] = useState(!userData.mfa_enabled);
    const [ apiKeys, setApiKeys ] = useState({} as IApiClient);
    const [ showCredentials, setShowCredentials ] = useState(false);

    const { environment } = props;

    const iconColor = useColorModeValue("BOD.2", "CG.1"); // BOD.2 | CG.1
    const infTextColor = "TBO.5";

    const envType = environment === ApiClientEnv.test ? "Test" : "Live";

    useEffect(() => {
        const load = async () => {
            const apiClients = await apiClientService.getAll({
                company: company.id,
                environment: environment,
                is_valid: true
            }, {
                filterError: (axiosError) => !(axiosError.response?.status == 403 &&
                    axiosError.response?.data["detail"] == "You don't have MFA enabled, it is required to perform this action.")
            });

            if (apiClients.length) {
                setApiKeys(apiClients[0]);
                setShowCredentials(true);
            }
        };

        if (userData.mfa_enabled) {
            load().finally(() => setIsLoaded(true));
        }
    }, []);

    const generatedUserAPIKeys = (data: IApiClient) => {
        setApiKeys(data);
        setShowCredentials(true);

        if (environment === ApiClientEnv.test)
            setApiCredentialsGenerated(!isEmpty(data) ? true : false);

        if (environment === ApiClientEnv.live)
            setLiveCredentialsGenerated(!isEmpty(data) ? true : false);
    };

    return (
        <BoxCard h="auto">
            <Box margin={"1vh auto"} h={20}>
                <Icon as={environment === ApiClientEnv.test ? VscDebugAlt : LiveIcon} width={20} height={20} color={iconColor} />
            </Box>

            <Box display={"inline-flex"} margin="1vh auto">
                <Heading size='md' color="text">
                    {envType} Account
                </Heading>
            </Box>

            { !isLoaded
                ? <APICredentialsSkeleton isLoaded={isLoaded} />
                : !userData.mfa_enabled
                    ? <Box display="flex" alignItems={"center"} justifyContent={"center"} mt={10}>
                        <AiOutlineInfoCircle />
                        <Text ml={2}>You need to have 2FA set on your
                            <Link
                                as={RouterLink}
                                color="TBO.5"
                                to="/app/profile/"
                            > account </Link>
                            to view your API Credentials.
                        </Text>
                    </Box>
                    : !showCredentials
                        ? <GenerateAPICredentials userApiKeys={generatedUserAPIKeys} environment={environment} />
                        : <UserAPIKeys userAPIKeys={apiKeys} environment={environment} />
            }

        </BoxCard>
    );

};

export default CredentialCard;
