import { IConsultation, usePagedCrudService } from "services";

export function useConsultationService() {
    const service = usePagedCrudService<IConsultation>({
        url: "/api/mock/consultation/",
        entityTitle: "Consultation",
        model: "consultation"
    });

    return service;
}
