export const linkStyles = {
    components: {
        Link: {
            baseStyle: {
                textDecoration: "none",
                boxShadow: "none",
                color: "textColor",
                _focus: {
                    boxShadow: "none",
                },
                _active: {
                    boxShadow: "none",
                },
                _hover: {
                    textDecoration: "underline",
                    border: "none",
                    color: "TBO.5"
                },
            },
            _hover: {
                textDecoration: "none",
                border: "none",
            },
        },
    },
};
