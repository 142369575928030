import React, { useEffect, useState, useCallback, useMemo, memo } from "react";

import {
    Box,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox,
} from "@chakra-ui/react";
import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
    IFiltersActions,
} from "components/paged-grid";
import {
    ICompany,
    IContract,
    ITemplateRecepient,
    IContractSigner,
    ITemplate,
    useContractService,
    useTemplateService,
    IPermAction,
    useUserService,
    IIntegrationProduct
} from "services";
import BoxCard from "components/card/Card";

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onClose?: () => void;
};

function ManageTemplatesModal(props: Props) {
    const { isVisible, setIsVisible } = props;
    const templateService = useTemplateService();
    const [ reloadKey, setReloadKey ] = useState(0);
    const [ updateTemplates, setUpdateTemplates ] = useState(false);

    const onCancel = () => {
        setIsVisible(false);
        props.onClose?.();
    };

    const columns: GridColumn[] = useMemo(() => [
        {
            field: "name",
            header: "Name",
            props: { },
            getComponent: (row: Row<ITemplate>) => {
                return <Text as="span">{row.data.name}</Text>;
            },
        },
        {
            field: "is_active",
            header: "Active",
            props: { w: "24px" },
            getComponent: (row: Row<ITemplate>) => {
                return <Checkbox isReadOnly={true} isChecked={row.data.is_active} />;
            },
        },
    ], []);

    const globalActions: GridActionsButton[] = useMemo(() => [
        {
            text: "Update Contract Templates",
            variant: "primary",
            type: IPermAction.Sync,
            handler: () => {
                setUpdateTemplates(true);
                templateService.syncTemplates().then(() => setUpdateTemplates(false));
            },
            getComponent() {
                return <Button
                    variant="primary"
                    isLoading={updateTemplates}
                    onClick={this.handler}
                >
                    {this.text}
                </Button>;
            },
        },
    ], [ updateTemplates ]);

    if (!isVisible)
        return null;

    return (
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Manage Contract Templates</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BoxCard variant="table">
                        <PagedGrid<ITemplate>
                            reloadKey={reloadKey}
                            dataService={templateService}
                            columns={columns}
                            //rowActions={rowActions}
                            globalActions={globalActions}
                            selectionMode={SelectionMode.None}
                            //filters={filters}
                            //filtersActions={filtersActions}
                            rowsPerPage={5}
                            tableProps={{ layout: "auto", w: "auto" }}
                        />
                    </BoxCard>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={updateTemplates}
                        variant="secondary"
                        onClick={onCancel}
                    >Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default memo(ManageTemplatesModal);
