import React, { useEffect, useState } from "react";

import { Box, Text } from "@chakra-ui/react";

import BoxCard from "components/card/Card";
import { usePatientService, useUserService } from "services";
import Patients from "../patients";

const TestPatients = () => {
    const patientService = usePatientService();
    const { userData } = useUserService();

    const [ showGenerateButton, setShowGenerateButton ] = useState(false);

    const load = async (company?: string) => {
        const patients = await patientService.getPage(
            {
                gp__company__id: !company
                    ? userData.impersonated_company || userData.company
                    : company,
                limit: 1
            });
        setShowGenerateButton(patients.count === 0);
    };

    useEffect(() => {
        load();
    }, []);

    const generateBtnHandler = () => setShowGenerateButton(false);

    const onChangeCompany = (company: string) => {
        load(company);
    };

    return (
        <Box mt={6}>
            {showGenerateButton && (
                <BoxCard w={{ sm: "auto", md: "400px", lg: "400px" }} align="center" margin={"auto"}>
                    <Text>It seems that your company has no assigned Test Patients yet, you can generate them by clicking the <b>Generate Test Patients</b> button!</Text>
                </BoxCard>
            )
            }

            <Box mt={2}>
                <Patients
                    testPatients={true}
                    pt="0px"
                    showGenerateButton={showGenerateButton}
                    generateBtnHandler={generateBtnHandler}
                    onChangeCompany={onChangeCompany}
                />
            </Box>
        </Box>
    );
};

export default TestPatients;
