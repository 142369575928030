import BoxCard from "components/card/Card";
import { GridColumn, PagedGrid, Row, SelectionMode } from "components/paged-grid";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IStripeInvoice } from "services/billing/invoice.interface";
import { useInvoiceService } from "services/billing/invoices.service";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import { getCurrencySymbol } from "components/utils/currency-helper";

const SuccessPayment = () => {
    // TODO: rework
    const invoiceService = useInvoiceService();

    const [ reloadKey, setReloadKey ] = useState(0);
    const [ invoicesData, setInvoicesData ] = useState<IStripeInvoice[]>([]);

    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: true });

    useEffect(() => {
    }, []);

    const [ searchParams, setSearchParams ] = useSearchParams();

    const success = !!searchParams.get("success");
    const session_id = searchParams.get("session_id");

    const columns: GridColumn[] = [
        {
            field: "amount_due",
            header: "Amount",
            sortable: true,
            getComponent: (row: Row<IStripeInvoice>) => {
                return <Flex gap={3}>
                    <Text fontWeight="bold">{getCurrencySymbol(row.data.currency)} {row.data.amount_due}</Text>
                    <Text as="span">{row.data.currency.toUpperCase()}</Text>
                </Flex>;
            },
        },
        {
            field: "status",
            header: "",
            sortable: true,
            getComponent: (row: Row<IStripeInvoice>) => {
                return <Flex gap={3}>
                    <Text as="span">{row.data.status.toUpperCase()}</Text>
                </Flex>;
            },
        },
        {
            field: "number",
            header: "Invoice Number",
            sortable: true,
            getComponent: (row: Row<IStripeInvoice>) => {
                return <Flex gap={3}>
                    <Text as="span">{row.data.number}</Text>
                </Flex>;
            },
        },
        {
            field: "customer_email",
            header: "Client",
            sortable: true,
            getComponent: (row: Row<IStripeInvoice>) => {
                return <Flex gap={3}>
                    <Text as="span">{row.data.customer_email}</Text>
                </Flex>;
            },
        },
    ];

    return <>
        <BoxCard variant="table" mt={6}>
            {isVisible ? (
                <Alert
                    status='success'
                    variant='left-accent'
                    pr={1}
                >
                    <Flex w="97%">
                        <AlertIcon />
                        <Text>Invoice Paid!</Text>

                    </Flex>
                    <CloseButton
                        onClick={onClose}
                    />
                </Alert>
            ) : null }
            <Heading margin={"3px auto"}>Historical Invoices</Heading>
            <PagedGrid<IStripeInvoice>
                reloadKey={reloadKey}
                dataService={invoiceService}
                columns={columns}
                // rowActions={rowActions}
                // globalActions={globalActions}
                selectionMode={SelectionMode.None}
                // filters={filters}
            />
        </BoxCard>
    </>;
};

export default SuccessPayment;