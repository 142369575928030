import React, { useState } from "react";

import { Button, FormControl, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { TextFieldFormik } from "components/fields";
import { ICompany, useIntegrationProductService } from "services";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import { IStripeProduct } from "services/billing/products.service";
import { useAppStore } from "store";
import { CurrencyInput } from "components/fields/CurrencyInput";

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: (addedPrice: BespokeProductPrice) => void,
    product: IStripeProduct;
    company: ICompany;
    [key: string]: any;
};

const priceBlank: Partial<BespokeProductPrice> = {
    // id: "",
    integration_product: "",
    cost: "",
    // price: "",
    product: "",
};

const formValidationSchema = Yup.object({
    integration_product: Yup.string(),
    // cost: Yup.number().max(10000000, "Cost must be less than or equal to 7 digits."),
    cost: Yup.string().required("This field may not be empty."),
    // price: Yup.string(),
    product: Yup.string(),
});

const AddBespokePrice = (props: Props) => {
    const bespokePriceService = useBespokePriceService();

    const integrationProduct = useAppStore(state => state.integrationProduct);

    const { isVisible, setIsVisible, product, company } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ price, setPrice ] = useState({ ...priceBlank, company: company.name ?? "", product: product.name });

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSubmit = async (values: Partial<BespokeProductPrice>, { setSubmitting, setErrors }: FormikHelpers<Partial<BespokeProductPrice>>) => {
        // replace all non digits characters
        const costInPence = Number.parseInt((values.cost ?? 0).toString().replace(/\D/g, ""));
        const formValues = { integration_product: integrationProduct.id, cost: costInPence, product: product.id };
        setIsLoading(true);
        bespokePriceService.createBespokePrice(formValues).then((resp) => {
            setIsLoading(false);
            props.onSuccess?.(resp);
            setIsVisible(false);
        }).catch((error) => {
            setErrors(error);
            setIsLoading(false);
        }).finally(() => setSubmitting(false));
    };

    if (!isVisible)
        return null;

    return (
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <Formik<Partial<BespokeProductPrice>>
                enableReinitialize={true}
                initialValues={price}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<Partial<BespokeProductPrice>>) => (
                    <FormControl as="form" onSubmit={formik.handleSubmit as any}>
                        <ModalContent bg="cardBG" color="text">
                            <ModalHeader>Add Price</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TextFieldFormik
                                    label="Company"
                                    type="text"
                                    name="company"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Company"
                                    disabled={true}
                                />
                                <TextFieldFormik
                                    label="Product"
                                    type="text"
                                    name="product"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Product"
                                    disabled={true}
                                />
                                <CurrencyInput
                                    label="Cost"
                                    name="cost"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="£0.00"
                                    required={true}
                                    maxLength={10}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    isLoading={isLoading}
                                    variant="secondary"
                                    onClick={onCancel}
                                >Cancel</Button>
                                <Button
                                    isLoading={isLoading}
                                    variant="primary"
                                    ml={3}
                                    type="submit"
                                >Create</Button>
                            </ModalFooter>
                        </ModalContent>
                    </FormControl>
                )}
            </Formik>
        </Modal>
    );
};

export default AddBespokePrice;
