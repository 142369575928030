import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Flex,
    IconButton,
    Link,
    Select,
    Spacer,
    Stack,
    Text,
    Tooltip,
    FormControl,
    Icon,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useColorModeValue } from "@chakra-ui/system";
import { HiOutlineDocument } from "react-icons/hi";
import { BiDownload, BiRefresh } from "react-icons/bi";

import BoxCard from "components/card/Card";
import UploadFile from "components/upload-files/UploadFile";
import { AcceptFiles } from "components/upload-files/accept-files.interface";
import {
    IFileUploadRequest,
    IFileUploadResponse,
    useFileService,
    ICompany,
    IIntegrationProduct,
    InProgressCompleteChoice,
    YesNoChoice,
    useIntegrationProductService,
    useCompanyService,
    DocumentTypeChoice
} from "services";
import useTicks from "hooks/use-ticks";
import { useAppStore } from "store";
import {
    InputFormik, SelectFormik
} from "components/fields";
import { omit } from "lodash";
import { RiDeleteBinLine } from "react-icons/ri";
import Prompt from "components/Prompt";

type MixedData = Partial<ICompany> & Partial<IIntegrationProduct>;

const blankData: MixedData = {
    data_protection_status: undefined,
    privacy_policy: "",
    dpia: "",
    ico_reg_number: "",
    medical_device: undefined, // Product
    dcb0129_status: undefined,
    // incident_management_log
    // clinical safety
    // hazard log
    // compliance assessment
    clinical_safety_officer: undefined,
    cso_email: "",
    iso27001: undefined // Product
};

const formValidationSchema = Yup.object({
    privacy_policy: Yup.string()
        .url("This field must be a valid URL")
        .nullable(),
    data_protection_status: Yup.number().nullable(),
    dpia: Yup.string()
        .url("This field must be a valid URL")
        .nullable(),
    ico_reg_number: Yup.string()
        .nullable(),
    medical_device: Yup.number().nullable(),
    clinical_safety_officer: Yup.string()
        .nullable(),
    cso_email: Yup.string()
        .email("This field must be a valid email")
        .nullable(),
});

const getShortFileName = (fileName: string) => {
    if (!fileName)
        return "";

    const extensionIndex = fileName.lastIndexOf(".");
    const hasExtension = extensionIndex !== -1;
    const fileNameLength = fileName.length;

    if (fileNameLength <= 20)
        return fileName;

    const newFileName = fileName.substring(0, 15);
    const extension = hasExtension ? fileName.substring(extensionIndex, fileNameLength) : "";
    const lastChar = hasExtension ? fileName[extensionIndex - 1] : "";

    const shortFileName = `${newFileName}...${lastChar}${extension}`;

    return shortFileName;
};
const downloadBucket = process.env.REACT_APP_DOWNLOAD_BUCKET || "#";
const Clinicial_Safety_Report_URL = `https://${downloadBucket}.s3.eu-west-2.amazonaws.com/downloads/Template%20-%20Clinical%20Safety%20Case%20Report%20-%20DCB0129.docx`;
const Hazard_Log_URL = `https://${downloadBucket}.s3.eu-west-2.amazonaws.com/downloads/HazardLog%20Template.xlsx`;

const DeveloperCompany = () => {
    const integrationProductService = useIntegrationProductService();
    const companyService = useCompanyService();
    const fileService = useFileService();

    const { updateInformationGovernanceTick } = useTicks();

    const integrationProduct = useAppStore(state => state.integrationProduct);
    const company = useAppStore(state => state.company);

    const [ isLoaded, setIsloaded ] = useState(false);
    const [ mixedData, setMixedData ] = useState<MixedData>({
        data_protection_status: company.data_protection_status,
        privacy_policy: company.privacy_policy ?? "",
        dpia: company.dpia ?? "",
        ico_reg_number: company.ico_reg_number ?? "",
        medical_device: integrationProduct.medical_device,
        dcb0129_status: company.dcb0129_status,
        clinical_safety_officer: company.clinical_safety_officer ?? "",
        cso_email: company.cso_email ?? "",
        iso27001: integrationProduct.iso27001
    });

    const [ isVisible, setIsVisible ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ accept, setAccept ] = useState({} as AcceptFiles);
    const [ documents, setDocuments ] = useState<{ [key: string]: IFileUploadResponse }>({} as any);
    const [ uploadType, setUploadType ] = useState<DocumentTypeChoice | undefined>(undefined);

    const iconCheckColor = "TBO.5";
    const iconUncheckColor = useColorModeValue("#51547F", "#F2F0F7");

    useEffect(() => {
        const load = async () => {
            const _files = await fileService.getAll({ company: company.id });

            const _documents = Object.values(DocumentTypeChoice)
                .filter(e => typeof e === "string")
                .reduce((acc, key) => ({
                    ...acc,
                    [key]: _files.find((file) => file.type === (DocumentTypeChoice as any)[key]) || {}
                }), {});
            setDocuments(_documents);

            setIsloaded(true);
        };

        load();

        return () => {
            // Update HomePage "Complete Information Governance" Tick
            updateInformationGovernanceTick();
        };
    }, []);

    const dialogCloseHandler = () => {
        setIsVisible(false);
    };

    const uploadBtnHandler = (type: DocumentTypeChoice) => {
        setAccept({
            accept: {
                ...(type == DocumentTypeChoice.screenshot ? {
                    // Image formats
                    "image/jpeg": [ ".jpg", ".jpeg" ],
                    "image/png": [],
                    "image/gif": [],
                    "image/svg+xml": [],
                    "image/svg": [],
                    "image/webp": [],
                    "image/bmp": [],
                } : {}),
                ...(type != DocumentTypeChoice.screenshot ? {
                    // MS Office formats
                    "application/msword": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                    "application/vnd.ms-excel": [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
                    // Mac formats
                    "application/vnd.apple.pages": [],
                    "application/vnd.apple.numbers": [],
                    // PDF
                    "application/pdf": [],
                } : {}),
            },
            acceptText: ""
        });
        setIsVisible(true);
    };

    const onUploadhandler = async (uploadedFile: File) => {
        if (uploadType == undefined || uploadType == null) {
            return;
        }

        setIsLoading(true);

        const newFile: IFileUploadRequest = {
            file: uploadedFile,
            company: company.id,
            file_name: uploadedFile.name,
            type: uploadType
        };

        const { id, version } = documents[DocumentTypeChoice[uploadType]];

        try {
            let postedFile: IFileUploadResponse = {} as IFileUploadResponse;
            if (!id) {
                postedFile = await fileService.uploadFile(newFile, { });
            } else {
                postedFile = await fileService.replaceFile(id, { ...newFile, version }, { });
            }

            setDocuments((documents) => ({
                ...documents,
                [DocumentTypeChoice[uploadType]]: postedFile
            }));
        } catch (error: any) {
            // detail: "Not found" - if replacing non-existent document
            // "non_field_errors": [ "File with type Incident Management Log already exists for company ..." ]
            // if trying to upload over an existing
            if ("detail" in error && error["detail"].includes("Not found")) {
            }
            if ("non_field_errors" in error && error["non_field_errors"][0].includes("already exists")) {
            }
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFile = async (id: string, type: DocumentTypeChoice) => {
        try {
            if (type == undefined)
                return;

            setIsLoading(true);
            await fileService.deleteFile(id);

            setDocuments((documents) => ({
                ...documents,
                [DocumentTypeChoice[type]]: {} as any
            }));
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (values: MixedData, { setSubmitting, setErrors, resetForm }: FormikHelpers<MixedData>) => {
        let errors = {};

        const updateProduct = {
            medical_device: values.medical_device,
            iso27001: values.iso27001,
            company: company.id,
        };

        const changed =
            (integrationProduct.medical_device !== values.medical_device) ||
            (integrationProduct.iso27001 !== values.iso27001);

        if (integrationProduct.id) {
            changed && await integrationProductService.update(
                integrationProduct.id,
                { ...updateProduct, version: integrationProduct.version },
                { showSuccessMessage: false }
            ).then((_product) => {
            }).catch((error) => {
                errors = { ...errors, ...error };
            });
        } else {
            await integrationProductService.create(
                updateProduct,
                { showSuccessMessage: false }
            ).then((_product) => {
            }).catch((error) => {
                errors = { ...errors, ...error };
            });
        }

        const updateCompany = {
            ...omit(values, [ "medical_device", "iso27001" ]),
            version: company.version
        };

        company.id && await companyService.update(
            company.id, updateCompany, { showSuccessMessage: false }
        ).then((_company) => {
        }).catch((error) => {
            errors = { ...errors, ...error };
        });

        setSubmitting(false);

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // Reset Form imperatively, because dirty and isValid are not cleared
            resetForm({ values });
        }
    };

    if (!isLoaded)
        return null;

    const UploadButton = (type: DocumentTypeChoice, text: string) => {
        const fileUpload = documents[DocumentTypeChoice[type]];

        return <Flex w="100%" flexWrap={"wrap"}>
            { !fileUpload.id
                ?
                <Button
                    variant="secondary"
                    onClick={() => {
                        setUploadType(type);
                        uploadBtnHandler(type);
                    }}
                    isLoading={isLoading && uploadType === type}
                    loadingText="Uploading"
                >
                    { `UPLOAD ${text}` }
                </Button>
                :
                <Flex alignItems="center" gap={2} ml={2}>
                    <Icon as={HiOutlineDocument} color="TBO.5" />
                    <Link
                        as="a"
                        href={fileUpload.temp_url || "#"}
                        color={iconCheckColor}
                    >
                        {getShortFileName(fileUpload.file_name)}
                    </Link>
                    <Tooltip hasArrow fontSize="xs" label="Replace file." >
                        <IconButton
                            aria-label="Replace file"
                            icon={<BiRefresh size={25} />}
                            variant="ghost"
                            size={"sm"}
                            onClick={() => {
                                setUploadType(type);
                                uploadBtnHandler(type);
                            }}
                            isLoading={isLoading && uploadType === type}
                        />
                    </Tooltip>
                    <Tooltip hasArrow fontSize="xs" label="Delete file." >
                        <IconButton
                            aria-label={"Delete"}
                            icon={<RiDeleteBinLine size={20} />}
                            variant="ghost"
                            size={"sm"}
                            onClick={() => {
                                setUploadType(type);
                                deleteFile(fileUpload.id, type);
                            }}
                        />
                    </Tooltip>
                </Flex>
            }
        </Flex>;
    };

    const donwloadTemplateLink = (href: string) =>
        <Link
            as="a"
            href={href || "#"}
            color={iconCheckColor}
            display="flex"
            alignItems="center"
        >
            <Text mr="5px">Download Template</Text> <BiDownload />
        </Link>;


    return (
        <Box mt={6}>
            <Formik<MixedData>
                enableReinitialize={true}
                initialValues={mixedData}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<MixedData>) => (
                    <FormControl
                        as="form"
                        onSubmit={formik.handleSubmit as any}
                    >
                        <Prompt when={formik.dirty} message="You have unsaved data on this page. Want to leave anyway?" />
                        <BoxCard>
                            <Box>
                                <Stack gap={5}>
                                    <Text
                                        color="gray.500"
                                        textAlign="start"
                                    >
                                        Before you can access Live credentials we need to confirm that your Company&apos;s Information Governance, Security & Clincal Safety meet certain standards
                                    </Text>
                                    <Box>
                                        {/* Data Protection Data Security & Protection Toolkit Status */}
                                        <Stack direction={{ base: "column", lg: "row" }}>
                                            <Text>Data Protection Data Security & Protection Toolkit Status</Text>
                                            <Spacer />
                                            <Stack w={{ base: "100%", lg: "35%" }}>
                                                <SelectFormik
                                                    type="number"
                                                    name="data_protection_status"
                                                    placeholder='Select option'
                                                >
                                                    <option value={InProgressCompleteChoice.in_progress}>In Progress</option>
                                                    <option value={InProgressCompleteChoice.complete}>Complete</option>
                                                </SelectFormik>
                                            </Stack>
                                        </Stack>

                                        {/* Screenshot from the Data Security & Protection Toolkit portal showing completion */}
                                        <Stack direction={{ base: "column", lg: "row" }} mt={5}>
                                            <Text pl="5">Screenshot from the Data Security & Protection Toolkit portal showing completion</Text>
                                            <Spacer />
                                            <Flex w={{ base: "100%", lg: "35%" }}>
                                                {UploadButton(DocumentTypeChoice.screenshot, "SCREENSHOT")}
                                            </Flex>
                                        </Stack>
                                    </Box>

                                    {/* Link/URL to your Privacy Policy */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Link/URL to your Privacy Policy</Text>
                                        <Spacer />
                                        <Flex minW={{ base: "100%", lg: "35%" }} justify="flex-end">
                                            <InputFormik
                                                type="url"
                                                name="privacy_policy"
                                                maxLength={100}
                                            />
                                        </Flex>
                                    </Stack>

                                    {/* Link/URL to your DPIA */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Link/URL to your DPIA</Text>
                                        <Spacer />
                                        <Flex minW={{ base: "100%", lg: "35%" }} justify="flex-end">
                                            <InputFormik
                                                type="url"
                                                name="dpia"
                                                maxLength={100}
                                            />
                                        </Flex>
                                    </Stack>

                                    {/* ICO Registration Number */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>ICO Registration Number</Text>
                                        <Spacer />
                                        <Flex minW={{ base: "100%", lg: "35%" }} justify="flex-end">
                                            <InputFormik
                                                type="text"
                                                name="ico_reg_number"
                                                maxLength={20}
                                            />
                                        </Flex>
                                    </Stack>

                                    {/* Is the Product/Service a medical device as governed by the MHRA? */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Is the Product/Service a medical device as governed by the MHRA?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="number"
                                                name="medical_device"
                                                placeholder='Select option'
                                            >
                                                <option value={YesNoChoice.no}>No</option>
                                                <option value={YesNoChoice.yes}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>

                                    <Box>
                                        {/* DCB0129 Status */}
                                        <Stack direction={{ base: "column", lg: "row" }}>
                                            <Text>DCB0129 Status</Text>
                                            <Spacer />
                                            <Stack w={{ base: "100%", lg: "35%" }}>
                                                <SelectFormik
                                                    type="number"
                                                    name="dcb0129_status"
                                                    placeholder='Select option'
                                                >
                                                    <option value={InProgressCompleteChoice.in_progress}>In Progress</option>
                                                    <option value={InProgressCompleteChoice.complete}>Complete</option>
                                                </SelectFormik>
                                            </Stack>
                                        </Stack>

                                        {/* Safety Incident Management Log */}
                                        <Stack direction={{ base: "column", lg: "row" }} mt={3}>
                                            <Text pl="5">Safety Incident Management Log</Text>
                                            <Spacer />
                                            <Flex w={{ base: "100%", lg: "35%" }}>
                                                {UploadButton(DocumentTypeChoice.incident_management_log, "DOCUMENT")}
                                            </Flex>
                                        </Stack>

                                        {/* Clinical Safety Case Report */}
                                        <Stack direction={{ base: "column", lg: "row" }} mt={3}>
                                            <Flex alignItems={"center"} gap={3}>
                                                <Text pl="5">Clinical Safety Case Report </Text>
                                                {donwloadTemplateLink(Clinicial_Safety_Report_URL)}
                                            </Flex>
                                            <Spacer />
                                            <Flex w={{ base: "100%", lg: "35%" }} direction="column">
                                                {UploadButton(DocumentTypeChoice.clinical_safety, "DOCUMENT")}
                                            </Flex>
                                        </Stack>

                                        {/* Hazard Log */}
                                        <Stack direction={{ base: "column", lg: "row" }} mt={3}>
                                            <Flex alignItems={"center"} gap={3}>
                                                <Text pl="5">Hazard Log</Text>
                                                {donwloadTemplateLink(Hazard_Log_URL)}
                                            </Flex>
                                            <Spacer />
                                            <Flex w={{ base: "100%", lg: "35%" }} direction="column">
                                                {UploadButton(DocumentTypeChoice.hazard_log, "DOCUMENT")}
                                            </Flex>
                                        </Stack>

                                        {/* Compliance Assessment */}
                                        <Stack direction={{ base: "column", lg: "row" }} mt={3}>
                                            <Text pl="5">Compliance Assessment</Text>
                                            <Spacer />
                                            <Flex w={{ base: "100%", lg: "35%" }}>
                                                {UploadButton(DocumentTypeChoice.compliance_assessment, "DOCUMENT")}
                                            </Flex>
                                        </Stack>
                                    </Box>

                                    {/* Clinical Safety Officer (CSO) Name */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Clinical Safety Officer (CSO) Name</Text>
                                        <Spacer />
                                        <Flex minW={{ base: "100%", lg: "35%" }} justify="flex-end">
                                            <InputFormik
                                                type="text"
                                                name="clinical_safety_officer"
                                                maxLength={100}
                                            />
                                        </Flex>
                                    </Stack>

                                    {/* CSO Email Address */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>CSO&apos;s Email Address</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <InputFormik
                                                type="email"
                                                name="cso_email"
                                                maxLength={100}
                                            />
                                        </Stack>
                                    </Stack>

                                    {/* Is the Product ISO 27001 certified? */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Is the Product ISO 27001 certified?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="number"
                                                name="iso27001"
                                                placeholder="Select option"
                                            >
                                                <option value={YesNoChoice.no}>No</option>
                                                <option value={YesNoChoice.yes}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        </BoxCard>

                        <Flex direction={"row"} justifyContent={"end"} mt={5}>
                            <Button
                                variant={"primary"}
                                alignSelf="flex-end"
                                type="submit"
                                isDisabled={!(formik.isValid && formik.dirty)}
                            >
                                Save
                            </Button>
                        </Flex>
                    </FormControl>
                )}
            </Formik>

            {isVisible && (
                <UploadFile
                    isVisible
                    header="Upload File"
                    onClose={dialogCloseHandler}
                    onSave={onUploadhandler}
                    accept={accept}
                />
            )}
        </Box>
    );
};

export default DeveloperCompany;
