import React, { useCallback, useEffect, useState } from "react";

import {
    Flex,
    Heading,
    Stat,
    StatLabel,
    StatNumber,
    Image,
    Button,
    Badge,
    StatHelpText,
    useColorModeValue,
    Text,
    Tag,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";

import BoxCard from "components/card/Card";
import { getTextWithCurrency } from "components/utils/currency-helper";
import CustomMoment from "components/utils/CustomMoment";
import { IStripePrice, IStripeProduct, useProductsService } from "services/billing/products.service";
import SubscriptionsSkeleton from "../SubscriptionsSkeleton";
import { PurchasedResponse } from "../sign-up-fee/SignUpFee";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import BespokePrices from "../BespokePricesModal";
import { ProductIcon } from "views/admin/convenet-integration/FigmaIcons";
import { usePortalService } from "services/billing/portal.service";
import { useAppStore } from "store";
import { SubscriptionOptionChoice } from "services";

type Props = {
    product: IStripeProduct;
}

//#region utils
export enum StripePriceRecurringInterval {
    month = "month",
    year = "year",
    week = "week",
    day = "day"
}

const intervalTextMap: Record<StripePriceRecurringInterval, (count: number) => string> = {
    [StripePriceRecurringInterval.day]: count => `every ${count === 1 ? "day" : `${count} days`}`,
    [StripePriceRecurringInterval.week]: count => `every ${count === 1 ? "week" : `${count} weeks`}`,
    [StripePriceRecurringInterval.month]: count => `every ${count === 1 ? "month" : `${count} months`}`,
    [StripePriceRecurringInterval.year]: count => `every ${count === 1 ? "year" : `${count} years`}`
};

export const getIntervalText = (interval: StripePriceRecurringInterval, interval_count = 1) => {
    if (!interval)
        return intervalTextMap["day"](interval_count);
    return intervalTextMap[interval](interval_count);
};

//#endregion


const SubscriptionItem = (props: Props) => {
    const productService = useProductsService();
    const portalService = usePortalService();

    const integrationProduct = useAppStore(state => state.integrationProduct);
    const company = useAppStore(state => state.company);

    const { product } = props;

    const iconColor = useColorModeValue("gray.200", "white");

    const [ hasPurchased, setHasPurchased ] = useState({} as PurchasedResponse);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ redirecting, setRedirecting ] = useState(false);
    const [ showLinkForChangePrice, setShowLinkForChangePrice ] = useState(false);
    const [ showBespokePricesModal, setShowBespokePricesModal ] = useState(false);
    const [ updatePaymentMethod, setUpdatePaymentMethod ] = useState(false);
    const bespokePricesService = useBespokePriceService();
    const [ price, setPrice ] = useState({} as IStripePrice);
    const [ standardPrice, setStandardPrice ] = useState(false);

    const onChangePrice = async (addedPrice: BespokeProductPrice) => {
        setPrice(addedPrice.price_details);
    };

    const onDeletePrice = () => {
        setPrice(product.default_price);
    };

    useEffect(() => {
        const func = async () => {
            if (integrationProduct.subscription_option === SubscriptionOptionChoice.standard) {
                setStandardPrice(true);
                setPrice(product.default_price);
            }

            if (integrationProduct.subscription_option === SubscriptionOptionChoice.bespoke) {
                // get bestpoke price for the current company and product
                const productBespokePrice = await bespokePricesService.getAll({ integration_product__id: integrationProduct.id, product__id: product.id });

                if (productBespokePrice.length > 0) {
                    setPrice(productBespokePrice[0].price_details);
                } else {
                    setPrice(product.default_price);
                }
            }

            const purchased: PurchasedResponse = await productService.hasPurchasedProduct(product.djstripe_id, integrationProduct.id);

            if (purchased.purchased) {
                setHasPurchased({ ...purchased });
                setShowLinkForChangePrice(false);
                setUpdatePaymentMethod(true);
            } else {
                setShowLinkForChangePrice(true);
            }

            setIsLoading(false);
        };

        func();
    }, []);

    const pay = async () => {
        try {
            setRedirecting(true);
            const subscriptionLink = await productService.checkout(product.djstripe_id, { payload: { price: price.id, integration_product: integrationProduct.id } });
            window.location.href = subscriptionLink;
        } catch (error) {
            console.log(error);
        }
    };

    const priceText = useCallback(() => {
        return price.id ? getTextWithCurrency(price.currency, price.unit_amount / 100) : "";
    }, [ price ]);

    // const interval = useCallback(() => {
    //     return price.id ? getIntervalText(price.recurring?.interval as StripePriceRecurringInterval, price.recurring?.interval_count) : "";
    // }, [ price ]);

    const updatePaymentMethodHandler = async () => {
        const _portalURL = await portalService.redirectToPortal("/app/billing/api-subscription");
        window.location.href = _portalURL;
    };

    const menu = <Menu>
        <MenuButton
            boxSize={6}
            as={IconButton}
            hidden={standardPrice}
            aria-label='Options'
            icon={<BsThreeDots />}
            variant='outline'
            alignSelf="end"
        />
        <MenuList>
            { showLinkForChangePrice &&
                        <MenuItem onClick={ () => setShowBespokePricesModal(true) }>
                            Change Price
                        </MenuItem>
            }
            { updatePaymentMethod &&
                        <MenuItem onClick={ () => updatePaymentMethodHandler() }>
                            Update Payment Method
                        </MenuItem>
            }
        </MenuList>
    </Menu>;

    if (!product.id)
        return null;

    return <>
        {isLoading
            ? <SubscriptionsSkeleton />
            :
            <>
                <BoxCard margin={"1px auto"} w={{ sm: "auto", md: "450px", lg: "450px" }} my={5}>
                    <Flex p={2} direction="column">
                        <Stat>
                            <StatLabel>
                                <Flex justify="space-between">
                                    <Heading size="md">{product.name}</Heading>
                                    { menu }
                                </Flex>
                            </StatLabel>
                            <StatNumber>
                                <Flex gap={3}>
                                    {/* <Text fontSize="md">{`${priceText()} ${interval()}`}</Text> */}
                                    <Text fontSize="md">{`${priceText()} `}</Text>
                                    <Tag colorScheme="blue">Plus VAT</Tag>
                                </Flex>

                            </StatNumber>
                            <StatHelpText>{product.description || <br/>}</StatHelpText>
                        </Stat>
                        <Flex justify="center" mb={5}>
                            <Image
                                src={product.images[0]}
                                fallback={<ProductIcon boxSize={200} color={iconColor} />}
                                alt='Product Image'
                                boxSize='200px'
                                objectFit='cover'
                            />
                        </Flex>
                        {!hasPurchased.purchased ?
                            <Button
                                onClick={pay}
                                isLoading={redirecting}
                                loadingText="Redirecting to Stripe..."
                            >
                                Pay {product.name}
                            </Button>
                            :
                            <Badge colorScheme={"whatsapp"} textAlign="center">
                                Subscription Started on {<CustomMoment date={hasPurchased.timestamp} dateFormat="dd/MM/yyyy"></CustomMoment>}
                            </Badge>
                        }
                    </Flex>
                </BoxCard>
            </>
        }
        { showBespokePricesModal &&
            <BespokePrices
                product={product}
                onChange={onChangePrice}
                company={company}
                onDeletePrice={onDeletePrice}
                showModal={showBespokePricesModal}
                setShowModal={setShowBespokePricesModal}
            />
        }
    </>;
};

export default SubscriptionItem;
