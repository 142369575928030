import { IStripeCustomer } from "./customer.interface";
import { IStripeInvoice } from "./invoice.interface";

export interface IStripeCharge {
  amount: number;
  amount_captured: number;
  amount_refunded: number;
  application: string;
  application_fee: string;
  application_fee_amount: number;
  balance_transaction: string; // balance transaction ID
  billing_details: IStripeBillingDetails;
  calculated_statement_descriptor: string;
  captured: boolean;
  created: Date;
  currency: string;
  customer: string; // customer ID
  description: string;
  disputed: boolean;
  djstripe_created: string;
  djstripe_id: number;
  djstripe_owner_account: string; // account ID
  djstripe_updated: string;
  failure_code: string;
  failure_message: string;
  fraud_details: any;
  id: string;
  invoice: string; // invoice ID
  livemode: boolean;
  metadata: any;
  on_behalf_of: any;
  outcome: Outcome;
  paid: boolean;
  payment_intent: string; // payment intent ID
  payment_method: string; // payment method ID
  payment_method_details: PaymentMethodDetails;
  receipt_email: string;
  receipt_number: number;
  receipt_url: string;
  refunded: boolean;
  shipping: any;
  source: any;
  source_transfer: any;
  statement_descriptor: any;
  statement_descriptor_suffix: any;
  status: IStripeChargeStatus;
  transfer: any;
  transfer_data: any;
  transfer_group: any;
}

export enum IStripeChargeStatus {
    succeeded = "succeeded",
    pending = "pending",
    failed = "failed"
}

export interface IStripeBallanceTransaction {
  id: string
  object: string
  amount: number
  available_on: number
  created: number
  currency: string
  description: string
  exchange_rate: any
  fee: number
  fee_details: IStripeFeeDetail[]
  net: number
  reporting_category: string
  source: string
  status: string
  type: string
}

export interface IStripeFeeDetail {
  amount: number
  application: any
  currency: string
  description: string
  type: string
}

export interface IStripeBillingDetails {
  address: IStripeBillindDetailsAddress
  email: string
  name: string
  phone: any
}

export interface IStripeBillindDetailsAddress {
  city: any
  country: string
  line1: any
  line2: any
  postal_code: string
  state: any
}

export interface Outcome {
  network_status: string
  reason: any
  risk_level: string
  risk_score: number
  seller_message: string
  type: string
}

export interface PaymentMethodDetails {
  card: Card
  type: string
}

export interface Card {
  brand: string
  checks: Checks
  country: string
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  installments: any
  last4: string
  mandate: any
  network: string
  three_d_secure: any
  wallet: any
}

export interface Checks {
  address_line1_check: any
  address_postal_code_check: string
  cvc_check: string
}

export interface Refunds {
  object: string
  data: any[]
  has_more: boolean
  url: string
}