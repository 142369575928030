import { mode } from "@chakra-ui/theme-tools";
export const inputStyles = {
    components: {
        Input: {
            baseStyle: {
                field: {
                    fontWeight: 400,
                    bg: "fieldBG",
                    color: "text",
                    border: "1px solid",
                    borderColor: "fieldBorder",
                    _placeholder: { color: "gray.400", fontWeight: "400" },
                    _focus: {
                        border: "2px solid",
                        borderColor: "navy.400"
                    },
                },
            },

            defaultProps: {
                variant: "main",
            },

            variants: {
                main: (props: any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",

                    },
                }),
                filter: (props:any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        w:"180px",
                        mx: "8px",
                    },
                }),
                modal: (props:any) => ({
                    field: {
                        border: "1px solid",
                        borderColor: "gray.400",
                        _placeholder: { color: "gray.400", fontWeight: "400" },
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        w:"265px",
                        mx: "8px",
                    },
                }),
                auth: (props: any) => ({
                    field: {
                        fontWeight: "500",
                    },
                }),
                authSecondary: (props: any) => ({
                    field: {

                    },
                }),
                search: (props: any) => ({
                    field: {
                        border: "none",
                        borderRadius: "inherit",
                    },
                }),
            },
        },
        NumberInput: {
            baseStyle: {
                field: {
                    fontWeight: 400,
                    bg: "fieldBG",
                    color: "text",
                    border: "1px solid",
                    borderColor: "fieldBorder",
                    _placeholder: { color: "gray.400", fontWeight: "400" },
                    _focus: {
                        border: "2px solid",
                        borderColor: "navy.400"
                    },
                },
            },

            defaultProps: {
                variant: "main",
            },

            variants: {
                main: (props: any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                    },
                }),
                filter: (props:any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        w:"180px",
                        mx: "8px",
                    },
                }),
                modal: (props:any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        w:"265px",
                        mx: "8px",
                    },
                }),
                auth: (props: any) => ({
                    field: {

                    },
                }),
                authSecondary: (props: any) => ({
                    field: {

                    },
                }),
                search: (props: any) => ({
                    field: {
                        border: "none",

                        borderRadius: "inherit",

                    },
                }),
            },
        },
        Select: {
            baseStyle: {
                field: {
                    fontWeight: 400,
                    bg: "fieldBG",
                    color: "text",
                    border: "1px solid",
                    borderColor: "fieldBorder",
                    _placeholder: { color: "gray.400", fontWeight: "400" },
                    _focus: {
                        border: "2px solid",
                        borderColor: "navy.400"
                    },
                },
                icon: {
                    right: "14.5px",
                },
            },

            defaultProps: {
                variant: "main",
            },

            variants: {
                main: (props: any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                    },
                    icon: {
                        color: "secondaryGray.600",
                        right: "14.5px",
                    },
                }),
                filter: (props:any) => ({
                    field: {
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        w:"230px",
                        mx: "8px",
                    },
                    icon: {
                        color: "secondaryGray.600",
                        right: "14.5px",
                    },
                }),
                modal: (props:any) => ({
                    field: {
                        border: "1px solid",
                        borderColor: "gray.400",
                        _placeholder: { color: "gray.400", fontWeight: "400" },
                        fontSize: "text.sm",
                        p: "10px 16px",
                        height: "40px",
                        borderRadius: "card",
                        textAlign: "start",
                        w:"265px",
                        mx: "8px",
                    },
                    icon: {
                        color: "secondaryGray.600",
                        right: "14.5px",
                    },
                }),
                pagination: (props:any) => ({
                    field: {
                        w:"75px",
                        h:"32px",
                        p: "6px 12px",
                        borderRadius:"6px",
                        backgroundColor:"lightFieldBackground",
                        color:"lightText",
                        borderColor:"lightFieldBorder",
                        _focus: {
                            border: "2px solid",
                            borderColor: "navy.400"
                        },
                    },
                    icon:{
                        right: "14.5px",
                    }
                }),
                mini: (props: any) => ({
                    field: {

                        border: "0px solid transparent",
                        fontSize: "0px",


                    },
                    icon: {
                        color: "secondaryGray.600",
                    },
                }),
                subtle: (props: any) => ({
                    box: {
                        width: "unset",
                    },
                    field: {
                        bg: "transparent",
                        border: "0px solid",

                        borderColor: "transparent",
                        width: "max-content",

                    },
                    icon: {
                        color: "secondaryGray.600",
                    },
                }),
                transparent: (props: any) => ({
                    field: {
                        bg: "transparent",
                        border: "0px solid",
                        width: "min-content",

                        borderColor: "transparent",
                        padding: "0px",
                        paddingLeft: "8px",
                        paddingRight: "20px",
                        fontWeight: "700",
                        fontSize: "14px",

                    },
                    icon: {
                        transform: "none !important",
                        position: "unset !important",
                        width: "unset",
                        color: "secondaryGray.600",
                        right: "0px",
                    },
                }),
                auth: (props: any) => ({
                    field: {

                    },
                }),
                authSecondary: (props: any) => ({
                    field: {

                    },
                }),
                search: (props: any) => ({
                    field: {
                        border: "none",
                        borderRadius: "inherit",
                    },
                }),
            },
        },
        // PinInputField: {
        //   variants: {
        //     main: (props: any) => ({
        //       field: {
        //         bg: "red !important",
        //         border: "1px solid",
        //         color: mode("secondaryGray.900", "white")(props),
        //         borderColor: mode("secondaryGray.100", "whiteAlpha.100")(props),
        //         borderRadius: "16px",
        //         _placeholder: { color: "secondaryGray.600" },
        //       },
        //     }),
        //   },
        // },
    },
};
