import { useAppStore } from "store";
import { TOptions, usePagedCrudService } from "./crud.service";
import { IIntegrationProduct } from "./integration-product.interface";

const integrationProductService = (function () {
    const integrationProductService = usePagedCrudService<IIntegrationProduct>({
        url: "/api/mock/integration-product/",
        entityTitle: "Integration Product",
        model: "integrationproduct"
    });

    // remove, not used
    const setWaiveSignupFee = (id: string, value: boolean) =>
        integrationProductService._post(
            `/api/mock/integration-product/${id}/set-waive-signup-fee/}`, {
                payload: { value }
            }
        );

    const update = (id: string, item: Partial<IIntegrationProduct>, options?: Partial<TOptions>): Promise<IIntegrationProduct> =>
        integrationProductService.update(id, item, options).then((integrationProduct) => {
            // update appStore
            const _integrationProductStore = useAppStore.getState().integrationProduct;
            const _updateStoreState = {
                ..._integrationProductStore,
                ...integrationProduct
            };
            useAppStore.setState({ integrationProduct: _updateStoreState });

            return integrationProduct;
        });

    return {
        ...integrationProductService,
        setWaiveSignupFee,
        update
    };
})();

export const useIntegrationProductService = () => integrationProductService;
