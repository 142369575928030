import React from "react";
import { Box, BoxProps, useStyleConfig, useColorModeValue } from "@chakra-ui/react";

function BoxCard(props: BoxProps & { variant?: string, direction?: string, align?: string }) {
    const { variant, children, ...rest } = props;
    const styles = useStyleConfig("BoxCard", { variant });

    return (
        <Box __css={styles} {...rest}>
            {children}
        </Box>
    );
}

export default BoxCard;
