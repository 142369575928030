import { mode } from "@chakra-ui/theme-tools";
import { StyleFunctionProps } from "@chakra-ui/styled-system";

export const tabStyles = {
    components: {
        Tabs: {
            baseStyle: {
            },

            defaultProps: {
                colorScheme: "red",
                variant: "convenet"
            },

            variants: {
                convenet: (props: StyleFunctionProps) => {
                    const { colorScheme: c } = props;
                    return {
                        tab: {
                            color: mode("gray.800", "gray.100")(props),
                            borderBottom: "none",
                            _selected: {
                                color: mode("TBO.5", "white")(props),
                                borderBottom: "2px solid",
                                borderBottomColor: mode("TBO.5", "white")(props),
                            },
                        },
                        tabpanels: { mt: 5 },
                        tabpanel: { p: 0 }
                    };
                },
            },

        },
    },
};
