import React, { memo } from "react";
import {
    Flex,
    FormLabel,
    Select,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useField } from "formik";

const DropDownFormik: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        mb = "10px",
        required,
        variant = "modal"
    } = props;
    const id = props.id || props.name;
    const brandStars = useColorModeValue("red", "brand.400");

    // Note: a placeholder creates a nullable choice for <select>
    // by creating a void <option>, this can be inappropriate
    //delete props.placeholder;

    // override handlers for "number" and "boolean" pseudo-types
    let override: { value: unknown, onChange: (e: any) => void } = {} as any;

    if (props.type === "number") {
        override = {
            value: meta.value == null ? "" : String(meta.value),
            onChange: (e: any) => {
                const value = parseInt(e.target.value);
                helpers.setValue(isNaN(value) ? null : value);
            }
        };
    } else if (props.type == "boolean") {
        override = {
            value: meta.value == null ? "" : String(meta.value),
            onChange: (e: any) => {
                const newValue = e.target.value === "true" || +e.target.value > 0;
                helpers.setValue(e.target.value != "" ? newValue : null);
            }
        };
    }

    const onChangeWrap = (e: any) => {
        helpers.setTouched(true);
        (props.onChange || override?.onChange || field.onChange)(e);
    };

    return (
        <Flex
            direction={{ base: "column", md: "row" }}
            align={{ base: "start", md: "center" }}
            justify={{ base: "space-start", md: "space-between" }}
            flexWrap={"wrap"}
            textAlign={{ base: "start", md:"end" }}
            mb={mb}
        >
            <FormLabel
                display="flex"
                fontSize="text.md"
                maxW={{ base: "100%", md: "165px" }}
                fontWeight="normal"
                mb={{ base: "2", md: "0" }}
                me="0"
                color="text"
                htmlFor={id}
                _hover={{ cursor: "pointer" }}
            >
                {label}
                {required && <Text color={brandStars}>*</Text>}
            </FormLabel>
            <Flex direction="column" alignItems="start" w={props.w || "auto"}>
                <Select
                    {...field}
                    {...props}
                    {...override}
                    id={id}
                    onChange={onChangeWrap}
                    required={null}
                    isRequired={false}
                    w={"auto"}
                    variant={variant}
                    fontSize="text.sm"
                    mb="0"
                    rootProps={{
                        __css: {
                            position: "relative",
                            "> select": {
                                ml: 0
                            }
                        }
                    }}

                    _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                    fontWeight="500"
                    size="lg"
                    bg="fieldBG"
                    color="text"
                    borderRadius="card"
                >
                </Select>
                {meta.touched && meta.error ? (
                    <Text as="span" fontSize="text.xs" color={"red"} h="14px" ml={5}>
                        {meta.error}
                    </Text>
                ) : null}
            </Flex>
        </Flex>
    );
};

export default memo(DropDownFormik);
