import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Chakra imports
import {
    Box,
    Button,
    Card,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Stack,
    Switch,
    Text,
    VStack
} from "@chakra-ui/react";

import qs from "qs";
import { TokenResponse } from "@react-oauth/google";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";

// Assets
import { IoCopyOutline } from "react-icons/io5";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import TextFieldFormik from "components/fields/TextFieldFormik";
import { IUserMe, useUserService, useAuthService } from "services";
import BoxCard from "components/card/Card";
import QRCode from "react-qr-code";
import { useAlert } from "components/alert-modal";

import GoogleButton from "views/auth/components/GoogleButton";

const formValidationSchema = Yup.object({
    first_name: Yup.string()
        .max(30, "Must be at most 30 characters."),
    last_name: Yup.string()
        .max(30, "Must be at most 30 characters.")
});

export default function UserProfile() {
    const navigate = useNavigate();
    const userService = useUserService();
    const { userData } = userService;
    const authService = useAuthService();
    const [ showEnableMfa, setShowEnableMfa ] = useState(false);
    const [ showDisableMfa, setShowDisableMfa ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingModal, setIsLoadingModal ] = useState(false);
    const [ secret, setSecret ] = useState("");
    const [ uriLink, setUriLink ] = useState("");
    const [ code, setCode ] = useState("");
    const [ last, setLast ] = useState(false);
    const alertShow = useAlert();

    useEffect(() => {
        // Maybe not the best way to get the number of users in the company
        userService.getAll().then((users) => setLast(users.length <= 1));
    }, []);

    const onGoogleConnect = (response: TokenResponse) => {
        setIsLoading(true);
        authService.connectGoogle(
            { access_token: response.access_token }
        ).then(async (response) => {
            // Update user/me
            await userService.me();
        }).catch((error) => {
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleSubmit = (values: Partial<IUserMe>, { setSubmitting, setErrors }: FormikHelpers<Partial<IUserMe>>) => {
        setIsLoading(true);

        // userData is updated inside update
        userService.meUpdate(userData.id, values).then(response => {
        }).catch((error) => {
            setErrors(error);
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    };

    const onMfaRequest = () => {
        userService.mfaActivate().then((uri) => {
            setSecret(qs.parse(uri.split("?")[1])?.secret as string);
            setUriLink(uri);
            setShowEnableMfa(true);
        }).catch((error) => {
            setShowEnableMfa(false);
        });
    };

    const onMfaConfirm = () => {
        setIsLoadingModal(true);
        userService.mfaActivateConfirm(code)
            .then((backup_codes) => {
                setShowEnableMfa(false);
            }).catch((error) => {
            }).finally(() => {
                setIsLoadingModal(false);
                setSecret("");
                setCode("");
            });
    };

    const onMfaDisable = () => {
        setIsLoadingModal(true);
        userService.mfaDeactivate(code).then(() => {
            setShowDisableMfa(false);
            setCode("");
        }).catch((error) => {
        }).finally(() => {
            setIsLoadingModal(false);
        });
    };

    const onMeDelete = () => {
        alertShow({
            header: "Warning",
            message: "Are you sure you want to delete your account? " +
                (last ? `This action will also delete ${userData.company_name}` : ""),
            yes: "Delete",
            no: "Cancel",
            onSuccess: () => {
                userService.meDelete().then(() => navigate("/auth/login"));
            }
        });
    };

    const initialFormValues: Partial<IUserMe> = {
        first_name: userData.first_name || "",
        last_name: userData.last_name || ""
    };

    return (<>
        <Box mt={6}>
            <Formik<Partial<IUserMe>>
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={formValidationSchema}
                onSubmit={handleSubmit}
            >
                {(formik: FormikProps<Partial<IUserMe>>) => (
                    <FormControl
                        as="form"
                        onSubmit={formik.handleSubmit as any}
                    >
                        <BoxCard>

                            {/* Settings */}
                            <Flex flexDirection="column">
                                <Heading as="h1" mb="5" fontSize="headingSmall.md" fontWeight="400" color="TBO.5">
                                Personal Information
                                </Heading>

                                <Stack w="100%">

                                    <SimpleGrid templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
                                        columnGap={{ base: "10", md: "16", lg: "64" }}
                                        rowGap="4">
                                        <TextFieldFormik
                                            label="First Name"
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                        />
                                        <TextFieldFormik
                                            label="Last Name"
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                        />
                                        <TextFieldFormik
                                            label="Email"
                                            type="text"
                                            name="email"
                                            placeholder={userData.email}
                                            disabled
                                        />

                                    </SimpleGrid>
                                    <Flex
                                        align="center"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        mt={5}
                                    >
                                        {/* <Button
                                            isLoading={isLoading}
                                            variant="primary"
                                            mr={3}
                                            type="submit"
                                            isDisabled={!(formik.isValid && formik.dirty)}
                                        >
                                                Save
                                        </Button> */}
                                    </Flex>

                                </Stack>
                            </Flex>
                            <Divider my={10}/>
                            <Flex flexDirection="column" fontSize="text.sm">
                                <Flex w="100%" background="CG.1" borderRadius="card" p="10px 15px" align="center" justify="space-between">
                                    <Box>
                                        <Text>
                                            Sign In with Google is currently:
                                            <Text as="span" ml={2} color="TBO.5">{userData.google_account_status != 2 ? "ENABLED" : "DISABLED"}</Text>
                                        </Text>
                                    </Box>
                                    {userData.google_account_status == 2 && <GoogleButton
                                        label="Enable"
                                        isLoading={isLoading}
                                        onSuccess={onGoogleConnect}
                                    />}
                                </Flex>
                            </Flex>
                            <Divider my={10} />
                            {/* Two Factor */}
                            <Flex flexDirection="column">
                                <Heading as="h1" mb="3" fontSize="headingSmall.md" fontWeight="400" color="TBO.5">
                                    Two-Factor Authentication (2FA)
                                </Heading>
                                <Text fontSize={"text.sm"} color="secondaryText" mb="5">
                                    Increase your account security by enabling Two-Factor
                                    Authentication (2FA)
                                </Text>
                                <Flex w="100%" background="CG.1" borderRadius="card" p="10px 15px" minH="60px" align="center" justify="space-between">
                                    <Flex align="center" justify="start" fontSize="text.sm">
                                        Two-Factor Authentication is currently:
                                        <Text ml="2" fontSize="text.sm" lineHeight="regular" color="TBO.5">
                                            {!userData.mfa_enabled ? "DISABLED" : "ENABLED"}
                                        </Text>
                                    </Flex>
                                    <Flex align="center">
                                        <Button id="two_factor"
                                            variant='secondary'
                                            isDisabled={ userData.mfa_enabled ? showDisableMfa: showEnableMfa }
                                            onClick={ () => userData.mfa_enabled ? setShowDisableMfa(true) : onMfaRequest() }
                                        >
                                            {userData.mfa_enabled ? "Disable" : "Enable"}
                                        </Button>

                                    </Flex>
                                </Flex>
                            </Flex>
                        </BoxCard>


                        {/* Main Fields | useful code*/}
                        {/*<Grid
                            templateColumns={{
                                base: "1fr",
                                lg: "1.34fr 1fr 1.62fr",
                            }}
                            templateRows={{
                                base: "repeat(3, 1fr)",
                                lg: "1fr",
                            }}
                            gap={{ base: "20px", xl: "20px" }}
                        >
                            <Banner
                                gridArea="1 / 1 / 2 / 2"
                                banner={banner}
                                avatar={avatar}
                                name="Adela Parkson"
                                job="Product Designer"
                                posts="17"
                                followers="9.7k"
                                following="274"
                            />
                            <Storage
                                gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
                                used={25.6}
                                total={50}
                            />
                        </Grid>
                        <Grid
                            mb="20px"
                            templateColumns={{
                                base: "1fr",
                                lg: "repeat(2, 1fr)",
                                "2xl": "1.34fr 1.62fr 1fr",
                            }}
                            templateRows={{
                                base: "1fr",
                                lg: "repeat(2, 1fr)",
                                "2xl": "1fr",
                            }}
                            gap={{ base: "20px", xl: "20px" }}
                        >
                            <Projects
                                gridArea="1 / 2 / 2 / 2"
                                banner={banner}
                                avatar={avatar}
                                name="Adela Parkson"
                                job="Product Designer"
                                posts="17"
                                followers="9.7k"
                                following="274"
                            />
                            <General
                                gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
                                minH="365px"
                                pe="20px"
                            />
                            <Notifications
                                used={25.6}
                                total={50}
                                gridArea={{
                                    base: "3 / 1 / 4 / 2",
                                    lg: "2 / 1 / 3 / 3",
                                    "2xl": "1 / 3 / 2 / 4",
                                }}
                            />
                        </Grid>*/}
                        <Flex mt="8" justifyContent="space-between">
                            <Button
                                variant="secondary"
                                onClick={onMeDelete}
                            >
                                Delete Account
                            </Button>
                            <Button
                                isLoading={isLoading}
                                variant="primary"
                                type="submit"
                                isDisabled={!(formik.isValid && formik.dirty)}
                            >
                                Save
                            </Button>
                        </Flex>
                    </FormControl>
                )}
            </Formik>
        </Box>

        <Modal isOpen={showEnableMfa} onClose={() => setShowEnableMfa(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex alignContent="center" justifyContent="center" flexDirection="column">
                        <Text>
                            Please, use your mobile phone with Google Authenticator or similar
                            application to scan this QR code and add your account. Then enter
                            the code this app shows below for finalizing your 2FA setup.
                        </Text>
                        <Box p={3} mx="auto"><QRCode value={uriLink} /></Box>
                        <Flex p={3} alignItems="center">
                            <Text mr="auto">Copy secret into the clipboard</Text>
                            <IconButton
                                aria-label="Copy"
                                icon={<IoCopyOutline size="20px"/>}
                                colorScheme="gray"
                                variant="setup"
                                onClick={() => navigator.clipboard.writeText(secret)}
                            />
                        </Flex>
                        <InputGroup>
                            <Input
                                placeholder="Enter code"
                                type="text"
                                autoFocus={true}
                                maxLength={6}
                                value={code}
                                onChange={({ target: { value } })=> setCode(value)}
                                onKeyDown={({ type, key, target: { value } }: any) => {
                                    if (type === "keydown" && key === "Enter") {
                                        onMfaConfirm();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button isLoading={isLoadingModal} onClick={() => setShowEnableMfa(false)} variant="secondary" mr={3}>Cancel</Button>
                    <Button isLoading={isLoadingModal} onClick={() => onMfaConfirm()}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <Modal isOpen={showDisableMfa} onClose={() => setShowDisableMfa(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex alignContent="center" justifyContent="center" flexDirection="column">
                        <Text mb="2">
                            Please, enter code from your Authenticator app to disable 2FA
                        </Text>
                        <InputGroup>
                            <Input
                                placeholder="Enter code"
                                type="text"
                                autoFocus={true}
                                maxLength={6}
                                value={code}
                                onChange={({ target: { value } })=> setCode(value)}
                                onKeyDown={({ type, key, target: { value } }: any) => {
                                    if (type === "keydown" && key === "Enter") {
                                        onMfaDisable();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button isLoading={isLoadingModal} onClick={() => setShowDisableMfa(false)} variant="secondary" mr={3}>Cancel</Button>
                    <Button isLoading={isLoadingModal} onClick={() => onMfaDisable()}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>);
}
