import { usePagedCrudService } from "services";
import { IMedicalRecord } from "./medical-record.interface";

const medicalRecordService = (function() {
    const service = usePagedCrudService<IMedicalRecord>({
        url: "/api/mock/medical-record/",
        entityTitle: "Medical Record",
        model: "medicalrecord"
    });

    return service;
})();

export const useMedicalRecordService = () => medicalRecordService;
