import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Skeleton,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";

import Prescriptions from "../prescriptions/Prescriptions";
import RepeatMedications from "../repeat-medication/RepeatMedications";
import MedicalRecord from "../medical-record/MedicalRecord";
import Appointments from "../appointments/Appointments";
import Proxies from "../proxies/Proxies";
import Consultations from "../Consultations";
import { IPatient, LinkStatusEnum, PatientGenderEnum, usePatientService } from "services";
import EditPatientDetailsModal from "./EditPatientDetailsModal";
import BoxCard from "components/card/Card";
import CustomMoment from "components/utils/CustomMoment";
import { usePatientCloneService } from "services";
import { LabelText } from "components/label-text/label-text";

type Props = {
    id?: string;
    isVisible?: boolean;
    setIsVisible?: (value: boolean) => void;
    onSuccess?: () => void;
    [key: string]: any;
};

const PatientDetails = (props: Props) => {
    const patientService = usePatientService();
    const patientCloneService = usePatientCloneService();

    const { patientId } = useParams();
    const navigate = useNavigate();
    const toast = useToast();

    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ activePatient, setActivePatient ] = useState<IPatient>({} as IPatient);
    const [ showPatientDetails, setShowPatientDetails ] = useState<boolean>(false);
    const [ showPatientSettings, setShowPatientSettings ] = useState<boolean>(false);
    const [ showRestoreButton, setShowRestoreButton ] = useState(false);

    useEffect(() => {
        if (!patientId)
            return;

        patientService.get(patientId).then((patient) => {
            setShowRestoreButton(!!patient.copied_from_patient);
            setActivePatient(patient);
            setIsLoaded(true);
        });

    }, [ patientId ]);

    const onUpdatePatient = (patient: IPatient) => {
        setActivePatient(patient);
    };

    const onCloseHandler = () => {
        setShowPatientDetails(false);
        setShowPatientSettings(false);
    };

    const resetLinkBtnHandler = async () => {
        await patientService.regenerateLinkageKey(activePatient.id);
        const updatedPatient = await patientService.update(activePatient.id, {
            ...activePatient,
            link_status: LinkStatusEnum.Unregistered
        });
        setActivePatient(updatedPatient);
    };

    const restorePatientBtnHandler = async () => {
        try {
            await patientCloneService.restorePatient(activePatient.id);
            const patient = await patientService.get(activePatient.id, { showSuccessMessage: false });
            setActivePatient(patient);
            toast({
                title: "Patient Restored!",
                position: "top-right",
                status: "success",
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box mt="5">
            {/* <Box
                pl={4}
                display="flex"
                alignContent={"center"}
                alignItems="center"
            >
                <IconButton
                    aria-label="back"
                    icon={<ArrowBackIcon />}
                    size="lg"
                    variant="ghost"
                    color={"TBO.6"}
                    mr={"8px"}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Text fontWeight={700} fontSize={16}>{`${
                    patient.first_name ?? ""
                } ${patient.surname}`}</Text>
            </Box> */}
            <VStack spacing={5}>
                <Skeleton isLoaded={isLoaded} w="100%">
                    <BoxCard>
                        <Flex
                            direction="column"
                            w="100%"
                            gap={4}
                        >
                            <Flex flexWrap="wrap" justify="space-between">
                                <LabelText labelText="Forename" text={activePatient.first_name} />
                                <LabelText labelText="Surname" text={activePatient.surname} />
                                <LabelText labelText="Gender" text={PatientGenderEnum[activePatient.gender]}/>
                                <LabelText labelText="" text=""></LabelText>
                            </Flex>
                            <Flex flexWrap="wrap" justify="space-between">
                                <LabelText labelText="NHS Number" text={activePatient.nhs_number ?? ""} />
                                <Flex w="280px">
                                    <Text fontWeight={"bold"} minW="8.75rem">DoB</Text>

                                    <Text color="gray.500">
                                        <CustomMoment date={activePatient.date_of_birth} dateFormat="dd/MM/yyyy" />
                                    </Text>
                                </Flex>
                                <LabelText labelText="GP ODS Code" text={activePatient.gp_ods_code ?? ""} />
                                <LabelText labelText="" text=""></LabelText>
                            </Flex>
                            <Flex flexWrap="wrap" justify="space-between">
                                <Box maxW="280px">
                                    <LabelText labelText="Address" text={`${activePatient.address_line_1 ?? ""} ${
                                        activePatient.address_line_2 ?? ""
                                    } ${activePatient.address_line_3 ?? ""} `} />
                                </Box>
                                <Box>
                                    <LabelText labelText="" text=""></LabelText>
                                </Box>
                                <Flex maxW="280px" wordBreak="break-word">
                                    <LabelText labelText="Linkage" text={activePatient.linkage_key ?? ""} />
                                </Flex>
                                <LabelText labelText="" text=""></LabelText>

                            </Flex>
                            <Flex flexWrap="wrap" justify="space-between">
                                <LabelText labelText="Postcode" text={activePatient.post_code ?? ""} />
                                <LabelText labelText="" text=""></LabelText>
                                <LabelText labelText="Account" text={activePatient.account_id ?? ""} />
                                <LabelText labelText="" text=""></LabelText>
                            </Flex>
                        </Flex>
                        <Flex justify="flex-end" align="center" mt="5">
                            { showRestoreButton &&
                                <Button
                                    variant="tertiary"
                                    mr={2}
                                    onClick={restorePatientBtnHandler}
                                >
                                    RESTORE
                                </Button>
                            }
                            { activePatient.link_status === LinkStatusEnum.Registered
                                ? <Button

                                    variant="tertiary"
                                    mr="2"
                                    onClick={ resetLinkBtnHandler }
                                >
                                    UNREGISTER
                                </Button>
                                : <Text fontSize="14px" letterSpacing="0.025em" mr="4">
                                    UNREGISTERED
                                </Text>}
                            <Button
                                variant="secondary"
                                mr={2}
                                onClick={() => setShowPatientDetails(true)}
                            >
                                EDIT
                            </Button>
                        </Flex>
                    </BoxCard>
                </Skeleton>
                <BoxCard>
                    <Tabs
                        isFitted
                        m={5}
                        isLazy
                    >
                        <TabList>
                            <Tab>Prescription Orders</Tab>
                            <Tab>Repeat Medications</Tab>
                            <Tab>Medical Record</Tab>
                            <Tab>Appointments</Tab>
                            <Tab>Proxies</Tab>
                            <Tab>Consultations</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Prescriptions activePatient={activePatient} />
                            </TabPanel>
                            <TabPanel>
                                <RepeatMedications activePatient={activePatient} />
                            </TabPanel>
                            <TabPanel>
                                <MedicalRecord activePatient={activePatient} />
                            </TabPanel>
                            <TabPanel>
                                <Appointments activePatient={activePatient} />
                            </TabPanel>
                            <TabPanel>
                                <Proxies activePatient={activePatient} />
                            </TabPanel>
                            <TabPanel>
                                <Consultations activePatient={activePatient} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </BoxCard>
            </VStack>

            {showPatientDetails && (
                <EditPatientDetailsModal
                    id={activePatient.id}
                    isVisible={showPatientDetails}
                    setIsVisible={onCloseHandler}
                    onSuccess={props.onSuccess}
                    onUpdate={onUpdatePatient}
                    activePatient={activePatient}
                />
            )}
        </Box>
    );
};

export default PatientDetails;
