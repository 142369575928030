import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAppStore } from "store";

import { usePagedCrudService } from "./crud.service";
import { IUser, IUserMe, IUserInvite } from "./user.interface";
import { IPermission, Role, IRole, IPermAction } from "./role.interface";

// Trick to create a static instance and return it in useUserService()
const userService = (function() {
    const url = "/api/auth/user/";

    const userService = usePagedCrudService<IUser>({
        url,
        entityTitle: "User",
        model: "user"
    });

    const updateUserData = (data: Partial<IUserMe>) => {
        const userData = {
            ...useAppStore.getState().userData,
            ...data
        };
        useAppStore.setState({ userData });
    };

    const me = (): Promise<IUserMe> => {
        return userService._get<IUserMe>(`${url}me/`).then((user) => {
            // merge permissions from all roles
            // maybe do it on backend??
            const permissions = user.roles_details
                ?.flatMap((role: any) => role["permissions_details"] as IPermission)
                .sort((a, b) => a.id - b.id)
                .filter((v, i, a) => !i || v.id != a[i - 1].id) || [];

            useAppStore.setState({ userData: user, permissions });

            return user;
        });
    };

    const meUpdate = (id: string, item: Partial<IUserMe>): Promise<IUserMe> =>
        userService._patch<IUserMe>(
            `${url}me/`,
            {
                payload: item as any,
                successMessage: "Profile saved",
            }
        ).then((user) => {
            // merge permissions from all roles
            // maybe do it on backend??
            const permissions = user.roles_details
                ?.flatMap((role: any) => role["permissions_details"] as IPermission)
                .sort((a, b) => a.id - b.id)
                .filter((v, i, a) => !i || v.id != a[i - 1].id) || [];

            useAppStore.setState({ userData: user, permissions });

            return user;
        });

    const meDelete = (): Promise<IUserMe> =>
        userService._delete<IUserMe>(`${url}me/`);

    const invite = (invite: IUserInvite): Promise<IUserInvite> => {
        return userService._post<IUserInvite>(`${url}invite/`, {
            payload: invite, successMessage: "Invite sent"
        });
    };

    const inviteCancel = (id: string): Promise<any> => {
        return userService._delete<any>(`${url}${id}/invite/`, {
            successMessage: "Invite Cancelled and User deleted"
        });
    };

    const resendInvite = (userID: string): Promise<IUserInvite> => {
        return userService._post<IUserInvite>(`${url}${userID}/invite/resend/`, {
            payload: { }, successMessage: "Invite resent"
        });
    };

    const getAllRoles = (): Promise<IRole[]> => {
        return userService._get<IRole[]>("/api/auth/role/");
    };

    const setIsDeleted = (id: string, value = true): Promise<IUser> => {
        return userService._patch(`${url}${id}/setisdeleted/`, { payload: { value } });
    };

    const mfaActivate = (): Promise<string> => {
        return userService._post<{ details: string }>("/api/auth/mfa/app/activate/", {
            successMessage: null
        }).then((response) => response.details);
    };

    const mfaActivateConfirm = (code: string): Promise<string[]> => {
        return userService._post<{ backup_codes: string[] }>("/api/auth/mfa/app/activate/confirm/", {
            payload: { code },
            successMessage: null
        }).then((response) => {
            updateUserData({ mfa_enabled: true });
            return response.backup_codes;
        });
    };

    const mfaDeactivate = (code: string): Promise<any> => {
        return userService._post("/api/auth/mfa/app/deactivate/", {
            payload: { code },
            successMessage: null
        }).then(() => {
            updateUserData({ mfa_enabled: false });
        });
    };

    const mfaDeactivateUser = (userId: string): Promise<any> => {
        return userService._post(`/api/auth/mfa/${userId}/app/deactivate/`, {
            successMessage: null
        });
    };

    return {
        ...userService,

        me,
        meUpdate,
        meDelete,

        invite,
        inviteCancel,
        resendInvite,
        getAllRoles,
        setIsDeleted,
        mfaActivate,
        mfaActivateConfirm,
        mfaDeactivate,
        mfaDeactivateUser,
    };
})();

// Now be a real React hook
export function useUserService() {
    const userData = useAppStore((state) => state.userData);
    const permissions = useAppStore((state) => state.permissions);

    return {
        ...userService,

        meHasRole: (roleName: Role): boolean => {
            const hasRole = userData.roles_details?.find((r) => r.name.includes(roleName));

            return userData.is_superuser || !!hasRole;
        },

        meHasPemissions: (model: string, action: IPermAction): boolean => {
            const permission = permissions.find((perm) => perm.codename === `${action}_${model}`);

            return userData.is_superuser || !!permission;
        },

        meIsSuperUser: () => userData.is_superuser,

        userData
    };
}
