// Taken from https://gist.github.com/rddimon/af66932bc2e799606c5da912be3e6ea6
// with some tweaks

const objectToFormData = function (obj: any, form?: FormData, namespace?: string) {

    const fd = form || new FormData();
    let formKey: string;

    for (const property in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(property)) {
            const propValue = obj[property];

            if (namespace) {
                formKey = namespace + "." + property;
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File and not an Array,
            // use recursivity.
            const isArray = Array.isArray(propValue);
            const isFile = propValue instanceof File;
            const isDate = propValue instanceof Date;

            if (propValue == null) {
                fd.append(formKey, "");
            } else if (propValue instanceof Object && !isArray && !isFile && !isDate) {
                objectToFormData(propValue, fd, property);
            } else if (isArray) {
                propValue.map(function (item: any) {
                    const keyVal: any = {};
                    keyVal[formKey] = item;
                    objectToFormData(keyVal, fd);
                });
            } else if (isDate) {
                fd.append(formKey, propValue.toISOString());
            } else {
                // if it's a string or a File object
                fd.append(formKey, propValue);
            }
        }
    }

    return fd;
};

export default objectToFormData;
