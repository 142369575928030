import { SlotTypeStatusChoice } from "./appointment-slot.interface";

export interface IAppointment {
    // id = models.UUIDField(primary_key=True, default=uuid4, editable=False)
    id: string;

    // patient = models.ForeignKey(Patient, models.DO_NOTHING)
    patient: string;
    patient_full_name: string;

    // start_time = models.DateTimeField(blank=True, null=True)
    start_time?: Date;

    // end_time = models.DateTimeField(blank=True, null=True)
    end_time?: Date;

    // slot_type_name = models.CharField(max_length=20, blank=True, null=True)
    slot_type_name?: string;

    // slot_type_status = models.CharField(
    //     max_length=20, blank=True, null=True, choices=SlotTypeStatusChoice.choices
    // )
    slot_type_status?: SlotTypeStatusChoice;

    // session_type = models.CharField(max_length=20, blank=True, null=True)
    session_type?: string;

    booking_reason?: string;
}

/// Appointment Requests and Responses from Convenet API
/// http://test-swagger.convenet.io.s3-website.eu-west-2.amazonaws.com/#/

export interface GetAppointmentResponse {
    appointments: Appointment[];
    isProxied: boolean;
    requestId: string;
}

export interface Appointment {
    locationPostcode: string;
    locationName: string;
    slotTypeStatus: string;
    slotTypeName: string;
    locationAddLine3: string;
    locationAddLine2: string;
    locationAddLine1: string;
    appointmentId: string;
    locationAddLine5: string;
    canBeCancelled: boolean;
    locationAddLine4: string;
    staffName: string;
    sessionType: string;
    startTime: string;
    bookingDate: string;
    endTime: string;
}

export interface BookAppointmentRequest {
    telephoneNumber: string;
    appointmentSlotId: string;
    bookingReason: string; // max Length 150
    startTime: string;
    endTime: string;
    telephoneContactType: TelephoneContactTypeEnum;
}

export enum TelephoneContactTypeEnum {
    "Unknown" = "Unknown",
    "Home" = "Home",
    "Work" = "Work",
    "Mobile" = "Mobile",
    "Other" = "Other",
}

export interface DeleteAppointmentRequest {
    cancellationReason: string; // max Length 150
    appointmentId: string;
}

export interface AppointmentSlotResponse {
    isProxied:        boolean;
    appointmentSlots: AppointmentSlot[];
    requestId:        string;
}

export interface AppointmentSlot {
    locationPostcode:     string;
    locationName:         string;
    appointmentSlotId:    string;
    slotTypeStatus:       string;
    slotTypeName:         string;
    sessionType:          string;
    startTime:            string;
    endTime:              string;
    locationAddress:      string;
    clinicianDisplayName: string;
}
