import React, { memo, useCallback, useEffect, useState } from "react";

import {
    Box,
    Button,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    useColorModeValue,
    Text,
    Input,
    InputProps,
    Spacer,
    HStack
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";
import { VscJson } from "react-icons/vsc";
import { useDropzone } from "react-dropzone";

import { HSeparator } from "components/separator/Separator";

const jsonequals = (x: any, y: any) => {
    // If both x and y are null or undefined and exactly the same
    if (JSON.stringify(x) === JSON.stringify(y)) {
        return true;
    }

    // checking only that the uploaded JSON has the same properties that the original JSON(1st order properties only)
    for (const p in x) {
        // eslint-disable-next-line no-prototype-builtins
        if (!y.hasOwnProperty(p)) {
            return false;
        }
    }

    return true;
};

const UploadJson: React.FC<{
    isVisible: boolean;
    header: string;
    onClose: () => void;
    onSave: (jsonFile: any) => void; // MedicationListResponse |  GetRecordResponse
    jsonFile: any;                   // MedicationListResponse |  GetRecordResponse
}> = (props) => {
    const [ file, setFile ] = useState({} as File);
    const [ uploadedFile, setUploadedFile ] = useState<any>();
    const [ error, setError ] = useState("");
    const [ isDisabled, setIsDisabled ] = useState(false);

    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const borderColor = useColorModeValue(
        "secondaryGray.100",
        "whiteAlpha.100"
    );
    const bg = useColorModeValue("gray.100", "navy.700");

    const closeHandler = () => {
        props.onClose();
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        // Do something with the files`
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;

                try {
                    const json = JSON.parse(binaryStr as string);
                    const equal = jsonequals(json, props.jsonFile);
                    if (!equal) {
                        setError(
                            "The format of the uploaded JSON file is not correct."
                        );
                        setIsDisabled(true);
                        setFile({} as File);
                        setUploadedFile({} as any);
                    } else {
                        setFile(file);
                        setUploadedFile(json);
                        setError("");
                        setIsDisabled(false);
                        props.onSave(json);
                        props.onClose();
                    }

                } catch (error) {
                    console.log(error);
                }
            };
            reader.readAsText(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "application/json": [ ".json" ] },
        onDropAccepted: onDrop,
    });

    const saveHandler = () => {
        // save json
        props.onSave(uploadedFile);
        props.onClose();
    };

    return (
        <Modal isOpen={props.isVisible} onClose={closeHandler}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.header}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HSeparator />
                    <Flex
                        align="center"
                        justify="center"
                        bg={bg}
                        border="1px dashed"
                        borderColor={borderColor}
                        borderRadius="16px"
                        w="100%"
                        mt={25}
                        h="max-content"
                        minH="100%"
                        cursor="pointer"
                        {...getRootProps({ className: "dropzone" })}
                    >
                        <Input
                            variant="main"
                            {...(getInputProps() as InputProps)}
                        />
                        <Box>
                            <Flex justify="center" mx="auto">
                                <Icon
                                    as={MdUpload}
                                    w="50px"
                                    h="50px"
                                    color={brandColor}
                                />
                            </Flex>
                            <Flex justify="center" mx="auto" mb="12px">
                                <Text
                                    fontSize="xl"
                                    fontWeight="700"
                                    color={brandColor}
                                >
                                    Upload Files
                                </Text>
                            </Flex>
                            <Text
                                fontSize="sm"
                                fontWeight="500"
                                color="secondaryGray.500"
                            >
                                Only JSON files are allowed
                            </Text>
                        </Box>
                    </Flex>
                    {(error === "" && file?.name) && (
                        <Flex bg={"gray.100"} mt={5} alignItems="center">
                            <Icon
                                as={VscJson}
                                w="auto"
                                h="100%"
                                color={brandColor}
                            />
                            <Text ml={2}>{file?.name} Uploaded!</Text>
                        </Flex>
                    )}
                    {error !== "" && <Text color={"red"} mt={3}>{error}</Text>}
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        {/*
                        <Button variant="secondary" onClick={closeHandler}>
                            Close
                        </Button>
                        <Spacer />
                        <Button variant="primary" isDisabled={isDisabled} onClick={saveHandler}>
                            Save
                        </Button>
                        */}
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default memo(UploadJson) ;
