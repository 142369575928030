import React from "react";

import {
    Box,
    Flex
} from "@chakra-ui/react";

import { ApiClientEnv } from "services";
import CredentialCard from "./CredentialCard";

const APICredentials = () => {
    return (
        <Box mt={6}>
            <Flex gap={6} direction={{ sm: "column", md: "column", lg: "row" }}>
                <CredentialCard key={0} environment={ApiClientEnv.test} />
                <CredentialCard key={1} environment={ApiClientEnv.live} />
            </Flex>
        </Box>);
};

export default APICredentials;
