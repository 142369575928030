import React from "react";
// chakra imports
import AccordionLinks from "./AccordionLinks";
import NavLinkItem from "./NavLinkItem";
import { IMenuRoute } from "routes";
import { Accordion } from "@chakra-ui/react";
import { useUserService } from "services";

export default function SidebarLinks(props: { routes: IMenuRoute[] }) {
    const { routes } = props;
    const userService = useUserService();

    // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
    const createLinks = (routes: IMenuRoute[]) => {
        const filteredRoutes = routes.filter((route) =>
            "superuser" in route ? userService.userData.is_superuser == route.superuser : true
        ).filter((route) => {
            return route.allowedRoles && Array.isArray(route.allowedRoles)
                ? route.allowedRoles.some((r) => userService.meHasRole(r))
                : true;
        });

        return filteredRoutes.map((route, index) => {
            if (route.children && route.children.length > 0) {
                const nestedLinks = createLinks(route.children);
                return (
                    <AccordionLinks
                        key={Math.random()}
                        items={route.children}
                        links={nestedLinks}
                        title={route.name}
                        route={route}
                        index={index}
                    />
                );
            } else {
                return (
                    <NavLinkItem
                        key={Math.random()}
                        index={index}
                        route={route}
                    />
                );
            }
        });
    };

    return <>
        <Accordion defaultIndex={[]} allowMultiple border="transparent">
            {createLinks(routes)}
        </Accordion>
    </>;
}
