import React, { memo } from "react";

import { Flex, FormLabel, Text, Textarea } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { useField } from "formik";

const TextAreaFormik: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        required,
        placeholder,
        mb
    } = props;
    const id = props.id || props.name;
    const brandStars = useColorModeValue("red", "brand.400");

    const onChangeWrap = (e: any) => {
        helpers.setTouched(true);
        (props.onChange || field.onChange)(e);
    };

    return (
        <Flex direction="column" mb={mb ? mb : "10px"}>
            <FormLabel
                display="flex"
                ms="4px"
                fontSize="text.md"
                fontWeight="normal"
                color="text"
                mb={mb ? mb : "8px"}
                htmlFor={id}
                maxW="9rem"
                _hover={{ cursor: "pointer" }}
            >
                {label}
                {required && <Text color={brandStars}>*</Text>}
            </FormLabel>
            <Flex direction="column" alignItems="start"  w={props.w || "100%"}>
                <Textarea
                    {...field}
                    {...props}
                    id={id}
                    onChange={onChangeWrap}
                    required={null}
                    isRequired={false}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder={placeholder}
                    fontSize="14px"
                    w={props.w ? props.w : "inherit"}
                    borderColor={meta.error ? "red" : "inherit"}
                    borderRadius="card"
                />
                {meta.touched && meta.error ? (
                    <Text as="span" fontSize="text.xs" color={"red"} h="14px">
                        {meta.error}
                    </Text>
                ) : null}
            </Flex>
        </Flex>
    );
};

export default memo(TextAreaFormik);
