import { SignUpFeeOptionChoice, SubscriptionOptionChoice } from "./integration-product-review.interface";

export enum CommissionedByChoice {
    CCGs = "CCGs",
    STPs = "STPs",
    ICSs = "ICSS",
    OTHER = "Other"
}

export enum YesNoChoice {
    no = 0,
    yes = 1
}

export enum IntegrationProductStatusChoice {
    in_development = 0,
    under_review = 1,
    live = 2
}

export interface IIntegrationProduct {
    id: string; // UUID
    company: string; // UUID
    company_name: string;
    name: string[200];
    description?: string | null;
    commissioned_by?: CommissionedByChoice | null;
    commissioned_by_other?: string | null;
    direct_patient_care?: YesNoChoice;
    medical_device?: YesNoChoice;
    iso27001?: YesNoChoice;
    uses_pds?: boolean;
    uses_appointments?: boolean;
    uses_medical_records?: boolean;
    uses_medications?: boolean;
    uses_prescriptions?: boolean;
    uses_proxy?: boolean;
    uses_consultation?: boolean;
    free_to_use?: boolean;
    nhs_sponsor?: boolean;
    waive_sign_up_fee?: boolean;
    status?: IntegrationProductStatusChoice;
    subscriptions?: number[];
    sign_up_fee_option?: SignUpFeeOptionChoice;
    subscription_option?: SubscriptionOptionChoice;
    nhs_approval?: boolean;
    pentest_completed?: boolean;
    version: number;
}
