import React, { useState } from "react";

import { Box, Flex, GridItem, IconButton, Input, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineInfoCircle } from "react-icons/ai";

import { IoCopyOutline } from "react-icons/io5";
import { ApiClientEnv, IApiClient, useUserService } from "services";

type Props = {
    userAPIKeys: IApiClient
    environment: ApiClientEnv;
}

const UserAPIKeys = (props: Props) => {
    const { userData } = useUserService();
    const [ showSecret, setShowSecret ] = useState(false);
    const { userAPIKeys, environment } = props;
    const toast = useToast();

    const textColor = "gray.500";
    const infTextColor = "TBO.5";

    const envType = environment === ApiClientEnv.test ? "Test" : "Live";

    return (
        <Box mt={2}>
            { userData.mfa_enabled && <>
                <Flex direction={{ sm: "column", md: "row" }}  gap={{ base: 2, lg: 5 }}>
                    <Box w={{ sm: "100%", md: "30%" }}>
                        <Text color={textColor}>
                            {envType} Credentials Client ID:
                        </Text>
                    </Box>
                    <Box>
                        <Text>{userAPIKeys.client_id}</Text>
                    </Box>
                </Flex>
                <Flex
                    direction={{ sm: "column", md: "row",  }}
                    mt={5}
                    gap={{ base: 2, lg: 5 }}
                >
                    <Box w={{ sm: "100%", md: "30%" }}>
                        <Text color={textColor}>{envType} Secret: </Text>
                    </Box>
                    <Flex alignItems={"center"} justifyContent={"center"}>
                        <Input
                            type={showSecret ? "text" : "password"}
                            value={ userAPIKeys.client_secret || "" }
                            readOnly
                            // w="70%"
                            mr={1}
                        />
                        <IconButton
                            aria-label="show"
                            icon={showSecret ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            variant="setup"
                            mr={1}
                            onClick={() => setShowSecret((prevValue) => !prevValue) }
                        />
                        <IconButton
                            aria-label="Copy"
                            icon={<IoCopyOutline size="20px"/>}
                            colorScheme="gray"
                            variant="setup"
                            onClick={() => {
                                toast({
                                    title: "Copied to Clipboard!",
                                    position: "top-right",
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true
                                });
                                navigator.clipboard.writeText(userAPIKeys.client_secret || "");
                            }}
                        />
                    </Flex>
                </Flex>
                <Flex color={infTextColor} alignItems={"center"} justifyContent={"center"} mt={5}>
                    <AiOutlineInfoCircle />
                    <Text ml={2}>Keep this information somewhere safe and secure.</Text>

                </Flex>
            </>}
        </Box>
    );
};

export default UserAPIKeys;
