import { usePagedCrudService } from "services/crud.service";
import { IStripeCharge } from "./charge.interface";

export function useChargeService() {
    const service = usePagedCrudService<IStripeCharge>({
        url: "/api/billing/historic/charge/",
        entityTitle: "Charges",
        model: "djstripe_charge"
    });

    return service;
}