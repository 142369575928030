import React from "react";

import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text,
    Textarea,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

const CancelAppointmentModal: React.FC<{
    isVisible: boolean;
    onClose: () => void;
    onSave: (cancellationReason: string) => void;
}> = (props) => {
    const { onClose } = useDisclosure();
    const [ value, setValue ] = React.useState("");

    const closeHandler = () => {
        props.onClose();
    };

    const saveHandler = () => {
        props.onSave(value);
    };

    const handleInputChange = (e: any) => {
        const inputValue = e.target.value;
        setValue(inputValue);
    };

    const isDisabled = value === "";

    return (
        <Modal isOpen={props.isVisible} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign={"center"}>
                    Cancel Appointment
                </ModalHeader>
                <ModalBody>
                    <HSeparator />
                    <Text mb="8px" mt="8px">
                        Cancellation Reason:
                    </Text>
                    <Textarea
                        maxLength={150}
                        value={value}
                        onChange={handleInputChange}
                        placeholder="Write the reasons for your cancellation here"
                        size="sm"
                        maxHeight={700}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button variant="secondary" mr={3} onClick={closeHandler}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        isDisabled={isDisabled}
                        onClick={saveHandler}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CancelAppointmentModal;
