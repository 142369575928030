import React from "react";
import { useNavigate, To } from "react-router-dom";

import { Box, IconButton, Text } from "@chakra-ui/react";
import { MdArrowBackIosNew } from "react-icons/md";

export default function BackButton({ backPath }: { backPath: string | number | undefined }) {
    const navigate = useNavigate();

    if (!backPath)
        return null;

    return (
        <IconButton
            aria-label="back"
            icon={<MdArrowBackIosNew />}
            height={"48px"}
            minWidth={"unset"}
            width={"20px"}
            variant="ghost"
            fontSize={"headingLarge.md"}
            color={"TBO.5"}
            mr={"8px"}
            onClick={() => {
                navigate(backPath as To);
            }}
        />
    );
}
