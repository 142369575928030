// More Details in "Convenet API Instructions" page 30
// https://blueberryconsultants-my.sharepoint.com/personal/steve_moorse_bbconsult_co_uk/_layouts/15/Doc.aspx?sourcedoc={9677dcd5-4966-4819-b91d-4e81dff712a6}

export enum PrescriptionStatusResponse {
    Requested = "Requested",
    Processed = "Processed",
    Issued = "Issued",
    Rejected = "Rejected"
}
export interface IPrescription {
    // id = models.UUIDField(primary_key=True, default=uuid4, editable=False)
    id: string;

    // medicine_id = models.CharField(max_length=50)
    medicine_id: string;

    // prescription_id = models.CharField(max_length=50)
    prescription_id: string;

    // medicine_name = models.CharField(max_length=200)
    medicine_name: string;

    // status = models.CharField(max_length=20)
    status: PrescriptionStatusResponse;

    // request_date = models.DateTimeField()
    request_date: Date;

    // patient = models.ForeignKey(Patient, models.DO_NOTHING)
    patient: string;

}
