import React, { useState, useEffect, useContext } from "react";
// Chakra imports
import {
    Box,
    useColorModeValue,
    Portal,
    Button,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import ThemeSwitchButton from "components/theme-switch-button/ThemeSwitchButton";

// Layout components
import Navbar from "components/navbar/Navbar";
import Sidebar, { SidebarResponsive } from "components/sidebar/Sidebar";
import {
    menuRoutes,
    routes
} from "routes";
import { Outlet, useLocation, matchRoutes } from "react-router-dom";
import SidebarProvider from "contexts/SidebarContext";
import { useAppStore } from "store";
import { useAuthService, useUserService } from "services";
import { AppContext } from "contexts/AppContext";
import useTicks from "hooks/use-ticks";
import UpdateDisallowedModal from "./UpdateDisallowedModal";

// https://stackoverflow.com/questions/3715047/how-to-reload-a-page-using-javascript
function hardreload() {
    //window.location.reload();
    window.location.replace(window.location.pathname + window.location.search + window.location.hash);
}

function UpdateBanner() {
    const [ showUpdateBanner, newversion ] = useAppStore((state) => [ state.showUpdateBanner, state.newversion ]);

    if (!showUpdateBanner) {
        return null;
    }

    return (
        <Portal>
            <Alert
                status="warning"
                position="fixed"
                top={0}
                zIndex={10001}
                flexDirection="column"
                alignItems="center"
                bgColor="white"
                flexDir="row"
            >
                <AlertIcon />
                <AlertTitle>Update <strong>{newversion}</strong></AlertTitle>
                <AlertDescription flexGrow={1}>
                    The new version of the Convenet is out!
                    Please, reload the page fully for the changes to take effect.
                </AlertDescription>
                <Button onClick={hardreload}>Reload</Button>
            </Alert>
        </Portal>
    );
}

function IdleBanner() {
    const authService = useAuthService();
    const { setIsLoggedIn } = useContext(AppContext);
    const [ visible, setVisible ] = useState(false);
    const [ status, setStatus ] = useState<string>("Active");
    const [ remaining, setRemaining ] = useState<number>(0);
    const { activate, getRemainingTime } = useIdleTimer({
        onIdle: () => {
            setStatus("Idle");
            setVisible(false);
            authService.logout().then(() => {
                setIsLoggedIn(false);
            });
        },
        onActive: () => {
            setStatus("Active");
            setVisible(false);
        },
        onPrompt: () => { setStatus("Prompted"); setVisible(true); },
        onAction: (event?: Event, idleTimer?: IIdleTimer) => {
            // Needed to break "Prompted" status
            idleTimer?.activate();
        },
        timeout: 1000 * 60 * 30, // 30 minutes
        promptBeforeIdle: 1000 * 60, // 1 minute
        throttle: 500
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const _remaining = Math.ceil(getRemainingTime() / 1000);
            setRemaining(_remaining);
            sessionStorage.setItem("remaining", String(_remaining));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    if (!visible)
        return null;

    return (
        <Portal>
            <Alert
                position="fixed"
                top={0}
                zIndex={10000}
                flexDirection="column"
                alignItems="flex-start"
                bgColor="white"
            >
                <AlertDescription>You will be logged out in {remaining} seconds</AlertDescription>
            </Alert>
        </Portal>
    );
}

function useLoadUserData() {
    const { load: loadHomePageTicks, updateInformationGovernanceTick } = useTicks();
    const userService = useUserService();
    const [ isInitialized, setIsInitialized ] = useState(false);

    // Initial data load
    useEffect(() => {
        const loadTicks = async () => {
            try {
                await userService.me();
                await loadHomePageTicks();
            } finally {
                setIsInitialized(true);
            }
        };
        loadTicks();
    }, []);

    useEffect(() => {
        const update = async () => {
            await loadHomePageTicks();
        };

        isInitialized && update();
    }, [ userService.userData.impersonated_company ]);

    return { isInitialized };
}

export default function AdminLayout(): JSX.Element {
    const themeBackground = useColorModeValue("lightThemeBackground", "darkThemeBackground");
    const [ brandText, setBrandText ] = useState("");
    const [ backPath, setBackPath ] = useState<string | number>("");
    const setPageTitle = useAppStore((state) => state.setPageTitle);
    const { isInitialized } = useLoadUserData();
    const location = useLocation();

    useEffect(() => {
        const route = matchRoutes(routes, location)?.filter(m => m.route.handle)?.map(m => m.route)[0];

        if (!route?.handle)
            return;

        const { name = "", backPath = "" } = route.handle;

        setBackPath(backPath);
        setBrandText(name);
        setPageTitle(name);

        const newtitle = name ? `Convenet Portal - ${name}` : "Convenet Portal";
        (document.head.querySelector("title") as any).innerHTML = newtitle;
    }, [ location ]);

    return <>
        {isInitialized && <Box as="main">
            <SidebarProvider value={{ menuRoutes }}>
                <IdleBanner />
                <UpdateBanner />
                <Sidebar routes={menuRoutes} />
                <SidebarResponsive routes={menuRoutes} />
                <Box
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    backgroundColor={themeBackground}
                    overflow="auto"
                    position="relative"
                    maxHeight="100%"
                    w={{ base: "100%", xl: "calc( 100% - 290px )" }}
                    maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Box
                        mx="auto"
                        position="relative"
                        minH="100vh"
                    >
                        <Box minH={{ base:"120px", md:"85px" }} w="inherit" position="relative">
                            <Navbar
                                brandText={brandText}
                                backPath={backPath}
                                zIndex="1000"
                                position="fixed"
                                secondary={false}
                            />
                        </Box>
                        <Box position="relative" pr={{ base: "20px", md:"32px" }} pl={{ base: "20px", md: "35px" }}
                            mx="auto"
                            pt="0"
                            pb={{ base: "50px", md: "100px" }}
                        >
                            <Outlet />
                        </Box>

                    </Box>
                </Box>
                {/* <ThemeSwitchButton /> */}
                <UpdateDisallowedModal />
                <Footer />
            </SidebarProvider>
        </Box>}
    </>;
}
