import React, { useEffect, useContext } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";

import ConvenetIntegration from "views/admin/default/index";
import ManageCompanies from "views/admin/companies";
import ManageContracts from "views/admin/contracts";
import ManageUsers from "views/admin/users";
import Profile from "views/admin/profile";

import Login from "views/auth/login";
import CreateAccount from "views/auth/registration";
import CreateAccountConfirm from "views/auth/registration-confirm";
import ResetPassword from "views/auth/password-reset";
import PasswordConfirm from "views/auth/password-confirm";

import Patients from "../views/admin/patients";
import ManageGP from "views/admin/gp";
import PatientDetails from "views/admin/patients/components/patient-details/PatientDetails";
import Dashboard from "views/admin/dashboard";
import CheckIsSuitable from "views/admin/convenet-integration/check-suitable/CheckIsSuitable";
import DevelopIntegration from "../views/admin/convenet-integration/Develop";
import APICredentials from "views/admin/convenet-integration/api-credentials/ApiCredentials";
import DeveloperTeam from "views/admin/convenet-integration/developer-team/DeveloperTeam";
import DeveloperProduct from "views/admin/convenet-integration/DeveloperProduct";
import DeveloperCompany from "views/admin/convenet-integration/your-company/DeveloperCompany";
import SubmitForReview from "views/admin/convenet-integration/SubmitForReview";
import TestPatients from "views/admin/convenet-integration/TestPatients";
import SignUpFee from "views/admin/billing/sign-up-fee/SignUpFee";
import SuccessPayment from "views/admin/billing/SuccessPayment";
import FailedPayment from "views/admin/billing/FailedPayment";
import SignContract from "views/admin/convenet-integration/SignContract";

import DocuSignCallback from "views/auth/docusign-callback";
import APISubscription from "views/admin/billing/api-subscription/APISubscription";
import AuthAudit from "views/admin/auth-audit";

import TestGPs from "views/admin/convenet-integration/TestGPs";
import TestGPDetails from "views/admin/convenet-integration/test-gp-details";
import ManageProductReview from "views/admin/product-review/ManageProductReview";
import PaymentHistory from "views/admin/billing/PaymentHistory";
import ChatExpert from "views/admin/convenet-integration/ChatExpert";
import TokenisationIntegration from "../views/admin/tokenise/Tokenisation";

export const authRoutes: RouteObject[] = [
    {
        path: "/docusign-callback",
        element: <DocuSignCallback />,
    },
    {
        path: "/*",
        element: <Navigate to="/auth/login" replace={true} />,
    },
    {
        path: "/auth/*",
        element: <AuthLayout />,
        children: [
            {
                handle: { name: "Sign In" },
                path: "login",
                element: <Login />,
            },
            {
                handle: { name: "Sign Up" },
                path: "registration",
                element: <CreateAccount />,
            },
            {
                handle: { name: "Invitation Confirm" },
                path: "registration-confirm/:user/:token",
                element: <CreateAccountConfirm />,
            },
            {
                handle: { name: "Restore Password" },
                path: "password-reset",
                element: <ResetPassword />,
            },
            {
                handle: { name: "Confirm Password" },
                path: "password-confirm/:uid/:token",
                element: <PasswordConfirm />
            },
            {
                path: "*",
                element: <Navigate to="/auth/login" replace={true} />,
            },
        ],
    },
];

export const routes: RouteObject[] = [
    {

        path: "/docusign-callback",
        element: <DocuSignCallback />,
    },
    {
        path: "/*",
        element: <Navigate to="/app/default" replace={true} />,
    },
    {
        path: "/auth/*",
        element: <AuthLayout />,
        children: [
            {
                handle: { name: "Confirm Password" },
                path: "password-confirm/:uid/:token",
                element: <PasswordConfirm />
            },
            {
                handle: { name: "Invitation Confirm" },
                path: "registration-confirm/:user/:token",
                element: <CreateAccountConfirm />,
            },
            {
                path: "*",
                element: <Navigate to="/app/default" replace={true} />,
            },
        ]
    },
    {

        path: "/app/*",
        element: <AdminLayout />,
        children: [
            {
                handle: { name: "Home" },
                path: "default",
                element: <ConvenetIntegration />,
            },
            {
                handle: { name: "Dashboard" },
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                handle: { name: "Manage Companies" },
                path: "companies",
                element: <ManageCompanies />,
            },
            {
                handle: { name: "Manage Contracts" },
                path: "contracts",
                element: <ManageContracts />,
            },
            {
                handle: { name: "Manage Users" },
                path: "users",
                element: <ManageUsers />,
            },
            {
                handle: { name: "Auth Audit" },
                path: "auth-audit",
                element: <AuthAudit />,
            },
            {
                handle: { name: "User Profile" },
                path: "profile",
                element: <Profile />,
            },
            {
                handle: { name: "Manage Test Patients" },
                path: "patient",
                element: <Patients />,
            },
            {
                handle: { name: "Edit Patient", backPath: -1 },
                path: "patient/:patientId",
                element: <PatientDetails />,
            },
            {
                handle: { name: "Manage GPs" },
                path: "gp",
                element: <ManageGP />,
            },
            {
                handle: { name: "GPs" },
                path: "gp",
                element: <ManageGP />,
            },
            {
                handle: { name: "Check Suitability", backPath: -1 },
                path: "convenet-integration/check-suitable",
                element: <CheckIsSuitable />,
            },
            {
                handle: { name: "Develop", backPath: -1 },
                path: "convenet-integration/develop",
                element: <DevelopIntegration />,
            },
            {
                handle: { name: "API Credentials", backPath: -1 },
                path: "convenet-integration/api-credentials",
                element: <APICredentials />,
            },
            {
                handle: { name: "Your Team", backPath: -1 },
                path: "convenet-integration/team",
                element: <DeveloperTeam />,
            },
            {
                handle: { name: "Your Product", backPath: -1 },
                path: "convenet-integration/product",
                element: <DeveloperProduct />,
            },
            {
                handle: { name: "Your Company", backPath: -1 },
                path: "convenet-integration/company",
                element: <DeveloperCompany />,
            },
            {
                handle: { name: "Submit for Review", backPath: -1 },
                path: "convenet-integration/submit",
                element: <SubmitForReview />,
            },
            {
                handle: { name: "Test Patients", backPath: -1 },
                path: "convenet-integration/test-patients",
                element: <TestPatients />,
            },
            {
                handle: { name: "Test Patients", backPath: -1 },
                path: "convenet-integration/test-patients/:patientId",
                element: <PatientDetails />,
            },
            {
                handle: { name: "Test GPs", backPath: -1 },
                path: "convenet-integration/test-gps",
                element: <TestGPs />,
            },
            {
                handle: { name: "Test GPs", backPath: -1 },
                path: "convenet-integration/test-gps/:gpId",
                element: <TestGPDetails />,
            },
            {
                handle: { name: "Chat to an Expert", backPath: -1 },
                path: "convenet-integration/contact-expert",
                element: <ChatExpert />,
            },
            {
                handle: { name: "Sign Up Fee", backPath: -1 },
                path: "billing/sign-up-fee",
                element: <SignUpFee />,
            },
            {
                handle: { name: "Success Payment", backPath: -1 },
                path: "billing/success-payment/",
                element: <SuccessPayment />,
            },
            {
                handle: { name: "Failed Payment", backPath: -1 },
                path: "billing/failed-payment",
                element: <FailedPayment />,
            },
            {
                handle: { name: "API Subscription", backPath: -1 },
                path: "billing/api-subscription",
                element: <APISubscription />,
            },
            {
                handle: { name: "Payment History", backPath: -1 },
                path: "billing/payment-history",
                element: <PaymentHistory />,
            },
            {
                handle: { name: "Sign Contract", backPath: -1 },
                path: "sign-contract",
                element: <SignContract />,
            },
            {
                handle: { name: "Manage Product Review", backPath: -1 },
                path: "product-review",
                element: <ManageProductReview />,
            },
            {
                handle: { name: "Tokenisation" },
                path: "tokenisation",
                element: <TokenisationIntegration />,
            },
            {
                path: "*",
                element: <Navigate to="/app/default" replace={true} />,
            },
        ]
    },
];
