import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
    colors: {
        brand: {
            100: "#E9E3FF",
            200: "#422AFB",
            300: "#422AFB",
            400: "#7551FF",
            500: "#422AFB",
            600: "#3311DB",
            700: "#02044A",
            800: "#190793",
            900: "#11047A",
        },
        brandScheme: {
            100: "#E9E3FF",
            200: "#7551FF",
            300: "#7551FF",
            400: "#7551FF",
            500: "#422AFB",
            600: "#3311DB",
            700: "#02044A",
            800: "#190793",
            900: "#02044A",
        },
        brandTabs: {
            100: "#E9E3FF",
            200: "#422AFB",
            300: "#422AFB",
            400: "#422AFB",
            500: "#422AFB",
            600: "#3311DB",
            700: "#02044A",
            800: "#190793",
            900: "#02044A",
        },
        WHITE: "#FFFFFF",
        themeBackground: "#EDF2F7",
        profileIcon: "#B3B3B3",
        secondaryGray: {
            100: "#E0E5F2",
            200: "#E1E9F8",
            300: "#F4F7FE",
            400: "#E9EDF7",
            500: "#8F9BBA",
            600: "#A3AED0",
            700: "#707EAE",
            800: "#707EAE",
            900: "#1B2559",
        },
        red: {
            100: "#FEEFEE",
            500: "#EE5D50",
            600: "#E31A1A",
        },
        blue: {
            50: "#EFF4FB",
            500: "#3965FF",
        },
        orange: {
            100: "#FFF6DA",
            500: "#FFB547",
        },
        green: {
            100: "#E6FAF5",
            500: "#01B574",
        },
        navy: {
            50: "#d0dcfb",
            100: "#aac0fe",
            200: "#a3b9f8",
            300: "#728fea",
            400: "#3652ba",
            500: "#1b3bbb",
            600: "#24388a",
            700: "#1B254B",
            800: "#111c44",
            900: "#0b1437",
        },
        gray: {
            50: "#F7FAFC",
            100: "#FAFCFE",
            200: "#E2E8F0",
            300: "#CBD5E0",
            400: "#A0AEC0",
            500: "#718096",
            600: "#4A5568",
            700: "#2D3748",
            800: "#1A202C",
            900: "#171923",
        },
        // Bailey's Ocean Dream
        BOD: {
            1: "#5C5F8D",
            2: "#51547F",
            3: "#44476E",
            4: "#3A3C61",
            5: "#2C2E4F",
            6: "#22243E",
        },
        // Turner's Burnt Orange
        TBO: {
            1: "#FFA596",
            2: "#FF9280",
            3: "#FF826E",
            4: "#FF7059",
            5: "#FF6147",
            6: "#FF4D30",
        },
        // Cool Grey
        CG: {
            1: "#F2F0F7",
            2: "#E8E5F0",
            3: "#E0DCE6",
            4: "#D6D2DD",
            5: "#CBC7D2",
            6: "#C0BCC9",
        },
        // Dark Grey
        DG: {
            1: "#555555",
            2: "#4A4A4A",
            3: "#333333",
            4: "#222222",
            5: "#111111",
            6: "#000000",
        },
        // Blue Sapphire
        BS: {
            1: "#336382",
            2: "#296182",
            3: "#226182",
            4: "#155E83",
            5: "#0B5B82",
            6: "#045A82",
        },
        // Spiro Disco Ball
        SDB: {
            1: "#85E6FF",
            2: "#68E1FF",
            3: "#53DDFF",
            4: "#29D4FF",
            5: "#15D1FF",
            6: "#01CDFF",
        },
        ICONS: {
            1: "#470137",
            2: "#FF61F6",
            3: "#2684FF",
            4: "#4FD1C5",
            5: "#7BCBD4",
            6: "#29C6B7",
            7: "#3BCBBE",
            8: "#DC395F",
            9: "#EB001B",
            10: "#F79E1B",
            11: "#36C5F0",
            12: "#2EB67D",
            13: "#ECB22E",
            14: "#E01E5A",
            15: "#2EBD59",
            16: "#F7931A",
            17: "#10C537",
            delete: "#c94a4a",
            close: "#FB3434"
        },
        BTN: {
            1: "#F2EFFF",
        },
        CHART: {
            radialBar: "#6bae62",

        }
    },
    fontSizes: {
        headingLarge: {
            xs: "0.875rem", // 14px
            sm: "1rem", // 16px
            md: "1.25rem", // 20px
            lg: "1.875rem", // 30px
            xl: "2.25rem", // 36px
            "2xl": "3rem", // 48px
            "3xl": "3.75rem", // 60px
            "4xl": "4.5rem", // 72px
        },
        headingSmall:{
            xs: "0.875rem", // 14px
            sm: "1rem", // 16px
            md: "1.25rem", // 20px
            lg: "1.5rem", // 24px
            xl: "1.875rem", // 30px
            "2xl": "2.25rem", // 36px
            "3xl": "3rem", // 48px
            "4xl": "3.75rem", // 60px
        },
        text: {
            xs: "0.75rem", // 12px
            sm: "0.875rem", // 14px
            md: "1rem", // 16px
            lg: "1.125rem", // 18px
            xl: "1.25rem", // 20px
            "2xl": "1.5rem", // 24px
            "3xl": "1.875rem", // 30px
            "4xl": "2.25rem", // 36px
            "5xl": "3rem", // 48px
            "6xl": "3.75rem", // 60px
        },
        //Heading
        h:{
            1: "0.875rem", // 14px
            2: "1rem", // 16px
            3: "1.5rem", // 24px
            4: "2.25rem", // 36px
            5: "3rem", // 48px
        }
    },
    font: {
        normal: 400,
        semibold: 500,
        bold: 700,
        extrabold: 800,
    },
    shadows: {
        card: "0px 20px 35px 2px rgba(0, 0, 0, 0.15)",
        xs: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
        sm: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        base: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
        md: "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
        lg: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        xl: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
        "2xl": "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
        outline: "0px 0px 0px 3px rgba(63, 153, 225, 0.6)",
        inner: "inset 0px 2px 4px rgba(0, 0, 0, 0.06)",
        dark_lg: "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
        button: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)", //deleted from buttons, but stored here if needed
    },
    lineHeights: {
        regular: "1.5",
        tight: "1.4",
        tighter: "1.3",
    },
    space: {
        0: "0",
        px: "1px",
        1: "0.25rem", // 4px
        2: "0.5rem", // 8px
        3: "0.75rem", // 12px
        4: "1rem", // 16px
        5: "1.25rem", // 20px
        6: "1.5rem", // 24px
        7: "1.75rem", // 28px
        8: "2rem", // 32px
        9: "2.25rem", // 36px
        10: "2.5rem", // 40px
        12: "3rem", // 48px
        14: "3.5rem", // 56px
        16: "4rem", // 64px
        20: "5rem", // 80px
        24: "6rem", // 96px
        32: "8rem", // 128px
        40: "10rem", // 160px
        48: "12rem", // 192px
        56: "14rem", // 224px
        64: "16rem", // 256px,
        card: "28px",
    },
    radii: {
        none: "0px",
        sm: "0.125rem", //2px
        base: "0.25rem", //4px
        md: "0.375rem", //6px
        lg: "0.5rem", //8px
        xl: "0.75rem", //12px
        "2xl": "1rem", //16px
        "3xl": "1.5rem", //24px
        full: "9999px",
        card: "0.9375rem" // 15px
    },
    breakpoints: {
        sm: "320px",
        "2sm": "380px",
        md: "768px",
        lg: "960px",
        xl: "1200px",
        "2xl": "1536px",
        "3xl": "1920px",
    },
    styles: {
        global: (props: any) => ({
            body: {
                overflowX: "hidden",
                bg: mode("secondaryGray.300", "navy.900")(props),
                fontFamily: "Inter",
                letterSpacing: "-0.5px",
                color: "lightText",
                fontSize: "text.md",
            },
            input: {
                color: "gray.700",
            },
            html: {
                fontFamily: "Inter",
            },
        }),
    },
};
