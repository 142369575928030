import React, { useEffect, useRef, useCallback } from "react";
import { useBeforeUnload, unstable_useBlocker as useBlocker } from "react-router-dom";


function usePrompt(message: string | boolean, { beforeUnload }: any = {}) {

    const blocker = useBlocker(
        useCallback(
            () => (typeof message === "string" ? !window.confirm(message) : false),
            [ message ]
        )
    );
    const prevState = useRef(blocker.state);

    useEffect(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [ blocker ]);

    useBeforeUnload(
        useCallback(
            (event) => {
                if (beforeUnload && typeof message === "string") {
                    event.preventDefault();
                    event.returnValue = message;
                }
            },
            [ message, beforeUnload ]
        ),
        { capture: true }
    );
}

type Props = {
    when: boolean;
    message: string;
}

function Prompt({ when, message, ...rest }: Props) {
    usePrompt(when ? message : false, rest);

    return null;
}

export default Prompt;
