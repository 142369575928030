import React, { useEffect, useState } from "react";

import { Flex, Select, Spacer, Stack, Text } from "@chakra-ui/react";
import { FieldMetaProps, FormikProps } from "formik";

import { getIntervalText, StripePriceRecurringInterval } from "../billing/api-subscription/SubscriptionItem";
import { getTextWithCurrency } from "components/utils/currency-helper";
import { IStripeProduct, useProductsService } from "services/billing/products.service";
import { IIntegrationProductReview, SubscriptionOptionChoice } from "services/integration-product-review.interface";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import { useAppStore } from "store";
import ShowChangeBespokePrice from "./ShowChangeBespokePrice";
import BespokePrices from "views/admin/billing/BespokePricesModal";
import { PurchasedResponse } from "../billing/sign-up-fee/SignUpFee";
import { SelectFormik } from "components/fields";

type Props = {
    formikInstance: FormikProps<any>;
    integrationProductReview: IIntegrationProductReview;
    existPrice: (value: boolean) => void;
};

const SubscriptionSelect = (props: Props) => {
    const productsService = useProductsService();
    const bespokeProductPriceService = useBespokePriceService();

    const { formikInstance, integrationProductReview, existPrice } = props;
    const company = useAppStore(state => state.company);
    const integrationProduct = useAppStore(state => state.integrationProduct);

    // subscription states
    const [ subscriptionsItems, setSubscriptionsItems ] = useState<IStripeProduct[]>([]);
    const [ selectedSubscriptionProduct, setSelectedSubscriptionProduct ] = useState<IStripeProduct>({} as IStripeProduct);
    const [ showBespokePricesModalForSubscription, setShowBespokePricesModalForSubscription ] = useState(false);
    const [ showSubscriptionPrice, setShowSubscriptionPrice ] = useState(false);
    const [ subscriptionPriceError, setSubscriptionPriceError ] = useState(false);
    const [ subscriptionBespokePrice, setSubscriptionBespokePrice ] = useState<BespokeProductPrice>({} as BespokeProductPrice);
    const [ subscriptionPaid, setSubscriptionPaid ] = useState(false);

    useEffect(() => {
        const load = async () => {
            // values for subscription dropdown
            const products = await productsService.getProducts();
            const subscriptionsProducts = products.filter(product =>
                product.default_price.type === "recurring"
            );
            setSubscriptionsItems(subscriptionsProducts);

            setShowSubscriptionPrice(integrationProductReview.subscription_option === SubscriptionOptionChoice.bespoke);

            const _chosen_subscription = (subscriptionsProducts.filter(prod => prod.djstripe_id === +(integrationProductReview.subscriptions[0])))[0];
            if (_chosen_subscription) {
                setSelectedSubscriptionProduct(_chosen_subscription);
                const _hasBespokePrice = await bespokeProductPriceService.getAll({ integration_product__company__id: company.id, product__id: _chosen_subscription.id });

                if (_hasBespokePrice.length === 0 && integrationProductReview.subscription_option === SubscriptionOptionChoice.bespoke) {
                    existPrice(false);
                    setSubscriptionPriceError(true);
                }

                setSubscriptionBespokePrice(_hasBespokePrice[0]);

                const purchased: PurchasedResponse = await productsService.hasPurchasedProduct(_chosen_subscription.djstripe_id, integrationProduct.id);
                setSubscriptionPaid(purchased.purchased);
            }
        };

        load();
    }, []);

    const getBespokePrice = async (id: string): Promise<BespokeProductPrice> => {
        const query = (await bespokeProductPriceService.getAll({ integration_product__company__id: company.id, product__id: id }))[0];
        return query;
    };

    const getSubscriptionTypeSelectProps = (formik: FormikProps<any>, name: string) => ({
        name,
        value: (formik.values as any)[name],
        onChange: async (e: any) => {
            const value = parseInt(e.target.value);
            formik.setFieldValue(name, isNaN(value) ? -1 : value);

            const _subscription = subscriptionsItems.filter(prod => prod.djstripe_id === value)[0];
            setSelectedSubscriptionProduct(_subscription);

            if (formik.values["subscription_option"] === SubscriptionOptionChoice.bespoke) {
                const _bespokePrice = await getBespokePrice(_subscription.id);
                setSubscriptionBespokePrice(_bespokePrice);
                existPrice(!!_bespokePrice);
            }
        }
    });

    const getSubscriptionOptionSelectProps = (formik: FormikProps<any>, name: string) => ({
        name,
        value: String((formik.values as any)[name]),
        onChange: async (e: any) => {
            if (formik.values["chosen_subscription"] === "") {
                formik.setFieldError("chosen_subscription", "Before adding a bespoke price, you must first select a subscription.");
                return;
            }

            const value = Number.parseInt(e.target.value);
            formik.setFieldValue(name, value, true);

            setShowSubscriptionPrice(value === SubscriptionOptionChoice.bespoke ? true : false);

            if (value === SubscriptionOptionChoice.bespoke) {
                const _bespokePrice = await getBespokePrice(selectedSubscriptionProduct.id);
                setSubscriptionBespokePrice(_bespokePrice);
                existPrice(!!_bespokePrice);
            } else {
                existPrice(true);
            }
        }
    });

    const onChangePrice = async (addedPrice: BespokeProductPrice) => {
        setSubscriptionBespokePrice(addedPrice);
        setSubscriptionPriceError(false);
        existPrice(true);
    };

    const onDeletePrice = () => {
        setSubscriptionBespokePrice({} as BespokeProductPrice);
        setSubscriptionPriceError(true);
        existPrice(false);
    };

    const showError = ({ error, touched }: FieldMetaProps<unknown>) => (
        <Text as="span" fontSize="text.xs" color={"red"} h="14px">{error ?  error : ""}</Text>
    );

    return <>
        {/* Subscription Type */}
        <Stack direction={{ base: "column", lg: "row" }}>
            <Text>Subscription Type</Text>
            <Spacer />
            <Stack w={{ base: "100%", lg: "35%" }}>
                <SelectFormik
                    placeholder='Select option'
                    disabled={subscriptionPaid}
                    {...getSubscriptionTypeSelectProps(formikInstance, "chosen_subscription")}
                >
                    {subscriptionsItems.map((prod: IStripeProduct, idx: number) => {
                        const price = prod.default_price;
                        const priceText = getTextWithCurrency(price ? price.currency : "GBP", (price ? price.unit_amount : 0) / 100);
                        const interval = getIntervalText(price?.recurring?.interval as StripePriceRecurringInterval, price?.recurring?.interval_count);

                        return <option key={idx} value={prod.djstripe_id} >
                            {`${prod.name} (${priceText} ${interval})`}
                        </option>;
                    }
                    )}
                </SelectFormik>
            </Stack>
        </Stack>

        {/* Subscription Option */}
        <Stack direction={{ base: "column", lg: "row" }}>
            <Text>Subscription Option</Text>
            <Spacer />
            <Stack w={{ base: "100%", lg: "35%" }}>
                <SelectFormik
                    type="number"
                    placeholder='Select option'
                    disabled={subscriptionPaid}
                    {...getSubscriptionOptionSelectProps(formikInstance, "subscription_option")}
                >
                    <option value={SubscriptionOptionChoice.standard}>Standard</option>
                    <option value={SubscriptionOptionChoice.bespoke}>Bespoke</option>
                </SelectFormik>
            </Stack>
        </Stack>

        {showSubscriptionPrice && !subscriptionPaid &&
            <ShowChangeBespokePrice
                bespokePrice={subscriptionBespokePrice}
                priceError={subscriptionPriceError}
                showModal={() => setShowBespokePricesModalForSubscription(true)} />
        }

        {showBespokePricesModalForSubscription &&
            <BespokePrices
                product={selectedSubscriptionProduct}
                onChange={onChangePrice}
                company={company}
                onDeletePrice={onDeletePrice}
                showModal={showBespokePricesModalForSubscription}
                setShowModal={setShowBespokePricesModalForSubscription}
            />
        }
    </>;
};

export default SubscriptionSelect;
