import { useBaseService } from "./crud.service";
import {
    IStartQueryParams, IStartQueryResponse, IGetQueryResultsResponse
} from "./log.interface";
import debounce from "lodash/debounce";

const logService = (function() {
    const base = useBaseService();

    const url = "/api/mock/logs/query/";

    const startQuery = (payload: IStartQueryParams): Promise<IStartQueryResponse> => {
        return base._post(url, { payload });
    };

    const getQueryResults = (queryId: string): Promise<IGetQueryResultsResponse> => {
        return base._get(url, { payload: { queryId } });
    };

    const runQuery = (payload: IStartQueryParams): Promise<IGetQueryResultsResponse> => {
        return base._post(`${url}run/`, { payload });
    };

    const runQuery2 = (
        payload: IStartQueryParams,
        callback?: (result: IGetQueryResultsResponse) => void
    ): Promise<IGetQueryResultsResponse> => {
        const debounced = debounce((result: IGetQueryResultsResponse) => callback?.(result), 200);

        return new Promise<IGetQueryResultsResponse>((resolve, reject) => {
            startQuery(payload).then(async (response) => {
                try {
                    // Run until status === "Complete"
                    let result: IGetQueryResultsResponse;
                    do {
                        result = await getQueryResults(response.queryId);
                        callback && debounced(result);
                    } while (result.status === "Scheduled" || result.status === "Running");

                    debounced.cancel();
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            });
        });
    };

    return {
        startQuery,
        getQueryResults,
        runQuery,
        runQuery2,

        model: "logservice"
    };
})();

export const useLogService = () => logService;
