export enum SlotTypeStatusChoice {
    unknown = "Unknown",
    practice = "Practice",
    telephone = "Telephone",
    visit = "Visit",
    video = "Video",
}

export interface IAppointmentSlot {
    id: string; // UUID
    start_time: Date | string;
    end_time: Date | string;
    slot_type_name?: string[20];
    slot_type_status?: SlotTypeStatusChoice;
    appointment_slot_id?: string[20];
    session_type?: string[20];
    gp: string; // UUID

}
