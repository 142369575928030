import { mode } from "@chakra-ui/theme-tools";
export const textareaStyles = {
    components: {
        Textarea: {
            baseStyle: {
                field: {
                    fontWeight: 400,
                    bg: "fieldBG",
                    color: "text",
                    border: "1px solid",
                    borderColor: "fieldBorder",
                    fontSize: "text.sm",
                    _placeholder: { color: "gray.400", fontWeight: "400" },
                    _focus: {
                        border: "2px solid",
                        borderColor: "navy.400"
                    },
                },
            },

            variants: {
                main: (props: any) => ({
                    field: {
                        fontSize: "sm",
                        p: "10px 16px",
                        borderRadius: "card",
                    },
                }),
                auth: (props: any) => ({
                    field: {
                    },
                }),
                authSecondary: (props: any) => ({
                    field: {
                    },
                }),
                search: (props: any) => ({
                    field: {
                        border: "none",
                        borderRadius: "inherit",
                    },
                }),
            },
        },
    },
};
