import React, { memo } from "react";
import { useField } from "formik";
import { useColorModeValue } from "@chakra-ui/system";
import { Box, Flex, FormLabel, Input, Stack, Text } from "@chakra-ui/react";

const TextFieldFormik: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        required,
        placeholder,
        auth = false,
        mb = "10px",
        variant = "modal",
        ...rest
    } = props;
    const id = props.id || props.name;
    const brandStars = useColorModeValue("red", "brand.400");

    const onChangeWrap = (e: any) => {
        !auth && helpers.setTouched(true);
        (props.onChange || field.onChange)(e);
    };

    const onBlurWrap = (e: any) => {
        if (auth) {
            helpers.setError(undefined);
        } else {
            (props.onBlur || field.onBlur)(e);
        }
    };

    return (
        <Flex
            direction={{ base:"column", md:"row" }}
            align={{ base: "start", md: "center" }}
            justify={{ base: "space-start", md: "space-between" }}
            flexWrap={"wrap"} mb={mb}
        >
            { label &&
                <FormLabel
                    display="flex"
                    fontSize="text.md"
                    fontWeight="normal"
                    color="text"
                    mb={{ base: "2", md: "0" }}
                    me="0"
                    htmlFor={id}
                    _hover={{ cursor: "pointer" }}
                    maxW="8.3rem"
                >
                    {label}
                    {required && <Text color={brandStars}>*</Text> }

                </FormLabel>
            }
            <Flex direction="column" alignItems="start"  w={props.w || "auto"}>
                <Input
                    {...field}
                    {...rest}
                    id={id}
                    onChange={onChangeWrap}
                    onBlur={onBlurWrap}
                    // Substitute "email" with "text" to disable built-in browser validation - Steve wanted type email
                    type={rest.type}
                    required={null}
                    isRequired={false}
                    variant={variant}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder={placeholder}
                    fontWeight="500"
                    size="lg"
                    borderColor={meta.error ? "red" : "gray.400"}
                />
                {meta.touched && meta.error ? (
                    <Text as="span" fontSize="text.xs" color={"red"} h="14px" ml={3}>
                        {meta.error}
                    </Text>
                ) : null}
            </Flex>
        </Flex>
    );
};

export default memo(TextFieldFormik);
