import React, { memo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Heading, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { IoEllipsisHorizontalCircle } from "react-icons/io5";

import BoxCard from "components/card/Card";
import { LiveIcon } from "../convenet-integration/FigmaIcons";
import { useAppStore } from "store";
import { BsCheck2 } from "react-icons/bs";

const GoLiveCard = () => {
    const textLink = "TBO.5";
    const iconColor = useColorModeValue("BOD.2", "CG.1");
    const iconCheckColor = "#11A04A";
    const iconUncheckColor = "#DBDBDB";

    const isProductLive = useAppStore(state => state.isProductLive);
    const pentestCompletedTick = useAppStore(state => state.pentestCompletedTick);
    const nhsApprovalTick = useAppStore(state => state.nhsApprovalTick);
    const liveCredentialsGenerated = useAppStore(state => state.liveCredentialsGenerated);

    return <BoxCard mr="0" mb={{ base: "5", md: "0" }} minH="530px" h="100%" opacity={ isProductLive ? 1 : 0.5 } >
        <Box margin={"1vh auto"}>
            <LiveIcon boxSize={24} color={iconColor} />
        </Box>

        <Flex justify="center" align={"center"} direction="column" >
            <Heading fontSize="text.lg" color="textDark" lineHeight="regular">
                            Go Live
            </Heading>
            <Heading fontSize="text.sm" fontWeight="normal" lineHeight="regular"color={textLink}>Stage 3</Heading>
        </Flex>

        <Flex direction={"column"} margin="1em auto" gap={"5"}>
            {/* Complete Pentest */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { pentestCompletedTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                {/* // todo: ADD LINK? - color on hover is changed to textLink */}
                <Text>Complete Pentest</Text>
            </Flex>

            {/* NHS Approval */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { nhsApprovalTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Text>NHS Approval</Text>
            </Flex>

            {/* Live API Credentials */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { liveCredentialsGenerated
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/api-credentials"}
                >
                    Live API Credentials
                </Link>
            </Flex>
        </Flex>
    </BoxCard>;
};

export default memo(GoLiveCard);
