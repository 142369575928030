import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
} from "@chakra-ui/react";
import AuthPage from "./components/AuthPage";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { TextFieldFormik } from "components/fields";
import { useAuthService } from "services";

type EmailConfirmation = {
    email: string;
}

const initialData: EmailConfirmation = {
    email: ""
};

const formValidationSchema = Yup.object({
    email: Yup.string()
        .email()
        .required("Email is a required field")
});

function ResetPassword() {
    const navigate = useNavigate();
    const authService = useAuthService();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ showSecondView, setShowSecondView ] = useState(false);
    const [ email, setEmail ] = useState("");

    const onSubmit = async (values: EmailConfirmation, { setSubmitting, setErrors }: FormikHelpers<EmailConfirmation>) => {
        setIsLoading(true);

        authService.passwordReset(values.email).then(() => {
            setIsLoading(false);
            setShowSecondView(true);
            setEmail(values.email);

        }).catch((errors) => {
            setErrors({ ...errors });
            setIsLoading(false);
        }).finally(() => setSubmitting(false));
    };

    return (
        <AuthPage>
            { !showSecondView &&
                <Flex
                    w={{ base: "100%", sm: "376px" }}
                    mx="auto"
                    justify="center"
                    alignItems="center"
                    px={{ base: "15px", md: "43px" }}
                    py={{ base: "10px", md: "35px" }}
                    flexDirection="column"
                    backgroundColor="authCardBG"
                    borderRadius={"card"}
                    boxShadow={"md"}
                    textAlign="center"
                >
                    <Heading
                        color="text"
                        fontSize="headingSmall.lg"
                        mb="9"
                        lineHeight="1.5"
                        fontWeight="600"
                    >
                            Password Recovery
                    </Heading>
                    <Text
                        mb="6"
                        lineHeight="1.6"
                        fontWeight="400"
                        maxW="300px"
                        textAlign="center"
                    >
                    Enter the email address you use to Sign-in into your account.
                    </Text>
                    <Formik<EmailConfirmation>
                        enableReinitialize={true}
                        initialValues={initialData}
                        validationSchema={ formValidationSchema }
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<EmailConfirmation>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <TextFieldFormik
                                    type="email"
                                    name="email"
                                    auth={true}
                                    w="100%"
                                    mr={0}
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Email"
                                    required={true}
                                />
                                <Button
                                    variant={"primaryFullWidth"}
                                    mb="3"
                                    type="submit"
                                    isLoading={isLoading}
                                >
                                    Send Email
                                </Button>
                            </FormControl>

                        )}
                    </Formik>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        maxW="100%"
                        mt="0px"
                    >
                        <Text
                            color="textDetails"
                            fontWeight="400"
                            lineHeight={1.5}
                            fontSize="text.xs"
                        >
                            <NavLink to="/auth/login">
                                <Text
                                    color="textBrand"
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                        Ready to sign in?
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            }

            { showSecondView &&
                <Flex
                    w={{ base: "100%", sm: "376px" }}
                    mx="auto"
                    justify="center"
                    alignItems="center"
                    px={{ base: "15px", md: "43px" }}
                    py={{ base: "10px", md: "35px" }}
                    flexDirection="column"
                    backgroundColor="authCardBG"
                    borderRadius={"card"}
                    boxShadow={"md"}
                    textAlign="center"
                >
                    <Heading
                        color="text"
                        fontSize="headingSmall.lg"
                        mb="9"
                        lineHeight="1.5"
                        fontWeight="600"
                    >
                            Password Recovery
                    </Heading>
                    <Text mb={5}>
                        We have sent an email to <i>{email}</i>. This email will enable you to change your password.
                    </Text>
                    <Button
                        variant={"primaryFullWidth"}
                        mb="3"
                        onClick={ () => navigate("/") }
                    >
                        Sign In
                    </Button>
                </Flex>
            }
        </AuthPage>
    );
}

export default ResetPassword;
