import { usePagedCrudService } from "./crud.service";
import { IRole, IPermission } from "./role.interface";

const roleService = (function() {
    const roleService = usePagedCrudService<IRole>({
        url: "/api/auth/role/",
        entityTitle: "Role",
        model: "role"
    });

    return {
        ...roleService,

        getAllPermissions(): Promise<IPermission[]> {
            return roleService._get<IPermission[]>("/api/auth/permission/");
        }
    };
})();

export const useRoleService = () => roleService;
