import { createContext } from "react";

import {
    TGoogleData,
} from "services";

type AppContextType = {
    googleId: TGoogleData;
    isLoggedIn: boolean;
    setIsLoggedIn: (value: boolean) => void;
};

export const AppContext = createContext<AppContextType>(<any>{});
