import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    Select,
    Spacer,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useAlert } from "components/alert-modal";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import BoxCard from "components/card/Card";
import {
    YesNoChoice,
    IntegrationProductStatusChoice,
    useIntegrationProductService,
    useIntegrationProductReviewService,
    IIntegrationProductReview,
    SignUpFeeOptionChoice,
    ApiScopeEnum
} from "services";
import useTicks from "hooks/use-ticks";
import { useAppStore } from "store";
import SignUpFeeSelect from "./SignUpFeeSelect";
import SubscriptionSelect from "./SubscriptionSelect";
import { SelectFormik } from "components/fields";
import Prompt from "components/Prompt";

type ProductReviewData = {
    chosen_subscription: string;
}

type MixedData = Partial<ProductReviewData> & Partial<Omit<IIntegrationProductReview, "contract_templates">>;

const statusNames = {
    [IntegrationProductStatusChoice.in_development]: "In Development",
    [IntegrationProductStatusChoice.under_review]: "Under Review",
    [IntegrationProductStatusChoice.live]: "Live"

};

const ManageProductReview = () => {
    const integrationProductReviewService = useIntegrationProductReviewService();
    const integrationProductService = useIntegrationProductService();

    const company = useAppStore(state => state.company);
    const integrationProduct = useAppStore(state => state.integrationProduct);
    const setSignUpFeeWaived = useAppStore(state => state.setSignUpFeeWaived);

    const { updateIntegrationProduct } = useTicks();

    const [ isLoaded, setIsloaded ] = useState(false);
    const [ mixedData, setMixedData ] = useState<MixedData>({});

    const [ integrationProductReview, setIntegrationProductReview ] =
        useState<IIntegrationProductReview>({} as IIntegrationProductReview);

    const [ existSignUpFeePrice, setExistSignUpFeePrice ] = useState(true);
    const [ existSubscriptionPrice, setExistSubscriptionPrice ] = useState(true);

    const alertShow = useAlert();

    useEffect(() => {
        const load = async () => {
            const _integrationProductReview =
                (await integrationProductReviewService.getAll({ integration_product__company__id: company.id }))[0];
            setIntegrationProductReview(_integrationProductReview);

            const {
                sign_up_fee_option,
                subscriptions,
                pentest_completed,
                nhs_approval,
                subscription_option,
                scopes } = _integrationProductReview;

            setMixedData({
                sign_up_fee_option,
                subscriptions,
                pentest_completed,
                nhs_approval,
                subscription_option,
                scopes,
                chosen_subscription: subscriptions.length ? subscriptions[0] : "",
            });

            setIsloaded(true);
        };

        load();

    }, []);

    const handleGoLive = () => {
        alertShow({
            header: "Warning",
            message: `Are you sure ${integrationProduct.name ?? ""} is ready to go live?`,
            onSuccess: async () => {
                const updatedIP = await integrationProductService.update(
                    integrationProduct.id,
                    { status: IntegrationProductStatusChoice.live, version: integrationProduct.version },
                    { showSuccessMessage: false }
                );
                useAppStore.setState({ isProductLive: updatedIP.status === IntegrationProductStatusChoice.live });
            }
        });
    };

    const onSubmit = async (values: MixedData, { setSubmitting, setErrors, resetForm }: FormikHelpers<MixedData>) => {
        let errors = {};
        const subscriptionsArray = values.chosen_subscription == "-1"
            ?  []
            : values.chosen_subscription
                ? [ values.chosen_subscription ]
                : values.subscriptions ? [ ...values.subscriptions ] : [];

        // update appStore
        setSignUpFeeWaived(values.sign_up_fee_option === SignUpFeeOptionChoice.waive);

        if (values.sign_up_fee_option === SignUpFeeOptionChoice.bespoke) {
            if (!existSignUpFeePrice || !existSubscriptionPrice) {
                return;
            }
        }

        // update status
        // According to Steve: don't update IP status when saving the Review
        /*if (integrationProduct.status == IntegrationProductStatusChoice.in_development) {
            await integrationProductService.update(
                integrationProduct.id,
                {
                    status: IntegrationProductStatusChoice.under_review,
                    version: integrationProduct.version
                },
                { showSuccessMessage: false }
            ).catch((error) => {
                errors = { ...errors, ...error };
            });
        }*/

        await integrationProductReviewService.update(
            integrationProductReview?.id,
            { ...values, subscriptions: subscriptionsArray }
        ).catch(error => {
            errors = { ...errors, ...error };
        });

        updateIntegrationProduct();

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // Reset Form imperatively, because dirty and isValid are not cleared
            resetForm({ values });
        }
    };

    const formValidationSchema = Yup.object({
        sign_up_fee_option: Yup.number().oneOf([ 0, 1, 2 ]),
        // TODO:
        // sign_up_fee_amount: showSignUpAmountInput
        //     ? Yup.number()
        //         .required(" Please provide a bespoke price for the Sign Up Fee.")
        //         .max(10000000, "Cost must be less than or equal to 7 digits.")
        //     : Yup.number()
        //         .max(10000000, "Cost must be less than or equal to 7 digits."),
        chosen_subscription: Yup.number(),
        nhs_approval: Yup.boolean(),
        pentest_completed: Yup.boolean(),
        subscription_option: Yup.number().oneOf([ 0, 1 ]),
    });

    const onChangeSignUpFeeSelect = (value: boolean) => {
        setExistSignUpFeePrice(value);
    };

    const onChangeSubscriptionSelect = (value: boolean) => {
        setExistSubscriptionPrice(value);
    };

    if (!isLoaded)
        return null;

    const getScopesProps = (formik: FormikProps<MixedData>, scope: ApiScopeEnum) => ({
        isChecked: formik.values.scopes?.includes(scope),
        onChange: (e: any) => {
            const scopes = e.target.checked
                ? [ ...new Set(formik.values.scopes?.concat([ scope ])) ]
                : formik.values.scopes?.filter(s => s !== scope);
            formik.setFieldValue("scopes", scopes);
        }
    });

    return (
        <Box mt={6}>
            <Formik<MixedData>
                enableReinitialize={true}
                initialValues={mixedData}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<MixedData>) => (
                    <FormControl
                        as="form"
                        onSubmit={formik.handleSubmit as any}
                    >
                        <Prompt when={formik.dirty} message="You have unsaved data on this page. Want to leave anyway?" />
                        <BoxCard>
                            <Box>
                                <Stack spacing={30}>
                                    {/* Product Status */}
                                    <Stack
                                        paddingLeft="15px"
                                        paddingRight="8px"
                                        alignItems={{ base: "baseline", md: "center" }}
                                        bgColor="CG.1"
                                        borderRadius="card"
                                        boxShadow="none"
                                        minH="64px"
                                        direction={{ base: "column", md: "row" }}
                                    >
                                        <Text>Product Status:
                                            <Text as="span" color="TBO.5" textTransform="uppercase">
                                                {" " + statusNames[integrationProduct.status || 0]}
                                            </Text>
                                        </Text>
                                        <Spacer />
                                        {integrationProduct.status == IntegrationProductStatusChoice.under_review && <Button
                                            variant="primary"
                                            onClick={handleGoLive}
                                        >
                                            Go Live
                                        </Button>}
                                    </Stack>

                                    <SignUpFeeSelect
                                        formikInstance={formik}
                                        integrationProductReview={integrationProductReview}
                                        existPrice={onChangeSignUpFeeSelect} />

                                    <SubscriptionSelect
                                        formikInstance={formik}
                                        integrationProductReview={integrationProductReview}
                                        existPrice={onChangeSubscriptionSelect} />

                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Live Credentials Scopes</Text>
                                        <Spacer />
                                        <Box w={{ sm: "100%", md: "35%", lg: "35%" }}>
                                            <VStack alignItems="left">
                                                <Checkbox
                                                    {...getScopesProps(formik, ApiScopeEnum.Patient)}
                                                >
                                                    Patient
                                                </Checkbox>
                                                <Checkbox
                                                    {...getScopesProps(formik, ApiScopeEnum.Pds)}
                                                >
                                                    PDS
                                                </Checkbox>
                                                <Checkbox
                                                    {...getScopesProps(formik, ApiScopeEnum.Consultation)}
                                                >
                                                    Consultation
                                                </Checkbox>
                                            </VStack>
                                        </Box>
                                    </Stack>

                                    {/* Pentest Completed */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Pentest Completed</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="boolean"
                                                name="pentest_completed"
                                                placeholder='Select option'
                                            >
                                                <option value={"false"}>No</option>
                                                <option value={"true"}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>

                                    {/* NHS Approval */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>NHS Approval</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="boolean"
                                                name="nhs_approval"
                                                placeholder='Select option'
                                            >
                                                <option value={"false"}>No</option>
                                                <option value={"true"}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Box>
                        </BoxCard>
                        <Flex direction="row" justifyContent="end" mt={5}>
                            <Button
                                variant="primary"
                                alignSelf="flex-end"
                                type="submit"
                                isDisabled={!existSignUpFeePrice || !existSubscriptionPrice}
                            >
                                Save
                            </Button>
                        </Flex>
                    </FormControl>
                )}
            </Formik>
        </Box>
    );
};

export default ManageProductReview;
