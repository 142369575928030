import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { globalStyles } from "./styles";
import { multiSelectStyles } from "./components/multiselect";
import { semanticStyles } from "./semanticTokens";
import { tabStyles } from "./components/tab";

export default extendTheme(
    globalStyles,
    semanticStyles, // semantic tokens (colors)
    badgeStyles, // badge styles
    buttonStyles, // button styles
    linkStyles, // link styles
    progressStyles, // progress styles
    sliderStyles, // slider styles
    inputStyles, // input styles
    textareaStyles, // textarea styles
    switchStyles, // switch styles
    tabStyles,
    multiSelectStyles,
    CardComponent // card component
);
