import React, { useContext, useState, useReducer, useEffect, memo } from "react";

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    Box,
    SimpleGrid,
    Flex,
    Text,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { ICompany, IRole, IUser, useAuthService, UserEnum, useUserService } from "services";
import { MultiSelect, Option } from "components/MultiSelect";
import { TextFieldFormik, DropDownFormik } from "components/fields";

const userBlank: Partial<IUser> = {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    roles: [],
    status: UserEnum.Inactive,
};

const formValidationSchema = Yup.object({
    email: Yup.string().email().required("Email is a required field"),
    first_name: Yup.string().max(30).nullable(),
    last_name: Yup.string().max(30).nullable(),
    company: Yup.string().required("Company is a required field"),
    roles: Yup.array().of(Yup.string()).required("Role is a required field")
});

type Props = {
    id: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void;
    companies?: ICompany[];
    roles?: IRole[];
} & { [key: string]: any };

function CreateEditUser(props: Props) {
    const userService = useUserService();
    const authService = useAuthService();
    const { userData } = userService;
    const [ isSuperUser ] = useState(userService.meIsSuperUser());
    const {
        id,
        isVisible,
        setIsVisible,
        onSuccess,
        companies = [],
        roles = [],
    } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ user, setUser ] = useState<Partial<IUser>>({
        ...userBlank,
        ...(isSuperUser ? {} : { company: userData.company })
    });
    const options: Option[] = roles.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const onCancel = () => {
        setIsVisible(false);
    };

    const onReset = () => {
        setIsLoading(true);
        if (user.status == UserEnum.Invited && user.id) {
            userService.resendInvite(user.id).then((r) => {
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            });
        } else if (user.status == UserEnum.Active && (user.email)) {
            authService.passwordReset(user.email).then((r) => {
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            });
        } else {
            setIsLoading(false);
        }
    };

    const onDelete = () => {
        if (!user.id)
            return;

        setIsLoading(true);
        if (user.status == UserEnum.Invited) {
            userService.inviteCancel(user.id).then(() => {
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch(() => {
                setIsLoading(false);
            });
        } else {
            userService.setIsDeleted(user.id).then(() => {
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }
    };

    const onSubmit = (values: Partial<IUser>, { setSubmitting, setErrors }: FormikHelpers<Partial<IUser>>) => {
        if (id) {
            setIsLoading(true);
            userService.update(id, values).then(() => {
                setUser(values);
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            // Note: POST /api/auth/user/ was disabled, so this code won't work anymore
            /*
            setIsLoading(true);
            userService.create(values).then((response) => {
                setUser(userBlank);
                setIsLoading(false);
                onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => {
                setSubmitting(false);
            });
            */
        }
    };

    useEffect(() => {
        setUser({ ...userBlank });

        if (id) {
            setIsLoading(true);
            userService.get(id).then((response) => {
                setUser({
                    id: response.id,
                    email: response.email,
                    first_name: response.first_name,
                    last_name: response.last_name,
                    company: response.company,
                    roles: response.roles,
                    status: response.status,
                });
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
                onCancel();
            });
        }
    }, [ id ]);

    if (!isVisible) return null;

    const userNotInactiveCreated = ![ UserEnum.Inactive, UserEnum.Created ].includes(user.status || 0);

    return (
        <Modal
            size="5xl"
            isOpen={isVisible}
            onClose={onCancel}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <Formik<Partial<IUser>>
                enableReinitialize={true}
                initialValues={user}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<Partial<IUser>>) => (
                    <FormControl as="form" onSubmit={formik.handleSubmit as any}>
                        <ModalContent bg="cardBG">
                            <ModalHeader>
                                {!id ? "Create User" : "Edit User"}
                            </ModalHeader>
                            <ModalCloseButton />
                            <Box borderTop="1px solid" borderColor="gray.300" mx="card" />
                            <ModalBody my="card">
                                <SimpleGrid
                                    templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
                                    columnGap={{ base: "10", md: "16", lg: "32" }}
                                    rowGap="4"
                                >
                                    <TextFieldFormik
                                        label="Email"
                                        type="email"
                                        name="email"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Email"
                                        required={true}
                                    />
                                    <TextFieldFormik
                                        label="First Name"
                                        type="text"
                                        name="first_name"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="First Name"
                                        maxLength={30}
                                    />
                                    <TextFieldFormik
                                        label="Last Name"
                                        type="text"
                                        name="last_name"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        placeholder="Last Name"
                                        maxLength={30}
                                    />
                                    {isSuperUser && <DropDownFormik
                                        label="Company"
                                        name="company"
                                        bg="fieldBG"
                                        color="text"
                                        borderRadius="card"
                                        required
                                        placeholder="Select a Company"
                                    >
                                        {companies.map((item: ICompany, i: number) =>
                                            <option key={i} value={item.id}>{item.name}</option>
                                        )}
                                    </DropDownFormik>}
                                    <MultiSelect
                                        labelProps={{
                                            ml: "4px",
                                            mr: "12px",
                                            mb: "12px",
                                            fontSize: "sm",
                                            fontWeight: "bold",
                                            w: "100%"
                                        }}
                                        selectedListProps={{
                                            options, // hack to show labels instead of values
                                            bg: "fieldBG",
                                        }}
                                        options={options}
                                        value={formik.values.roles}
                                        label="Roles"
                                        required
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => formik.setFieldValue("roles", e || [] as any, true)}
                                    ></MultiSelect>
                                </SimpleGrid>
                            </ModalBody>
                            { userNotInactiveCreated &&
                            <Box mx="card">
                                <Box borderTop="1px solid" borderColor="gray.300" mb="card" />

                                <Text fontSize="sm" mt="4">Actions</Text>
                                <Button
                                    mt={2}
                                    mr={3}
                                    variant="secondary"
                                    onClick={() => onReset()}
                                >
                                    {user.status == UserEnum.Invited ? "Resend invite" : user.status == UserEnum.Active ? "Password Reset" : ""}
                                </Button>
                                <Button
                                    mt={2}
                                    variant="secondary"
                                    borderColor="red.500"
                                    color="red.500"
                                    onClick={() => onDelete()}
                                >
                                    {user.status == UserEnum.Invited ? "Cancel invite" : "Delete"}
                                </Button>
                            </Box>
                            }
                            <Box borderTop="1px solid" borderColor="gray.300" m="card" mb="0" />
                            <ModalFooter>
                                <Flex
                                    align="center"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    mt={5}
                                >
                                    <Button
                                        isLoading={isLoading}
                                        variant="secondary"
                                        onClick={onCancel}
                                    >
                                    Cancel
                                    </Button>
                                    <Button
                                        isLoading={isLoading}
                                        variant="primary"
                                        ml={3}
                                        type="submit"
                                        isDisabled={!(formik.isValid && formik.dirty) || (formik.values.roles?.length === 0)}
                                    >
                                        {!id ? "Create" : "Save"}
                                    </Button>
                                </Flex>
                            </ModalFooter>
                        </ModalContent>
                    </FormControl>
                )}
            </Formik>
        </Modal>
    );
}

export default memo(CreateEditUser);
