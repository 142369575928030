import React, { useState, useEffect } from "react";
// Chakra Imports
import {
    Box,
    BoxProps,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Flex,
    Link,
    Text,
} from "@chakra-ui/react";
import NavbarMenu from "components/navbar/NavbarMenu";
import BackButton from "components/navbar/BackButton";
import { css } from "@emotion/react";
import { useAppStore } from "store";

import { Icon } from "@chakra-ui/icons";
import { FaBuilding } from "react-icons/fa";

export type AdminNavbarProps = {
    brandText: string;
    backPath?: string | number;
    secondary: boolean;
    message?: string;
};

const navbarStyle = css`
  /* Firefox prefix */
  @-moz-document url-prefix() {
    width: -moz-available;
  }
`;

export default function Navbar(props: BoxProps & AdminNavbarProps) {
    const { position, zIndex, secondary, message, brandText, backPath, width } = props;

    // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
    const navbarFilter = "none";
    const navbarBackdrop = "blur(20px)";
    const navbarShadow = "none";
    const navbarBorder = "transparent";
    const secondaryMargin = "0px";
    const paddingX = "0";
    const gap = "0px";

    return (
        <Box
            as="header"
            position={position}
            css={navbarStyle}
            boxShadow={navbarShadow}
            bg="themeBG"
            borderColor={navbarBorder}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            backgroundPosition="center"
            backgroundSize="cover"
            // borderRadius="16px"
            borderWidth="1.5px"
            borderStyle="solid"
            /*transitionDelay="0s, 0s, 0s, 0s"
            transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
            transition-property="box-shadow, background-color, filter, border"
            transitionTimingFunction="linear, linear, linear, linear"*/
            alignItems={{ xl: "center" }}
            display={secondary ? "block" : "flex"}
            minH="90px"
            maxW={{ md:"unset", lg: "100%", xl: "83%" }}
            lineHeight="25.6px"
            mx="auto"
            mt={secondaryMargin ? secondaryMargin : "18px"}
            pb="8px"
            w="-webkit-fill-available"
            zIndex={zIndex}
        >
            <Flex
                w="100%"
                flexDirection={{
                    sm: "column",
                    md: "row",
                }}
                alignItems="center"
                mb={gap}
                pr={{ base:"20px", md:"32px" }} pl={{ base:"20px", md:"35px" }}
                pt="22px"
                ml="2"
            >
                {/* Here we create navbar brand, based on route name */}
                <Box
                    color="text"
                    bg="inherit"
                    borderRadius="inherit"
                    fontWeight="bold"
                    fontSize="30px"
                    display="flex"
                    alignContent={"center"}
                    alignItems="center"
                    justifyContent={"start"}
                    minW="300px"
                    w="100%"
                >
                    <BackButton backPath={backPath} />
                    <Text fontSize={"headingLarge.lg"} fontWeight={700} color="gray.700" whiteSpace="nowrap">
                        {brandText}
                    </Text>
                </Box>

                <Box display="flex" justifyContent={{ base: "start", md: "end" }}
                    ml={{ sm: "unset", md: "auto" }}
                    mr="3"
                    w="100%"
                >
                    <Button backgroundColor="TBO.5" variant="no-effects" textTransform="none" >
                        <Icon as={FaBuilding} color="white" marginRight={3} w={4} h={4}></Icon>
                        <Text
                            fontSize="text.md"
                            fontWeight="700"
                            color="white"
                        >
                            { useAppStore.getState().company.name ?? "" }
                        </Text>
                    </Button>
                </Box>

                <Box ml={{ base: "unset", md: "auto" }}  w={{ sm: "100%", md: "unset" }}>
                    <NavbarMenu
                        secondary={props.secondary}
                    />
                </Box>
            </Flex>
            {secondary ? <Text color="white">{message}</Text> : null}
        </Box>
    );
}
