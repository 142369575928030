interface ITemplateTab {
    id: string;
    name: string;
    value: string;
    required: boolean;
    recepient_id: string; // UUID
    template: string; // UUID
}

export interface ITemplateRecepient {
    id: string;
    role: string;
}

export interface ITemplate {
    id: string; // UUID
    name: string;
    is_active: boolean;

    // computed:
    tabs: ITemplateTab[];
    recipients: ITemplateRecepient[];
}

export interface IContractSigner {
    id: string; // UUID
    role: string; // ITemplateRecepient.role
    signed: boolean;
    user: string; // UUID
    user_first_name: string;
    user_last_name: string;
}

export enum ContractStatus {
    // DocuSign Statuses
    created = "Created",
    sent = "Sent",
    void = "Void",
    completed = "Completed",
    declined = "Declined",
    // Local only, not from DocuSign
    // DocuSign doesn't have a status for when a contract is partially signed, it would still be in the "Sent" status
    partially_signed = "Partially Signed",
}

export interface IContract {
    id: string; // UUID
    integration_product: string; // UUID
    integration_product_name: string;
    company_name: string;
    template: string; // UUID
    template_name: string;
    signers: IContractSigner[];
    envelope_id: string;
    status: ContractStatus;
}

export interface USignResponse {
    envelope_id: string; // UUID
    redirect_url: string; // link to docusign.net
    role: string;
}
