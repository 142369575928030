import { usePagedCrudService } from "./crud.service";
import { IGP } from "./gp.interface";

const gpService = (function() {
    const gpService = usePagedCrudService<IGP>({
        url: "/api/mock/gp/",
        entityTitle: "GP",
        model: "gp"
    });

    return gpService;
})();

export const useGPService = () => gpService;
