import React, { useContext, useEffect, useState, useCallback } from "react";

import {
    Text,
    Badge,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast
} from "@chakra-ui/react";
import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
} from "components/paged-grid";
import {
    ApiClientEnv,
    ApiScopeEnum,
    IApiClient,
    IPermAction,
    useApiClientService
} from "services";
import { useAlert } from "components/alert-modal";

type Props = {
    id: string; // company_id
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void,
};

export default function EditApiKeysModal(props: Props) {
    const toast = useToast();
    const alertShow = useAlert();
    const { id, isVisible, setIsVisible } = props;
    const apiClientService = useApiClientService();
    const [ key, setKey ] = useState(0);

    const reloadGrid = () => { // Force PagedGrid to reload the data
        setKey((value) => value + 1);
    };

    const onCancel = () => {
        setIsVisible(false);
        props.onSuccess?.();
    };

    const apiClientDelete = (api_client: IApiClient) => {
        alertShow({
            header: "Warning",
            message: "Are you sure you want to delete Cognito API keys?",
            onSuccess: () => {
                apiClientService.delete(api_client.id).then(() => reloadGrid());
            }
        });
    };

    const apiClientCreate = (api_client: Partial<IApiClient>) => {
        apiClientService.create(api_client).then(() => reloadGrid());
    };

    const columns: GridColumn[] = [
        {
            field: "id",
            header: "ID",
            getComponent: (row: Row<IApiClient>) => {
                return <Text as="span">{row.data.id?.substring(row.data.id.length - 6)}</Text>;
            },
        },
        {
            field: "environment",
            header: "Type",
            getComponent: (row: Row<IApiClient>) => {
                return <Text>{ApiClientEnv[row.data.environment]}</Text>;
            }
        },
        {
            field: "client_id",
            header: "Client ID",
            getComponent: (row: Row<IApiClient>) => {
                return <Button
                    colorScheme="gray"
                    variant="setup"
                    textTransform="none"
                    onClick={() => {
                        toast({
                            title: "Copied to Clipboard!",
                            position: "top-right",
                            status: "success",
                            duration: 3000,
                            isClosable: true
                        });
                        navigator.clipboard.writeText(row.data.client_id || "");
                    }}
                >
                    {row.data.client_id?.substring(0, 8)}...
                </Button>;
            }
        },
        {
            field: "client_secret",
            header: "Client Secret",
            getComponent: (row: Row<IApiClient>) => {
                return <Button
                    colorScheme="gray"
                    variant="setup"
                    textTransform="none"
                    onClick={() => {
                        toast({
                            title: "Copied to Clipboard!",
                            position: "top-right",
                            status: "success",
                            duration: 3000,
                            isClosable: true
                        });
                        navigator.clipboard.writeText(row.data.client_secret || "");
                    }}
                >
                    {row.data.client_secret?.substring(0, 8)}...
                </Button>;
            }
        },
        {
            field: "is_valid",
            header: "Valid",
            getComponent: (row: Row<IApiClient>) => {
                return <Badge
                    borderRadius="16px"
                    colorScheme={row.data.is_valid ? "green" : "red"}
                >
                    {row.data.is_valid ? "YES" : "NO"}
                </Badge>;
            },
        }
    ];

    const rowActions: GridActionsRowButton<IApiClient>[] = [
        {
            text: "Edit",
            type: IPermAction.Delete,
            handler: (row: Row<IApiClient>) => apiClientDelete(row.data),
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Test",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                apiClientCreate({
                    company: id,
                    environment: ApiClientEnv.test,
                    scopes: [ ApiScopeEnum.Patient, ApiScopeEnum.Consultation, ApiScopeEnum.Pds ]
                });
            },
        },
        {
            text: "Add Live",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                apiClientCreate({
                    company: id,
                    environment: ApiClientEnv.live,
                    scopes: [ ApiScopeEnum.Patient, ApiScopeEnum.Consultation, ApiScopeEnum.Pds ]
                });
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "company",
            match: () => "",
            visible: () => false,
            getValue: () => id,
            getComponent: () => <></>
        },
    ];

    if (!isVisible) {
        return null;
    }

    return (
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false} size="5xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Manage Cognito Api Keys</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <PagedGrid<IApiClient>
                        reloadKey={key}
                        columns={columns}
                        rowActions={rowActions}
                        globalActions={globalActions}
                        dataService={apiClientService}
                        filters={filters}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onCancel()} variant="secondary" mr={3}>Cancel</Button>
                    <Button onClick={() => onCancel()}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
