import React, { useContext, memo } from "react";

import {
    Button,
    Icon,
    useColorModeValue,
    ButtonProps,
} from "@chakra-ui/react";

import {
    CodeResponse,
    OverridableTokenClientConfig,
    TokenResponse,
    useGoogleLogin,
} from "@react-oauth/google";

import { FcGoogle } from "react-icons/fc";
import { AppContext } from "contexts/AppContext";

type Props = {
    onSuccess: (response: TokenResponse) => void;
    isLoading: boolean;
    label?: string;
};

function GoogleButton(props: Props & ButtonProps) {
    const { onSuccess, isLoading, label, ...rest } = props;
    const { googleId } = useContext(AppContext);

    const googleText = useColorModeValue("BOD.5", "white");
    const googleHover = useColorModeValue({ bg: "CG.5" }, { bg: "CG.3" });
    const googleActive = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "white" }
    );

    const googleLogin = useGoogleLogin({
        onSuccess,
        onError: (response: any) => console.log(response),
        onNonOAuthError: (error: any) => console.log(error),
        scope: googleId.scope,
        overrideScope: true,
        redirect_uri: googleId.redirectUri,
    } as OverridableTokenClientConfig);

    if (!googleId)
        return null;

    return (
        <Button
            variant="secondary"
            {...rest}
            color={googleText}
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            isDisabled={isLoading}
            onClick={() => googleLogin()}
        >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            {label || "Sign in with Google"}
        </Button>
    );
}

export default memo(GoogleButton);
