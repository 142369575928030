import React, { useEffect, useState } from "react";

import { Flex, Text } from "@chakra-ui/react";

import { IStripeProduct, useProductsService } from "services/billing/products.service";
import SubscriptionItem from "./SubscriptionItem";
import SubscriptionsSkeleton from "../SubscriptionsSkeleton";
import { useAppStore } from "store";

const APISubscription = () => {
    const productsService = useProductsService();

    const integrationProduct = useAppStore(state => state.integrationProduct);

    const [ isLoading, setIsLoading ] = useState(true);
    const [ noSubscriptionSelected, setNoSubscriptionSelected ] = useState(false);
    const [ product, setProduct ] = useState<IStripeProduct>({} as IStripeProduct);

    useEffect(() => {
        const load = async () => {
            if (!integrationProduct.subscriptions?.length) {
                setNoSubscriptionSelected(true);
                return;
            }

            const _selectedSubscriptionProduct = await productsService.getProductById(integrationProduct?.subscriptions[0]);

            setProduct(_selectedSubscriptionProduct);
            setIsLoading(false);
        };

        load();
    }, []);

    if (noSubscriptionSelected) {
        return <Text>The Convenet Subscription payment will be required when your product is ready to go live</Text>;
    }

    return <>
        {isLoading
            ? <SubscriptionsSkeleton />
            : <Flex wrap="wrap">
                {/* {products.map((product: IStripeProduct, idx: number) => <SubscriptionItem key={idx} product={product} />)} */}
                <SubscriptionItem product={product} />
            </Flex>
        }
    </>;
};

export default APISubscription;