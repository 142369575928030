import React, { useContext, useState, useEffect } from "react";

import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { ICompany, IUserInvite, IRole, Role, useUserService } from "services";
import { MultiSelect, Option } from "components/MultiSelect";
import { TextFieldFormik, DropDownFormik } from "components/fields";

const inviteBlank: IUserInvite = {
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    roles: []
};

const formValidationSchema = Yup.object({
    email: Yup.string().email().required("Email is a required field"),
    first_name: Yup.string().max(30).required("First names is a required field"),
    last_name: Yup.string().max(30).required("First names is a required field"),
    company: Yup.string().required("Company is a required field"),
    roles: Yup.array().of(Yup.string()).required("Role is a required field")
});

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void,
    companies: ICompany[];
    roles: IRole[];
    showRolesField?: boolean; // this field is used in "Your Team" page, to hide the Roles Dropdown
    showCompanyField?: boolean; // this field is used in "Your Team" page, to hide the Company Dropdown
} & { [key: string]: any; };

export default function InviteUser(props: Props) {
    const userService = useUserService();
    const { userData } = userService;
    const [ isSuperUser ] = useState(userService.meIsSuperUser());
    const { isVisible, setIsVisible, onSuccess, companies, roles } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const options: Option[] = roles.map((item) => ({ label: item.name, value: item.id }));
    const initialValues = {
        ...inviteBlank,
        company: userData.company,
        roles: [ roles.find(role => role.name === Role.Developer)?.id || "" ]
    };

    const { showCompanyField = true, showRolesField = true } = props;

    const onSubmit = (values: IUserInvite, { setSubmitting, setErrors }: FormikHelpers<IUserInvite>) => {
        setIsLoading(true);
        userService.invite(values).then((response) => {
            setIsLoading(false);
            onSuccess?.();
            setIsVisible(false);
        }).catch((error) => {
            setErrors(error);
            setIsLoading(false);
        }).finally(() => setSubmitting(false));
    };

    const onCancel = () => {
        setIsVisible(false);
    };

    if (!isVisible)
        return null;

    return (
        <Modal size={"md"} isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <Formik<IUserInvite>
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<IUserInvite>) => (
                    <FormControl as="form" onSubmit={formik.handleSubmit as any}>
                        <ModalContent bg="cardBG" color="text">
                            <ModalHeader>Invite New User</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <TextFieldFormik
                                    label="Email"
                                    type="email"
                                    name="email"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Email"
                                    required={true}
                                />
                                <TextFieldFormik
                                    label="First Name"
                                    type="text"
                                    name="first_name"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="First Name"
                                    maxLength={30}
                                    required={true}
                                />
                                <TextFieldFormik
                                    label="Last Name"
                                    type="text"
                                    name="last_name"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Last Name"
                                    maxLength={30}
                                    required={true}
                                />
                                {isSuperUser && showCompanyField && <DropDownFormik
                                    label="Company"
                                    name="company"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    required
                                    placeholder="Select a Company"
                                >
                                    {companies.map((item: ICompany, i: number) =>
                                        <option key={i} value={item.id}>{item.name}</option>
                                    )}
                                </DropDownFormik>}
                                {isSuperUser && showRolesField && <MultiSelect
                                    labelProps={{
                                        ml: "4px", mr: "12px", mb: "8px",
                                        fontSize: "sm", fontWeight: "bold"
                                    }}
                                    selectedListProps={{
                                        options, // hack to show labels instead of values
                                        bg: "fieldBG",
                                    }}
                                    options={options}
                                    value={formik.values.roles}
                                    label="Roles"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => formik.setFieldValue("roles", e || [] as any, true)}
                                ></MultiSelect>}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    isLoading={isLoading}
                                    variant="secondary"
                                    onClick={onCancel}
                                >Cancel</Button>
                                <Button
                                    isLoading={isLoading}
                                    variant="primary"
                                    ml={3}
                                    type="submit"
                                    isDisabled={!(formik.isValid && formik.dirty) || (formik.values.roles?.length === 0)}
                                >Send</Button>
                            </ModalFooter>
                        </ModalContent>
                    </FormControl>
                )}
            </Formik>
        </Modal>
    );
}
