import React, { useEffect, useState } from "react";

import { Box, Button, Collapse, Flex, Link, Text } from "@chakra-ui/react";

import { GridActionsRowButton, GridColumn, IFilterSettings, PagedGrid, Row, SelectionMode, SortOrder } from "components/paged-grid";
import { IConsultation, IPatient, IPermAction, useConsultationService } from "services";
import DefaultSkeleton from "./DefaultSkeleton";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CustomMoment from "components/utils/CustomMoment";

type Props = {
    activePatient: IPatient;
}

const Consultations = (props: Props) => {
    const consultationService = useConsultationService();

    const { activePatient } = props;

    const [ key, setKey ] = useState(0);
    const [ isLoaded, setIsLoaded ] = useState(false);

    useEffect(() => {
    }, []);

    const columns: GridColumn[] = [
        /*{
            field: "status",
            header: "Status",
            getComponent: (row: Row<IConsultation>) => {
                return (
                    <Box my={3}>
                        <Text as="span" fontSize={14}>
                            { row.data.status }
                        </Text>
                    </Box>
                );
            },
        },*/
        {
            field: "consultation_submitted",
            header: "Date submitted",
            sortable: true,
            sortDir: SortOrder.Desc,
            getComponent: (row: Row<IConsultation>) => {
                return <>
                    {row.data.consultation_submitted
                        ? <CustomMoment date={row.data.consultation_submitted} dateFormat="dd/MM/yyyy hh:mm aaa" utc={true} />
                        : <Text>-</Text>}
                </>;
            },
        }
    ];

    const rowActions: GridActionsRowButton<IConsultation>[] = [
        {
            text: "View PDF",
            type: IPermAction.View,
            handler: (row: Row<IConsultation>) => {},
            getComponent(row: Row<IConsultation>) {
                const pdfSource = `data:application/pdf;base64,${row.data.pdf_ref}`;
                const base64Rejex = /^(?:[A-Z0-9+/]{4})*(?:[A-Z0-9+/]{2}==|[A-Z0-9+/]{3}=|[A-Z0-9+/]{4})$/i;
                const isBase64Valid = base64Rejex.test(row.data.pdf_ref);

                const link = isBase64Valid
                    ? <Link
                        as="a"
                        href={pdfSource || "#"}
                        textDecoration="underline"
                        download={`Consultation_PDF_${activePatient.first_name || ""}_${activePatient.surname || ""}`}
                    >
                        <Flex align={"center"} gap={1}>
                            <AiOutlineEye/> <Text>View PDF</Text>
                        </Flex>
                    </Link>
                    : <Text>-</Text>;

                return link;
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            getValue: () => activePatient.id,
            getComponent: () => <></>,
        },

    ];

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IConsultation>
                        reloadKey={key}
                        columns={columns}
                        dataService={consultationService}
                        filters={filters}
                        selectionMode={SelectionMode.None}
                        rowActions={rowActions}
                        isLoaded={ gridLoaded }
                    />
                </Collapse>
            </Box>
        </>
    );
};

export default Consultations;
