import React, { useEffect, useState, useCallback, Fragment } from "react";

import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";

import {
    ICompany,
    IContract,
    ITemplateRecepient,
    IContractSigner,
    ITemplate,
    useContractService,
    IUser,
    useUserService,
    IIntegrationProduct
} from "services";
import { DropDownFormik } from "components/fields";

type Props = {
    id: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void,
    integrationProducts: IIntegrationProduct[],
    users: IUser[],
    templates: ITemplate[],
    [key: string]: any;
};

const contractBlank: Partial<IContract> = {
    integration_product: "",
    template: "",
    signers: [
        {
            user: "",
            role: ""
        },
        {
            user: "",
            role: ""
        },
    ] as IContractSigner[],
};

const formValidationSchema = Yup.object({
    integration_product: Yup.string().required("Company is a required field"),
});

export default function CreateEditModal(props: Props) {
    const { userData } = useUserService();
    const contractService = useContractService();
    const { id, isVisible, setIsVisible, integrationProducts } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ contract, setContract ] = useState<Partial<IContract>>(cloneDeep(contractBlank));
    const [ templateRoles, setTemplateRoles ] = useState([] as ITemplateRecepient[]);
    const [ filteredUsers, setFilteredUsers ] = useState(props.users);

    const onCancel = () => {
        setIsVisible(false);
    };

    const onSubmit = (values: IContract, { setSubmitting, setErrors }: FormikHelpers<IContract>) => {
        if (id) {
            setIsLoading(true);
            contractService.update(id, {
                // Omit 'template' when updating, since we can't update it after creation
                id: values.id,
                integration_product: values.integration_product,
                signers: values.signers
            }).then(() => {
                setIsLoading(false);
                props.onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => setSubmitting(false));
        } else {
            setIsLoading(true);
            contractService.create(values).then((response) => {
                setContract(cloneDeep(contractBlank));
                setIsLoading(false);
                props.onSuccess?.();
                setIsVisible(false);
            }).catch((error) => {
                setErrors(error);
                setIsLoading(false);
            }).finally(() => setSubmitting(false));
        }
    };

    const filterUsers = (company_id: string) => {
        const filtered = props.users.filter((u) => u.company === company_id) || [];

        setFilteredUsers(filtered);
    };

    const filterUsersByIP = (integration_product_id: string) => {
        const company_id = integrationProducts.find((e) => e.id === integration_product_id)?.company || "";
        filterUsers(company_id);
    };

    const filterRoles = (template_id: string): ITemplateRecepient[] => {
        const roles = props.templates.find((t) => t.id === template_id)?.recipients || [];

        setTemplateRoles(roles);

        return roles;
    };

    useEffect(() => {
        setContract(cloneDeep(contractBlank));

        if (id) {
            setIsLoading(true);
            contractService.get(id).then((response) => {
                setContract(response);
                setIsLoading(false);
                filterUsersByIP(response.integration_product);
                filterRoles(response.template);
            }).catch(() => {
                setIsLoading(false);
                onCancel();
            });
        }
    }, [ id ]);

    useEffect(() => {
        if (isVisible && !id) {
            filterUsers(userData.company);
        }
    }, [ isVisible ]);

    if (!isVisible)
        return null;

    return (
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <Formik<IContract>
                enableReinitialize={true}
                initialValues={contract as IContract}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<IContract>) => (
                    <FormControl as="form" onSubmit={formik.handleSubmit as any}>
                        <ModalContent bg="cardBG" color="text" minW="460px">
                            <ModalHeader>{!id ? "Create Contract" : "Edit Contract"}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <DropDownFormik
                                    label="Company"
                                    id="iproduct"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    required
                                    placeholder="Select a Company"
                                    name="integration_product"
                                    value={formik.values.integration_product}
                                    onChange={(e: any) => {
                                        const newVal = e.target.value as string;
                                        filterUsersByIP(newVal);
                                        formik.setFieldValue("integration_product", newVal, true);
                                    }}
                                >
                                    {integrationProducts.map((item: IIntegrationProduct, i: number) =>
                                        <option key={i} value={item.id || ""}>{item?.company_name}</option>
                                    )}
                                </DropDownFormik>
                                <DropDownFormik
                                    label="Template"
                                    id="template"
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    required
                                    isDisabled={formik.values.id}
                                    placeholder="Select a Template"
                                    name="template"
                                    value={formik.values.template}
                                    onChange={(e: any) => {
                                        const newVal = e.target.value as string;
                                        const roles = filterRoles(newVal);
                                        formik.setFieldValue("template", newVal, true);
                                        formik.setFieldValue("signers", roles.map(r => ({ user: "", role: "" })));
                                    }}
                                    onBlur={() => {}}
                                >
                                    {props.templates.map((item: ITemplate, i: number) =>
                                        <option key={i} value={item.id}>{item.name}</option>
                                    )}
                                </DropDownFormik>
                                {templateRoles.map((_, i) =>
                                    <Fragment key={i}>
                                        <DropDownFormik
                                            label={`Signatory ${i+1}`}
                                            id={"signer" + i}
                                            bg="fieldBG"
                                            color="text"
                                            borderRadius="card"
                                            required
                                            isDisabled={!formik.values.integration_product}
                                            placeholder={`Select Signatory ${i+1}`}
                                            name={`signers[${i}].user`}
                                            value={formik.values.signers[i].user}
                                            onChange={(e: any) => {
                                                const newVal = e.target.value as string;
                                                formik.setFieldValue(`signers[${i}].user`, newVal, true);
                                            }}
                                            onBlur={() => {}}
                                        >
                                            {filteredUsers.map((item: IUser, i: number) =>
                                                <option key={i} value={item.id}>{item.first_name} {item.last_name}</option>
                                            )}
                                        </DropDownFormik>
                                        <DropDownFormik
                                            label={`Signatory ${i+1} Role`}
                                            id={"signer_role" + i}
                                            bg="fieldBG"
                                            color="text"
                                            borderRadius="card"
                                            required
                                            isDisabled={!formik.values.template}
                                            placeholder={`Select Signatory Role ${i+1}`}
                                            name={`signers[${i}].role`}
                                            value={formik.values.signers[i].role}
                                            onChange={(e: any) => {
                                                const newVal = e.target.value as string;
                                                formik.setFieldValue(`signers[${i}].role`, newVal, true);
                                            }}
                                            onBlur={() => {}}
                                        >
                                            { templateRoles.map((item: ITemplateRecepient, i: number) =>
                                                <option key={i} value={item.role}>{item.role}</option>
                                            )}
                                        </DropDownFormik>
                                    </Fragment>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    isLoading={isLoading}
                                    variant="secondary"
                                    onClick={onCancel}
                                >Cancel</Button>
                                <Button
                                    isLoading={isLoading}
                                    variant="primary"
                                    ml={3}
                                    type="submit"
                                >{!id ? "Create" : "Save"}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </FormControl>
                )}
            </Formik>
        </Modal>
    );
}
