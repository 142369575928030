import React, { useState } from "react";

import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    PagedGrid,
    Row,
    SelectionMode,
    IFilterSettings,
    SortOrder,
} from "components/paged-grid";
import { IAppointment, IPatient, IGP, IPermAction, useAppointmentService } from "services";
import { useAlert } from "components/alert-modal";
import CancelAppointmentModal from "./CancelAppointmentModal";
import AddAppointmentModal from "./AddAppointmentModal";
import DefaultSkeleton from "../DefaultSkeleton";
import CustomMoment from "components/utils/CustomMoment";

type Props = {
    activePatient?: IPatient;
    activeGP?: IGP;
};

const Appointments = (props: Props) => {
    const appointmentService = useAppointmentService();

    const { activePatient, activeGP } = props;

    const alertShow = useAlert();

    const [ key, setKey ] = useState(0);
    const [ appointment, setAppointment ] = useState<IAppointment>({} as any);
    const [ isVisibleCancelModal, setIsVisibleCancelModal ] = useState(false);
    const [ isVisibleAddModal, setIsVisibleAddModal ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const reloadGrid = () => setKey((value) => value + 1);

    const columns: GridColumn[] = [
        {
            field: "start_time",
            header: "Start Time",
            sortable: true,
            sortDir: SortOrder.Desc,
            getComponent: (row: Row<IAppointment>) => {
                return (
                    <Box my={3}>
                        <CustomMoment date={row.data.start_time} dateFormat="dd/MM/yyyy hh:mm aaa" utc={true} />
                    </Box>
                );
            },
        },
        {
            field: "end_time",
            header: "End Time",
            getComponent: (row: Row<IAppointment>) => {
                return <CustomMoment date={row.data.end_time} dateFormat="dd/MM/yyyy hh:mm aaa" utc={true}/>;
            },
        },
        {
            field: "patient",
            header: "Patient",
            visible: () => !activePatient,
            getComponent: (row: Row<IAppointment>) => {
                return <Text>{row.data.patient_full_name}</Text>;
            },
        },
        {
            field: "booking_reason",
            header: "Booking Reason",
            //props: { w: "100%" },
            getComponent: (row: Row<IAppointment>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text>{row.data.booking_reason}</Text>
                        </Box>
                    </Flex>
                );
            },
        },
    ];

    const rowActions: GridActionsRowButton<IAppointment>[] = [
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IAppointment>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete the appointment?",
                    onSuccess: () => {
                        setAppointment(row.data);
                        setIsVisibleCancelModal(true);
                    }
                });
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Appointment",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setIsVisibleAddModal(true);
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "patient",
            visible: () => false,
            enabled: () => !!activePatient,
            getValue: () => activePatient?.id,
            getComponent: () => <></>,
        },
        {
            field: "patient__gp",
            visible: () => false,
            enabled: () => !!activeGP,
            getValue: () => activeGP?.id,
            getComponent: () => <></>,
        },
    ];

    const closeCancelAppointmentHandler = () => {
        setIsVisibleCancelModal(false);
    };

    const closeAddAppointmentHandler = () => {
        setIsVisibleAddModal(false);
    };

    const saveCancelAppointmentHandler = async (cancellationReason: string) => {
        try {
            await appointmentService.delete(appointment.id);
            reloadGrid();
            setIsVisibleCancelModal(false);
        } catch (error) {
            console.log(error);
        }
    };

    const saveAddAppointmentHandler = () => {
        setIsVisibleAddModal(false);
        reloadGrid();
    };

    const gridLoaded = () => {
        setIsLoaded(true);
    };

    return (
        <>
            { !isLoaded && <DefaultSkeleton /> }

            <Box display={ isLoaded ? "block" : "none"}>
                <Collapse in={isLoaded} animateOpacity>
                    <PagedGrid<IAppointment>
                        reloadKey={key}
                        columns={columns}
                        dataService={appointmentService}
                        selectionMode={SelectionMode.None}
                        rowActions={rowActions}
                        globalActions={globalActions}
                        filters={filters}
                        isLoaded={gridLoaded}
                    />
                </Collapse>
            </Box>

            {isVisibleCancelModal && (
                <CancelAppointmentModal
                    isVisible={isVisibleCancelModal}
                    onClose={closeCancelAppointmentHandler}
                    onSave={saveCancelAppointmentHandler}
                />
            )}

            {isVisibleAddModal && (
                <AddAppointmentModal
                    isVisible={isVisibleAddModal}
                    onClose={closeAddAppointmentHandler}
                    onSave={saveAddAppointmentHandler}
                    gpId={activeGP?.id}
                    patientId={activePatient?.id}
                />
            )}
        </>
    );
};

export default Appointments;
