import React, { useEffect, useState } from "react";

import { Box, Text, Button, Card } from "@chakra-ui/react";
import { useAlert } from "components/alert-modal";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    IFilterSettings,
    PagedGrid,
    Row,
    SelectionMode
} from "components/paged-grid";
import {
    ICompany,
    IPermAction,
    IRole,
    IUser,
    Role,
    useCompanyService,
    UserEnum,
    useUserService
} from "services";
import InviteUser from "views/admin/users/components/InviteUser";
import BoxCard from "components/card/Card";
import { FiMail } from "react-icons/fi";

const DeveloperTeam = () => {
    const companyService = useCompanyService();
    const userService = useUserService();
    const { userData } = userService;
    const [ reloadKey, setReloadKey ] = useState(0);
    const [ companies, setCompanies ] = useState<ICompany[]>([] as any);
    const [ roles, setRoles ] = useState<IRole[]>([]);
    const [ isInviteVisible, setIsInviteVisible ] = useState(false);
    const alertShow = useAlert();

    useEffect(() => {
        companyService.getAll().then((response) => {
            setCompanies(response);
        });
        userService.getAllRoles().then((response) => {
            setRoles(response.filter(roles => roles.name !== Role.ConvenetUser));
        });
    }, [ ]);

    const reloadGrid = () => {
        // Force PagedGrid to reload the data
        setReloadKey((value) => value + 1);
    };

    const onInviteNewUserHandler = () => {
        reloadGrid();
    };

    const columns: GridColumn[] = [
        {
            field: "first_name",
            header: "First Name",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">
                    {row.data.first_name || "" }
                </Text>;
            },
        },
        {
            field: "last_name",
            header: "Last Name",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">
                    {row.data.last_name || "" }
                </Text>;
            },
        },
        {
            field: "email",
            header: "E-mail",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">{row.data.email}</Text>;
            },
        },
        {
            field: "roles",
            header: "Roles",
            visible: () => userData.is_superuser,
            getComponent: (row: Row<IUser>) => {
                return <>
                    {row.data.roles.map((id, i) => <Text key={i}>{roles.find((role) => role.id === id)?.name}</Text>)}
                </>;
            },
        },
    ];

    const rowActions: GridActionsRowButton<IUser>[] = [
        {
            text: "Resend Invite",
            icon: () => <FiMail />,
            variant: "primary",
            type: IPermAction.Add,
            visible: (row: Row<IUser>) => row.data.status === UserEnum.Invited,
            handler: (row: Row<IUser>) => {
                userService.resendInvite(row.data.id);
            },
        },
        {
            text: "Cancel Invite",
            type: IPermAction.CancelInvite,
            visible: (row: Row<IUser>) => row.data.status === UserEnum.Invited,
            handler: (row: Row<IUser>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to cancel an invitation? This will permanently delete the user.",
                    onSuccess: () => {
                        userService.inviteCancel(row.data.id).then(() => setReloadKey((value) => value + 1));
                    }
                });
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Invite User",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setIsInviteVisible(true);
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "company", // user.company.name
            match: () => "exact",
            getValue: () => userData.company, // "" === no filter
            getComponent: () => <></>
        }
    ];

    return <Box mt={6}>
        <BoxCard variant="table">
            <PagedGrid<IUser>
                reloadKey={reloadKey}
                dataService={userService}
                columns={columns}
                rowActions={rowActions}
                globalActions={globalActions}
                selectionMode={SelectionMode.None}
                filters={filters}
            />
        </BoxCard>

        { isInviteVisible &&
            <InviteUser
                isVisible={isInviteVisible}
                setIsVisible={setIsInviteVisible}
                onSuccess={onInviteNewUserHandler}
                companies={companies}
                roles={roles}
                showCompanyField={false}
                showRolesField={false}
            />
        }
    </Box>;
};

export default DeveloperTeam;
