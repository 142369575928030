import { usePagedCrudService } from "./crud.service";
import { IUser } from "./user.interface";

export interface IAuthLog {
    id: string;
    event: AuthLogEvent;
    extra_detail: object;
    remote_address: string;
    timestamp: Date;
    source_user?: IUser;
    user?: IUser;
    user_agent: string;
    user_email: string;
}

export enum AuthLogEvent {
    Login = 1,
    Logout = 2,
    LoginFailed = 3,
    LoginMfaFailed = 4,
    AnonymousInviteSent = 5,
    InviteSent = 6,
    RegistrationSuccess = 7,
    PasswordResetInitiated = 8,
    PasswordResetSuccess = 9
}

const authLogEvents = {
    [AuthLogEvent.Login]: "Login",
    [AuthLogEvent.Logout]: "Logout",
    [AuthLogEvent.LoginFailed]: "Login failed",
    [AuthLogEvent.LoginMfaFailed]: "Login second step failed",
    [AuthLogEvent.AnonymousInviteSent]: "Anonymous invite initiated",
    [AuthLogEvent.InviteSent]: "Invite initiated",
    [AuthLogEvent.RegistrationSuccess]: "Registration succeeded",
    [AuthLogEvent.PasswordResetInitiated]: "Password reset initiated",
    [AuthLogEvent.PasswordResetSuccess]: "Password reset succeeded"
};

const authLogService = (function() {
    const authLogService = usePagedCrudService<IAuthLog>({
        url: "/api/auth/log/",
        entityTitle: "Auth Log",
        model: "authlog"
    });

    return {
        ...authLogService,
        getAuthEventMessage: (event: AuthLogEvent) => authLogEvents[event]
    };
})();

export const useAuthLogService = () => authLogService;
