import { usePagedCrudService } from "services";
import { IPrescription } from "./prescriptions.interface";

const prescriptionService = (function() {
    const prescriptionService = usePagedCrudService<IPrescription>({
        url: "/api/mock/prescription/",
        entityTitle: "Prescription",
        model: "prescription"
    });

    return prescriptionService;
})();

export const usePrescriptionService = () => prescriptionService;
