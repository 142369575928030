import React from "react";
import {
    Flex,
    Link,
    List,
    ListItem,
    Text,
} from "@chakra-ui/react";

export default function Footer() {
    const version = process.env.REACT_APP_VERSION || null;
    const textColor = "lightText";
    const iconCheckColor = "ICONS.17";

    return (
        <Flex
            as="footer"
            zIndex={3000}
            position="fixed"
            bottom="0"
            flexDirection={{
                base: "column",
                xl: "row",
            }}
            alignItems="center"
            fontSize="10px"
            justifyContent="space-between"
            px="18px"
            w="100%"
            minH="32px"
            bg="CG.2"
        >
            <Text
                color={textColor}
                textAlign={{
                    base: "center",
                    xl: "start",
                }}
                mb={{ base: "20px", xl: "0px" }}
            >
                <Text as="span" fontWeight="500" ms="4px">
                    { (version ? `Version: ${version} ` : "") + `Copyright © ${new Date().getFullYear()} Convenet Ltd. All rights reserved.`}
                </Text>
            </Text>
            <List display="flex">
                <ListItem>
                    <Link
                        href="https://www.convenet.io/privacy-policy/"
                        isExternal
                    >
                        Privacy Policy
                    </Link>
                </ListItem>
            </List>
        </Flex>
    );
}
