import React, { ReactNode } from "react";
import {
    Flex,
    Heading,
    Text,
} from "@chakra-ui/react";

type Props = {
    children?: ReactNode,
}
export default function AuthPage({ children }: Props) {
    return (
        <Flex
            w="100%"
            h="100%"
            mx="auto"
            me="auto"
            alignItems="center"
            flexDirection="column"
            zIndex="10"
            marginTop={{ base: "120px", md: "70px" }}
        >
            <Heading
                color="white"
                fontSize="headingLarge.lg"
                mb="4"
                lineHeight="1.2"
                fontWeight="700"
                maxW="300px"
                textAlign="center"
            >
                The fastest NHS integration, period.
            </Heading>
            <Text
                color="white"
                fontSize="text.xs"
                mb="6"
                lineHeight="1.6"
                fontWeight="600"
                maxW="300px"
                textAlign="center"
            >
                Get access to NHS integrations 26x faster using the Convenet
                API with 100% GP coverage.
            </Text>

            {children}
        </Flex>
    );
}
