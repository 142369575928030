export const getCurrencySymbol = (currency: string) => {
    const code = currency?.toUpperCase();
    if (code) {
        switch (code) {
        case "GBP": return "£";
        case "USD": return "$";
        case "EUR": return "€";
        default: return "£";
        }
    }
};

export const getTextWithCurrency = (currency: string, value: number): string => {
    const currencySymbol = getCurrencySymbol(currency || "GBP");

    return currencySymbol + ((value ?? 0))?.toLocaleString("en-US",
        { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getSterlingPoundSymbol = () => {
    return "£";
};