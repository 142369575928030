import React, { useState } from "react";

import { Box, Button, Flex, Spacer, Stack, Stat, StatHelpText, StatLabel, StatNumber, Text } from "@chakra-ui/react";

import { getTextWithCurrency } from "components/utils/currency-helper";
import { BespokeProductPrice, useBespokePriceService } from "services/billing/bespoke-price.service";
import { useAppStore } from "store";

type Props = {
    bespokePrice: BespokeProductPrice;
    priceError: boolean;
    showModal: () => void;
}

const ShowChangeBespokePrice = (props: Props) => {

    const { bespokePrice = {} as BespokeProductPrice, showModal, priceError } = props;

    return <Stack direction={{ base: "column", lg: "row" }}>
        <Spacer />
        <Flex w={{ sm: "100%", md: "35%", lg: "35%" }}
            direction={{ base: "column", lg: "row" }}
            align="center"
            justify={{ sm: "center", md: "space-evenly" }}  >
            <Box>
                <Stat size={"sm"} >
                    <StatLabel>Bespoke Price</StatLabel>
                    <StatNumber>
                        <Text>
                            {getTextWithCurrency("gbp", (bespokePrice.cost || 0) / 100)}
                        </Text>
                    </StatNumber>
                    <StatHelpText>
                        {priceError &&
                    <Text color={"red"}>You must add a price.</Text>
                        }
                    </StatHelpText>
                </Stat>

            </Box>
            <Box>
                <Button variant="outline" onClick={() => showModal()} >
                    {!bespokePrice.cost ? "Add" : "View"} Price
                </Button>

            </Box>
        </Flex>
    </Stack>;
};

export default ShowChangeBespokePrice;