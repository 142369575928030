import React, { useState, useContext } from "react";

import {
    Portal,
    Box,
    Button,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
} from "@chakra-ui/react";

import Cookies from "js-cookie";

import { AppContext } from "contexts/AppContext";

export default function CookieBanner() {
    const { isLoggedIn } = useContext(AppContext);
    const [ isVisible, setIsVisible ] = useState(!Cookies.get("CookieBannerShown"));

    const hideBanner = (saveCookie: boolean) => {
        setIsVisible(false);
        saveCookie && Cookies.set("CookieBannerShown", true);
    };

    return (
        !isLoggedIn ? null :
            <Portal>
                {isVisible && <Alert
                    position="fixed"
                    bottom={0}
                    zIndex={10000}
                    flexDirection="column"
                    alignItems="flex-start"
                    bgColor="white"
                >
                    <Box>
                        <AlertTitle>Cookies</AlertTitle>
                        <AlertDescription>
                            <Text>
                                We use cookies to ensure that we give you the best experience on our website.
                                We can also use these cookies to record how often you visit the site and the areas
                                you visit within it. Although they can identify a user’s computer, cookies themselves
                                do not personally identify users. You can set your browser to refuse cookies if you
                                wish to.
                            </Text>
                            <Text>
                                If you continue without changing your settings, we’ll assume that you are happy to
                                receive all cookies on this website.
                            </Text>
                        </AlertDescription>
                    </Box>

                    <CloseButton
                        position="absolute"
                        right={0}
                        top={0}
                        onClick={() => hideBanner(false)}
                    />
                    <Button
                        alignSelf="flex-end"
                        onClick={() => hideBanner(true)}
                    >
                        Got it
                    </Button>
                </Alert>}
            </Portal>
    );
}
