import { IApiClient } from "./api-client.interface";

export enum InProgressCompleteChoice {
    in_progress = 0,
    complete = 1
}

export enum YesNoInProgressChoice {
    no = 0,
    yes = 1,
    in_progress = 2
}

export interface ICompany {
    id: string[32]; // UUID
    name: string;
    api_clients?: IApiClient[];
    billing_contact?: string;
    ico_reg_number?: string[20];
    data_protection_status?: InProgressCompleteChoice;
    privacy_policy?: string[100];
    dpia?: string[100];
    dcb0129_status?: InProgressCompleteChoice;
    clinical_safety_officer?: string[100];
    cso_email?: string[100];
    ods_code_status?: YesNoInProgressChoice;
    documents?: any[];
    version: number;
}
