import { useBaseService, usePagedCrudService } from "../crud.service";
import { IStripeInvoice } from "./invoice.interface";

export function useInvoiceService() {
    const service = usePagedCrudService<IStripeInvoice>({
        url: "/api/billing/historic/invoice/",
        entityTitle: "Invoices",
        model: "djstripe_invoice"
    });

    return service;
}