import React from "react";

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    HStack,
    Text
} from "@chakra-ui/react";

import { IMenuRoute } from "routes";
import { redirect, useNavigate } from "react-router-dom";

const AccordionLinks: React.FC<{
    title: string;
    items: any;
    links: any;
    route: IMenuRoute;
    index: number;
}> = (props) => {
    const { title } = props;

    return (
        <React.Fragment>
            <AccordionItem>
                <h2>
                    <AccordionButton _hover={{ bg: "none" }} ml="2px">
                        <Box flex="1" textAlign="left">
                            <HStack>
                                <Text me="0px">{title}</Text>
                            </HStack>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel ml="-15px" pb={4}>
                    {props.links}
                </AccordionPanel>
            </AccordionItem>
        </React.Fragment>
    );
};

export default AccordionLinks;
