import { Flex, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
    labelText: string;
    text: string;
    minW?: string;
};

export function LabelText(props: Props) {
    return (
        <Flex minW={props.minW ?? "17.5rem"}>
            <Text fontWeight={"bold"} minW="8.75rem">{props.labelText}</Text>

            <Text color="gray.500">
                {props.text}
            </Text>
        </Flex>
    );
}
