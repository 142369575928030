import React, { useState, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Stack,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
import AuthPage from "./components/AuthPage";
import { AppContext } from "contexts/AppContext";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { PasswordFieldFormik } from "components/fields";
import { useAuthService } from "services";
import zxcvbn, { ZXCVBNResult } from "zxcvbn";

type TPasswordConfirm = {
    new_password1: string,
    new_password2: string,
    uid: string,
    token: string,
}

const initialData: TPasswordConfirm = {
    new_password1: "",
    new_password2: "",
    uid: "",
    token: "",
};

const formValidationSchema = Yup.object({
    new_password1: Yup.string()
        /*.test(
            "weakness-test",
            "Password is too weak",
            (value, testContext) => zxcvbn(value || "").score >= 3
        )*/
        .required("This field is required."),
    new_password2: Yup.string()
        .test(
            "equality-test",
            "Must coincide with the password above",
            (value, testContext) => value === testContext.parent.new_password1
        )
        .required("This field is required.")
});


function PasswordConfirm() {
    const params = useParams();
    const navigate = useNavigate();
    const authService = useAuthService();
    const { setIsLoggedIn } = useContext(AppContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ result, setResult ] = useState<ZXCVBNResult | null>(null);

    const onSubmit = async (values: TPasswordConfirm, { setSubmitting, setErrors }: FormikHelpers<TPasswordConfirm>) => {
        const _result = zxcvbn(values.new_password1);
        setResult(_result);

        if (_result.score < 3) {
            setErrors({ new_password1: "Password is too weak" });
            return;
        }

        const reqBody: TPasswordConfirm = { ...values, token: params.token || "", uid: params.uid || "" } ;
        setIsLoading(true);

        authService.passwordConfirm(reqBody).then(() => {
            setIsLoading(false);
            setIsLoggedIn(false);
            navigate("/auth/login");
        }).catch((errors) => {
            setErrors({ ...errors });
            setIsLoading(false);
        }).finally(() => setSubmitting(false));
    };

    return (
        <AuthPage>
            <Flex
                w={{ base: "90%", sm: "376px" }}
                mx="auto"
                justify="center"
                alignItems="center"
                px="43px"
                py="35px"
                flexDirection="column"
                backgroundColor="authCardBG"
                borderRadius={"card"}
                boxShadow={"md"}
                textAlign="center"
            >
                <Heading
                    color="text"
                    fontSize="headingSmall.lg"
                    mb="9"
                    lineHeight="1.5"
                    fontWeight="600"
                >
                    New Password Confirmation
                </Heading>
                <Formik<TPasswordConfirm>
                    enableReinitialize={true}
                    initialValues={initialData}
                    validationSchema={ formValidationSchema }
                    onSubmit={onSubmit}
                >
                    {(formik: FormikProps<TPasswordConfirm>) => (
                        <FormControl
                            as="form"
                            onSubmit={formik.handleSubmit as any}
                        >
                            {result && formik.errors["new_password1"] == "Password is too weak" && <Alert status="error" mb={4}>
                                <AlertIcon />
                                <Stack>
                                    <AlertTitle>{result?.feedback.warning}</AlertTitle>
                                    {result.feedback.suggestions.map((s, i) => <Text key={i}>{s}</Text>)}
                                </Stack>
                            </Alert>}
                            <PasswordFieldFormik
                                type="password"
                                name="new_password1"
                                auth={true}
                                bg="fieldBG"
                                color="text"
                                borderRadius="card"
                                placeholder="New Password"
                                required={true}
                            />
                            <PasswordFieldFormik
                                type="password"
                                name="new_password2"
                                auth={true}
                                bg="fieldBG"
                                color="text"
                                borderRadius="card"
                                placeholder="Confirm New Password"
                                required={true}
                            />
                            <Button
                                variant={"primaryFullWidth"}
                                mb="3"
                                isLoading={isLoading}
                                type="submit"
                                isDisabled={!formik.isValid}
                            >
                                Submit
                            </Button>
                        </FormControl>
                    )}
                </Formik>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    maxW="100%"
                >
                    <Text
                        color="textDetails"
                        fontWeight="400"
                        lineHeight={1.5}
                        fontSize="text.xs"
                    >
                        <NavLink to="/auth/login">
                            <Text
                                color="textBrand"
                                as="span"
                                ms="5px"
                                fontWeight="500"
                            >
                                    Ready to sign in?
                            </Text>
                        </NavLink>
                    </Text>
                </Flex>
            </Flex>
        </AuthPage>
    );
}

export default PasswordConfirm;
