import { isBoolean, isNull, isUndefined } from "lodash";
import { ICompany, IFileUploadResponse, IIntegrationProduct, InProgressCompleteChoice, YesNoChoice, YesNoInProgressChoice } from "services";

const checkIsNotNullOrUndefined = (value: any) => !isNull(value) && !isUndefined(value);

const checkTruthyAndYesNoChoiceYes = (value: YesNoChoice | YesNoInProgressChoice | undefined, choice: YesNoInProgressChoice = YesNoInProgressChoice.yes): boolean =>
    checkIsNotNullOrUndefined(value) && value === choice;

const checkTruthy = (value: any): boolean => checkIsNotNullOrUndefined(value) && value;

export const getInformationGovernanceTickValue = (_uploadedfiles: Partial<IFileUploadResponse>[], _company: ICompany, _integrationProduct: IIntegrationProduct) => {
    const _informationGovernanceTick = checkIsNotNullOrUndefined(_company.data_protection_status)
        && (_uploadedfiles.length === 5) // there are 5 differents documents that have to be uploaded to this to be true
        && checkIsNotNullOrUndefined(_company.privacy_policy)
        && checkIsNotNullOrUndefined(_company.dpia)
        && checkIsNotNullOrUndefined(_company.ico_reg_number)
        && checkIsNotNullOrUndefined(_integrationProduct.medical_device)
        && (checkIsNotNullOrUndefined(_company.dcb0129_status) && (_company.dcb0129_status === InProgressCompleteChoice.complete))
        && checkIsNotNullOrUndefined(_company.clinical_safety_officer)
        && checkIsNotNullOrUndefined(_company.cso_email)
        && checkIsNotNullOrUndefined(_integrationProduct.iso27001);
    return _informationGovernanceTick;
};

export const getProductDetailsTickValue = (_integrationProduct: IIntegrationProduct): boolean => {
    return (checkIsNotNullOrUndefined(_integrationProduct.name) && _integrationProduct.name.length > 0)
        && checkIsNotNullOrUndefined(_integrationProduct.commissioned_by)
        && checkTruthyAndYesNoChoiceYes(_integrationProduct.direct_patient_care)
        && (!!_integrationProduct.uses_pds || !!_integrationProduct.uses_appointments || !!_integrationProduct.uses_medical_records ||
            !!_integrationProduct.uses_medications || !!_integrationProduct.uses_prescriptions || !!_integrationProduct.uses_proxy ||
            !!_integrationProduct.uses_consultation);
};

export const getCheckIsSuitableTickValue = (_integrationProduct: Partial<IIntegrationProduct>, _company: Partial<ICompany>): boolean => {
    return checkTruthyAndYesNoChoiceYes(_integrationProduct.direct_patient_care)
        && checkTruthy(_integrationProduct.free_to_use)
        && checkTruthyAndYesNoChoiceYes(_company.ods_code_status)
        && checkTruthy(_integrationProduct.nhs_sponsor);
};

export const checkisSuitableODSCode = (_integrationProduct: Partial<IIntegrationProduct>, _company: Partial<ICompany>): boolean => {
    return checkTruthyAndYesNoChoiceYes(_integrationProduct.direct_patient_care)
        && checkTruthy(_integrationProduct.free_to_use)
        && checkTruthyAndYesNoChoiceYes(_company.ods_code_status, YesNoInProgressChoice.in_progress)
        && checkTruthy(_integrationProduct.nhs_sponsor);
};

