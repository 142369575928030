import React, { useState, useContext, useEffect, useMemo } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Stack,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
import { TokenResponse } from "@react-oauth/google";

import AuthPage from "./components/AuthPage";
import { TRegDataConfirm } from "services/auth.type";

import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { PasswordFieldFormik } from "components/fields";
import { useAuthService } from "services";
import { AppContext } from "contexts/AppContext";
import zxcvbn, { ZXCVBNResult } from "zxcvbn";

import GoogleButton from "./components/GoogleButton";

const regDataInitial: TRegDataConfirm = {
    password1: "",
    password2: "",
    token: "",
    user: "",
};

const formValidationSchema = Yup.object({
    password1: Yup.string()
        /*.test(
            "weakness-test",
            "Password is too weak",
            (value, testContext) => zxcvbn(value || "").score >= 3
        )*/
        .required("This field is required."),
    password2: Yup.string()
        .test(
            "equality-test",
            "Must coincide with the password above",
            (value, testContext) => value === testContext.parent.password1
        )
        .required("This field is required."),
});

function CreateAccountConfirm() {
    const { setIsLoggedIn } = useContext(AppContext);
    const token = useParams<string>().token || "";
    const user = useParams<string>().user || "";
    const navigate = useNavigate();
    const authService = useAuthService();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ result, setResult ] = useState<ZXCVBNResult | null>(null);

    useEffect(() => {
        if (!token || !user) navigate("/");
    });

    const onGoogleLoginSuccess = (response: TokenResponse) => {
        setIsLoading(true);

        authService.acceptInviteGoogle({
            user,
            token,
            access_token: response.access_token
        }).then(() => {
            setIsLoggedIn(false);
            navigate("/auth/login");
        }).catch((error) => {
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const onSubmit = async (values: TRegDataConfirm, { setSubmitting, setErrors }: FormikHelpers<TRegDataConfirm>) => {
        const _result = zxcvbn(values.password1);
        setResult(_result);

        if (_result.score < 3) {
            setErrors({ password1: "Password is too weak" });
            return;
        }

        setIsLoading(true);

        await authService.registerv2Confirm({ ...values, token, user }).then(() => {
            setIsLoading(false);
            setIsLoggedIn(false);
            navigate("/auth/login");
        }).catch((errors) => {
            setErrors({ ...errors });
            setIsLoading(false);
        }).finally(() => setSubmitting(false));
    };

    return (
        <AuthPage>
            <Flex
                w={{ base: "100%", sm: "376px" }}
                mx="auto"
                justify="center"
                alignItems="center"
                px={{ base: "15px", md: "43px" }}
                py={{ base: "10px", md: "35px" }}
                flexDirection="column"
                backgroundColor="authCardBG"
                borderRadius={"card"}
                boxShadow={"md"}
                textAlign="center"
            >
                <Heading
                    color="text"
                    fontSize="headingSmall.lg"
                    mb="9"
                    lineHeight="1.5"
                    fontWeight="600"
                >
                    Create new account.
                </Heading>
                <Formik<TRegDataConfirm>
                    enableReinitialize={true}
                    initialValues={regDataInitial}
                    validationSchema={formValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik: FormikProps<TRegDataConfirm>) => (
                        <FormControl
                            as="form"
                            onSubmit={formik.handleSubmit as any}
                        >
                            {result && formik.errors["password1"] == "Password is too weak" && <Alert status="error" mb={4}>
                                <AlertIcon />
                                <Stack>
                                    <AlertTitle>{result?.feedback.warning}</AlertTitle>
                                    {result.feedback.suggestions.map((s, i) => <Text key={i}>{s}</Text>)}
                                </Stack>
                            </Alert>}
                            <PasswordFieldFormik
                                type="password"
                                name="password1"
                                auth={true}
                                bg="fieldBG"
                                color="text"
                                borderRadius="card"
                                placeholder="Password"
                                required={true}
                            />

                            <PasswordFieldFormik
                                type="password"
                                name="password2"
                                auth={true}
                                bg="fieldBG"
                                color="text"
                                borderRadius="card"
                                placeholder="Confirm Password"
                                required={true}
                            />

                            <Button
                                variant="primaryFullWidth"
                                mb="3"
                                isLoading={isLoading}
                                type="submit"
                                isDisabled={!formik.isValid}
                            >
                                Register
                            </Button>
                        </FormControl>

                    )}
                </Formik>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    maxW="100%"
                    mt="0px"
                >
                    <Text
                        color="textDetails"
                        fontWeight="400"
                        lineHeight={1.5}
                        fontSize="text.xs"
                    >
                        <NavLink to="/auth/login">
                            <Text
                                color="textBrand"
                                as="span"
                                ms="5px"
                                fontWeight="500"
                            >
                                Ready to sign in?
                            </Text>
                        </NavLink>
                    </Text>
                </Flex>

                <GoogleButton
                    mt="6"
                    isLoading={isLoading}
                    onSuccess={onGoogleLoginSuccess}
                />
            </Flex>
        </AuthPage>
    );
}

export default CreateAccountConfirm;
