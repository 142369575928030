import React, { memo } from "react";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useField } from "formik";

function PasswordFieldFormik(props: any) {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        label,
        required,
        placeholder,
        auth = false,
        errorMessage = null,
        mb,
        ...rest
    } = props;
    const id = props.id || props.name;
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const [ show, setShow ] = React.useState(false);

    const onChangeWrap = (e: any) => {
        helpers.setTouched(true);
        (props.onChange || field.onChange)(e);
    };

    const onBlurWrap = (e: any) => {
        if (auth) {
            helpers.setError(undefined);
        } else {
            (props.onBlur || field.onBlur)(e);
        }
    };

    return (
        <Flex direction={{ base:"column", md:"row" }} align={{ base: "start", md: "center" }} justify={{ base: "space-start", md: "space-between" }} flexWrap={"wrap"} mb={mb ? mb : "10px"}>
            {label && (
                <FormLabel
                    ms="4px"
                    fontSize="text.md"
                    fontWeight="normal"
                    color="text"
                    display="flex"
                    htmlFor={id}
                    _hover={{ cursor: "pointer" }}
                >
                    {label}
                    {required && <Text color={brandStars}>*</Text> }
                </FormLabel>
            )}
            <Flex direction="column" alignItems="start"  w={props.w || "100%"}>
                <InputGroup size="md">
                    <Input
                        {...field}
                        {...rest}
                        onChange={onChangeWrap}
                        onBlur={onBlurWrap}
                        id={id}
                        autoComplete="on"
                        required={null}
                        isRequired={false}
                        placeholder={placeholder}
                        size="lg"
                        type={show ? "text" : "password"}
                        borderColor={ meta.error ? "red.500" : null}
                    />
                    <InputRightElement display="flex" alignItems="center">
                        <Icon
                            color="secondaryText"
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => setShow(!show)}
                        />
                    </InputRightElement>
                </InputGroup>
                {meta.touched && meta.error ? (
                    <Text as="span" fontSize="text.xs" color={"red"} h="auto" ml={3}>
                        {meta.error}
                    </Text>
                ) : null}
            </Flex>
        </Flex>
    );
}

export default memo(PasswordFieldFormik);
