import React, { useState, useEffect } from "react";

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    Text,
    ModalBody,
    FormControl,
    Flex,
    Button,
    ModalFooter,
    VStack,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { format, addDays } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import {
    TextFieldFormik,
    DropDownFormik,
    TextAreaFormik,
} from "components/fields";

import {
    IAppointmentSlot,
    SlotTypeStatusChoice,
    useGPService,
    useAppointmentSlotService,
    IGP,
} from "services";

const formValidationSchema = Yup.object({
    start_time: Yup.string().required("Required"),
    duration: Yup.number()
        .min(1, "Must be minimum 1 minute")
        .required("Required"),
    // end_time: Yup.string().nullable(),
    repeat_days: Yup.number()
        .min(1, "Must be minimum 1 day")
        .max(10, "Must be at most 10 days")
        .required("Required"),
    slot_type_name: Yup.string()
        .max(20, "Must be at most 20 characters.")
        .nullable(),
    slot_type_status: Yup.string()
        .oneOf([ "Unknown", "Practice", "Telephone", "Visit", "Video" ])
        .nullable(),
    session_type: Yup.string()
        .max(20, "Must be at most 20 characters.")
        .nullable(),
});

interface IAppointmentSlotExt extends Partial<IAppointmentSlot> {
    duration: number;
    repeat_days: number;
}

const blankApoointmentSlot: Partial<IAppointmentSlotExt> = {
    start_time: "",
    // end_time: "",
    duration: 0,
    repeat_days: 1,
    slot_type_name: "",
    slot_type_status: SlotTypeStatusChoice.unknown,
    appointment_slot_id: "", // ??
    session_type: "",
    gp: "",
};

type Props = {
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
    gpId?: string;
};

export default function AddAppointmentSlotModal(props: Props) {
    const appointmentSlotService = useAppointmentSlotService();
    const gpService = useGPService();
    const { gpId } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ initialFormValues ] = useState({
        ...blankApoointmentSlot,
        ...(gpId ? { gp: gpId } : {})
    });
    const [ gps, setGPs ] = useState<IGP[]>([] as any);

    useEffect(() => {
        if (gpId)
            return;

        gpService.getAll(/*{ company: ?? }*/).then((gps) => {
            setGPs(gps);
        });
    }, [ gpId ]);

    const closeHandler = () => {
        props.onClose();
    };

    const _minDate = format(new Date(), "yyyy-MM-dd hh:mm");

    const onSubmit = (
        values: Partial<IAppointmentSlotExt>,
        { setSubmitting, setErrors }: FormikHelpers<Partial<IAppointmentSlotExt>>
    ) => {
        setIsLoading(true);

        const { duration, repeat_days, ...rest } = values;

        const start_time = zonedTimeToUtc(values.start_time || "", "UTC");

        const startTimeDate = start_time; //values.start_time ? new Date(values.start_time) : new Date();
        const startTimeInMiliseconds = startTimeDate.getTime();
        const durationInMiliseconds = (duration || 0) * 60000;
        const endTime = new Date(startTimeInMiliseconds + durationInMiliseconds);

        const payloads = Array(repeat_days || 1).fill(0).map((_, i) => i)
            .map((i) => {
                return {
                    ...rest as IAppointmentSlot,
                    start_time: addDays(startTimeDate, i),
                    end_time: addDays(endTime, i),
                } as IAppointmentSlot;
            });

        const promises = payloads.map((payload) => appointmentSlotService.create(payload));

        Promise.allSettled(promises).then((_) => {
            props.onSave?.();
        }).catch((error) => {
            setErrors(error);
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    };

    return (
        <Modal
            size={"lg"}
            isOpen={props.isVisible}
            onClose={closeHandler}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text color="text">Add Appointment Slot</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik<Partial<IAppointmentSlotExt>>
                        initialValues={initialFormValues}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<Partial<IAppointmentSlotExt>>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <VStack spacing={4} align="stretch">
                                    <TextFieldFormik
                                        type="datetime-local"
                                        label="Start Time"
                                        name="start_time"
                                        mb="0px"
                                        min={_minDate}
                                        placeholder="Start Time"
                                    />
                                    <TextFieldFormik
                                        type="number"
                                        label="Duration (mins)"
                                        name="duration"
                                        placeholder="Duration (mins)"
                                        mb="0px"
                                    />
                                    <TextFieldFormik
                                        type="number"
                                        label="Repeat (days)"
                                        name="repeat_days"
                                        placeholder="Repeat (days)"
                                        mb="0px"
                                    />
                                    {!gpId && <DropDownFormik
                                        label="GP"
                                        name="gp"
                                        placeholder="Select a Patient"
                                    >
                                        {gps.map((gp) =>
                                            <option key={gp.id} value={gp.id}>{gp.gp_title}</option>
                                        )}
                                    </DropDownFormik>}
                                    <TextFieldFormik
                                        type="text"
                                        label="Slot type name"
                                        name="slot_type_name"
                                        placeholder="Slot type name"
                                    />
                                    <DropDownFormik
                                        label="Slot type status"
                                        name="slot_type_status"
                                        placeholder="Select a status"
                                    >
                                        {Object.values(SlotTypeStatusChoice).map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </DropDownFormik>
                                    <TextFieldFormik
                                        type="text"
                                        label="Session type"
                                        name="session_type"
                                        placeholder="Session type"
                                    />
                                    <Flex
                                        align="center"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            isLoading={isLoading}
                                            variant="secondary"
                                            onClick={closeHandler}
                                            mr={3}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            isLoading={isLoading}
                                            variant="primary"
                                            type="submit"
                                            isDisabled={!(formik.isValid &&formik.dirty)}
                                        >
                                            Save
                                        </Button>
                                    </Flex>
                                </VStack>
                            </FormControl>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
}
