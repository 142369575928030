export enum SortOrder {
    None = 0,
    Asc = 1,
    Desc = 2,
}

export interface GridColumn {
    field: string,
    header?: string;
    sortable?: boolean;
    sortDir?: SortOrder; // initial sort order
    props?: any; // passed to Th/Td
    visible?: () => boolean;
    getComponent?: (row: any) => JSX.Element | null;
}
