import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { createStandaloneToast } from "@chakra-ui/toast";

import axiosClient from "./axios-client";
import { useAppStore } from "store";
import { IPagedData } from "./paged-data.interface";
import { IFilterCommand } from "components/paged-grid";

export type TOptions = {
    payload: object | FormData;
    successMessage: string | null;
    showErrorMessage: boolean | null;
    showSuccessMessage: boolean | null;
    filterError?: (error: AxiosError<any>) => boolean;
};

type CrudMethod = <T>(url: string, options?: Partial<TOptions>) => Promise<T>;

export interface IBaseService {
    _get: CrudMethod;
    _post: CrudMethod;
    _patch: CrudMethod;
    _put: CrudMethod;
    _delete: CrudMethod;
}

export interface IPagedCrudService<T> extends IBaseService {
    getAll: (filterCommand?: IFilterCommand, options?: Partial<TOptions>) => Promise<T[]>;
    get: (id: string, options?: Partial<TOptions>) => Promise<T>;
    create: (item: Partial<T>, options?: Partial<TOptions>) => Promise<T>;
    createMany: (item: Partial<T>[], options?: Partial<TOptions>) => Promise<T[]>;
    update: (id: string, item: Partial<T> | FormData, options?: Partial<TOptions>) => Promise<T>;
    delete: (id: string, options?: Partial<TOptions>) => Promise<T>;
    getPage: (filterCommand: IFilterCommand, options?: Partial<TOptions>) => Promise<IPagedData<T>>;
    url: string;
    entityTitle: string;
    model: string;
}

export function useBaseService(): IBaseService {
    const { toast } = createStandaloneToast({
        defaultOptions: {
            duration: 2000,
            isClosable: true,
            position: "top-right",
        }
    });

    // Convert "data_field": [ "Validation error" ] into "data_field": "Validation error"
    const normalizeFields = (data: any) => {
        return Object.keys(data).reduce((p, key, i) => {
            const value = Array.isArray(data[key]) ? data[key][0] : data[key];
            return { ...p, [key]: value };
        }, {});
    };

    const parseError = (data: any): { [key: string]: string } => {
        if (!data)
            return {};

        if (typeof data === "object")
            return normalizeFields(data);

        if (typeof data === "string" && data.startsWith("<!DOCTYPE html>"))
            // TODO: parse html content and retrieve the error
            return {
                "non_field_errors": "Internal Server Error"
            };

        return {};
    };

    const toastSuccess = (description: string) => {
        toast?.({
            title: "Success",
            description,
            status: "success",
        });
    };

    const toastError = (title: string, errorData: object) => {
        if (!toast)
            return;

        const decode = (obj: any) =>
            Object.keys(obj || {}).filter(key => key != "access_token").map((key, i) =>
                key === "non_field_errors" || key === "detail" ? obj[key] : `${key}: ${obj[key]}`);

        // We can't use JSX syntax here, create manually
        const el = React.createElement("div", null,
            decode(errorData).map((e, key) =>
                React.createElement("p", { key }, e)));

        toast?.({
            title: title || "Error",
            description: el,
            status: "error",
        });
    };

    const query = <T>(axiosMethod: () => Promise<any>, options: Partial<TOptions> = {}) => {
        return new Promise<T>((resolve, reject) => {
            const showErrorMessage = "showErrorMessage" in options ? options.showErrorMessage : true;
            const showSuccessMessage = "showSuccessMessage" in options ? options.showSuccessMessage : true;

            axiosMethod(
            ).then((response: AxiosResponse<T>) => {
                if (options.successMessage && showSuccessMessage) {
                    toastSuccess(options.successMessage);
                }
                resolve(response.data);
            }).catch((error: AxiosError<T>) => {
                const errorData = parseError(error.response?.data);
                const showErrorFilter = options.filterError ? options?.filterError(error) : true;

                // If log session has expired, reload the page
                if (errorData["detail"] === "Authentication credentials were not provided.") {
                    window.location.href = "/";
                }

                if ("version" in errorData) {
                    sessionStorage.setItem("versionError", String(true));
                    useAppStore.setState({ versionError: true });
                } else if (showErrorMessage && showErrorFilter) {
                    toastError(error.message, errorData);
                }
                reject(errorData);
            });
        });
    };

    const _get = <T>(url : string, options: Partial<TOptions> = {}): Promise<T> => {
        if (options.payload instanceof FormData) {
            throw "Can't use FormData with axios GET method";
        }
        const encodedParams = options.payload ? `?${qs.stringify(options.payload)}` : "";

        return query<T>(() => axiosClient.get<T>(`${url}${encodedParams}`), options);
    };

    const _post = <T>(url: string, options: Partial<TOptions> = {}): Promise<T> => {
        const isFormData = options.payload instanceof FormData;

        return query<T>(() => axiosClient.post<T>(
            url,
            options.payload,
            isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
        ), options);
    };

    const _patch = <T>(url: string, options: Partial<TOptions> = {}): Promise<T> => {
        const isFormData = options.payload instanceof FormData;

        return query<T>(() => axiosClient.patch<T>(
            url,
            options.payload,
            isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
        ), options);
    };

    const _put = <T>(url: string, options: Partial<TOptions> = {}): Promise<T> => {
        const isFormData = options.payload instanceof FormData;

        return query<T>(() => axiosClient.put<T>(
            url,
            options.payload,
            isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
        ), options);
    };

    const _delete = <T>(url : string, options: Partial<TOptions> = {}): Promise<T> => {
        return query<T>(() => axiosClient.delete<T>(
            url
        ), options);
    };

    return {
        _get, _post, _patch, _put, _delete
    };
}

type PagedCrudServiceProps = {
    url: string,
    entityTitle: string,
    model: string
}

export function usePagedCrudService<TEntity>({ url, entityTitle, model }: PagedCrudServiceProps): IPagedCrudService<TEntity> {
    const base = useBaseService();

    const getAll = (filterCommand?: IFilterCommand, options?: Partial<TOptions>): Promise<TEntity[]> => {
        return base._get<TEntity[]>(`${url}`, {
            payload: filterCommand,
            ...options
        });
    };

    const get = (id: string, options?: Partial<TOptions>): Promise<TEntity> => {
        return base._get<TEntity>(`${url}${id}/`, {
            successMessage: `Item "${entityTitle}" retrieved`,
            ...options,
        });
    };

    const create = (item: Partial<TEntity>, options?: Partial<TOptions>): Promise<TEntity> => {
        return base._post<TEntity>(`${url}`, {
            payload: item as any,
            successMessage: `Item "${entityTitle}" created`,
            ...options
        });
    };

    const createMany = (item: Partial<TEntity>[], options?: Partial<TOptions>): Promise<TEntity[]> => {
        return base._post<TEntity[]>(`${url}`, {
            payload: item,
            successMessage: `Successfully created "${entityTitle}"`,
            ...options
        });
    };

    const update = (id: string, item: Partial<TEntity> | FormData, options?: Partial<TOptions>): Promise<TEntity> => {
        return base._patch<TEntity>(`${url}${id}/`, {
            payload: item as any,
            successMessage: `Item "${entityTitle}" saved`,
            ...options
        });
    };

    const del = (id: string, options?: Partial<TOptions>): Promise<TEntity> => {
        return base._delete<TEntity>(`${url}${id}/`, {
            successMessage: `Item "${entityTitle}" deleted`,
            ...options
        });
    };

    const getPage = (filterCommand: IFilterCommand, options?: Partial<TOptions>): Promise<IPagedData<TEntity>> => {
        return base._get<IPagedData<TEntity>>(`${url}`, {
            payload: filterCommand,
            ...options
        });
    };

    return {
        ...base,
        getAll, get, create, createMany, update, delete: del, getPage, url, entityTitle, model
    };
}
