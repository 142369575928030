import React, { useContext, useState, useEffect, memo } from "react";
import { NavLink } from "react-router-dom";

import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    InputGroup,
    Alert,
    AlertIcon,
    Stack,
} from "@chakra-ui/react";

import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useTimer } from "react-timer-hook";

import { TokenResponse } from "@react-oauth/google";

import { AppContext } from "contexts/AppContext";
import AuthPage from "./components/AuthPage";
import { TAuthData, useAuthService } from "services";
import { TextFieldFormik, PasswordFieldFormik } from "components/fields";

import GoogleButton from "./components/GoogleButton";

const authDataInitial: TAuthData = {
    email: "",
    password: "",
};

const formValidationSchema = Yup.object({
    email: Yup.string()
        .email("Must be a valid email.")
        .required("Email is a required field"),
    password: Yup.string()
        .required("Password is a required field."),
});

type LockoutProps = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    timeout?: number;
}

function _LockoutBanner(props: LockoutProps) {
    const { isVisible, setIsVisible, timeout = 150 } = props;
    const {
        isRunning,
        seconds,
        minutes,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp: (function() {
            const time = new Date();
            time.setSeconds(time.getSeconds() + timeout);
            return time;
        })(),
        onExpire: () => setIsVisible(false)
    });

    if (!isVisible)
        return null;

    return <>
        <Alert status="error">
            <AlertIcon />
            Too many failed login attempts. User is locked out. Please, try again in {minutes * 60 + seconds} seconds.
        </Alert>
    </>;
}

const LockoutBanner = memo(_LockoutBanner);

function SignIn() {
    const authService = useAuthService();
    const { setIsLoggedIn } = useContext(AppContext);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingMFA, setIsLoadingMFA ] = useState(false);
    const [ showMfa, setShowMfa ] = useState(false);
    const [ token, setToken ] = useState("");
    const [ code, setCode ] = useState("");

    const [ isLockoutVisible, SetIsLockoutVisible ] = useState(false);
    const [ lockTimeout, setLockTimeout ] = useState(150);

    const [ showGoogleRegister, setShowGoogleRegister ] = useState(false);
    const [ accessToken, setAccessToken ] = useState("");
    const [ companyName, setCompanyName ] = useState("");

    const onGoogleLoginSuccess = (response: TokenResponse) => {
        setIsLoading(true);

        authService.loginGoogle(
            response.access_token, {
                filterError: (axiosError) => !(axiosError.response?.status == 404 &&
                    axiosError.response?.data["detail"] == "No user with this email address exists.")
            }
        ).then((response) => {
            setIsLoggedIn(true);
        }).catch((error) => {
            // detail: "No user with this email address exists."
            if (error["detail"] == "No user with this email address exists.") {
                setShowGoogleRegister(true);
                setAccessToken(error["access_token"]);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const onSubmit = async (values: TAuthData, { setSubmitting, setErrors }: FormikHelpers<TAuthData>) => {
        setIsLoading(true);

        await authService.login(values).then((response) => {
            // 2FA
            if (response?.ephemeral_token) {
                setToken(response?.ephemeral_token);
                setShowMfa(true);
            } else {
                setIsLoggedIn(true);
                setIsLoading(false);
            }
        }).catch((error) => {
            /*
            detail: "Too many failed login attempts"
            lockout: "True"
            lockout_timeout: "149.764485"
            */
            if (error.lockout || error.detail === "Too many failed login attempts") {
                SetIsLockoutVisible(true);
                setLockTimeout(error.lockout_timeout || 150);
            } else {
                setErrors(error);
            }
            setIsLoading(false);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const onMfaLogin = () => {
        setIsLoadingMFA(true);

        authService.loginMfa({
            ephemeral_token: token,
            code
        }).then(() => {
            setToken("");
            setShowMfa(false);
            setIsLoggedIn(true);
            setIsLoading(false);
        }).catch((error) => {
            setCode("");
        }).finally(() => {
            setIsLoadingMFA(false);
        });
    };

    const onMfaCancel = () => {
        setShowMfa(false);
        setIsLoadingMFA(false);
        setIsLoading(false);
        setCode("");
    };

    const onGoogleRegister = () => {
        setIsLoadingMFA(true);

        authService.registerGoogle({
            access_token: accessToken,
            company_name: companyName
        }).then((response) => {
            console.log(response);
            setShowGoogleRegister(false);
            setIsLoggedIn(true);
            setIsLoading(false);
        }).catch((error) => {
            setCompanyName("");
        }).finally(() => {
            setIsLoadingMFA(false);
        });
    };

    const onGoogleCancel = () => {
        setShowGoogleRegister(false);
        setIsLoadingMFA(false);
        setIsLoading(false);
        setCompanyName("");
    };

    useEffect(() => {
        authService.isUserLocked().then((response) => {
            SetIsLockoutVisible(response.lockout);
            setLockTimeout(response.lockout_timeout);
        });
    }, []);

    return (<>
        <AuthPage>
            <Flex
                w={{ base: "90%", sm: "376px" }}
                mx="auto"
                justify="center"
                alignItems="center"
                px="43px"
                py="35px"
                flexDirection="column"
                backgroundColor="authCardBG"
                borderRadius={"card"}
                boxShadow={"md"}
                textAlign="center"
            >
                <Heading
                    color="text"
                    fontSize="headingSmall.lg"
                    mb="9"
                    lineHeight="1.5"
                    fontWeight="600"
                >
                    Sign In
                </Heading>
                {isLockoutVisible && <LockoutBanner isVisible={isLockoutVisible} setIsVisible={SetIsLockoutVisible} timeout={lockTimeout}/>}
                <Formik<TAuthData>
                    enableReinitialize={true}
                    initialValues={authDataInitial}
                    validationSchema={formValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik: FormikProps<TAuthData>) => (
                        <FormControl
                            as="form"
                            onSubmit={formik.handleSubmit as any}
                            display={isLockoutVisible ? "none" : "block"}
                        >
                            <Box mb={"20px"}>
                                <TextFieldFormik
                                    type="email"
                                    name="email"
                                    auth={true}
                                    bg="fieldBG"
                                    color="text"
                                    borderRadius="card"
                                    placeholder="Email"
                                    required={true}
                                    w="100%"
                                    mr={0}
                                />
                            </Box>

                            <PasswordFieldFormik
                                type="password"
                                name="password"
                                auth={true}
                                bg="fieldBG"
                                color="text"
                                borderRadius="card"
                                placeholder="Password"
                                required={true}
                            />

                            <Flex
                                justifyContent="space-between"
                                align="center"
                                mb="5"
                            >
                                <Flex display="flex" alignItems="center">
                                    <Checkbox
                                        id="remember-login"
                                        colorScheme="brandScheme"
                                        me="10px"
                                        borderColor="textDetails"
                                    />
                                    <FormLabel
                                        htmlFor="remember-login"
                                        mb="0"
                                        fontWeight="normal"
                                        color="textDetails"
                                        fontSize="sm"
                                    >
                                        Remember me
                                    </FormLabel>
                                </Flex>
                                <NavLink to="/auth/password-reset">
                                    <Text
                                        color="textBrand"
                                        fontSize="sm"
                                        w="124px"
                                        fontWeight="500"
                                    >
                                        Forgot password?
                                    </Text>
                                </NavLink>
                            </Flex>
                            <Button
                                variant={"primaryFullWidth"}
                                mb="3"
                                type="submit"
                                isLoading={isLoading}
                            >
                                Sign In
                            </Button>
                        </FormControl>

                    )}
                </Formik>
                <Stack display={isLockoutVisible ? "none" : "block"}>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        maxW="100%"
                    >
                        <Text
                            color="textDetails"
                            fontWeight="400"
                            lineHeight={1.5}
                            fontSize="text.xs"
                        >
                            Do not have an account?
                            <NavLink to="/auth/registration">
                                <Text
                                    color="textBrand"
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                    Sign Up
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>

                    <GoogleButton
                        mt="1.5rem"
                        isLoading={isLoading}
                        onSuccess={onGoogleLoginSuccess}
                    />
                </Stack>
            </Flex>
        </AuthPage>

        {/* MFA modal */}
        <Modal isOpen={showMfa} onClose={() => onMfaCancel()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text fontSize="text.md">
                        Multi-factor Authentication
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex alignContent="center" justifyContent="center" flexDirection="column">
                        <Text mb="2">
                            Please, enter code from your Authenticator app to log in
                        </Text>
                        <InputGroup>
                            <Input
                                placeholder="Enter code"
                                type="text"
                                autoFocus={true}
                                maxLength={6}
                                value={code}
                                onChange={({ target: { value } }) => setCode(value)}
                                onKeyDown={({ type, key, target: { value } }: any) => {
                                    if (type === "keydown" && key === "Enter") {
                                        onMfaLogin();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onMfaCancel()} isLoading={isLoadingMFA} variant="secondary" mr={3}>Cancel</Button>
                    <Button onClick={() => onMfaLogin()} isLoading={isLoadingMFA}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        {/* Company name modal */}
        {showGoogleRegister && <Modal isOpen={showGoogleRegister} onClose={() => onGoogleCancel()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text fontSize="text.md">
                        Company Name
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex alignContent="center" justifyContent="center" flexDirection="column">
                        <Text mb="2">
                            Please enter your Company Name.
                        </Text>
                        <InputGroup>
                            <Input
                                placeholder="Enter Company Name"
                                type="text"
                                autoFocus={true}
                                maxLength={50}
                                value={companyName}
                                onChange={({ target: { value } }) => setCompanyName(value)}
                                onKeyDown={({ type, key, target: { value } }: any) => {
                                    if (type === "keydown" && key === "Enter") {
                                        onGoogleRegister();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onGoogleCancel()} isLoading={isLoadingMFA} variant="secondary" mr={3}>Cancel</Button>
                    <Button onClick={() => onGoogleRegister()} isLoading={isLoadingMFA}>Ok</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>}
    </>);
}

export default SignIn;
