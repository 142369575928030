import { usePagedCrudService } from "./crud.service";
import { ITemplate } from "./contract.interface";

const templateService = (function() {
    const templateService = usePagedCrudService<ITemplate>({
        url: "/api/contracts/template/",
        entityTitle: "Contract Template",
        model: "template"
    });

    const syncTemplates = () =>
        templateService._patch<any>(`${templateService.url}sync/`, { payload: { } });

    return {
        ...templateService,
        syncTemplates,
    };
})();

export const useTemplateService = () => templateService;
