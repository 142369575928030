import React, { useState, useEffect, Fragment, memo, useMemo, useCallback, useRef } from "react";
import {
    Text,
    Input,
    HStack,
    VStack,
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    List,
    ListItem,
    Radio,
    RadioGroup,
    Checkbox,
} from "@chakra-ui/react";

import { IRole, IUser, IPermission, useRoleService } from "services";

import { useDebounce } from "hooks";

type Props = {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    onSuccess?: () => void,
};

function EditRoles(props: Props) {
    const roleService = useRoleService();
    const { isVisible, setIsVisible, onSuccess } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ roles, setRoles ] = useState<IRole[]>([] as any);
    const [ permissions, setPermissions ] = useState<IPermission[]>([] as any);
    const [ filteredPermissions, setFilteredPermissions ] = useState<IPermission[]>([] as any);
    const [ chosenRole, setChosenRole ] = useState<IRole>({} as any);
    const [ roleValue, setRoleValue ] = useState<string>("0");
    const [ filter, setFilter ] = useState<string>("");
    const debouncedFilter = useDebounce<string>(filter, 500);

    const loadData = async () => {
        const _roles = await roleService.getAll();
        const _permissions = await roleService.getAllPermissions();

        setRoles(_roles);
        setChosenRole(_roles[0]);
        setPermissions(_permissions);
        setFilteredPermissions(_permissions);
    };

    const isPermChecked = useCallback((permission: IPermission) =>
        chosenRole.permissions.includes(permission.id), [ chosenRole ]);

    const updateChosenRole = useCallback((id: number, checked: boolean) => {
        setChosenRole((role) => {
            const permissions = role.permissions.filter((_id: number) => _id !== id);
            if (checked) {
                permissions.push(id);
            }
            //permissions.sort((a: number, b: number) => a - b);

            return { ...role, permissions };
        });
    }, []);

    const checkFilter = useCallback((name: string): boolean => {
        const filter = debouncedFilter;

        if (!filter)
            return true;

        const terms = filter.replace(/([^\w]+|\s+)/g, " ").toLowerCase().split(" ");

        for (const term of terms) {
            if (!name.toLowerCase().includes(term))
                return false;
        }

        return true;
    }, [ debouncedFilter ]);

    const onSave = () => {
        setIsLoading(true);
        roleService.update(chosenRole.id, chosenRole).then((response) => {
            setRoles((roles) => roles.map((role: IRole) => role.id !== response.id ? role : response));
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const onCancel = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible && !roles.length && !permissions.length) {
            loadData();
        }
    }, [ isVisible ]);

    useEffect(() => {
        setFilteredPermissions(permissions.filter((permission) => checkFilter(permission.name)));
    }, [ debouncedFilter ]);

    // Optimize heavy re-rendering
    const permList = useMemo(() => (
        <List color="text">
            {filteredPermissions.map((permission, i) =>
                <ListItem key={permission.id}>
                    <Checkbox
                        isChecked={isPermChecked(permission)}
                        onChange={(e: any) => updateChosenRole(permission.id, e.target.checked)}
                    >
                        {permission.name}
                    </Checkbox>
                </ListItem>
            )}
        </List>
    ), [ filteredPermissions, chosenRole ]);

    return <>
        <Modal isOpen={isVisible} onClose={onCancel} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent minW="600px" bg="cardBG">
                <ModalHeader>Edit Roles</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack spacing={"8px"} alignItems="start">
                        <Box
                            as="section"
                            w="50%"
                            h="270px"
                            border={"1px solid grey"}
                            p="2"
                            bg="fieldBG"
                            borderRadius="card"
                        >
                            <RadioGroup as={List}
                                name="role-group"
                                value={roleValue}
                                onChange={(e) => {
                                    setChosenRole(roles[parseInt(e)]);
                                    setRoleValue(e);
                                }}
                                defaultValue="0"
                                color="text"
                            >
                                {roles.map((role, i) =>
                                    <ListItem key={i}><Radio value={`${i}`}>{role.name}</Radio></ListItem>
                                )}
                            </RadioGroup>
                        </Box>
                        <VStack
                            as="section"
                            w="50%"
                        >
                            <Input
                                type="search"
                                placeholder="Enter search term"
                                size="sm"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <Box
                                border={"1px solid grey"}
                                p="2"
                                w="100%"
                                h="230px"
                                overflowY={"scroll"}
                                bg="fieldBG"
                                borderRadius="15px 0 0 15px"
                            >
                                {permList}
                            </Box>
                        </VStack>
                    </HStack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        variant="secondary"
                        onClick={onCancel}
                    >Cancel</Button>
                    <Button
                        isLoading={isLoading}
                        variant="primary"
                        ml={3}
                        onClick={onSave}
                    >Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>;
}

export default memo(EditRoles);
