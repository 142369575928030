import React, { useState } from "react";

import { Badge, Flex, Heading, Link, Text, Button } from "@chakra-ui/react";

import BoxCard from "components/card/Card";
import { GridColumn, IFilterSettings, PagedGrid, Row, SelectionMode, SortOrder } from "components/paged-grid";
import { IStripeCharge, IStripeChargeStatus } from "services/billing/charge.interface";
import { useChargeService } from "services/billing/charge.service";
import { useUserService } from "services";
import CustomMoment from "components/utils/CustomMoment";
import { ViewIcon } from "@chakra-ui/icons";

const PaymentHistory = () => {
    const chargeService = useChargeService();
    const { userData } = useUserService();

    const [ reloadKey, setReloadKey ] = useState(0);

    const reloadGrid = () => {
        // Force PagedGrid to reload the data
        setReloadKey((value) => value + 1);
    };

    const columns: GridColumn[] = [
        {
            field: "amount",
            header: "Amount Paid",
            getComponent: (row: Row<IStripeCharge>) => {
                return (
                    <Text as="span" fontWeight={"bold"}>{`${row.data.amount} GBP`}</Text>
                );
            },
        },
        {
            field: "status",
            header: "Status",
            getComponent: (row: Row<IStripeCharge>) => {
                const status = row.data.status === IStripeChargeStatus.succeeded
                    ? <Button  borderRadius="8px"
                        bgColor="green.500"
                        color="white"
                        size="xs">SUCCEEDED</Button>
                    : row.data.status === IStripeChargeStatus.failed
                        ? <Button  borderRadius={"8px"}
                            color="white"
                            bgColor="gray.500"
                            size="xs">FAILED</Button>
                        : <Badge colorScheme="purple" variant='outline'>PENDING</Badge>;
                return (
                    <>{status}</>
                );
            },
        },
        {
            field: "description",
            header: "Description",
            getComponent: (row: Row<IStripeCharge>) => {
                return (
                    <Text as="span">{`${row.data.description || ""}`}</Text>
                );
            },
        },
        {
            field: "billing_details.email",
            header: "Client",
            getComponent: (row: Row<IStripeCharge>) => {
                return (
                    <Text as="span">{`${row.data.billing_details.email || ""}`}</Text>
                );
            },
        },
        {
            field: "djstripe_created",
            header: "Date Paid",
            sortable: true,
            sortDir: SortOrder.Desc,
            getComponent: (row: Row<IStripeCharge>) => {
                return (
                    <CustomMoment date={row.data.created} dateFormat="dd/MM/yyyy"></CustomMoment>
                );
            },
        },
        {
            field: "receipt_url",
            header: "Receipt",
            getComponent: (row: Row<IStripeCharge>) => {
                const status = row.data.status === IStripeChargeStatus.succeeded;
                if (!status) return <>-</>;
                return (
                    <Link isExternal href={row.data.receipt_url}>
                        <ViewIcon />
                    </Link>
                );
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "customer__company__id",
            match: () => "exact",
            getValue: () => userData.impersonated_company || userData.company, // "" === no filter
            getComponent: () => <></>,
        },
    ];

    return <BoxCard variant="table" mt={6}>
        <PagedGrid<IStripeCharge>
            reloadKey={reloadKey}
            dataService={chargeService}
            columns={columns}
            selectionMode={SelectionMode.None}
            filters={filters}
        />
    </BoxCard>;
};

export default PaymentHistory;