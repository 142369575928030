import React, { memo, useCallback, useEffect, useState } from "react";

import {
    Box,
    Button,
    Flex,
    Icon,
    ModalCloseButton,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useDisclosure,
    Text,
    Input,
    InputProps,
    Spacer,
    HStack
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";
import { VscJson } from "react-icons/vsc";
import { FileRejection, useDropzone } from "react-dropzone";

import { HSeparator } from "components/separator/Separator";
import { AcceptFiles } from "./accept-files.interface";

const UploadFile: React.FC<{
    isVisible: boolean;
    header: string;
    onClose: () => void;
    onSave: (file: File) => void;
    accept: AcceptFiles;
}> = (props) => {
    const [ uploadedFile, setUploadedFile ] = useState<any>();
    const [ error, setError ] = useState("");
    //const [ isDisabled, setIsDisabled ] = useState(false);

    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const borderColor = useColorModeValue(
        "secondaryGray.100",
        "whiteAlpha.100"
    );
    const bg = useColorModeValue("gray.100", "navy.700");

    const closeHandler = () => {
        props.onClose();
    };

    const saveHandler = () => {
        props.onSave(uploadedFile);
        props.onClose();
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        // Do something with the files
        setUploadedFile(acceptedFiles[0]);
        setError("");
        //setIsDisabled(false);
    }, []);

    const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
        // Do something with the files
        setError(
            rejectedFiles[0]?.errors[0]?.message || "Something went wrong."
        );
        //setIsDisabled(true);
        setUploadedFile({} as File);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: props.accept.accept,
        maxFiles: 1,
        onDropAccepted: (acceptedFiles: any) => {
            onDrop(acceptedFiles);
            props.onSave(acceptedFiles[0]);
            props.onClose();
        },
        onDropRejected: onDropRejected
    });

    return (
        <Modal isOpen={props.isVisible} onClose={closeHandler}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.header}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HSeparator />
                    <Flex
                        align="center"
                        justify="center"
                        bg={bg}
                        border="1px dashed"
                        borderColor={borderColor}
                        borderRadius="16px"
                        w="100%"
                        mt={25}
                        h="max-content"
                        minH="100%"
                        cursor="pointer"
                        {...getRootProps({ className: "dropzone" })}
                    >
                        <Input
                            variant="main"
                            {...(getInputProps() as InputProps)}
                        />
                        <Box>
                            <Flex justify="center" mx="auto">
                                <Icon
                                    as={MdUpload}
                                    w="50px"
                                    h="50px"
                                    color={brandColor}
                                />
                            </Flex>
                            <Flex justify="center" mx="auto" mb="12px">
                                <Text
                                    fontSize="xl"
                                    fontWeight="700"
                                    color={brandColor}
                                >
                                    Upload File
                                </Text>
                            </Flex>
                            <Text
                                fontSize="sm"
                                fontWeight="500"
                                color="secondaryGray.500"
                            >
                                { props.accept.acceptText }
                            </Text>
                        </Box>
                    </Flex>
                    {(error === "" && uploadedFile?.name) && (
                        <Flex bg={"gray.100"} mt={5} alignItems="center">
                            <Icon
                                as={VscJson}
                                w="auto"
                                h="100%"
                                color={brandColor}
                            />
                            <Text ml={2}>{uploadedFile?.name} is to be uploaded</Text>
                        </Flex>
                    )}
                    {error !== "" && <Text color={"red"} mt={3}>{error}</Text>}
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        { /*
                        <Button variant="secondary" onClick={closeHandler}>
                            Close
                        </Button>
                        <Spacer />
                        <Button variant="primary" isDisabled={isDisabled} onClick={saveHandler}>
                            Save
                        </Button>
                        */ }
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default memo(UploadFile);
