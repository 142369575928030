import { useBaseService } from "services";

const patientCloneService = (function() {
    const base = useBaseService();
    const url = "/api/mock/clone/";

    const generateTestPatients = (): Promise<any> => {
        return base._post(url);
    };

    const restorePatient = (patientId: string): Promise<any> => {
        return base._post(`${url}patient/${patientId}/`);
    };

    return {
        generateTestPatients,
        restorePatient
    };
})();

export const usePatientCloneService = () => patientCloneService;
