import React from "react";
import { Box, BoxProps, useStyleConfig, useColorModeValue } from "@chakra-ui/react";

function OptionBox(props: BoxProps & { variant?: string, direction?: string, align?: string } & { value: number}) {
    const { variant, children, ...rest } = props;

    return (
        <Box as="option" __css={ { "--select-bg": "lightFieldBackground " }} color="lightText" backgroundColor="lightFieldBackground" borderRadius="card" {...rest}>
            {children}
        </Box>
    );
}

export default OptionBox;
