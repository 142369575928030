import React from "react";

import { useColorModeValue } from "@chakra-ui/system";
import { useField } from "formik";
import { Flex, FormLabel, Input, Text } from "@chakra-ui/react";

export const CurrencyInput: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        required,
        placeholder,
        mb = "10px",
        variant = "modal",
        ...rest
    } = props;
    const id = props.id || props.name;
    const brandStars = useColorModeValue("red", "brand.400");

    const formatNumber = (n: string) => {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatCurrency = (input: React.FormEvent<HTMLInputElement>) => {
        // appends £ to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        let input_val = input.currentTarget.value;

        // don't validate empty input
        if (input_val === "") { return; }

        // original length
        const original_len = input_val.length;

        // initial caret position
        let caret_pos = input.currentTarget.selectionStart ?? 0;

        // check for decimal
        if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            const decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            let left_side = input_val.substring(0, decimal_pos);
            let right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            if (input.type === "blur") {
                right_side += "00";
            }

            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = "£" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            input_val = "£" + input_val;

            // final formatting
            if (input.type === "blur") {
                input_val += ".00";
            }
        }

        // send updated string to input
        helpers.setValue(input_val);

        // put caret back in the right position
        const updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.currentTarget.setSelectionRange(caret_pos, caret_pos);
    };

    return (
        <Flex
            direction={{ base:"column", md:"row" }}
            align={{ base: "start", md: "center" }}
            justify={{ base: "space-start", md: "space-between" }}
            flexWrap={"wrap"} mb={mb}
        >
            { label &&
                <FormLabel
                    display="flex"
                    fontSize="text.md"
                    fontWeight="normal"
                    color="text"
                    mb={{ base: "2", md: "0" }}
                    me="0"
                    htmlFor={id}
                    _hover={{ cursor: "pointer" }}
                    maxW="9rem"
                >
                    {label}
                    {required && <Text color={brandStars}>*</Text> }

                </FormLabel>
            }
            <Flex direction="column" alignItems="start"  w={props.w || "auto"}>
                <Input
                    {...field}
                    {...rest}
                    id={id}
                    onKeyUp={formatCurrency}
                    onBlur={formatCurrency}
                    type="text"
                    required={null}
                    isRequired={false}
                    variant={variant}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder={placeholder}
                    fontWeight="500"
                    size="lg"
                    borderColor={meta.error ? "red" : "gray.400"}
                />
                {meta.touched && meta.error ? (
                    <Text as="span" fontSize="text.xs" color={"red"} h="14px" ml={3}>
                        {meta.error}
                    </Text>
                ) : null}
            </Flex>
        </Flex>
    );
};