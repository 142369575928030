import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Input,
    Select,
    Text,
    useToast,
    Badge,
} from "@chakra-ui/react";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    IFiltersActions,
    IFilterSettings,
    PagedGrid,
    Row,
    RowPreview,
    SelectionMode,
} from "components/paged-grid";

import { useAlert } from "components/alert-modal";
import { ICompany, IPatient, IPermAction, LinkStatusEnum, PatientGenderEnum, useCompanyService, usePatientService, useUserService } from "services";
import CreateEditPatientModal from "./components/patient-details/CreateEditPatientModal";
import BoxCard from "components/card/Card";
import CustomMoment from "components/utils/CustomMoment";
import { usePatientCloneService } from "services";
import { BiCog, BiCopy } from "react-icons/bi";

type Props = {
    testPatients?: boolean;
    pt?: string;
    showGenerateButton?: boolean;
    generateBtnHandler?: () => void;
    onChangeCompany?: (company: string) => void;
}

const Patients = (props: Props) => {
    const { testPatients = false } = props;

    const companyService = useCompanyService();
    const patientService = usePatientService();
    const patientCloneService = usePatientCloneService();

    const alertShow = useAlert();
    const toast = useToast();
    const userService = useUserService();
    const userData = userService.userData;

    const [ reloadKey, setReloadKey ] = useState(0);
    const [ id, setId ] = useState("");
    const [ isVisible, setIsVisible ] = useState(false);
    const [ companies, setCompanies ] = useState<ICompany[]>([]);
    const [ filterFirstName, setFilterFirstName ] = useState("");
    const [ filterNhsNumber, setFilterNhsNumber ] = useState("");
    const [ filterGPName, setFilterGPName ] = useState("");
    const [ filterLinkStatus, setFilterLinkStatus ] = useState("");
    const [ filterCompany, setFilterCompany ] = useState(
        userData.impersonated_company || userData.company
    );
    const [ filterInclude, setFilterInclude ] = useState("");

    const showIncludeForClone = () => {
        const company_id = userData.impersonated_company || userData.company || "";
        return company_id === "00000000-0000-0000-0000-000000000001" && (
            userData.is_superuser || userService.meHasPemissions("patient", IPermAction.ManageForClone)
        );
    };

    const navigate = useNavigate();

    useEffect(() => {
        companyService.getAll().then((companies: ICompany[]) => {
            setCompanies(companies);
        }).catch(err => console.log(err));
    }, []);

    const reloadGrid = () => {
        // Force PagedGrid to reload the data
        setReloadKey((value) => value + 1);
    };

    const rowPreview: RowPreview<IPatient> = {
        name: "Row data preview",
        height: "auto",
        visible: () => false,
        getComponent: (row: Row<IPatient>) => {
            return (
                <Box position="relative" bgColor="CG.1">
                    <Grid
                        templateColumns={"0.1fr repeat(6,0.6fr) 1fr"}
                        gap={6}
                        p={4}
                        alignItems="center"
                        alignContent="center"
                        fontSize={"14px"}
                    >
                        {/* <GridItem colStart={1}></GridItem> */}
                        <GridItem colStart={2}>
                            <Text fontWeight={"bold"}>Forename</Text>
                        </GridItem>
                        <GridItem colStart={3}>
                            <Text color="gray.500">{row.data.first_name}</Text>
                        </GridItem>
                        <GridItem colStart={4}>
                            <Text fontWeight={"bold"}>Surname</Text>
                        </GridItem>
                        <GridItem colStart={5}>
                            <Text color="gray.500">{row.data.surname}</Text>
                        </GridItem>
                        <GridItem colStart={6}>
                            <Text fontWeight={"bold"}>Gender</Text>
                        </GridItem>
                        <GridItem colStart={7}>
                            <Text color="gray.500">
                                {PatientGenderEnum[row.data.gender]}
                            </Text>
                        </GridItem>
                        {/* <GridItem colStart={8}></GridItem> */}
                        <GridItem colStart={2}>
                            <Text fontWeight={"bold"}>NHS Number</Text>
                        </GridItem>
                        <GridItem colStart={3}>
                            <Text color="gray.500">{row.data.nhs_number}</Text>
                        </GridItem>
                        <GridItem colStart={4}>
                            <Text fontWeight={"bold"}>DoB</Text>
                        </GridItem>
                        <GridItem colStart={5}>
                            <Text color="gray.500">
                                <CustomMoment date={row.data.date_of_birth} dateFormat="dd/MM/yyyy" />
                            </Text>
                        </GridItem>
                        <GridItem colStart={6}>
                            <Text fontWeight={"bold"}>GP ODS Code</Text>
                        </GridItem>
                        <GridItem colStart={7}>
                            <Text color="gray.500">
                                {row.data.gp_ods_code || "-"}
                            </Text>
                        </GridItem>
                        <GridItem colStart={2}>
                            <Text fontWeight={"bold"}>Address</Text>
                        </GridItem>
                        <GridItem colStart={3} colSpan={3}>
                            <Text color="gray.500">
                                {" "}
                                {`${row.data.address_line_1 ?? ""} ${
                                    row.data.address_line_2 ?? ""
                                } ${row.data.address_line_3 ?? ""} `}
                            </Text>
                        </GridItem>
                        <GridItem colStart={6}>
                            <Text fontWeight={"bold"}>Postcode</Text>
                        </GridItem>
                        <GridItem colStart={7}>
                            <Text color="gray.500">{row.data.post_code ?? ""}</Text>
                        </GridItem>
                        <GridItem colStart={2}>
                            <Text fontWeight={"bold"}>Linkage</Text>
                        </GridItem>
                        <GridItem colStart={3} colSpan={3}>
                            <Text color="gray.500">
                                {row.data.linkage_key ?? ""}
                            </Text>
                        </GridItem>
                        <GridItem colStart={6}>
                            <Text fontWeight={"bold"}>Account</Text>
                        </GridItem>
                        <GridItem colStart={7}>
                            <Text color="gray.500">{row.data.account_id ?? ""}</Text>
                        </GridItem>
                    </Grid>

                    {/* <Text>Forename</Text>
                        <Text>{row.data.first_name}</Text> */}
                </Box>);

        }
    };

    const columns: GridColumn[] = [
        {
            field: "first_name",
            header: "Name",
            getComponent: (row: Row<IPatient>) => {
                return (
                    <Text as="span">{`${row.data.first_name} ${row.data.surname}`}</Text>
                );
            },
        },
        {
            field: "nhs_number",
            header: "NHS Number",
            getComponent: (row: Row<IPatient>) => {
                return <Text as="span">{row.data.nhs_number}</Text>;
            },
        },
        {
            field: "description",
            header: "Description",
            props: { whiteSpace: "normal", minWidth: "300px" },
            getComponent: (row: Row<IPatient>) => {
                return <Text as="span">{row.data.description}</Text>;
            },
        },
        {
            field: "gp_company_name",
            header: "GP Company",
            visible: () => false,
            getComponent: (row: Row<IPatient>) => <Text>{row.data.gp_company_name}</Text>,
        },
        {
            field: "gp_practice_name",
            header: "GP",
            visible: () => true,
            getComponent: (row: Row<IPatient>) => <Text>{row.data.gp_practice_name}</Text>,
        },
        {
            field: "link_status",
            header: "Status",
            getComponent: (row: Row<IPatient>) => {
                const registered = row.data.link_status === LinkStatusEnum.Registered;
                return <Button
                    borderRadius="8px"
                    bgColor={registered ? "green.500" : "gray.500"}
                    color="white"
                    size="xs"
                    pointerEvents={"none"}
                >
                    {registered ? "REGISTERED" : "UNREGISTERED"}
                </Button>;
            },
        },
        {
            field: "include_for_clone",
            header: "Included in Test Data",
            props: { whiteSpace: "normal" },
            visible: () => showIncludeForClone(),
            getComponent: (row: Row<IPatient>) => {
                return <Badge
                    colorScheme={row.data.include_for_clone ? "green" : "gray"}
                    borderRadius="8px"
                    pointerEvents="none"
                    userSelect="none"
                >
                    {row.data.include_for_clone != undefined ? (row.data.include_for_clone ? "YES" : "NO") : ""}
                </Badge>;
            },
        },
    ];

    const rowActions: GridActionsRowButton<IPatient>[] = [
        {
            text: "Copy registration details",
            icon: () => <BiCopy />,
            handler: (row: Row<IPatient>) => {
                const patientRegistrationDetails = {
                    "surname": row.data.surname,
                    "dateOfBirth": row.data.date_of_birth,
                    "linkageKey": row.data.linkage_key,
                    "accountID": row.data.account_id,
                    "gpOdsCode": row.data.gp_ods_code
                };
                const patientRegistrationDetailsStr = JSON.stringify(patientRegistrationDetails, null, 4);
                navigator.clipboard.writeText(patientRegistrationDetailsStr);
                toast({
                    description: `Registration details of ${row.data.first_name} ${row.data.surname} copied to Clipboard.`,
                    duration: 2000,
                    isClosable: true,
                    status: "success",
                    position: "top-right"
                });
            },
        },
        {
            text: "Edit",
            type: IPermAction.Change,
            icon: () => <BiCog />,
            handler: (row: Row<IPatient>) => {
                setId(row.data.id);
                setIsVisible(true);
                const backRoute = props.showGenerateButton != undefined
                    ? `/app/convenet-integration/test-patients/${row.data.id}`
                    : `/app/patient/${row.data.id}`;
                navigate(backRoute);
            },
        },
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IPatient>) => {
                alertShow({
                    header: "Delete Patient?",
                    yes: "Delete",
                    message: `Do you want to permanently delete patient ${row.data.first_name || ""} ${row.data.surname || ""}?`,
                    onSuccess: () => {
                        patientService
                            .delete(row.data.id)
                            .then(() => setReloadKey((value) => value + 1));
                    },
                });
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "ADD PATIENT",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setId("");
                setIsVisible(true);
            },
        },
    ];

    if (props.showGenerateButton) {
        globalActions.unshift(
            {
                text: "GENERATE TEST PATIENTS",
                variant: "secondary",
                type: IPermAction.Add,
                handler: () => {
                    // TODO: ask Joaquin to modify clone endpoint to allow passing company field, at the moment if the user has impersonated_company
                    // TODO: then clone it's not possibly
                    patientCloneService.generateTestPatients()
                        .then((resp) => {
                            props.generateBtnHandler?.();
                            reloadGrid();
                        }).catch(err => {
                            console.log(err);
                        });

                }
            },
        );
    }

    const filters: IFilterSettings[] = [
        {
            field: "full_name",
            match: () => "icontains",
            getValue: () => filterFirstName, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant={"filter"}
                        type="text"
                        placeholder="Search by Name..."
                        value={filterFirstName}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setFilterFirstName(value);
                                reloadGrid(); // reload grid
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setFilterFirstName(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
        {
            field: "nhs_number",
            match: () => "icontains",
            getValue: () => filterNhsNumber, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant={"filter"}
                        type="text"
                        placeholder="Search by NHS Number..."
                        value={filterNhsNumber}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setFilterNhsNumber(value);
                                reloadGrid(); // reload grid
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setFilterNhsNumber(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
        {
            field: "gp__practice_name",
            match: () => "icontains",
            getValue: () => filterGPName, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant={"filter"}
                        type="text"
                        placeholder="Search by GP..."
                        value={filterGPName}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setFilterGPName(value);
                                reloadGrid(); // reload grid
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setFilterGPName(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
        {
            field: "link_status",
            match: () => (filterLinkStatus !== "" ? "exact" : "lt"),
            getValue: () => filterLinkStatus, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        variant={"filter"}
                        value={filterLinkStatus}
                        placeholder="Search by Status..."
                        onChange={({ target: { value } }) =>
                            setFilterLinkStatus(value)
                        }
                    >
                        <option value={1}>Show only Registered</option>
                        <option value={0}>Show only Unregistered</option>
                    </Select>
                </Flex>
            ),
        },
        {
            field: "gp__company__id",
            match: () => "exact",
            visible: () => false,
            getValue: () => filterCompany, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        variant={"filter"}
                        value={filterCompany}
                        placeholder="Search by Company..."
                        onChange={({ target: { value } }) =>
                            setFilterCompany(value)
                        }
                    >
                        {companies.map((company, idx) => {
                            return <option key={idx} value={company.id}>{company.name}</option>;
                        })}
                    </Select>
                </Flex>
            ),
        },
        {
            field: "include_for_clone",
            match: () => "exact",
            visible: () => showIncludeForClone(),
            enabled: () => showIncludeForClone(),
            getValue: () => filterInclude,
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        variant="filter"
                        value={filterInclude}
                        placeholder="Include for Clone..."
                        onChange={({ target: { value } }) =>
                            setFilterInclude(value)
                        }
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Select>
                </Flex>
            ),
        }
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setFilterFirstName("");
            setFilterNhsNumber("");
            setFilterGPName("");
            setFilterLinkStatus("");
            setFilterCompany(userData.impersonated_company || userData.company);
            // pass user company to TestPatient
            props.onChangeCompany?.(userData.impersonated_company || userData.company);
            setFilterInclude("");
            reloadGrid();
        },
        onApply: () => {
            reloadGrid();
            // pass selected company from filter to TestPatient
            props.onChangeCompany?.(filterCompany);
        }
    };

    const onFinishingLoadingGrid = () => {
        props.onChangeCompany?.(filterCompany);
    };

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<IPatient>
                    reloadKey={reloadKey}
                    columns={columns}
                    dataService={patientService}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    rowPreview={rowPreview}
                    filters={filters}
                    filtersActions={filtersActions}
                    isLoaded={onFinishingLoadingGrid}
                />
            </BoxCard>

            {/* Modal for Add Button */}
            {isVisible && (
                <CreateEditPatientModal
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    onSuccess={reloadGrid}
                    testPatients={testPatients}
                />
            )}
        </Box>
    );
};

export default Patients;
