import React, { memo, useState, useEffect } from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
    Select,
    Spacer,
    Stack,
    Text,
    FormControl,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
    InputFormik,
    SelectFormik
} from "components/fields";
import * as Yup from "yup";
import BoxCard from "components/card/Card";
import {
    ICompany,
    IIntegrationProduct,
    useIntegrationProductService,
    YesNoChoice,
    useUserService,
    useCompanyService,
    YesNoInProgressChoice
} from "services";
import { checkisSuitableODSCode, getCheckIsSuitableTickValue } from "views/admin/default/utils";
import { useAppStore } from "store";
import useTicks from "hooks/use-ticks";
import Prompt from "components/Prompt";

type MixedData = Partial<ICompany> & Partial<IIntegrationProduct>;

const blankData: MixedData = {
    direct_patient_care: undefined,
    free_to_use: undefined,
    ods_code_status: YesNoInProgressChoice.no, // ICompany
    nhs_sponsor: undefined
};

const formValidationSchema = Yup.object({
    direct_patient_care: Yup.number().oneOf([ 0, 1 ]).required(),
    free_to_use: Yup.boolean().required(),
    ods_code_status: Yup.number().oneOf([ 0, 1, 2 ]).required(),
    nhs_sponsor: Yup.boolean().required(),
});

function CheckIsSuitable() {
    const integrationProductService = useIntegrationProductService();
    const companyService = useCompanyService();

    const integrationProduct = useAppStore(state => state.integrationProduct);
    const company = useAppStore(state => state.company);

    const { updateIsSuitableTick } = useTicks();

    const [ mixedData, setMixedData ] = useState<MixedData>({
        direct_patient_care: integrationProduct.direct_patient_care,
        free_to_use: integrationProduct.free_to_use,
        ods_code_status: company.ods_code_status,
        nhs_sponsor: integrationProduct.nhs_sponsor
    });

    const [ isUpdating, setIsUpdating ] = useState(false);
    const [ modalMessage, setModalMessage ] = useState("");
    const [ showModal, setShowModal ] = useState(false);

    const onSubmit = async (values: MixedData, { setSubmitting, setErrors, resetForm }: FormikHelpers<MixedData>) => {
        let errors = {};
        setIsUpdating(true);

        const updateProduct = {
            direct_patient_care: values.direct_patient_care,
            free_to_use: values.free_to_use,
            nhs_sponsor: values.nhs_sponsor
        };

        if (integrationProduct.id) {
            await integrationProductService.update(
                integrationProduct.id,
                { ...updateProduct, version: integrationProduct.version },
                { showSuccessMessage: false }
            ).catch((error: any) => {
                errors = { ...errors, ...error };
            });
        } else {
            await integrationProductService.create(
                updateProduct,
                { showSuccessMessage: false }
            ).catch((error) => {
                errors = { ...errors, ...error };
            });
        }

        // only update company if ods_code_status field change
        if (company.ods_code_status !== values.ods_code_status) {
            const updateCompany = {
                ods_code_status: values.ods_code_status,
                version: company.version
            };

            company.id && await companyService.update(
                company.id,
                updateCompany,
                { showSuccessMessage: false }
            ).catch((error) => {
                errors = { ...errors, ...error };
            });
        }

        // update AppStore isSuitable tick
        updateIsSuitableTick();
        setIsUpdating(false);
        setSubmitting(false);

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // Reset Form imperatively, because dirty and isValid are not cleared
            resetForm({ values });
        }
    };

    const showSuitabilityModal = () => {
        const productName = integrationProduct.name || "Your Product";
        const isSuitable = useAppStore.getState().isSuitable;

        const okMessage = `${productName} is suitable for using the Convent API`;
        const almostOkMessage = `${productName} is suitable for using the Convent API, but you will need an ODS code before you can go Live`;
        const notOkMessage = `${productName} is probably not suitable for using the Convenet API`;

        setModalMessage(
            isSuitable ? okMessage
                : checkisSuitableODSCode(integrationProduct, company)
                    ? almostOkMessage
                    : notOkMessage
        );

        setShowModal(true);
    };

    return (
        <Box mt={6}>
            <Formik<MixedData>
                enableReinitialize={true}
                initialValues={mixedData}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<MixedData>) => (
                    <FormControl
                        as="form"
                        onSubmit={formik.handleSubmit as any}
                    >
                        <Prompt when={formik.dirty} message="You have unsaved data on this page. Want to leave anyway?" />
                        <BoxCard>
                            <Text size="text.md" fontWeight={"normal"} color="lightSecondaryText">
                                Your product will be using NHS information and will need to abide by the NHS rules.
                                Before you start development please complete the following
                            </Text>

                            <Box mt="3%" >
                                <Stack spacing={30}>
                                    {/* Is your product solely for the provision of Direct Patient Care? */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Is your product solely for the provision of Direct Patient Care?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="number"
                                                name="direct_patient_care"
                                                placeholder='Select option'
                                            >
                                                <option value={YesNoChoice.no}>No</option>
                                                <option value={YesNoChoice.yes}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>

                                    {/* Is Your Product Free for Patient Use? */}
                                    <Stack direction={{ base: "column", lg: "row" }}>
                                        <Text>Is Your Product Free for Patient Use?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="boolean"
                                                name="free_to_use"
                                                placeholder='Select option'
                                            >
                                                <option value={"false"}>No</option>
                                                <option value={"true"}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={{ base:"column", lg:"row" }}>
                                        <Text>Does your Company have an NHS ODS code or is in the process of obtaining one?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="number"
                                                name="ods_code_status"
                                                placeholder='Select option'
                                            >
                                                <option value={YesNoInProgressChoice.no}>No</option>
                                                <option value={YesNoInProgressChoice.yes}>Yes</option>
                                                <option value={YesNoInProgressChoice.in_progress}>In Progress</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={{ base:"column", lg:"row" }}>
                                        <Text>Does your product have an NHS sponsor?</Text>
                                        <Spacer />
                                        <Stack w={{ base: "100%", lg: "35%" }}>
                                            <SelectFormik
                                                type="boolean"
                                                name="nhs_sponsor"
                                                placeholder='Select option'
                                            >
                                                <option value={"false"}>No</option>
                                                <option value={"true"}>Yes</option>
                                            </SelectFormik>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Box>
                        </BoxCard>

                        <Flex direction={"row"} justifyContent={"end"} mt={5}>
                            <Button
                                variant={"primary"}
                                alignSelf="flex-end"
                                type="button"
                                onClick={async () => {
                                    if (formik.isValid && formik.dirty)
                                        await formik.submitForm();
                                    showSuitabilityModal();
                                }}
                                isLoading={isUpdating}
                                mr={4}
                            >
                                Check Suitability
                            </Button>
                            <Button
                                variant={"primary"}
                                alignSelf="flex-end"
                                type="submit"
                                isLoading={isUpdating}
                                isDisabled={!(formik.isValid && formik.dirty)}
                            >
                                Save
                            </Button>
                        </Flex>


                    </FormControl>
                )}
            </Formik>

            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Convenet Suitability Check</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        { modalMessage }
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => setShowModal(false)}>
                            OK
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default memo(CheckIsSuitable);
