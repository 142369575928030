import React from "react";

import { Skeleton, Stack } from "@chakra-ui/react";

const DefaultSkeleton = () => {
    return <Stack padding={4} spacing={1}>
        <Skeleton h='40px' >
        </Skeleton>
        <Skeleton
            h='40px'
            color='white'
            fadeDuration={1}
        >
        </Skeleton>
        <Skeleton
            h='40px'
            fadeDuration={4}
            color='white'
        >
        </Skeleton>
    </Stack>;
};

export default DefaultSkeleton;