import { mode } from "@chakra-ui/theme-tools";

export const CardComponent = {
    components: {
        BoxCard: {
            baseStyle: {
                p: "card",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                position: "relative",
                borderRadius: "card",
                minWidth: "0px",
                wordWrap: "break-word",
                backgroundClip: "border-box",
                boxShadow: "card",
            },

            defaultProps: {
                variant: "primary",
            },

            variants: {
                primary: () => ({
                    bg: "cardBG",
                }),
                table: () => ({
                    bg: "tableBG",
                }),
            }
        }
    },
};
