import React, { useContext, useState, useEffect, Fragment, useMemo, memo } from "react";
import {
    Text,
    Flex,
    IconButton,
    Button,
    Box,
    Checkbox,
    Tooltip,
    Input,
    Card,
} from "@chakra-ui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    EditIcon,
    DeleteIcon,
    TriangleUpIcon,
    TriangleDownIcon,
    EmailIcon,
    RepeatIcon,
    AddIcon,
} from "@chakra-ui/icons";

import {
    PagedGrid,
    GridColumn,
    SelectionMode,
    Row,
    RowPreview,
    GridActionsRowButton,
    GridActionsButton,
    IFilterSettings,
    IFiltersActions,
} from "components/paged-grid";

import {
    ICompany,
    IUser,
    IRole,
    UserEnum,
    IPermAction,
    useCompanyService,
    useRoleService,
    useUserService
} from "services";

import CreateEditUser from "./components/CreateEditUser";
import InviteUser from "./components/InviteUser";
import EditRoles from "./components/EditRoles";

import { useAlert } from "components/alert-modal";
import BoxCard from "components/card/Card";
import CustomMoment from "components/utils/CustomMoment";
import { FiMail } from "react-icons/fi";

const devMode = !!process.env.REACT_APP_DEVMODE;

export default function ManageUsers() {
    const companyService = useCompanyService();
    const roleService = useRoleService();
    const userService = useUserService();
    const [ isSuperUser ] = useState(userService.meIsSuperUser());
    const [ reloadKey, setReloadKey ] = useState(0);
    const [ companies, setCompanies ] = useState<ICompany[]>([] as any);
    const [ roles, setRoles ] = useState<IRole[]>([]);
    const [ id, setId ] = useState("");
    const [ isVisible, setIsVisible ] = useState(false);
    const [ isInviteVisible, setIsInviteVisible ] = useState(false);
    const [ isEditRolesVisible, setIsEditRolesVisible ] = useState(false);
    const [ showDeleted, setShowDeleted ] = useState(false);
    const [ filterEmail, setFilterEmail ] = useState("");
    const [ filterFirstName, setFilterFirstName ] = useState("");
    const [ filterLastName, setFilterLastName ] = useState("");
    const [ filterCompanyName, setFilterCompanyName ] = useState("");
    const alertShow = useAlert();

    const reloadGrid = () => {
        // Force PagedGrid to reload the data
        setReloadKey((value) => value + 1);
    };

    const columns: GridColumn[] = useMemo(() => [
        {
            field: "email",
            header: "E‑mail", // non-breakable hyphen
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">{row.data.email}</Text>;
            },
        },
        {
            field: "first_name",
            header: "First Name",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">
                    {row.data.first_name || "" }
                </Text>;
            },
        },
        {
            field: "last_name",
            header: "Last Name",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <Text as="span">
                    {row.data.last_name || "" }
                </Text>;
            },
        },
        {
            field: "company",
            header: "Company",
            getComponent: (row: Row<IUser>) => {
                return <Text>{companies.find((company) => row.data.company === company.id)?.name}</Text>;
            },
        },
        {
            field: "roles",
            header: "Roles",
            getComponent: (row: Row<IUser>) => {
                return <>
                    {row.data.roles.map((id, i) => <Text key={i}>{roles.find((role) => role.id === id)?.name}</Text>)}
                </>;
            },
        },
        {
            field: "date_joined",
            header: "Joined",
            sortable: true,
            getComponent: (row: Row<IUser>) => {
                return <CustomMoment date={row.data.date_joined} dateFormat="dd/MM/yyyy" />;
            },
        },
        /*{
            field: "last_login",
            header: "Last Logged at",
            getComponent: (row: Row<IUser>) => {
                if (!row.data.last_login)
                    return <></>;
                return <Moment format="DD/MM/yy, h:mm:ss a">{row.data.last_login}</Moment>;
            },
        },*/
        {
            field: "mfa_enabled",
            header: "MFA",
            props: { textAlign: "start" },
            getComponent: (row: Row<IUser>) => {
                return (
                    <Checkbox
                        isReadOnly={!row.data.mfa_enabled}
                        isChecked={row.data.mfa_enabled}
                        onChange={({ target: { checked } }) => {
                            if (!checked && isSuperUser) {
                                alertShow({
                                    header: "Warning",
                                    message: "Are you sure you want to disable MFA for this user? " +
                                             "Note that it can be re-enabled by the user himself only!",
                                    onSuccess: () => {
                                        userService.mfaDeactivateUser(row.data.id).then(() => setReloadKey((value) => value + 1));
                                    }
                                });
                            }
                        }}
                    />
                );
            },
        },
        {
            field: "is_superuser",
            header: "Superuser",
            props: { px: "4px", textAlign: "start" },
            getComponent: (row: Row<IUser>) => {
                return <Checkbox isReadOnly={true} isChecked={row.data.is_superuser} />;
            },
        },
        /*{
            field: "is_staff",
            header: "Staff",
            props: { px: "4px", textAlign: "center" },
            getComponent: (row: Row<IUser>) => {
                return <Checkbox isReadOnly={true} isChecked={row.data.is_staff} />
            },
        },*/
        {
            field: "status",
            header: "Status",
            props: { px: "4px", textAlign: "start" },
            getComponent: (row: Row<IUser>) => {
                return <Text>{UserEnum[row.data.status]}</Text>;
            },
        },
    ], [ companies, roles ]);

    const rowActions: GridActionsRowButton<IUser>[] = useMemo(() => [
        {
            text: "Resend Invite",
            icon: () => <FiMail />,
            variant: "primary",
            type: IPermAction.Add,
            visible: (row: Row<IUser>) => row.data.status === UserEnum.Invited,
            handler: (row: Row<IUser>) => {
                userService.resendInvite(row.data.id);
            },
        },
        {
            text: "Edit",
            type: IPermAction.Change,
            visible: () => !showDeleted,
            handler: (row: Row<IUser>) => {
                setId(row.data.id);
                setIsVisible(true);
            },
        },
        {
            text: "Delete",
            type: IPermAction.Delete,
            visible: (row: Row<IUser>) => !showDeleted && row.data.status !== UserEnum.Invited,
            handler: (row: Row<IUser>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete a user?",
                    onSuccess: () => {
                        userService.setIsDeleted(row.data.id, true).then(() => setReloadKey((value) => value + 1));
                    }
                });
            },
        },
        {
            text: "Cancel Invite",
            type: IPermAction.CancelInvite,
            visible: (row: Row<IUser>) => !showDeleted && row.data.status === UserEnum.Invited,
            handler: (row: Row<IUser>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to cancel an invitation? This will permanently delete the user.",
                    onSuccess: () => {
                        userService.inviteCancel(row.data.id).then(() => setReloadKey((value) => value + 1));
                    }
                });
            },
        },
        {
            text: "Restore",
            type: IPermAction.Delete,
            visible: () => showDeleted,
            handler: (row: Row<IUser>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to restore a user?",
                    onSuccess: () => {
                        userService.setIsDeleted(row.data.id, false).then(() => setReloadKey((value) => value + 1));
                    }
                });
            },
            icon: () => <RepeatIcon />,
        },
    ], [ showDeleted ]);

    const globalActions: GridActionsButton[] = useMemo(() => [
        {
            text: "Invite User",
            variant: "secondary",
            type: IPermAction.Add,
            handler: () => {
                setIsInviteVisible(true);
            },
        },
        {
            text: "Edit Roles",
            variant: "secondary",
            visible: () => devMode && userService.meHasPemissions(roleService.model, IPermAction.Change),
            handler: () => {
                setIsEditRolesVisible(true);
            },
        },
    ], []);

    const filters: IFilterSettings[] = [
        {
            field: "is_active",
            getValue: () => !showDeleted,
            visible: () => isSuperUser,
            getComponent: () => <Flex my="8px">
                <Checkbox
                    colorScheme="gray"
                    bgColor="transparent"
                    minW="120px" mr="8px"
                    isChecked={showDeleted}
                    onChange={({ target: { checked } }) => {
                        setShowDeleted(checked);
                        reloadGrid(); // reload grid with new filter
                    }}
                >
                    Show Deleted
                </Checkbox>
            </Flex>
        },
        {
            field: "email",
            match: () => "icontains",
            getValue: () => filterEmail, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Email"
                    value={filterEmail}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterEmail(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterEmail(value); }}
                ></Input>
            </Flex>
        },
        {
            field: "first_name",
            match: () => "icontains",
            getValue: () => filterFirstName, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="First Name"
                    value={filterFirstName}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterFirstName(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterFirstName(value); }}
                ></Input>
            </Flex>
        },
        {
            field: "last_name",
            match: () => "icontains",
            getValue: () => filterLastName, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Last Name"
                    value={filterLastName}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterLastName(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterLastName(value); }}
                ></Input>
            </Flex>
        },
        {
            field: "company__name", // user.company.name
            match: () => "icontains",
            getValue: () => filterCompanyName, // "" === no filter
            getComponent: () => <Flex my="8px">
                <Input
                    variant="filter"
                    type="text"
                    placeholder="Company Name"
                    value={filterCompanyName}
                    onKeyDown={({ type, key, target: { value } }: any) => {
                        if (type === "keydown" && key === "Enter") {
                            setFilterCompanyName(value);
                            reloadGrid();
                        }
                    }}
                    onChange={({ target: { value } }) => { setFilterCompanyName(value); }}
                ></Input>
            </Flex>
        },
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setShowDeleted(false);
            setFilterEmail("");
            setFilterCompanyName("");
            setFilterFirstName("");
            setFilterLastName("");
            reloadGrid();
        },
        onApply: () => reloadGrid()
    };

    const load = async () => {
        const _companies = await companyService.getAll();
        const _roles = await userService.getAllRoles();

        setCompanies(_companies);
        setRoles(_roles);
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<IUser>
                    reloadKey={reloadKey}
                    dataService={userService}
                    columns={columns}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    filters={filters}
                    filtersActions={filtersActions}
                />
            </BoxCard>

            <CreateEditUser
                id={id}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                onSuccess={reloadGrid}
                companies={companies}
                roles={roles}
            />

            <InviteUser
                isVisible={isInviteVisible}
                setIsVisible={setIsInviteVisible}
                onSuccess={reloadGrid}
                companies={companies}
                roles={roles}
            />

            {devMode && <EditRoles
                isVisible={isEditRolesVisible}
                setIsVisible={setIsEditRolesVisible}
                onSuccess={() => true}
            />}
        </Box>
    );
}
