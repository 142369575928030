import React, { useState, useReducer, useContext, useEffect } from "react";
import {
    Text,
    IconButton,
    Button,
    Box,
    useDisclosure
} from "@chakra-ui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    EditIcon,
    DeleteIcon,
    TriangleUpIcon,
    TriangleDownIcon,
    AddIcon,
} from "@chakra-ui/icons";
import { IGetQueryResultsResponse, IStartQueryParams, useLogService } from "services";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import merge from "lodash/merge";

interface Props extends IStartQueryParams {
    label: string,
}

const defaultOptions: ApexOptions = {
    chart: {
        //height: 350,
        type: "radialBar",
        background: "#fff",
        /*events: {
            mouseMove(e, chart, options) {
                console.log("mouse moved")
            },
        },
        toolbar: {
            show: true,
        },
        zoom: {
            enabled: false
        }*/
    },
    //series: [67], // overriden
    colors: [ "#6bae62" ],
    plotOptions: {
        radialBar: {
            startAngle: -110,
            endAngle: 110,
            track: {
                show: true,
                startAngle: -110,
                endAngle: 110,
                background: "#333",
                strokeWidth: "116%",
                margin: 0,
                dropShadow: {
                    enabled: true,
                    color: "#22252b",
                    top: 0,
                    left: 0,
                    blur: 4,
                    opacity: 0.4
                }
            },
            hollow: {
                size: "35%",
                background: "#f2f2f2",
            },
            dataLabels: {
                name: {
                    show: true,
                    fontSize: "14px",
                    offsetY: 64
                },
                value: {
                    show: true,
                    fontWeight: 700,
                    fontSize: "18px",
                    offsetY: 10,
                    formatter: (val: number) => "no data",
                }
            }
        }
    },
    fill: {
        type: "solid",
        colors: [ "#6bae62" ],
    },
    labels: [ "" ],
};

export default function RadialBarChart(props: Props) {
    const { queryString, logGroupName, startTime, endTime, label } = props;
    const logService = useLogService();
    const [ key, setKey ] = useState(0);
    const [ value, setValue ] = useState(0);
    const [ options, updateOptions ] = useReducer((state: ApexOptions, action: Partial<ApexOptions>) => {
        merge(state, action);
        return { ...state };
    }, { ...defaultOptions, labels: [ label ] });

    const CheckLogData = async () => {
        const updateResult = (result: IGetQueryResultsResponse) => {
            const { recordsMatched, recordsScanned } = result.statistics;

            setKey((val) => val + 1);
            setValue(recordsScanned ? Math.round(recordsMatched * 100 / recordsScanned) : 0);
            updateOptions({
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            value: {
                                color: "#6bae62",
                                formatter: (val: number) => String(recordsMatched)//`${recordsMatched}/${recordsScanned}`
                            }
                        }
                    }
                }
            });
        };

        try {
            const result = await logService.runQuery2({
                queryString, logGroupName, startTime, endTime
            }, (res) => updateResult(res));

            updateResult(result);
        } catch (error) {
        }
    };

    useEffect(() => {
        CheckLogData();
    }, [ startTime, endTime ]);

    return <>
        <Box
            borderRadius="8px"
            m="4px"
            overflow="hidden"
        >
            <ReactApexChart
                key={key}
                options={options}
                series={[ value ]}
                type="radialBar"
                width="300px"
            />
        </Box>
    </>;
}
