import { useBaseService, TOptions } from "./crud.service";
import { TAuthData, TGoogleData, TRegData, TRegDataConfirm, IMfaResponse, IAuthDataMfa } from "./auth.type";

import objectToFormData from "./obj-toformdata";
import { IFilterCommand } from "components/paged-grid/filter.interface";

export enum DocumentTypeChoice {
    incident_management_log = 0,
    hazard_log = 1,
    clinical_safety = 2,
    compliance_assessment = 3,
    screenshot = 4
}

export interface IFileUploadRequest {
    file: File;
    file_name: string;
    company: string;
    type?: DocumentTypeChoice;
    version?: number;
}

export interface IFileUploadResponse {
    id: string;
    file_name: string;
    remote_file_name: string;
    company: string;
    temp_url: string;
    type?: DocumentTypeChoice;
    version?: number;
}

const fileService = (function() {
    const base = useBaseService();
    const url = "/api/mock/file/";

    const getAll = (filterCommand?: IFilterCommand): Promise<IFileUploadResponse[]> => {
        return base._get<IFileUploadResponse[]>(url, { payload: filterCommand });
    };

    const uploadFile = (item: IFileUploadRequest, options?: Partial<TOptions>): Promise<IFileUploadResponse> => {
        return base._post(url, {
            payload: objectToFormData(item),
            successMessage: "File Uploaded!",
            ...options
        });
    };

    const replaceFile = (id: string, item: IFileUploadRequest, options?: Partial<TOptions>): Promise<IFileUploadResponse> => {
        return base._patch(`${url}${id}/`, {
            payload: objectToFormData(item),
            successMessage: "File Replaced!",
            ...options
        });
    };

    const deleteFile = (id: string) => {
        return base._delete(`${url}${id}/`, { successMessage: "File Deleted!" });
    };

    return {
        uploadFile,
        replaceFile,
        getAll,
        deleteFile
    };
})();

export const useFileService = () => fileService;
