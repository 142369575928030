import React, { memo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Heading, Link, useColorModeValue } from "@chakra-ui/react";
import { BsCheck2 } from "react-icons/bs";
import { IoEllipsisHorizontalCircle, IoTriangle } from "react-icons/io5";

import BoxCard from "components/card/Card";
import { ClipboardCodeIcon } from "../convenet-integration/FigmaIcons";
import { useAppStore } from "store";

const DevelopCard = () => {
    const isSuitable = useAppStore((state) => state.isSuitable);
    const apiCredentialsGenerated = useAppStore((state) => state.apiCredentialsGenerated);

    const textLink = "TBO.5";
    const iconColor = useColorModeValue("BOD.2", "CG.1");
    const arrowIconColor = "TBO.5";
    const iconCheckColor = "#11A04A";
    const iconUncheckColor = "#DBDBDB";

    return <BoxCard mr={{ base: "0", md: "8" }} mb={{ base: "5", md: "0" }} minH="530px" h="100%" position="relative">
        <Box margin={"1vh auto"}>
            <ClipboardCodeIcon boxSize={24} color={iconColor} />
        </Box>

        <Flex justify="center" align={"center"} direction="column" >
            <Heading fontSize="text.lg" color="textDark" lineHeight="regular">
            Develop
            </Heading>
            <Heading fontSize="text.sm" fontWeight={"normal"} color={textLink} lineHeight="regular">Stage 1</Heading>
        </Flex>

        <Flex direction={"column"} margin="1em auto" gap={"5"}>
            {/* Check Convenet is Suitable */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { isSuitable
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/check-suitable"}
                >
                    Check Convenet is Suitable
                </Link>
            </Flex>

            {/* Generate API Credentials */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { apiCredentialsGenerated
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/api-credentials"}
                >
                    Generate API Credentials
                </Link>
            </Flex>

            {/* Develop against the sandbox */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                <Link as={RouterLink} to={"/app/convenet-integration/develop"}>
                    Develop against the sandbox
                </Link>
            </Flex>

            {/* Invite Team */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/team"}
                >
                Invite Team
                </Link>
            </Flex>
        </Flex>
        <Box position="absolute" color="TBO.2" top={{ base: "unset", md:"50%" }} right={{ base:"unset", md:"-23px" }} left={{ base: "49%", md:"unset" }} bottom={{ base:"-18px", md: "unset" }} transform={{ base:"rotate(180deg)", md:"rotate(90deg)" }}  >
            <IoTriangle size="15px" rotate="45" />
        </Box>
    </BoxCard>;
};

export default memo(DevelopCard);
