import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Spacer, Stack, Text, Link, VStack } from "@chakra-ui/react";

import BoxCard from "components/card/Card";

const DevelopIntegration = () => {
    const textLink = "TBO.5";

    const apiDocumentationLink = process.env.REACT_APP_API_DOC_URL || "https://developer.convenet.io/convenet-documentation/";
    const apiWIKILink = process.env.REACT_APP_WIKI_URL || "https://docs.convenet.io/";
    const apiCredentialsRoute = "/app/convenet-integration/api-credentials";
    const testPatientsRoute = "/app/convenet-integration/test-patients";
    const testGPRoute = "/app/convenet-integration/test-gps";
    const tokenEndpointLink = process.env.REACT_APP_TOKEN_ENDPOINT_LINK || "#";
    const postmanCollectionLink = `https://${process.env.REACT_APP_DOWNLOAD_BUCKET}.s3.eu-west-2.amazonaws.com/downloads/Convenet+API.postman_collection.json` || "#";
    const apiEndpointLink = process.env.REACT_APP_API_ENDPOINT_LINK  || "#";

    const getRouterLink = (routePath: string, linkText = "here") => {
        return (
            <Link
                as={RouterLink}
                to={routePath}
                color={textLink}
            >
                { linkText }
            </Link>
        );
    };

    const getLink = (link: string, linkText = "here") => {
        return (
            <Link
                as="a"
                isExternal
                color={textLink}
                href={ link }
            >
                { linkText }
            </Link>
        );
    };

    return <Box mt={6}>
        <BoxCard>
            <Box>
                <Stack gap={2}>
                    <VStack alignItems={"left"}>
                        <Text>Details of the API are { getLink(apiDocumentationLink) } </Text>
                        <Spacer />

                        <Text>How to use the API in practice is explained { getLink(apiWIKILink) } </Text>
                        <Spacer />

                        <Text>
                            The API is RESTful and use OAuth 2.0 authorisation, you can get your OAuth
                            credentials { getRouterLink(apiCredentialsRoute) }.
                        </Text>
                        <Text>
                            The token endpoint is: <Text as="span" color={textLink}>{tokenEndpointLink}</Text>
                        </Text>
                        <Text>
                            The API endpoint is: <Text as="span" color={textLink}>{apiEndpointLink}</Text>
                        </Text>
                        <Spacer />

                        <Text>Patients to test your application with are { getRouterLink(testPatientsRoute) }</Text>
                        <Spacer />

                        <Text>If your application needs to access a Patient&apos;s medical records, medication or appointments the Patient&apos;s GP can
                            restrict access to this data.  To test that your application will handle this situation, you can
                            change your { getRouterLink(testGPRoute, "GPs settings") } to simulate different restrictions. </Text>
                        <Spacer />
                        <Text>Initially why not try our
                            <Link as="a"
                                ml={1}
                                color={textLink}
                                href={postmanCollectionLink}
                            >
                                Postman Collection
                            </Link>
                        </Text>
                    </VStack>
                </Stack>
            </Box>
        </BoxCard>
    </Box>;
};

export default DevelopIntegration;
