import React, { useState, useEffect } from "react";
import { useLocation, Outlet, matchRoutes } from "react-router-dom";
import { authRoutes } from "routes";

import {
    Box,
    Flex,
    Icon,
    Text,
    Image,
} from "@chakra-ui/react";
import SidebarProvider, { SidebarContext } from "contexts/SidebarContext";
import Footer from "components/footer/FooterAdmin";
import ThemeSwitchButton from "components/theme-switch-button/ThemeSwitchButton";

import { FaKey } from "react-icons/fa";
import authBGImage from "assets/img/auth/auth-bg.png";
import logoDark from "assets/img/logo/logo-on-dark.svg";

export default function AuthLayout(): JSX.Element {
    const [ authTitle, setAuthTitle ] = useState("");
    const buttonBackgroundColor = "linear-gradient(3.14deg, #85E6FF 2.79%, #4FD1C5 97.6%)";
    const location = useLocation();

    useEffect(() => {
        const route = matchRoutes(authRoutes, location)?.filter(m => m.route.handle)?.map(m => m.route)[0];

        if (!route?.handle)
            return;

        const name = route?.handle?.name || "";

        setAuthTitle(name);
        if (name) {
            (document.head.querySelector("title") as any).innerHTML = `Convenet Portal - ${name}`;
        }
    }, [ location ]);

    return (
        <Box as="main">
            <SidebarProvider>
                <Box
                    bg="themeBG"
                    float="right"
                    minHeight="100vh"
                    height="100%"
                    position="relative"
                    w="100%"
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease"
                >
                    <Box mx="auto" minH="100vh">
                        <Flex
                            position="relative"
                            h="max-content"
                            backgroundColor="themeBG"
                        >
                            <Flex
                                minH="100vh"
                                w="100%"
                                mx="auto"
                                pt={{ sm: "50px", md: "0px" }}
                                px={{ lg: "30px", xl: "0px" }}
                                justifyContent="center"
                                direction="column"
                            >
                                <Box
                                    position="absolute"
                                    h="50vh"
                                    w="96%"
                                    top="20px"
                                    left="2%"
                                    backgroundImage={authBGImage}
                                    backgroundSize="cover"
                                    backgroundPosition="center"
                                    borderRadius="card"
                                    zIndex="1"
                                    px="12"
                                    py="10"
                                >
                                    <Flex as="header" justify="space-between" align="center">
                                        <Image src={logoDark} alt="logo" h="66px" color="white" />
                                    </Flex>
                                </Box>

                                <Outlet />

                            </Flex>
                        </Flex>
                    </Box>
                </Box>
                {/* <ThemeSwitchButton /> */}
                <Footer />
            </SidebarProvider>
        </Box>
    );
}
