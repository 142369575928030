import { MultiSelectTheme } from "components/MultiSelect";

export const multiSelectStyles = {
    components: {
        MultiSelect: {
            ...MultiSelectTheme,
            baseStyle: (props: Record<string, any>) => ({
                ...MultiSelectTheme.baseStyle(props),
                control: {
                    ...MultiSelectTheme.baseStyle(props).control,
                    borderRadius: "16px",
                },
                _focus: {
                    border: "2px solid",
                    borderColor: "navy.400"
                },
            })
        }
    }
};
