import React, { useState, useMemo } from "react";

import { Box, Flex, Text } from "@chakra-ui/react";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    PagedGrid,
    Row,
    SelectionMode,
    IFilterSettings,
    SortOrder,
} from "components/paged-grid";
import {
    IGP,
    IAppointmentSlot,
    SlotTypeStatusChoice,
    IPermAction,
    useAppointmentSlotService
} from "services";
import { differenceInMinutes } from "date-fns";
import CustomMoment from "components/utils/CustomMoment";
import { useAlert } from "components/alert-modal";
import AddAppointmentSlotModal from "./AddAppointmentSlotModal";

type Props = {
    activeGP: IGP;
};

export default function AppointmentSlots(props: Props) {
    const { activeGP } = props;
    const appointmentSlotService = useAppointmentSlotService();
    const alertShow = useAlert();
    const [ isVisibleAddAppSlot, setIsVisibleAddAppSlot ] = useState(false);
    const [ key, setKey ] = useState(0);
    const reloadGrid = () => setKey((value) => value + 1);

    const columns: GridColumn[] = useMemo(() => [
        {
            field: "start_time",
            header: "Start Time",
            sortable: true,
            sortDir: SortOrder.Asc,
            getComponent: (row: Row<IAppointmentSlot>) => {
                return (
                    <Box my={3}>
                        <CustomMoment date={row.data.start_time} dateFormat="dd/MM/yyyy hh:mm aaa" utc={true} />
                    </Box>
                );
            },
        },
        {
            field: "end_time",
            header: "Duration mins",
            getComponent: (row: Row<IAppointmentSlot>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text>{differenceInMinutes(new Date(row.data.end_time), new Date(row.data.start_time))}</Text>
                        </Box>
                    </Flex>
                );
            },
        },
        {
            field: "slot_type_name",
            header: "Name",
            getComponent: (row: Row<IAppointmentSlot>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text>{row.data.slot_type_name || "-"}</Text>
                        </Box>
                    </Flex>
                );
            },
        },
        {
            field: "slot_type_status",
            header: "Status",
            getComponent: (row: Row<IAppointmentSlot>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text>{row.data.slot_type_status || "-"}</Text>
                        </Box>
                    </Flex>
                );
            },
        },
        {
            field: "session_type",
            header: "Type",
            //props: { w: "100%" },
            getComponent: (row: Row<IAppointmentSlot>) => {
                return (
                    <Flex alignItems={"center"}>
                        <Box>
                            <Text>{row.data.session_type || "-"}</Text>
                        </Box>
                    </Flex>
                );
            },
        },
    ], []);

    const globalActions: GridActionsButton[] = [
        {
            text: "Add Appointment Slot",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setIsVisibleAddAppSlot(true);
            },
        },
    ];

    const rowActions: GridActionsRowButton<IAppointmentSlot>[] = [
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IAppointmentSlot>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete the appointment?",
                    onSuccess: () => {
                        appointmentSlotService.delete(row.data.id).then(() => reloadGrid());
                    }
                });
            },
        },
    ];

    const filters: IFilterSettings[] = useMemo(() => [
        {
            field: "gp",
            visible: () => false,
            getValue: () => activeGP.id,
            getComponent: () => <></>,
        },
        {
            field: "start_time",
            match: () => "gt",
            visible: () => false,
            getValue: () => { const date = new Date(); date.setHours(0, 0, 0, 0); return date; },
            getComponent: () => <></>,
        },
    ], [ activeGP ]);

    return (
        <>
            <Box>
                <PagedGrid<IAppointmentSlot>
                    reloadKey={key}
                    columns={columns}
                    dataService={appointmentSlotService}
                    selectionMode={SelectionMode.None}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    filters={filters}
                />
            </Box>
            {isVisibleAddAppSlot && (
                <AddAppointmentSlotModal
                    isVisible={isVisibleAddAppSlot}
                    onClose={() => setIsVisibleAddAppSlot(false)}
                    onSave={() => {
                        setIsVisibleAddAppSlot(false);
                        reloadGrid();
                    }}
                    gpId={activeGP.id}
                />
            )}
        </>
    );
}
