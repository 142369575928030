import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    IconButton,
    Input,
    Select,
    Text,
    Card
} from "@chakra-ui/react";

import {
    GridActionsButton,
    GridActionsRowButton,
    GridColumn,
    IFiltersActions,
    IFilterSettings,
    PagedGrid,
    Row,
    RowPreview,
    SelectionMode,
} from "components/paged-grid";

import CreateEditGP from "./components/CreateEditGP";
import { useAlert } from "components/alert-modal";
import { ICompany, IGP, IPermAction, useCompanyService, useGPService, useUserService } from "services";
import BoxCard from "components/card/Card";

const rowPreview: RowPreview<IGP> = {
    name: "Row data preview",
    height: "80px",
    visible: (row: Row<IGP>) => true,
    getComponent: (row: Row<IGP>) => (
        <Box textAlign="center" position="relative">
            <Box
                backgroundColor={"secondaryGray.100"}
                borderRadius="8px"
                position="relative"
                top="4px"
            >
                Row data preview:
                <br />
                <strong>ID</strong>: {row.data.id}
            </Box>
        </Box>
    ),
};

type Props = {
    testGPs?: boolean;
};

export default function ManageGPs(props: Props) {
    const { testGPs = false } = props;
    const navigate = useNavigate();
    const companyService = useCompanyService();
    const gpService = useGPService();
    const { userData } = useUserService();
    const [ key, setKey ] = useState(0);
    const [ id, setId ] = useState("");
    const [ isVisible, setIsVisible ] = useState(false);
    const [ companies, setCompanies ] = useState<ICompany[]>([] as any);
    const [ gp, setGp ] = useState<IGP>({} as IGP);

    const [ practiceName, setPracticeName ] = useState("");
    const [ filterGpSystemType, setFilterGpSystemType ] = useState("");
    const [ filterCompany, setFilterCompany ] = useState(
        testGPs ? (userData.impersonated_company || userData.company) : ""
    );
    const [ filterODSCode, setFilterODSCode ] = useState("");

    const alertShow = useAlert();
    const reloadGrid = () => setKey((value) => value + 1);

    useEffect(() => {
        companyService.getAll().then((companies) => setCompanies(companies));
    }, []);

    const columns: GridColumn[] = [
        {
            field: "practice_name",
            header: "Practice Name",
            getComponent: (row: Row<IGP>) => {
                return <Text as="span">{row.data.practice_name}</Text>;
            },
        },
        {
            field: "ods_code",
            header: "ODS Code",
            getComponent: (row: Row<IGP>) => {
                return <Text as="span">{row.data.ods_code}</Text>;
            },
        },
        {
            field: "system",
            header: "System",
            getComponent: (row: Row<IGP>) => {
                return <Text as="span">{row.data.system}</Text>;
            },
        },
        {
            field: "company",
            header: "Company",
            getComponent: (row: Row<IGP>) => {
                return <Text>{companies.find((company) => row.data.company === company.id)?.name}</Text>;
            },
        },
    ];

    const rowActions: GridActionsRowButton<IGP>[] = [
        {
            text: "Edit",
            type: IPermAction.Change,
            handler: (row: Row<IGP>) => {
                setId(row.data.id);
                setGp(row.data);
                if (testGPs) {
                    navigate(`/app/convenet-integration/test-gps/${row.data.id}/`);
                } else {
                    setIsVisible(true);
                }
            },
        },
        {
            text: "Delete",
            type: IPermAction.Delete,
            handler: (row: Row<IGP>) => {
                alertShow({
                    header: "Warning",
                    message: "Are you sure you want to delete the GP?",
                    onSuccess: () => {
                        gpService.delete(row.data.id).then(() => reloadGrid());
                    }
                });
            },
        },
    ];

    const globalActions: GridActionsButton[] = [
        {
            text: "Add",
            variant: "primary",
            type: IPermAction.Add,
            handler: () => {
                setId("");
                setGp({} as IGP);
                setIsVisible(true);
            },
        },
    ];

    const filters: IFilterSettings[] = [
        {
            field: "practice_name",
            match: () => "icontains",
            getValue: () => practiceName,
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant="filter"
                        type="text"
                        placeholder="Practice Name..."
                        value={practiceName}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setPracticeName(value);
                                reloadGrid(); // reload grid
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setPracticeName(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
        {
            field: "system",
            match: () => filterGpSystemType !== "" ? "exact" : "lt",
            getValue: () => filterGpSystemType, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        w="270px"
                        mr="8px"
                        bg="fieldBG"
                        color="text"
                        borderRadius="card"
                        value={filterGpSystemType}
                        placeholder="Search by GP System Type..."
                        onChange={({ target: { value } }) =>
                            setFilterGpSystemType(value)
                        }
                    >
                        <option value={"EMIS"}>EMIS</option>
                        <option value={"Vision"}>Vision</option>
                        <option value={"TPP"}>TPP</option>
                    </Select>
                </Flex>
            ),
        },
        {
            field: "company",
            match: () => "exact",
            visible: () => !testGPs,
            getValue: () => filterCompany, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Select
                        variant={"filter"}
                        value={filterCompany}
                        placeholder="Search by Company..."
                        onChange={({ target: { value } }) =>
                            setFilterCompany(value)
                        }
                    >
                        {companies.map((company, idx) => {
                            return <option key={idx} value={company.id}>{company.name}</option>;
                        })}
                    </Select>
                </Flex>
            ),
        },
        {
            field: "ods_code",
            match: () => "icontains",
            getValue: () => filterODSCode, // "" === no filter
            getComponent: () => (
                <Flex my="8px">
                    <Input
                        variant="filter"
                        w="230px"
                        type="text"
                        placeholder="Search by ODS Code..."
                        value={filterODSCode}
                        onKeyDown={({ type, key, target: { value } }: any) => {
                            if (type === "keydown" && key === "Enter") {
                                setFilterODSCode(value);
                                reloadGrid(); // reload grid
                            }
                        }}
                        onChange={({ target: { value } }) => {
                            setFilterODSCode(value);
                        }}
                    ></Input>
                </Flex>
            ),
        },
    ];

    const filtersActions: IFiltersActions = {
        onClear: () => {
            setPracticeName("");
            setFilterGpSystemType("");
            !testGPs && setFilterCompany("");
            setFilterODSCode("");
            reloadGrid();
        },
        onApply: () => reloadGrid()
    };

    return (
        <Box mt={6}>
            <BoxCard variant="table">
                <PagedGrid<IGP>
                    reloadKey={key}
                    columns={columns}
                    dataService={gpService}
                    rowActions={rowActions}
                    globalActions={globalActions}
                    selectionMode={SelectionMode.None}
                    //rowPreview={rowPreview}
                    filters={filters}
                    filtersActions={filtersActions}
                />
            </BoxCard>

            <CreateEditGP
                id={id}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                gpData={gp}
                onSuccess={reloadGrid}
            />
        </Box>
    );
}
