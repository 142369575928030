import React, { useState, useEffect } from "react";

import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    useDisclosure,
    Text,
    ModalBody,
    FormControl,
    Flex,
    Button,
    ModalFooter,
    VStack,
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import TextFieldFormik from "components/fields/TextFieldFormik";
import DropDownFormik from "components/fields/DropdownFormik";
import TextAreaFormik from "components/fields/TextAreaFormik";
import {
    IAppointment,
    SlotTypeStatusChoice,
    usePatientService,
    useAppointmentService,
    IPatient
} from "services";
import { format } from "date-fns";

const formValidationSchema = Yup.object({
    start_time: Yup.string().required("Required"),
    duration: Yup.number().required("Required"),
    // end_time: Yup.string().nullable(),
    booking_reason: Yup.string()
        .max(200, "Must be at most 200 characters.")
        .nullable(),
    slot_type_name: Yup.string()
        .max(20, "Must be at most 20 characters.")
        .nullable(),
    slot_type_status: Yup.string()
        .oneOf([ "Unknown", "Practice", "Telephone", "Visit", "Video" ])
        .nullable(),
    session_type: Yup.string()
        .max(20, "Must be at most 20 characters.")
        .nullable(),
});

interface IAppointmentExt extends Partial<IAppointment> {
    duration: number;
}

const blankApoointment: IAppointmentExt = {
    start_time: new Date(),
    // end_time: "",
    duration: 0,
    booking_reason: "",
    slot_type_name: "",
    slot_type_status: SlotTypeStatusChoice.unknown,
    session_type: "",
    patient: "",
};

type Props = {
    isVisible: boolean;
    onClose: () => void;
    onSave: () => void;
    gpId?: string;
    patientId?: string;
};

export default function AddAppointmentModal(props: Props) {
    const appointmentService = useAppointmentService();
    const patientService = usePatientService();
    const { patientId, gpId } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ initialFormValues ] = useState({
        ...blankApoointment,
        ...(patientId ? { patient: patientId } : {})
    });
    const [ patients, setPatients ] = useState<IPatient[]>([] as any);

    useEffect(() => {
        if (patientId)
            return;

        patientService.getAll({ gp: gpId }).then((patients) => {
            setPatients(patients);
        });
    }, [ patientId, gpId ]);

    const closeHandler = () => {
        props.onClose();
    };

    const onSubmit = (values: IAppointmentExt, { setSubmitting, setErrors }: FormikHelpers<IAppointmentExt>) => {
        setIsLoading(true);
        const start_time = zonedTimeToUtc(values.start_time || "", "UTC");

        const startTimeDate = start_time; //values.start_time ? new Date(values.start_time) : new Date();
        const startTimeInMiliseconds = startTimeDate.getTime();
        const durationInMiliseconds = values.duration * 60000;
        const endTime = new Date(startTimeInMiliseconds + durationInMiliseconds);
        const appointment: IAppointment = {
            ...values as IAppointment,
            start_time: startTimeDate,
            end_time: endTime,
        };

        appointmentService.create(appointment).then((response) => {
            props.onSave?.();
        }).catch((error) => {
            setErrors(error);
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    };

    const _minDate = format(new Date(), "yyyy-MM-dd hh:mm");

    return (
        <Modal
            size={"lg"}
            isOpen={props.isVisible}
            onClose={closeHandler}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text color="text">Book Appointment</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik<IAppointmentExt>
                        initialValues={initialFormValues}
                        validationSchema={formValidationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik: FormikProps<IAppointmentExt>) => (
                            <FormControl
                                as="form"
                                onSubmit={formik.handleSubmit as any}
                            >
                                <VStack spacing={4} align="stretch">
                                    <TextFieldFormik
                                        type="datetime-local"
                                        label="Start Time"
                                        name="start_time"
                                        min={_minDate}
                                        mb="0px"
                                        placeholder="Start Time"
                                    />
                                    <TextFieldFormik
                                        type="number"
                                        label="Duration (mins)"
                                        name="duration"
                                        placeholder="Duration (mins)"
                                        mb="0px"
                                    />
                                    {!patientId && <DropDownFormik
                                        label="Patient"
                                        name="patient"
                                        placeholder="Select a Patient"
                                    >
                                        {patients.map((patient) =>
                                            <option key={patient.id} value={patient.id}>
                                                {`${patient.first_name} ${patient.surname}`}
                                            </option>
                                        )}
                                    </DropDownFormik>}
                                    <TextFieldFormik
                                        type="text"
                                        label="Slot type name"
                                        name="slot_type_name"
                                        placeholder="Slot type name"
                                    />
                                    <TextAreaFormik
                                        type="text"
                                        label="Booking Reason"
                                        name="booking_reason"
                                        h={"80px"}
                                        placeholder="Booking Reason"
                                    />
                                    <DropDownFormik
                                        label="Slot type status"
                                        name="slot_type_status"
                                        placeholder="Select a status"
                                    >
                                        {Object.values(SlotTypeStatusChoice).map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </DropDownFormik>
                                    <TextFieldFormik
                                        type="text"
                                        label="Session type"
                                        name="session_type"
                                        placeholder="Session type"
                                    />
                                    <Flex
                                        align="center"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            isLoading={isLoading}
                                            variant="secondary"
                                            onClick={closeHandler}
                                            mr={3}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            isLoading={isLoading}
                                            variant="primary"
                                            type="submit"
                                            isDisabled={!(formik.isValid && formik.dirty)}
                                        >
                                            Save
                                        </Button>
                                    </Flex>
                                </VStack>
                            </FormControl>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
}
