import React, { memo, useState, useEffect } from "react";
import {
    Portal,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter
} from "@chakra-ui/react";
import { useAppStore } from "store";

function UpdateDisallowedModal() {
    const [ versionError, setVersionError ] = useAppStore(state => [ state.versionError, state.setVersionError ]);

    return <Portal>
        <Modal isOpen={versionError} onClose={() => setVersionError(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update disallowed</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    This screen has been changed by another person.
                    You must reload the screen before you can make your changes.
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={() => {
                        window.location.replace(window.location.pathname + window.location.search + window.location.hash);
                    }}>
                        Reload
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Portal>;
}

export default memo(UpdateDisallowedModal);
