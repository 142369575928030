import React, { useEffect, useState, memo } from "react";

import {
    Box,
    Stack,
    VStack,
    Text,
    Spacer,
    Flex,
    FormControl,
    Button
} from "@chakra-ui/react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
    CheckboxFormik,
    InputFormik,
    SelectFormik,
    DescriptionFormik,
} from "components/fields";
import * as Yup from "yup";
import BoxCard from "components/card/Card";
import {
    IIntegrationProduct,
    useIntegrationProductService,
    CommissionedByChoice,
    YesNoChoice,
    useUserService
} from "services";
import { useAppStore } from "store";
import { getProductDetailsTickValue } from "../default/utils";
import { omit } from "lodash";
import Prompt from "components/Prompt";

const formValidationSchema = Yup.object({
    name: Yup.string()
        .max(200, "Must be at most 200 characters.")
        .nullable(),
    description: Yup.string()
        .nullable(),
    commissioned_by: Yup.string()
        .oneOf([ ...Object.values(CommissionedByChoice), "" ])
        .nullable(),
    commissioned_by_other: Yup.string()
        .when("commissioned_by", {
            is: CommissionedByChoice.OTHER,
            then: (formValidationSchema) => Yup.string().required("This field is required").nullable(),
            otherwise: (formValidationSchema) => Yup.string().nullable()
        })
});

const blankProduct: Partial<IIntegrationProduct> = {
    name: "",
    description: "",
    commissioned_by: null,
    commissioned_by_other: ""
};

function DeveloperProduct() {
    const integrationProductService = useIntegrationProductService();
    const { userData } = useUserService();
    const [ isLoaded, setIsloaded ] = useState(false);
    const [ product, setProduct ] = useState<Partial<IIntegrationProduct>>({});

    useEffect(() => {
        const load = async () => {
            const _products = await integrationProductService
                .getAll({ company: userData.impersonated_company || userData.company });

            // NOTE: for now we choose the first one or create one
            // Perhaps, we'll add a dropdown choice later
            setProduct(
                !_products.length
                    ? { ...blankProduct, company: userData.impersonated_company || userData.company }
                    : _products[0]
            );

            setIsloaded(true);
        };

        load();
    }, []);

    const onSubmit = (
        values: Partial<IIntegrationProduct>,
        { setSubmitting, setErrors, resetForm }: FormikHelpers<Partial<IIntegrationProduct>>
    ) => {
        let errors = {};
        const newValues: any = { ...values };

        Object.entries(newValues).forEach(([ key, value ]) => {
            if (value === "")
                newValues[key] = null;
        });

        if (newValues.id) {
            integrationProductService.update(
                newValues.id,
                { ...newValues, version: product.version },
                { showSuccessMessage: false }
            ).then((_product) => {
                setProduct(_product);
                // update AppStore
                const _productDetailsTick = getProductDetailsTickValue(_product);
                useAppStore.setState({ completeProductDetailsTick: _productDetailsTick });
            }).catch((error) => {
                errors = { ...errors, ...error };
            });
        } else {
            integrationProductService.create(
                newValues,
                { showSuccessMessage: false }
            ).then((_product) => {
                setProduct(_product);
                // update AppStore
                const _productDetailsTick = getProductDetailsTickValue(_product);
                useAppStore.setState({ completeProductDetailsTick: _productDetailsTick });
            }).catch((error) => {
                errors = { ...errors, ...error };
            });
        }

        setSubmitting(false);

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            // Reset Form imperatively, because dirty and isValid are not cleared
            resetForm({ values });
        }
    };

    if (!isLoaded)
        return null;

    const initialValues = omit(product, "version");

    return <Box mt={6}>
        <BoxCard>
            {/* */}
            <Formik<Partial<IIntegrationProduct>>
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
            >
                {(formik: FormikProps<Partial<IIntegrationProduct>>) => (
                    <FormControl
                        as="form"
                        onSubmit={formik.handleSubmit as any}
                    >
                        <Prompt when={formik.dirty} message="You have unsaved data on this page. Want to leave anyway?" />
                        <Box>
                            <Stack gap={2}>
                                <Stack direction={{ base:"column", lg:"row" }}>
                                    <Text>What is the name of your Product?</Text>
                                    <Spacer />
                                    <Stack minW={{ base: "100%", lg: "35%" }} justify="flex-end">
                                        <InputFormik
                                            type="text"
                                            name="name"
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={{ base:"column", lg:"row" }}>
                                    <Text>Who is your customer with regard to funding your service?</Text>
                                    <Spacer />
                                    <Stack w={{ base: "100%", lg: "35%" }}>
                                        <SelectFormik
                                            type="text"
                                            name="commissioned_by"
                                            placeholder="Select option"
                                        >
                                            {Object.values(CommissionedByChoice).map((value) => (
                                                <option key={value} value={value}>{value}</option>
                                            ))}
                                        </SelectFormik>
                                        <Text>If Other please enter details</Text>
                                        <Spacer />
                                        <InputFormik
                                            type="text"
                                            placeholder="Other Details"
                                            name="commissioned_by_other"
                                        />
                                    </Stack>
                                </Stack>
                                <Stack direction={{ base:"column", lg:"row" }}>
                                    <Text>Confirm that your Product is solely for the provision of Direct Patient Care.</Text>
                                    <Spacer />
                                    <Stack w={{ base: "100%", lg: "35%" }}>
                                        <SelectFormik
                                            type="number"
                                            name="direct_patient_care"
                                            placeholder='Select option'
                                        >
                                            <option value={YesNoChoice.no}>No</option>
                                            <option value={YesNoChoice.yes}>Yes</option>
                                        </SelectFormik>
                                    </Stack>
                                </Stack>
                                <Box>
                                    <Text my="4">Which interfaces does your Product use?</Text>
                                    {/* <Spacer /> */}
                                    <VStack alignItems={"left"} ml={10} w={"auto"}>
                                        <CheckboxFormik label="Patient Search (PDS)" name="uses_pds" />
                                        <CheckboxFormik label="Appointments" name="uses_appointments" />
                                        <CheckboxFormik label="Medical Records" name="uses_medical_records" />
                                        <CheckboxFormik label="List Medications" name="uses_medications" />
                                        <CheckboxFormik label="Prescription Ordering" name="uses_prescriptions" />
                                        <CheckboxFormik label="Proxy Access" name="uses_proxy" />s
                                        <CheckboxFormik label="Consultation Summary" name="uses_consultation" />

                                    </VStack>
                                </Box>
                                <Stack>
                                    <Text mt="5">Describe your product / service. Include how it is solely for the purpose of Direct Patient Care; how it supports patients and/or NHS staff and how patient data will be used to deliver your patient facing service.</Text>
                                    <DescriptionFormik
                                        name="description"
                                    />
                                </Stack>
                            </Stack>

                        </Box>

                        <Flex direction={"row"} justifyContent={"end"} mt={5}>
                            <Button
                                variant={"primary"}
                                alignSelf="flex-end"
                                type="submit"
                                isDisabled={!(formik.isValid && formik.dirty)}
                            >
                                Save
                            </Button>
                        </Flex>
                    </FormControl>
                )}
            </Formik>
            {/* */}
        </BoxCard>
    </Box>;
}

export default memo(DeveloperProduct);
