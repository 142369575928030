import React, { memo } from "react";
import { useField } from "formik";
import { useColorModeValue } from "@chakra-ui/system";
import { Box, Flex, FormLabel, Input, Text, Textarea } from "@chakra-ui/react";

const DescriptionFormik: React.FC<any> = ({ label, ...props }) => {
    const [ field, meta, helpers ] = useField(props.name);
    const {
        required,
        placeholder,
        mb
    } = props;
    const id = props.id || props.name;

    const onChangeWrap = (e: any) => {
        helpers.setTouched(true);
        (props.onChange || field.onChange)(e);
    };

    return (
        <Flex direction="column" w="100%">
            <Textarea
                {...field}
                {...props}
                id={id}
                onChange={onChangeWrap}
                required={null}
                isRequired={false}
                ms={{ base: "0px", md: "0px" }}
                placeholder={placeholder}
                fontSize="14px"
                w={props.w ? props.w : "inherit"}
                borderColor={meta.error ? "red" : "inherit"}
                borderRadius="card"
            />
            {meta.touched && meta.error ? (
                <Text as="span" fontSize="text.xs" color={"red"} h="14px">
                    {meta.error}
                </Text>
            ) : null}
        </Flex>
    );
};

export default memo(DescriptionFormik);
