export const semanticStyles = {
    semanticTokens: {
        colors: {
            lightCardBackground: "WHITE", //to be used for cards when light background
            darkCardBackground: "BOD.3", //to be used for cards when dark background
            lightThemeBackground: "themeBackground", //used for background of whole page when light theme
            darkThemeBackground: "BOD.2", //used for background of whole page when dark theme
            lightTableBackground: "WHITE", //used for background of table
            darkTableBackground: "BOD.3", //used for background of table
            contentColor: "BOD.1", //used for content color (table header in light mode, some backgrounds in light mode)
            darkText: "CG.1",
            lightText: "gray.700",
            lightSecondaryText: "gray.500",
            lightTextDark: "BOD.3",
            lightTextDetails: "gray.400",
            darkTextDetails: "secondaryGray.600",
            lightTextBrand: "BOD.5",
            darkTextBrand: "CG.1",
            lightFieldBackground: "WHITE",
            darkFieldBackground: "BOD.1",
            lightFieldBorder: "gray.400",
            darkFieldBorder: "whitealpha.100",
            custom: {
                default: "brand.100",
                _dark: "brand.900",
            },
            themeBG:{
                default: "lightThemeBackground",
                _dark: "darkThemeBackground",
            },
            text:{
                default: "lightText",
                _dark: "darkText",
            },
            textDark: {
                default: "lightTextDark",
                _dark: "darkText",
            },
            secondaryText:{
                default: "lightSecondaryText",
                _dark: "darkText",
            },
            textTable:{
                default: "lightText",
            },
            secondaryTextTable:{
                default: "lightSecondaryText",
            },
            cardBG:{
                default: "lightCardBackground",
                _dark: "darkCardBackground",
            },
            authCardBG:{
                default: "CG.1",
                _dark: "darkCardBackground",
            },
            tableBG:{
                default: "lightTableBackground",
                _dark: "darkTableBackground",
            },
            tableBorderColor: {
                default: "gray.200",
            },
            textDetails:{
                default: "lightTextDetails",
                _dark: "darkTextDetails",
            },
            textBrand:{
                default: "lightTextBrand",
                _dark: "darkTextBrand",
            },
            fieldBG:{
                default: "lightFieldBackground",
                _dark: "darkFieldBackground",
            },
            fieldBorder:{
                default: "lightFieldBorder",
                _dark: "darkFieldBorder",
            },

            //BUTTONS
            primaryButtonBG: {
                default: "BOD.3",
                _dark: "CG.1",
            },
            primaryButtonText: {
                default: "CG.1",
                _dark: "BOD.5",
            },
            secondaryButtonBG: {
                default: "transparent",
                _dark: "transparent",
            },
            secondaryButtonText: {
                default: "BOD.5",
                _dark: "CG.1",
            },
            tertiaryButtonBG: {
                default: "transparent",
                _dark: "transparent",
            },
            tertiaryButtonText: {
                default: "BOD.5",
                _dark: "CG.1",
            },

            //for sidebar
            activeIcon: {
                default: "darkTextBrand",
                _dark: "lightTextBrand",
            },
            activeColor:{
                default: "lightText",
                _dark: "darkText",
            },
            inactiveColor:{
                default: "secondaryGray.600",
            },
            activeBox:{
                default: "lightCardBackground",
                _dark: "darkCardBackground",
            },
            activeBgIcon:{
                default: "darkCardBackground",
                _dark: "lightCardBackground",
            },

            //Menu
            menuBG:{
                default: "darkThemeBackground",
                _dark: "lightThemeBackground",
            },
            menuText:{
                default: "darkText",
                _dark: "lightText",
            },

        }
    },
};
