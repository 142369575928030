// Must match with standard roles created by backend
export enum Role {
    ConvenetUser = "Convenet User",
    Developer = "Developer",
    Custom = "Custom"
}

export interface IRole {
    id: string;
    name: string;
    permissions: number[]; // Or make it UUID[] ?
}

export enum IPermAction {
    View = "view",
    Add = "add",
    Change = "change",
    Delete = "delete",
    Sync = "sync", // Sync Template
    ManageForClone = "manageforclone",
    CancelInvite = "cancelinvite",
}

export interface IPermission {
    id: number;
    name: string;
    codename: string;
    content_type: number;
}
