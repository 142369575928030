import { Box, Button, Flex, Link } from "@chakra-ui/react";
import BoxCard from "components/card/Card";
import React from "react";

const ChatExpert = () => {
    return <Box mt={6}>
        <BoxCard w={"100%"} h="auto">
            <Flex>
                <Link
                    href="mailto:convenet-support@datavant.com"
                    isExternal
                    color="TBO.5">
                    Contact Convenet
                </Link>
            </Flex>
        </BoxCard>
    </Box>;
};

export default ChatExpert;
