import { isEmpty } from "lodash";
import { useEffect, useRef, useCallback } from "react";
import { ApiClientEnv, ICompany, IIntegrationProduct, IntegrationProductStatusChoice, useCompanyService, useFileService, useIntegrationProductService, useUserService } from "services";
import { useProductsService } from "services/billing/products.service";
import { SignUpFeeOptionChoice } from "services/integration-product-review.interface";
import { useAppStore } from "store";
import { PurchasedResponse } from "views/admin/billing/sign-up-fee/SignUpFee";
import { getCheckIsSuitableTickValue, getInformationGovernanceTickValue, getProductDetailsTickValue } from "views/admin/default/utils";

const useTicks = () => {
    const integrationProductService = useIntegrationProductService();
    const companyService = useCompanyService();
    const productService = useProductsService();
    const fileService = useFileService();

    const updateIntegrationProduct = async () => {
        const company = useAppStore.getState().company;
        const integrationProduct = useAppStore.getState().integrationProduct;

        const _integrationProduct = (await integrationProductService
            .getAll({ company: company.id }))[0];
        useAppStore.setState({ integrationProduct: {
            ...integrationProduct,
            ..._integrationProduct
        } });
        useAppStore.setState({ isProductLive: integrationProduct.status === IntegrationProductStatusChoice.live });
        useAppStore.setState({ pentestCompletedTick: _integrationProduct.pentest_completed });
        useAppStore.setState({ nhsApprovalTick: _integrationProduct.nhs_approval });
    };

    const updateInformationGovernanceTick = useCallback(async () => {
        const company = useAppStore.getState().company;
        const integrationProduct = useAppStore.getState().integrationProduct;

        const uploadedfiles = await fileService.getAll({ company: company.id });
        const _informationGovernanceTick: boolean = getInformationGovernanceTickValue(uploadedfiles, company, integrationProduct);
        useAppStore.setState({ informationGovernanceTick: _informationGovernanceTick });
    }, []);

    const updateIsSuitableTick = () => {
        const integrationProduct = useAppStore.getState().integrationProduct;
        const company = useAppStore.getState().company;

        const isSuitable = getCheckIsSuitableTickValue(integrationProduct, company);
        useAppStore.setState({ isSuitable: isSuitable });
    };

    const load = useCallback(async () => {
        const userData = useAppStore.getState().userData;

        const companyId = userData.impersonated_company || userData.company;
        let company = await companyService.get(companyId, { showSuccessMessage: false });
        // set Billing Contact to the current user if no Billing Contact present
        // Note: this will be removed, because the billing_contact_id would be set on company creation
        if (false && !company.billing_contact) {
            company = await companyService.update(company.id, { ...company, billing_contact: userData.id });
        }
        useAppStore.setState({ company: company });

        const _integrationProduct = (await integrationProductService
            .getAll({ company: company.id }))[0];
        useAppStore.setState({ integrationProduct: _integrationProduct });
        useAppStore.setState({ isProductLive: _integrationProduct.status === IntegrationProductStatusChoice.live });
        useAppStore.setState({ pentestCompletedTick: _integrationProduct.pentest_completed });
        useAppStore.setState({ nhsApprovalTick: _integrationProduct.nhs_approval });

        // 1 - Check Convenet is suitable - If all questions are answered Yes then show a green tick
        updateIsSuitableTick();

        // 2 - Generate API Credentials - If the Test credentials have been generated then show a green tick
        const testApiCredentials = company.api_clients?.filter(x => (x.environment === ApiClientEnv.test) && x.is_valid);
        useAppStore.setState({ apiCredentialsGenerated: testApiCredentials && testApiCredentials.length > 0 });

        const liveApiCredentials = company.api_clients?.filter(x => (x.environment === ApiClientEnv.live) && x.is_valid);
        useAppStore.setState({ liveCredentialsGenerated: liveApiCredentials && liveApiCredentials.length > 0 });

        // 3 - When the Sign Up fee has been paid show a green tick
        const _signUpFeeProduct = await productService.getDefaultOneTime();
        const _purchased: PurchasedResponse = await productService.hasPurchasedProduct(_signUpFeeProduct.djstripe_id, _integrationProduct.id);
        useAppStore.setState({ signUpFeePaidTick: _purchased.purchased });

        // update sign fee option waive
        if (_integrationProduct.sign_up_fee_option === SignUpFeeOptionChoice.waive) {
            useAppStore.setState({ signUpFeeWaived: true });
        }

        // 4 - When all the fields on the Product screen have been completed, at least one check box ticked for the
        // "Which interfaces does your Product use" question - show a green tick
        const _productDetailsTick = getProductDetailsTickValue(_integrationProduct);
        useAppStore.setState({ completeProductDetailsTick: _productDetailsTick });

        // 5 - When all the fields have been completed on the Company screen and all the questions with documents or screenshots
        // have something uploaded then if "DCB0129 Status" is Complete show a green tick
        await updateInformationGovernanceTick();
    }, []) ;

    return {
        load,
        updateInformationGovernanceTick,
        updateIntegrationProduct,
        updateIsSuitableTick
    };
};

export default useTicks;
