import React, { memo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Text, Box, Flex, Heading, Link, useColorModeValue } from "@chakra-ui/react";
import { BsCheck2 } from "react-icons/bs";
import { IoEllipsisHorizontalCircle, IoTriangle } from "react-icons/io5";

import BoxCard from "components/card/Card";
import { PageSearchIcon } from "../convenet-integration/FigmaIcons";
import { useAppStore } from "store";

const PrepareForReviewCard = () => {
    const completeProductDetailsTick = useAppStore(state => state.completeProductDetailsTick);
    const signUpFeePaidTick = useAppStore(state => state.signUpFeePaidTick);
    const informationGovernanceTick = useAppStore(state => state.informationGovernanceTick);
    const submitForReviewTick = useAppStore.getState().isSubmittedForReview();
    const isProductLive = useAppStore.getState().isProductLive;

    const textLink = "TBO.5";
    const iconColor = useColorModeValue("BOD.2", "CG.1");
    const arrowIconColor = "TBO.2";
    const iconCheckColor = "#11A04A";
    const iconUncheckColor = "#DBDBDB";

    return <BoxCard mr={{ base: "0", md: "8" }} mb={{ base: "5", md: "0" }} minH="530px" h="100%"  position="relative">
        <Box margin={"1vh auto"}>
            <PageSearchIcon boxSize={24} color={iconColor} />
        </Box>

        <Flex justify="center" align={"center"} direction="column" >
            <Heading fontSize="text.lg" color="textDark" lineHeight="regular" >
            Prepare for Review
            </Heading>
            <Heading fontSize="text.sm" fontWeight="normal" lineHeight="regular"color={textLink}>Stage 2</Heading>
        </Flex>

        <Flex direction={"column"} margin="1em auto" gap={"5"}>
            {/* Complete Product Details */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { completeProductDetailsTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/product"}
                >
                    Complete Product Details
                </Link>
            </Flex>

            <Flex alignItems="center" gap={2} fontSize="text.sm">
                <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/contact-expert"}
                >
                    Chat to an Expert
                </Link>
            </Flex>

            <Flex alignItems="center" gap={2} fontSize="text.sm">
                <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                <Link
                    as={RouterLink}
                    to={"/app/sign-contract"}
                >
                    Sign Contract
                </Link>
            </Flex>

            {/* Sign-up Fee */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { signUpFeePaidTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/billing/sign-up-fee"}
                >
                    Sign-up Fee
                </Link>
            </Flex>

            {/* Complete Information Governance */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { informationGovernanceTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/company"}
                >
                    Complete Information Governance
                </Link>
            </Flex>

            {/* Submit for Review */}
            <Flex alignItems="center" gap={2} fontSize="text.sm">
                { submitForReviewTick
                    ? <BsCheck2 color={iconCheckColor} size="30px"/>
                    : <IoEllipsisHorizontalCircle color={iconUncheckColor} size="30px"/>
                }
                <Link
                    as={RouterLink}
                    to={"/app/convenet-integration/submit"}
                >
                    Submit for Review
                </Link>
            </Flex>
        </Flex>
        <Box position="absolute" color={arrowIconColor} top={{ base: "unset", md:"50%" }} right={{ base:"unset", md:"-23px" }} left={{ base: "49%", md:"unset" }} bottom={{ base:"-18px", md: "unset" }} transform={{ base:"rotate(180deg)", md:"rotate(90deg)" }} >
            <IoTriangle size="15px" rotate="45" opacity={isProductLive ? 1 : 0.3} />
        </Box>
    </BoxCard>;
};

export default memo(PrepareForReviewCard);
