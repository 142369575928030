import React from "react";

//import { format, parseISO, toDate, parse } from "date-fns";
import { format } from "date-fns-tz";

type Props = {
    date: any;
    dateFormat?: string;
    utc?: boolean;
};

// TODO: add more functionalities, study date-fns
const CustomMoment = (props: Props) => {
    const {
        date,
        dateFormat = "dd/MM/yyyy hh:mm aaa",
        utc = false,
    } = props;

    let newDate = "";

    if (date) {
        const dateOptions: Intl.DateTimeFormatOptions = {
            timeZone: "UTC", // convert to, not from
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: dateFormat.toLowerCase().includes("hh") ? "2-digit" : undefined,
            minute: dateFormat.toLowerCase().includes(":mm") ? "2-digit" : undefined,
            hour12: true,
        };

        const _myDate = new Date(date);


        newDate = utc
            ? _myDate.toLocaleDateString(undefined, dateOptions)
            : format(_myDate, dateFormat/*, { timeZone: "UTC" }*/);
    }

    return <>{ newDate }</>;
};

export default CustomMoment;
