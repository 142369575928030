import React, { memo } from "react";
// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
// import SidebarCard from "components/sidebar/components/SidebarCard";
import { IMenuRoute } from "routes";

// FUNCTIONS

function SidebarContent(props: { routes: IMenuRoute[] }) {
    const { routes } = props;
    // SIDEBAR
    return (
        <Flex direction="column" height="100%" p="32px" borderRadius="30px">
            <Brand />
            <Stack direction="column" mb="auto" mt="8px">
                <Box >
                    <Links routes={routes} />
                </Box>
            </Stack>

            {/* <Box
                ps="20px"
                pe={{ md: "16px", "2xl": "0px" }}
                mt="60px"
                mb="40px"
                borderRadius="30px"
            >
                <SidebarCard />
            </Box> */}
        </Flex>
    );
}

export default memo(SidebarContent);
