import { useDisclosure } from "@chakra-ui/react";
import React from "react";

type SidebarCtxObj = {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

export const SidebarContext = React.createContext<SidebarCtxObj>({
    isOpen: false,
    onOpen: () => {},
    onClose: () => {}
});

const SidebarProvider: React.FC<any> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const sidebarCtxValues = { isOpen, onOpen, onClose };

    return (
        <SidebarContext.Provider value={sidebarCtxValues}>
            {props.children}
        </SidebarContext.Provider>
    );
};

export default SidebarProvider;
